import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
    CustomerSubType,
    IShowCitizenFilterViewModel,
    ISkipTakeResultObjectOfShowCitizenModel,
    ShowCitizenModel,
    WeekDays
} from '../../api/services';
import { environment } from '../../environments/environment';
import { check, excel } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import {
    FormBuilderMunicipalityPicker,
    FormBuilderOption,
    FormBuilderSelect,
    FormBuilderTypes
} from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { IDENTITIES, IUserRoles, ROLES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { Helpers } from '../util/helpers';
import { UtilUrlService } from '../util/util-url.service';
import { IUserDefaultMunicipalities } from './municipality.service';
import { CustomerTypePipe } from '../shared/customer-type.pipe';
import { AddressPipe } from '../shared/address.pipe';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatAnchor } from '@angular/material/button';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';
import { NgIf, NgFor } from '@angular/common';

interface IKeyValuePair {
    key: keyof IShowCitizenFilterViewModel;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
}

@Component({
    selector: 'iv-municipality-view-citizens',
    template: `
        <section class="municipality-view-citizens" *ngIf="!isFetching; else fetching">
            <form
                #citizensForm
                [formGroup]="form"
                class="municipality-view-citizens__form alt-theme form"
                autocomplete="off"
            >
                <iv-form-builder-element
                    *ngFor="let input of inputs"
                    [form]="form"
                    [input]="input"
                ></iv-form-builder-element>
            </form>

            <div class="municipality-view-citizens__results">
                <a
                    href="${environment.apiBaseUrl}/api/Citizen/ExportShowCitizens?{{ params }}"
                    target="_blank"
                    class="btn-with-icon btn-with-icon_last"
                    mat-raised-button
                    color="primary"
                >
                    ${Translations.municipality.common.exportToExcel}<span class="icon">${excel}</span>
                </a>

                <div *ngIf="validationErrors" class="municipality-view-citizens__errors">
                    <h4 *ngFor="let error of validationErrors">{{ error }}</h4>
                </div>

                <mat-table #table class="municipality-view-citizens__table" [dataSource]="dataSource">
                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell class="municipality-view-citizens__table-head" *matHeaderCellDef>
                            ${Translations.municipality.viewCitizens.table.name}
                        </mat-header-cell>
                        <mat-cell class="municipality-view-citizens__table-body" *matCellDef="let row">
                            {{ row.firstName }} {{ row.middleName }} {{ row.lastName }}
                        </mat-cell>
                    </ng-container>

                    <!-- Address Column -->
                    <ng-container matColumnDef="address">
                        <mat-header-cell class="municipality-view-citizens__table-head" *matHeaderCellDef>
                            ${Translations.municipality.viewCitizens.table.address}
                        </mat-header-cell>
                        <mat-cell class="municipality-view-citizens__table-body" *matCellDef="let row">
                            {{ row | address }}
                        </mat-cell>
                    </ng-container>

                    <!-- Citizen Column -->
                    <ng-container matColumnDef="customerSubType">
                        <mat-header-cell class="municipality-view-citizens__table-head" *matHeaderCellDef>
                            ${Translations.municipality.viewCitizens.table.typeOfCitizen}
                        </mat-header-cell>
                        <mat-cell class="municipality-view-citizens__table-body" *matCellDef="let row">
                            {{ row.customerSubType | customerType }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell class="municipality-view-citizens__table-head" *matHeaderCellDef>
                            ${Translations.municipality.viewCitizens.table.actions}
                        </mat-header-cell>
                        <mat-cell class="municipality-view-citizens__table-body" *matCellDef="let row">
                            <a [routerLink]="['', { outlets: { admin: ['citizen', row.customerNo, ''] } }]"
                                >${Translations.municipality.viewCitizens.table.chooseCitizen}</a
                            >
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="inactive">
                        <mat-header-cell class="municipality-view-citizens__table-head" *matHeaderCellDef>
                            ${Translations.municipality.viewCitizens.table.inactive}
                        </mat-header-cell>
                        <mat-cell class="municipality-view-citizens__table-body" *matCellDef="let row">
                            <i class="icon" *ngIf="!row.active">${check}</i>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>

                <div class="municipality-view-citizens__loading" *ngIf="isLoadingResults">
                    <mat-progress-spinner
                        color="accent"
                        mode="indeterminate"
                        [strokeWidth]="2"
                        [diameter]="30"
                    ></mat-progress-spinner>
                </div>

                <div class="municipality-view-citizens__results-empty" *ngIf="haveNoResults && !isLoadingResults">
                    ${Translations.municipality.common.noResults}
                </div>

                <mat-paginator
                    [length]="numOfResults"
                    [pageIndex]="pageIndex"
                    [pageSize]="pageSize"
                    (page)="onPaginatorChange($event)"
                    [pageSizeOptions]="pageSizeOptions"
                    [showFirstLastButtons]="true"
                ></mat-paginator>
            </div>
        </section>

        <ng-template #fetching>
            <div class="municipality-view-citizens__fetching center-content alt-theme">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="3"
                    [diameter]="60"
                ></mat-progress-spinner>
            </div>
        </ng-template>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, FormBuilderElementComponent, MatAnchor, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, RouterLink, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatProgressSpinner, MatPaginator, AddressPipe, CustomerTypePipe]
})
export class MunicipalityViewCitizensComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    inputs: FormBuilderTypes[];

    isLoadingResults = false;
    isFetching = true;
    haveNoResults = false;

    numOfResults = 0;

    displayedColumns: string[] = ['name', 'address', 'customerSubType'];
    dataSource = new MatTableDataSource<ShowCitizenModel>();
    validationErrors: string[] = [];

    showCitizens: ISkipTakeResultObjectOfShowCitizenModel;

    pageSizeOptions = [10, 50, 100];
    pageIndex = 0;
    pageSize = this.pageSizeOptions[0];

    citizenTypes: FormBuilderOption[];
    weekDays: FormBuilderOption[] = [];

    filterShowCitizen: IShowCitizenFilterViewModel = {
        municipalities: [],
        districts: [],
        subDistricts: [],
        dayOfWeekNo: 0,
        customerSubTypes: [],
        skip: 0,
        take: this.pageSize
    };

    private _filterShowCitizen = new BehaviorSubject<IShowCitizenFilterViewModel>(this.filterShowCitizen);

    private unsubscribe = new Subject<void>();

    params: string;

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private citizenService: CitizenService,
        private formBuilder: FormBuilderService,
        private urlService: UtilUrlService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.route.snapshot.queryParamMap.has('take')) {
            this.pageSize = +(this.route.snapshot.queryParamMap.get('take') || this.pageSizeOptions[0]);
            this.filterShowCitizen.take = this.pageSize;
        }

        if (this.route.snapshot.queryParamMap.has('skip')) {
            this.filterShowCitizen.skip = +(this.route.snapshot.queryParamMap.get('skip') || 0);
        }

        // Fill the Form select options with the citizen types
        this.citizenTypes = Object.keys(Translations.global.citizenTypes).map((key: string) => {
            return { label: Translations.global.citizenTypes[key], value: CustomerSubType[key] };
        });

        // Mapping the WeekDays
        this.weekDays = Object.keys(Translations.global.weekDays).map((key: string) => {
            const weekDayNumber = WeekDays[Helpers.capitalizeFirstLetter(key)];
            return { label: Translations.global.weekDays[key], value: weekDayNumber };
        });

        // Add the default value, if we want not have any filter by week day;
        this.weekDays.unshift({ label: '', value: '0' });

        // Get the user roles
        this.userService.user$
            .pipe(
                map(user => {
                    const defaultMunicipalites: IUserDefaultMunicipalities = {
                        municipality: 0,
                        districts: [],
                        subdistricts: []
                    };

                    if (user && user.roles) {
                        const userRoles = user.roles as IUserRoles[];
                        if (userRoles.includes(ROLES.MunicipalityAdmin)) {
                            defaultMunicipalites.municipality = user.municipalities ? user.municipalities[0] : 0;
                            defaultMunicipalites.districts = user.districts ? user.districts : 0;
                            defaultMunicipalites.subdistricts = user.subDistricts ? user.subDistricts : [];
                        }

                        if (userRoles.some(x => IDENTITIES.adminAndCustomerService.includes(x))) {
                            this.displayedColumns.push('actions');
                        }
                    }

                    // Add the information if the citizen is active or not
                    this.displayedColumns.push('inactive');

                    return defaultMunicipalites;
                }),
                first(),
                takeUntil(this.unsubscribe)
            )
            .subscribe(results => {
                // Check if we have query params and fill or not the form;
                this._fillForm(results);
            });

        this._filterShowCitizen
            .pipe(
                tap(filter => {
                    this.dataSource.data = [];
                    this.isLoadingResults = true;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const params: any = {};

                    Object.entries(filter).forEach(x => {
                        if (x[1] && (Array.isArray(x[1]) ? x[1].length : x[1] > 0)) {
                            params[x[0]] = x[1];
                        }
                    });

                    const httpParams = new HttpParams({ fromObject: params });
                    this.params = new HttpParams({ fromObject: params }).toString();

                    this.urlService.setState(httpParams);
                }),
                switchMap(filter => this.citizenService.showCitizens(filter)),
                map(result => {
                    this.numOfResults = result.numFound;
                const results = result.results || [];
                return results;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                results => {
                    this.isLoadingResults = false;
                    this.haveNoResults = false;
                    this.isFetching = false;

                    this.dataSource.data = results;

                    this.pageIndex =
                        this.filterShowCitizen.skip > 0 ? this.filterShowCitizen.skip / this.filterShowCitizen.take : 0;

                    if (results.length === 0) {
                        this.haveNoResults = true;
                    }
                    this.cd.markForCheck();
                },
                err => this.handleWithErrors(err)
            );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onPaginatorChange(event: PageEvent) {
        const settingsArray: IKeyValuePair[] = [];
        if (event.pageIndex * this.filterShowCitizen.take !== this.filterShowCitizen.skip) {
            settingsArray.push({ key: 'skip', value: event.pageIndex * this.filterShowCitizen.take });
        }
        if (event.pageSize !== this.filterShowCitizen.take) {
            settingsArray.push({ key: 'take', value: event.pageSize });
        }

        this._updateShowCitizenFilter(settingsArray);
    }

    private handleWithErrors(err: IntervareHttpErrorResponse) {
        this.validationErrors = [];

        if (err.validationErrors && err.validationErrors.length > 0) {
            this.validationErrors = err.validationErrors;
        } else {
            this.validationErrors.push(err.error.statusText);
        }

        this.isLoadingResults = false;
        this.dataSource.data = [];
        this.cd.markForCheck();
    }

    private _updateShowCitizenFilter(values: IKeyValuePair[]) {
        values.forEach(keyValue => {
            this.filterShowCitizen[keyValue.key] = keyValue.value;
        });
        this._filterShowCitizen.next(this.filterShowCitizen);
    }

    private _fillForm(results: IUserDefaultMunicipalities) {
        const queryParams = this.route.snapshot.queryParams;

        // Build the form
        if (!this._isEmptyObject(queryParams)) {

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const params: any = {};

            params.municipalities = queryParams.municipalities;

            if (queryParams.districts) {
                if (typeof queryParams.districts === 'string') {
                    params.districts = [queryParams.districts];
                } else {
                    params.districts = queryParams.districts;
                }
            }

            if (queryParams.subDistricts) {
                if (typeof queryParams.subDistricts === 'string') {
                    params.subdistricts = [queryParams.subDistricts];
                } else {
                    params.subdistricts = queryParams.subDistricts;
                }
            }

            if (queryParams.dayOfWeekNo) {
                params.dayOfWeekNo = queryParams.dayOfWeekNo;
            }

            if (queryParams.customerSubTypes) {
                params.customerSubTypes = queryParams.customerSubTypes;
            }

            this._buildForm(params);
        } else {
            this._buildForm(results);
        }

        this._watchForChanges();

        this.form.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _buildForm(params?: any) {
        const roles = this.userService.user$.getValue()!.roles as IUserRoles[];

        this.inputs = [
            new FormBuilderMunicipalityPicker({
                name: 'districts',
                multipleDistricts: true,
                multipleSubdistricts: true,
                allowEmptyValue: roles.some(x => IDENTITIES.adminAndCustomerService.includes(x)),
                required: false,
                value: {
                    municipality: params && params.municipalities ? Number(params.municipalities) : 0,
                    districts:
                        params && params.districts && params.districts.length
                            ? Array.isArray(params.districts)
                                ? params.districts.map((id: string) => Number(id))
                                : [+params.districts]
                            : 0,
                    subdistricts:
                        params && params.subdistricts
                            ? Array.isArray(params.subdistricts)
                                ? params.subdistricts.map((id: string) => Number(id))
                                : [+params.subdistricts]
                            : 0
                }
            }),
            new FormBuilderSelect({
                name: 'dayOfWeekNo',
                label: Translations.municipality.citizenOverview.dayOfWeekNo,
                required: false,
                value: params && params.dayOfWeekNo ? Number(params.dayOfWeekNo) : '',
                options: this.weekDays
            }),
            new FormBuilderSelect({
                name: 'customerSubTypes',
                label: Translations.municipality.citizenOverview.typeOfCitizen,
                required: false,
                multiple: true,
                value:
                    params && params.customerSubTypes
                        ? Array.isArray(params.customerSubTypes)
                            ? params.customerSubTypes.map((id: string) => Number(id))
                            : [+params.customerSubTypes]
                        : 0,
                options: this.citizenTypes
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }

    private _watchForChanges() {
        let firstChange = true;

        // Subscribe the changes to the form values;
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(changes => {
            // Get Data from form
            if (changes.districts) {
                this.filterShowCitizen.municipalities = changes.districts.municipality;
                this.filterShowCitizen.districts = changes.districts.districts;
                this.filterShowCitizen.subDistricts = changes.districts.subdistricts;
            }

            if (changes.dayOfWeekNo) {
                this.filterShowCitizen.dayOfWeekNo = changes.dayOfWeekNo;
            }

            if (changes.customerSubTypes) {
                this.filterShowCitizen.customerSubTypes = changes.customerSubTypes;
            }

            if (!firstChange) {
                this.filterShowCitizen.skip = 0;
            }

            firstChange = false;

            this._filterShowCitizen.next(this.filterShowCitizen);
        });
    }

    private _isEmptyObject(value: object): boolean {
        return Object.keys(value).length === 0 && value.constructor === Object;
    }
}
