import { isPlatformBrowser, isPlatformServer, DatePipe } from '@angular/common';
import { inject, Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, takeUntil } from 'rxjs/operators';
import { Helpers } from './helpers';

export interface IScreen {
    width: number;
    height: number;
}

export enum Breakpoints {
    Mobile = 567,
    Tablet = 1024
}

type DeviceType = 'desktop' | 'tablet' | 'mobile';

@Injectable()
export class UtilService implements OnDestroy{
    private unsubscribe = new Subject<void>();
    private datePipe = inject(DatePipe);
    private _size$: BehaviorSubject<IScreen> = new BehaviorSubject(this.getSize());
    size$: Observable<IScreen> = this._size$.asObservable().pipe(debounceTime(50));

    deviceType$ = new BehaviorSubject<DeviceType>('mobile');

    private _scrollbarWidth: BehaviorSubject<number> = new BehaviorSubject(this._getScrollbarWidth());
    scrollbarWidth$ = this._scrollbarWidth.asObservable();

    constructor( @Inject(PLATFORM_ID) private platformId: object) {
        this._size$.pipe(
            map<IScreen, DeviceType>(size => {
                if (size.width <= Breakpoints.Mobile) {
                    return 'mobile';
                }
                if (size.width <= Breakpoints.Tablet) {
                    return 'tablet';
                }
                return 'desktop';
            }),
            distinctUntilChanged(),
            takeUntil(this.unsubscribe)
        ).subscribe(device => this.deviceType$.next(device));

        this._size$.asObservable().pipe(
            skip(1),
            debounceTime(200),
            takeUntil(this.unsubscribe)
        ).subscribe(() => {
            this._scrollbarWidth.next(this._getScrollbarWidth());
        });

        if (this.isBrowser()) {
            window.addEventListener('resize', e => {
                this._size$.next(this.getSize());
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    isServer(): boolean {
        return isPlatformServer(this.platformId);
    }

    // Element parameter should be of type HTMLElement or Window
    // Typescript has a bug on union type and overloaded functions
    // Hence the any
    // Issue ref: https://github.com/Microsoft/TypeScript/issues/1805
    // Try catch necessary because of Samsung native browser not supporting ScrollToOptions
    scrollElementTo(element: HTMLElement | Window, y: number, smooth = true) {
        if (this.isBrowser()) {
            const ele = element as HTMLElement;
            try {

                // condition to check the IE11 version, because throw an error when try to scroll up;
                if (this.getIEVersion() !== 11) {
                    ele.scrollTo({ top: y, left: 0, behavior: smooth ? 'smooth' : 'auto' });
                } else {
                    ele.scrollTop = 0;
                }
            } catch (e) {
                ele.scrollTo(y, 0);
            }
        }
    }

    dateToString(date: Date) {
        const dateString = this.datePipe.transform(Helpers.localToUtcDate(date), 'yyyy-MM-dd');
        if (dateString) {
            return dateString;
        }
        return '';
    }

    // https://jsfiddle.net/jquerybyexample/gk7xA/
    getIEVersion() {
        const sAgent = window.navigator.userAgent;
        const idx = sAgent.indexOf('MSIE');

        // If IE, return version number.
        if (idx > 0) {
            return parseInt(sAgent.substring(idx + 5, sAgent.indexOf('.', idx)));
        }

        // If IE 11 then look for Updated user agent string.
        else if (!!navigator.userAgent.match(/Trident\/7\./)) {
            return 11;
        } else {
            // It is not IE
            return 0;
        }
    }

    public weeksInYear(year: number) {
        const d = new Date(year, 11, 31);
        const week = this.getNumberOfWeek(d);
        return week === 1 ? 52 : week;
    }

    private getNumberOfWeek(date: Date) {
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + 3 - (date.getDay() + 7) % 7);
        const firstWeek = new Date(date.getFullYear(), 0, 4);
        return 1 + Math.round(((date.getTime() - firstWeek.getTime()) / 86400000 - 3 + (firstWeek.getDay() + 7) % 7) / 7);
    }

    private _getScrollbarWidth() {
        if (this.isBrowser()) {
            const div = document.createElement('div');
            div.style.overflowY = 'scroll';
            div.style.position = 'absolute';
            document.body.appendChild(div);
            const width = div.offsetWidth;
            document.body.removeChild(div);

            return width;
        }

        return 0;
    }

    private getSize() {
        return this.isServer()
            ? { width: 375, height: 667 } // default/SSR response is a mobile viewport (e.g. iPhone 6)
            : { width: window.innerWidth, height: window.innerHeight };
    }
}
