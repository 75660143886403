import { HttpErrorResponse } from '@angular/common/http';
import { HttpEventType, HttpUserEvent } from '@angular/common/http';

import { ValidationResult } from '../../api/services';
import { environment } from '../../environments/environment';

export class IntervareHttpErrorResponse extends ValidationResult implements HttpUserEvent<any> {
    type: HttpEventType.User;
    validationErrors: string[] = [];
    error: HttpErrorResponse;
}

export class BaseService {

    /**
     * Prepends the base URL of the API (environment specific) to the specified addon
     *
     * @param {string} addon
     * @returns {string}
     * @memberof BaseService
     */
    apiBaseUrl(addon: string): string {
        return environment.apiBaseUrl + '/api' + addon;
    }
}
