import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { catchError, first, map, retry, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
    IChangePasswordModel,
    ICustomerToImpersonate,
    IForgotPasswordViewModel,
    ILoginCredentialsModel,
    IResetPasswordViewModel,
    IUserViewModel,
    UserViewModel
} from '../../api/services';
import { DialogService } from '../shared/dialog.service';
import { UserService } from '../user/user.service';
import { BaseService, IntervareHttpErrorResponse } from './base-service';
import { GenesysService } from './genesys/genesys.service';
import { CallsService } from './calls/calls.service';

@Injectable()
export class AuthService extends BaseService implements OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();

    /**
     * Login a user and get the resulting {IUserViewModel} back
     *
     * @param {ILoginCredentialsModel} loginCredentials
     * @returns {Observable<IUserViewModel>}
     * @throws {IntervareHttpErrorResponse}
     * @memberof UserService
     */
    login(loginCredentials: ILoginCredentialsModel): Observable<IUserViewModel> {
        return this.http.post(this.apiBaseUrl('/Auth/Login'), loginCredentials).pipe(
            map(UserViewModel.fromJS),
            tap(user => this.userService.setUser(user)), // <-- Notify the global user service about the incoming UserViewModel
            tap(user => this.callsService.init(user))
        );
    }

    /**
     * Logout the user
     *
     * @returns {Observable<boolean>}
     */
    logout(): Observable<boolean> {
        this.genesysService.disconnect();
        this.callsService.disconnect();

        return this.http.post(this.apiBaseUrl('/Auth/Logout'), {}).pipe(
            retry(1), // <-- The request might not go through the first time in some rare cases
            map(() => true),
            catchError(() => of(false)), // <-- Overwrite any errors to false
            tap(res => {
                if (res) {
                    // Notify the global user service, if the user was succesfully logged out
                    this.userService.setUser(undefined);
                }
            })
        );
    }

    /**
     * Checks if a user is logged in and thereby has an active session
     *
     * @returns {Observable<boolean>}
     */
    isLoggedIn(): Observable<boolean> {
        return this.http.get(this.apiBaseUrl('/Auth/IsLoggedIn')).pipe(
            map(x => !!x),
            tap(res => {
                if (!res) {
                    this.userService.setUser(undefined);
                }
            })
        );
    }

    /**
     * Gets the current logged in user
     *
     * @returns {Observable<IUserViewModel>}
     * @memberof AuthService
     */
    getCurrentUser(callRecordId?: number): Observable<IUserViewModel> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const params: any = {};
        if (callRecordId) {
            params.callRecordId = callRecordId;
        }
        return this.http.get(this.apiBaseUrl('/Auth/GetCurrentUser'), { params }).pipe(
            map(UserViewModel.fromJS),
            tap(user => this.userService.setUser(user)),
            tap(user => this.genesysService.init(user)),
            tap(user => this.callsService.init(user))
        );
    }

    /**
     * Sends an e-mail to the provided e-mail address with a token link to create a new password
     *
     * @param {{email: string}} forgotObj
     * @returns {Observable<boolean>}
     * @memberof AuthService
     */
    forgotPassword(forgotObj: IForgotPasswordViewModel): Observable<boolean> {
        return this.http.post(this.apiBaseUrl('/Auth/ForgotPassword'), forgotObj).pipe(
            map(() => true),
            catchError(() => of(true))
        );
    }

    /**
     * Resets the password of a user using a token
     *
     * @param {{userId: string, password: string, confirmPassword: string, token: string}} resetUserObj
     * @returns {Observable<boolean>}
     * @memberof AuthService
     */
    resetPassword(resetUserObj: IResetPasswordViewModel): Observable<string> {
        return this.http.post<string>(this.apiBaseUrl('/Auth/ResetPassword'), resetUserObj);
    }

    putResetPassword(loginCredentials: ILoginCredentialsModel): Observable<boolean> {
        return this.http.put(this.apiBaseUrl('/Auth/ResetPassword'), loginCredentials).pipe(map(() => true));
    }

    /**
     * Change the password of a user
     *
     * @param {IChangePasswordModel} data
     * @returns {Observable<boolean>}
     * @memberof AuthService
     */
    changePassword(data: IChangePasswordModel): Observable<IUserViewModel> {
        return this.http
            .put(this.apiBaseUrl('/Auth/ChangePassword'), data)
            .pipe(switchMap(() => this.getCurrentUser()));
    }

    /**
     * Switch to a specified citizen
     *
     * @param {ICustomerToImpersonate} data
     * @param {number} [callRecordId]
     * @returns {(Observable<IUserViewModel>)}
     * @memberof AuthService
     */
    switchToCitizen(data: ICustomerToImpersonate, callRecordId?: number): Observable<IUserViewModel | boolean> {
        return this.http.put<boolean>(this.apiBaseUrl('/Auth/SwitchToCitizen'), data).pipe(
            catchError((err: IntervareHttpErrorResponse) => {
                this.dialogService.showValidationResult(err.validationErrors);
                return EMPTY;
            }),
            switchMap(res => (res ? this.getCurrentUser(callRecordId).pipe(first()) : of(false)))
        );
    }

    init(): void {
        this.isLoggedIn()
            .pipe(
                switchMap(isLoggedIn => (isLoggedIn ? this.getCurrentUser() : of(undefined))),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private dialogService: DialogService,
        private genesysService: GenesysService,
        private callsService: CallsService
    ) {
        super();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
