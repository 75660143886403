import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
    standalone: true
})
export class HighlightPipe implements PipeTransform {
    transform(value: string | undefined, query: string): string | undefined {
        if (value) {
            const queryArray = query.split(' ').sort((a, b) => b.length - a.length);

            queryArray.forEach(x => {
                value = value!.replace(new RegExp('(' + x + ')(?![^<]*>|[^<>]*</)', 'gi'), (match: string) => {
                    return '<em>' + match + '</em>';
                });
            });

            return value;
        }
        return;
    }
}
