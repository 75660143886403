import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './base-service';
import { Observable, ReplaySubject } from 'rxjs';

export interface IChangeDeliveryDay {
    AlternativeDeliveryDateId: number;
    From: Date;
    To: Date;
    OriginalOrderDate: Date;
    DestinationOrderDate: Date;
    LastModifiedBy?: string | undefined;
    CreateDate: Date;
    LastModifiedDate: Date;
}

export interface IUpsertAlternativeDeliveryDay {
    alternativeDeliveryDateId: number;
    from: Date | string;
    to: Date | string;
    originalOrderDate: Date | string;
    destinationOrderDate: Date | string;
}

@Injectable()
export class AdministrationDeliveryService extends BaseService {

    actualDeliveryList$ = new ReplaySubject<IChangeDeliveryDay[]>(1);

    constructor(
        private http: HttpClient
    ) {
        super();
    }

    getDeliveryList(historyMode: boolean = false): Observable<IChangeDeliveryDay[]> {
        const params = new HttpParams().set('historyMode', historyMode.toString());
        return this.http.get<IChangeDeliveryDay[]>(this.apiBaseUrl('/AlternativeDeliveryDays/GetAlternativeDeliveryDays'), { params });
    }

    addDelivery(dates: IUpsertAlternativeDeliveryDay): Observable<IChangeDeliveryDay> {
        return this.http.post<IChangeDeliveryDay>(this.apiBaseUrl('/AlternativeDeliveryDays/UpsertChangedDeliveryDay'), dates);
    }

    deleteDelivery(deliveryId: string): Observable<boolean> {
        const params = new HttpParams({
            fromObject: {
                id: deliveryId
            }
        });
        return this.http.delete<boolean>(this.apiBaseUrl('/AlternativeDeliveryDays/DeleteAlternativeDay'), { params });
    }
}
