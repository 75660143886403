import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';

import {search} from '../../scripts/generated/icons';
import {Translations} from '../../translations/translations';
import {CommerceBasketCatalogItemDialogComponent} from '../commerce/commerce-basket-catalog-item-dialog.component';
import {ProductService} from '../services/product.service';
import {DialogService} from '../shared/dialog.service';
import {ElementRenderer} from '../shared/element-renderer.service';
import {RouteService} from '../shared/route.service';
import {IDENTITIES} from '../user/user-roles';
import {UserService} from '../user/user.service';
import {UtilService} from '../util/util.service';
import {Suggestions} from './search-models';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

interface IBaseSuggestion {
    title: string;
    extraClass: string;
}

interface ISearchSuggestion extends IBaseSuggestion {
    keyword: string;
}

interface ICategorySuggestion extends IBaseSuggestion {
    url: string;
}

type ISuggestion = ISearchSuggestion | ICategorySuggestion;

@Component({
    selector: 'iv-search-field',
    template: `
        <div role="search" class="search-field">
            <input
                class="search-field__input"
                type="text"
                placeholder="${Translations.search.field.placeholder}"
                tabindex="1"
                #searchField
                [(ngModel)]="term"
                (ngModelChange)="onInput($event)"
                (keydown.enter)="onEnter($event)"
                (keydown.arrowdown)="incrementSuggestion($event)"
                (keydown.arrowup)="decrementSuggestion($event)"
                (window:keydown.shift.control.f)="setFocus()"
                (focus)="onFocus()"
                (blur)="onBlur()">
            <div class="search-field__icon" aria-hidden="true" *ngIf="!isSearching">${search}</div>
            <mat-progress-spinner
                class="search-field__spinner"
                color="accent"
                [diameter]="20"
                [strokeWidth]="3"
                mode="indeterminate"
                *ngIf="isSearching"
            ></mat-progress-spinner>
            <div class="search-field__suggestions" *ngIf="showSuggestions && suggestions.length" aria-hidden="true">
                <ul class="search-field__suggestions-container">
                    <li
                        class="search-field__suggestions-item {{ suggestion.extraClass }}"
                        *ngFor="let suggestion of suggestions; trackBy: trackById; let i = index"
                        [class.active]="currentSuggestion === i"
                        (click)="onClickSuggestion(suggestion)"
                        (mouseleave)="currentSuggestion = -1"
                        [innerHTML]="suggestion.title">
                    </li>
                </ul>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, NgIf, MatProgressSpinner, NgFor]
})
export class SearchFieldComponent implements OnInit, OnDestroy {
    @ViewChild('searchField', { static: true }) searchField: ElementRef;

    isSearching = false;
    term: string;
    suggestions: ISuggestion[] = [];
    showSuggestions = false;
    currentSuggestion = -1;
    quickOrder = false;

    private timeout: number;
    private shouldHaveFocus = true;
    private shouldShowSuggestions = false;
    private productExists = false;
    private enterWasPressed = false;
    private lastTerm?: string | null;
    private searchQueries = new Subject<string>();
    private unsubscribe = new Subject<void>();

    @HostListener('document:mousemove') onMouseMove() {
        if (this.showSuggestions) {
            this.tickingCloseSuggestion();
        }
    }
    @HostListener('document:keypress') onkeypress() {
        this.enterWasPressed = false;
        if (this.showSuggestions) {
            this.tickingCloseSuggestion();
        }
    }

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private routeService: RouteService,
        private productService: ProductService,
        private utilService: UtilService,
        private dialogService: DialogService,
        private renderer: ElementRenderer,
        private userService: UserService
    ) {}

    ngOnInit() {

        // condition to check the IE11 version, because the 200ms debounceTime is not enough to trigger the router changes terms
        // with the performance to write normally
        let waitTime = 200;
        if (this.utilService.getIEVersion() === 11) {
            waitTime = 2500;
        }

        this.routeService.activeRoute$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(snapshot => {
            this.term = snapshot.paramMap.get('term') || '';
            if (this.lastTerm !== this.term) {
                this.lastTerm = this.term;
            }
        });

        this.productService.searchInFocus.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => {
            this.shouldHaveFocus = true;
            this.setFocus();
        });

        combineLatest([
            this.utilService.deviceType$,
            this.userService.isLoggedIn
        ]).pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(data => this.shouldShowSuggestions = data[0] !== 'mobile' && data[1]);

        this.searchQueries.pipe(
            debounceTime(waitTime),
            distinctUntilChanged(),
            takeUntil(this.unsubscribe),
        ).subscribe(term => this._navigateToSearch(term));

        this.searchQueries.pipe(
            debounceTime(waitTime),
            distinctUntilChanged(),
            switchMap(term =>
                combineLatest(
                    [this._suggestions(term), this.productService.productExists, this.productService.isSearching]
                )
            ),
            takeUntil(this.unsubscribe),
        ).subscribe(([suggestionsVm, productExists, isSearching]) => {
            this.suggestions.splice(0);
            this.suggestions = this.suggestions.concat(suggestionsVm.suggestions.map<ISearchSuggestion>(x => ({ title: x, keyword: x.replace('<em>', '').replace('</em>', ''), extraClass: 'search' })));
            this.suggestions = this.suggestions.concat(suggestionsVm.categories.map<ICategorySuggestion>(x => ({ title: Translations.search.suggestions.goToCategory + ' <em>' + x.name + '</em>', url: x.url, extraClass: 'category' })));

            this.productExists = productExists;
            this.isSearching = isSearching;

            // this.showDialogs();

            // if the enter key was pressed we will open the dialog to add the quick order
            if (this.enterWasPressed) {
                this.enterWasPressed = false;
                this.openQuickOrderDialog();
            }

            this.cd.detectChanges();
        });

        this.productService.productExists
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(productExist => this.productExists = productExist);

        this.productService.isSearching
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(isSearching => this.isSearching = isSearching);

        this.userService.activeRoles.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(roles => {
            this.quickOrder = roles.some(x => IDENTITIES.customerCenter.includes(x));
            this.shouldHaveFocus = true;

            this.closeSuggestions();
            this.setFocus();
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onInput(term: string) {

        if (this.lastTerm === term) {
            this.productService.isSearching.next(false);
            return;
        }

        this.lastTerm = term;
        this.productExists = false;
        this.productService.isSearching.next(true);

        if (this.utilService.getIEVersion() === 11) {

            // check if the term is not empty, because on IE is trigerring the change route
            if (term && term.length >= 0) {
                this.showSuggestions = this.shouldShowSuggestions;
                this.searchQueries.next(term);
                this.tickingCloseSuggestion();
            } else {
                this.showSuggestions = false;
                this.cd.markForCheck();
            }

        } else {

            this.showSuggestions = this.shouldShowSuggestions;
            this.searchQueries.next(term);
            this.tickingCloseSuggestion();
            this.cd.markForCheck();

        }

    }

    onEnter(event: KeyboardEvent) {
        event.stopPropagation();

        if (this.currentSuggestion > -1) {
            const suggestion = this.suggestions[this.currentSuggestion];
            this.onClickSuggestion(suggestion);
        } else if (/^[\d]{4,}$/.test(this.term) && this.quickOrder) {
            // Remove focus from target element to avoid opening dialog twice when pressing enter
            (event.target as HTMLInputElement).blur();

            // if the user hit the enter we will save the key that was pressed
            // because we need to wait for the searching
            this.enterWasPressed = true;

            // if (!this.isSearching) {
            //     this.showDialogs();
            // }
            //
            // this.setFocus();

            // if still searching we should wait until the search over
            if (!this.isSearching) {

                this.openQuickOrderDialog();
                // because the overlay is already opened we reset the flag;
                this.enterWasPressed = false;
            }
        } else {
            this.productService.focusFirstProduct();
        }

        return false;
    }

    onFocus() {
        if (this.term === '') {
            this.suggestions = [];
        }
        this.showSuggestions = this.shouldShowSuggestions;

        // when focus the shouldHaveFocus should be true to select all the field and keep it ready
        // to be updated
        this.shouldHaveFocus = true;
        this.setFocus();
    }

    onBlur() {
        if (this.utilService.isBrowser()) {
            // Hide the suggestions after timeout (to allow click events to propagate)
            window.setTimeout(() => this.closeSuggestions(), 200);
        }
    }

    onClickSuggestion(suggestion: ISuggestion) {
        if (this.isCategorySuggestion(suggestion)) {
            this.router.navigate([suggestion.url]);
        } else {
            this.term = suggestion.keyword;
            this.onInput(this.term);
        }
        this.closeSuggestions();
    }

    incrementSuggestion(event: KeyboardEvent) {
        event.stopPropagation();
        if (this.suggestions && this.currentSuggestion < this.suggestions.length - 1) {
            this.currentSuggestion++;
        }
        return false;
    }

    decrementSuggestion(event: KeyboardEvent) {
        event.stopPropagation();
        if (this.currentSuggestion > -1) {
            this.currentSuggestion--;
        }
        return false;
    }

    closeSuggestions() {
        this.suggestions = [];
        this.showSuggestions = false;
        this.currentSuggestion = -1;

        this.cd.markForCheck();
    }

    setFocus() {
        if (this.shouldHaveFocus) {

            this.renderer.invokeElementMethod(this.searchField, 'focus');

            setTimeout(() => {

                // this was added to fix the selection Range issue on safari mobile
                // https://stackoverflow.com/questions/3272089/programmatically-selecting-text-in-an-input-field-on-ios-devices-mobile-safari#comment60319255_6302507
                this.renderer.invokeElementMethod(this.searchField, 'setSelectionRange', [0, this.searchField.nativeElement.value.length]);
                this.cd.markForCheck();
            }, 0);

        }
    }

    isCategorySuggestion(suggestion: ISuggestion): suggestion is ICategorySuggestion {
        return !!(suggestion as ICategorySuggestion).url;
    }

    trackById(index: number) {
        return index;
    }

    private tickingCloseSuggestion() {

        if (this.utilService.isBrowser()) {

            this.clearTickingCloseSuggestion();

            this.timeout = window.setTimeout(() => {
                this.closeSuggestions();
            }, 3000);

        }

    }

    private openQuickOrderDialog(): void {
        // Catalog item no dialog
        this.dialogService.openDialogWithComponent(CommerceBasketCatalogItemDialogComponent, {
            autoFocus: false,
            position: { top: '1rem' },
            data: this.term
        }).afterClosed().subscribe(() => this.setFocus());
    }

    private openNoProductExistDialog(): void {
        this.dialogService.showMessage(Translations.replaceTokens(Translations.search.noExistingProduct, [this.term]))
            .afterClosed()
            .subscribe(() => this.setFocus());
    }

    private clearTickingCloseSuggestion() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    private _navigateToSearch(term: string): void {
        this.router.navigate([Translations.shop.paths.searchPage.path, term]);
    }

    private _suggestions(term: string): Observable<Suggestions> {
        if (!this.shouldShowSuggestions) {
            return of(new Suggestions());
        }

        return this.productService.getSuggestions(term).pipe(
            takeUntil(this.unsubscribe),
            map(Suggestions.fromDto)
        );
    }

    // TODO: Use this to verify if the product exists before open "quantity" dialog
    // TODO: Disabled for now since it's generating more issues regarding dialogs
    // private showDialogs(): void {
    //     // if still searching we should wait until the search over & verifying if the product exists
    //     if (this.enterWasPressed && (this.productExists && !this.isSearching)) {
    //         this.openQuickOrderDialog();
    //         // because the overlay is already opened we reset the flag;
    //         this.enterWasPressed = false;
    //         return;
    //     }
    //
    //     if (this.enterWasPressed && (!this.productExists && !this.isSearching)) {
    //         this.openNoProductExistDialog();
    //         // because the overlay is already opened we reset the flag;
    //         this.enterWasPressed = false;
    //         return;
    //     }
    // }
}
