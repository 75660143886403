import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Translations } from '../../translations/translations';
import { CitizenDetailsCreditnotesComponent } from '../citizen/citizen-details-creditnotes.component';
import { DialogOutletComponent } from '../shared/dialog-outlet.component';
import { AuthGuard } from '../user/auth.guard';
import { CategoryResolver } from './category-resolver.service';
import { CommerceBasketComponent } from './commerce-basket.component';
import { CommerceCataloguesComponent } from './commerce-catalogues.component';
import { CommerceOrderComponent } from './commerce-order.component';
import { CommerceOrdersComponent } from './commerce-orders.component';
import { CommerceProductDetailsComponent } from './commerce-product-details.component';
import { CommerceProductFilterPageComponent } from './commerce-product-filter-page.component';
import { CommerceProductListHomeComponent } from './commerce-product-list-home.component';
import { CommerceProductListPageComponent } from './commerce-product-list-page.component';
import { CommerceReceiptComponent } from './commerce-receipt.component';
import { ProductResolver } from './product-resolver.service';

export const commerceRoutes: Routes = [
    {
        path: Translations.shop.paths.shopPage.path,
        component: CommerceProductListPageComponent,
        data: {
            pageData: {
                title: Translations.shop.paths.shopPage.title
            }
        },
        children: [
            {
                path: '',
                component: CommerceProductListHomeComponent,
                pathMatch: 'full'
            },
            {
                path: Translations.shop.paths.discountPage.path,
                component: CommerceProductFilterPageComponent,
                resolve: {
                    pageData: CategoryResolver
                },
                data: {
                    isRootCategory: true
                }
            },
            {
                path: '**',
                component: CommerceProductFilterPageComponent,
                resolve: {
                    pageData: CategoryResolver
                }
            }
        ]
    },
    {
        path: 'product/:product-id',
        component: DialogOutletComponent,
        outlet: 'dialog',
        data: {
            dialogComponent: CommerceProductDetailsComponent
        },
        resolve: {
            data: ProductResolver
        }
    },
    {
        path: Translations.shop.paths.discountPage.path,
        component: CommerceCataloguesComponent,
        data: {
            pageData: {
                title: Translations.shop.paths.discountPage.title
            },
            showSpecials: 'weeklyDiscounts'
        },
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: Translations.shop.paths.topProductsPage.path,
        component: CommerceProductFilterPageComponent,
        data: {
            pageData: {
                title: Translations.shop.paths.topProductsPage.title
            },
            showSpecials: Translations.shop.paths.topProductsPage.path
        },
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: Translations.shop.paths.favoritesPage.path,
        component: CommerceProductFilterPageComponent,
        data: {
            pageData: {
                title: Translations.shop.paths.favoritesPage.title
            },
            showSpecials: 'favorites'
        },
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: Translations.shop.paths.creditNotePage.path,
        component: CitizenDetailsCreditnotesComponent,
        data: {
            pageData: {
                title: Translations.shop.paths.creditNotePage.title
            }
        },
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: Translations.shop.paths.ordersPage.path,
        canActivate: [
            AuthGuard
        ],
        canActivateChild: [
            AuthGuard
        ],
        children: [
            {
                path: ':orderNo',
                component: CommerceOrderComponent
            },
            {
                path: '',
                component: CommerceOrdersComponent,
                data: {
                    pageData: {
                        title: Translations.shop.paths.ordersPage.title
                    }
                }
            }
        ]
    },
    {
        path: Translations.shop.paths.basketPage.path,
        component: CommerceBasketComponent,
        data: {
            pageData: {
                title: Translations.shop.paths.basketPage.title
            }
        },
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: `${Translations.shop.paths.searchPage.path}/:term`,
        component: CommerceProductFilterPageComponent,
        data: {
            pageData: {
                title: Translations.shop.paths.searchPage.title
            }
        },
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: Translations.shop.paths.receiptPage.path,
        component: CommerceReceiptComponent,
        canActivate: [
            AuthGuard
        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(commerceRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CommerceRoutingModule { }
