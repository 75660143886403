import { Component, OnInit, OnDestroy } from '@angular/core';
import { Translations } from '../../translations/translations';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { CallListAdminService } from '../services/call-list-admin.service';
import { IPendingCalls } from '../../api/services';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'iv-administration-pending-calls',
    template: `
        <div class="administration-pending-calls" *ngIf="status === 'hasPendingCalls'; else unavailable">
            <mat-table [dataSource]="pendingCalls">
                <ng-container matColumnDef="pendingCallCount">
                    <mat-header-cell *matHeaderCellDef>${Translations.administration.pendingCalls.pendingCallCount}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.pendingCallCount }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="callList">
                    <mat-header-cell *matHeaderCellDef>${Translations.administration.pendingCalls.callList}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.callList }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="startingTime">
                    <mat-header-cell *matHeaderCellDef>${Translations.administration.pendingCalls.startingTime}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.startingTime }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
        </div>

        <ng-template #unavailable>
            <div class="administration-pending-calls__status alt-theme" *ngIf="status === 'fetching'">
                <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
            </div>

            <div class="administration-pending-calls__status" *ngIf="status === 'noPendingCalls'">
                ${Translations.administration.pendingCalls.noPendingCalls}
            </div>
        </ng-template>
    `,
    standalone: true,
    imports: [NgIf, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatProgressSpinner]
})
export class AdministrationPendingCallsComponent implements OnInit, OnDestroy {
    columns = ['pendingCallCount', 'callList', 'startingTime'];
    pendingCalls: MatTableDataSource<IPendingCalls>;
    status: 'fetching' | 'noPendingCalls' | 'hasPendingCalls' = 'fetching';

    private unsubscribe: Subject<void> = new Subject();

    constructor(private callListAdminService: CallListAdminService) { }

    ngOnInit() {
        this.callListAdminService.getPendingCallsList().pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(pendingCalls => {
            this.status = pendingCalls.length ? 'hasPendingCalls' : 'noPendingCalls';
            if (pendingCalls.length) {
                this.pendingCalls = new MatTableDataSource(pendingCalls);
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
