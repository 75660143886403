import { IUserDefaultMunicipalities } from '../municipality/municipality.service';
import { cities } from '../form-builder/elements/cities';
import { ROLES } from '../user/user-roles';
import { Params } from '@angular/router';
import { IUserViewModel } from '../../api/services';

export const emailRegex = /^[-_a-z0-9]+(\.[-_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;

export class Helpers {

    static CleanUrlSegment(url: string) {
        return url
            .trim()
            .toLowerCase()
            .replace(/[,]/g, '')
            .replace(/[\s]/g, '-')
            .replace(/[\/]/g, '')  // eslint-disable-line no-useless-escape
            .replace(/[&]/g, '')
            .replace(/--/g, '-');
    }

    /**
     * Takes a Date and returns an updated Date object converted to the client's local time
     *
     * @static
     * @param {Date} date
     * @returns {Date}
     * @memberof Helpers
     */
    static utcToLocal(date: Date): Date {
        if (date.getTimezoneOffset() !== 0) {
            date.setTime(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
        }
        return date;
    }

    /**
     * Takes a Date and normalizes the date field so the date is the correct one in the UTC timezone
     *
     * @static
     * @param {Date} date
     * @returns {Date}
     * @memberof Helpers
     */
    static localToUtcDate(date: Date): Date {
        const dateClone = new Date();
        dateClone.setTime(date.getTime());
        dateClone.setMinutes(dateClone.getMinutes() - dateClone.getTimezoneOffset());
        return dateClone;
    }

    static convertDateToTimeZone(date: Date): Date {
        // we added 120 min to guarantee we are working with the timezone of Denmark
        // and avoid make orders to the day before based on client location timezone
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 120);
        return date;
    }

    static weekNumber(obj: Date): number {
        const d = new Date(Date.UTC(obj.getFullYear(), obj.getMonth(), obj.getDate()));
        const dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Math.ceil(((((d as any) - (yearStart as any)) / 86400000) + 1) / 7);
    }

    static isValidEmail(email: string): boolean {
        return !!email && emailRegex.test(email.trim());
    }

    static capitalizeFirstLetter(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    static toLowerCase(value: string): string {
        return value.toLowerCase();
    }

    static filterDates(date1: Date, date2: Date): boolean {
        const startDate = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const endDate = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
        return startDate >= endDate;
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const flatten = (array: any[]): any[] => {
    return array.reduce(
        (a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const getCityByZipCode = (zipCode: string | undefined, cityName: string | undefined): string => {
    let city;
    if (zipCode) {
        city = cities[zipCode];
    }

    // just in case the zip code doesn't have the city for that zip code we return the saved city name;
    return city || cityName;
};

export enum WeekDays {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export const production = 'Production';

export const transformQueryParamsIntoMunicipalities = (user?: IUserViewModel, queryParams: Params = {}): IUserDefaultMunicipalities => {
    const defaultMunicipalites: IUserDefaultMunicipalities = {
        municipality: 0,
        districts: [],
        subdistricts: []
    };

    if (user && user.roles && user.roles.indexOf(ROLES.MunicipalityAdmin) !== -1) {
        defaultMunicipalites.municipality = user.municipalities ? user.municipalities[0] : 0;
        // if have more than one district should not select anyone
        defaultMunicipalites.districts = user.districts
            ? user.districts.length > 1
                ? []
                : user.districts
            : [];
        // if have more than one sub district should not select anyone
        defaultMunicipalites.subdistricts = user.subDistricts
            ? user.subDistricts.length > 1
                ? []
                : user.subDistricts
            : [];
    }

    const urlState = queryParams;

    if (urlState.municipalities) {
        defaultMunicipalites.municipality = +urlState.municipalities;
    }

    if (urlState.districts) {
        defaultMunicipalites.districts = +urlState.districts;
    }

    if (urlState.subDistricts) {
        if (Array.isArray(urlState.subDistricts)) {
            defaultMunicipalites.subdistricts = urlState.subDistricts.map(x => +x);
        } else {
            defaultMunicipalites.subdistricts = [+urlState.subDistricts];
        }
    }

    return defaultMunicipalites;
};

export const dateIsGreaterThan = (date1: Date, date2: Date): boolean => {
    return date1 > date2;
};

export const dateIsGreaterOrEqualsThanToday = (date: Date): boolean => {
    return date >= new Date();
};
