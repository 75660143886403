import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { Translations } from '../../translations/translations';
import { BasketService } from '../services/basket.service';
import { ElementRenderer } from '../shared/element-renderer.service';
import { DialogService } from '../shared/dialog.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'iv-commerce-basket-catalog-item-dialog',
    template: `
        <aside class="dialog">
            <form (submit)="onSubmit()">
            <h3 mat-dialog-title>${Translations.commerce.basket.catalogItemDialog.header} "{{ catalogItemNo }}"</h3>
                <mat-dialog-content class="low-density-dialog-content">
                    <mat-form-field>
                        <input #quantityField type="tel" name="quantity" matInput [(ngModel)]="quantity" placeholder="${Translations.commerce.productHead.quantity}">
                    </mat-form-field>
                </mat-dialog-content>
                <mat-dialog-actions class="dialog__actions alt-theme">
                    <button mat-raised-button color="primary">${Translations.global.btnAcknowledge}</button>
                    <button type="button" mat-button mat-dialog-close>${Translations.global.btnCancel}</button>
                </mat-dialog-actions>
            </form>
        </aside>
    `,
    standalone: true,
    imports: [FormsModule, MatDialogTitle, MatDialogContent, MatFormField, MatInput, MatDialogActions, MatButton, MatDialogClose]
})

export class CommerceBasketCatalogItemDialogComponent implements OnInit, OnDestroy {
    @ViewChild('quantityField', { static: true }) quantityField: ElementRef;

    quantity = 1;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) public catalogItemNo: string,
        private dialogRef: MatDialogRef<CommerceBasketCatalogItemDialogComponent>,
        private basketService: BasketService,
        private renderer: ElementRenderer,
        private dialogService: DialogService
    ) { }
 
    ngOnInit() {
        setTimeout(() => {
            this.renderer.invokeElementMethod(this.quantityField, 'focus');
            this.renderer.invokeElementMethod(this.quantityField, 'setSelectionRange', [0, 1]);
        }, 0);
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    onSubmit() {
        if (this.quantity) {
            const max = Translations.commerce.addtobasket.qtyWarningRules.max;

            if (this.quantity > max) {
                const msg = Translations.replaceTokens(Translations.commerce.addtobasket.qtyWarningRules.moreThanTen, max);

                this.dialogService.showMessage(msg).afterClosed().pipe(
                    tap(() => this.basketService.addCatalogItemToBasket(this.catalogItemNo, this.quantity)),
                    takeUntil(this.unsubscribeS$)
                ).subscribe(() => this.dialogRef.close());
            }
            else {
                this.basketService.addCatalogItemToBasket(this.catalogItemNo, this.quantity);
                this.dialogRef.close();
            }
        }
        else {
            this.dialogRef.close();
        }
    }
}
