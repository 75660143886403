import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, RouterLinkActive, RouterLink } from '@angular/router';
import {Subject} from 'rxjs';
import {first, map, takeUntil} from 'rxjs/operators';

import {MenuService} from '../services/menu.service';
import {Translations} from '../../translations/translations';
import { NgFor } from '@angular/common';

@Component({
    selector: 'iv-commerce-product-filter-page-quick-links',
    template: `
        <a class="commerce-product-filter-page__quick-link"
         [routerLinkActiveOptions]="{exact: true}"
         routerLinkActive="commerce-product-filter-page__quick-link--active"
         routerLink="/${Translations.shop.paths.shopPage.path}/${Translations.shop.paths.discountCategory.path}">${Translations.shop.buttons.allDiscounts}
        </a>
        <a class="commerce-product-filter-page__quick-link"
           [routerLinkActiveOptions]="{exact: true}"
           routerLinkActive="commerce-product-filter-page__quick-link--active"
           *ngFor="let menuItem of menu"
           [routerLink]="menuItem.url">{{menuItem.name}}
        </a>
    `,
    standalone: true,
    imports: [
        RouterLinkActive,
        RouterLink,
        NgFor,
    ],
})
export class CommerceProductFilterPageComponentQuickLinks implements OnInit, OnDestroy {

    private unsubscribe: Subject<void> = new Subject();

    menu: any[] = [];

    constructor(
        private menuService: MenuService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.route.url.pipe(takeUntil(this.unsubscribe)).subscribe((fragments) => {
            this.menuService.getCategoryMenu().pipe(
                first(),
                map((res) => {
                    return res.categories.find((category) => {
                        return category.id === Translations.shop.paths.discountCategory.path;
                    });
                }),
                map((discountCategory: any) => {
                    return discountCategory.subCategories;
                }),
            ).subscribe((res) => {
                this.menu = res;
            });

        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
