import { Component, OnInit } from '@angular/core';
import { Translations } from '../../translations/translations';
import { FormBuilderTypes, FormBuilderDatepicker, FormBuilderRadio, FormBuilderTextInput } from '../form-builder/form-builder-element.model';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { CitizenReceivedMethod } from '../../api/services';
import { MatButton } from '@angular/material/button';
import { ProgressButtonComponent } from './progress-button.component';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'iv-recieved-dialog',
    template: `
        <article class="recieved-dialog dialog">
            <form [formGroup]="form" (submit)="onSubmit()" *ngIf="form">
                <mat-dialog-content>
                    <iv-form-builder-element [form]="form" [input]="input" *ngFor="let input of inputs"></iv-form-builder-element>
                </mat-dialog-content>

                <mat-dialog-actions *ngIf="form">
                    <iv-progress-button color="primary" [disabled]="form.invalid">${Translations.form.actions.save}</iv-progress-button>
                    <button type="button" mat-button mat-dialog-close>${Translations.form.actions.cancel}</button>
                </mat-dialog-actions>
            </form>
        </article>
    `,
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatDialogContent, NgFor, FormBuilderElementComponent, MatDialogActions, ProgressButtonComponent, MatButton, MatDialogClose]
})
export class RecievedDialogComponent implements OnInit {
    inputs: FormBuilderTypes[];
    form: UntypedFormGroup;

    constructor(
        private dialogRef: MatDialogRef<RecievedDialogComponent>,
        private formBuilder: FormBuilderService
    ) { }

    ngOnInit() {
        this._buildForm();
    }

    onSubmit() {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }

    private _buildForm() {
        this.inputs = [
            new FormBuilderDatepicker({
                label: Translations.administration.citizen.inactivationPrompt.form.receivedDate,
                name: 'ReceivedDate',
                required: true
            }),
            new FormBuilderRadio({
                label: Translations.administration.citizen.inactivationPrompt.form.receivedMethod,
                name: 'ReceivedMethod',
                required: true,
                options: [
                    {
                        label: Translations.administration.citizen.inactivationPrompt.form.receivedVocally,
                        value: CitizenReceivedMethod.Vocally
                    },
                    {
                        label: Translations.administration.citizen.inactivationPrompt.form.receivedWritten,
                        value: CitizenReceivedMethod.Written
                    }
                ]
            }),
            new FormBuilderTextInput({
                label: Translations.administration.citizen.inactivationPrompt.form.receivedFrom,
                name: 'ReceivedFrom',
                required: true
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }
}
