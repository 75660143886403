import { ModuleWithProviders, NgModule } from '@angular/core';

import { UtilComponentLoaderComponent } from './util-component-loader.component';
import { UtilHiddenScrollComponent } from './util-hidden-scroll.component';
import { UtilUrlService } from './util-url.service';
import { UtilService } from './util.service';

@NgModule({
    imports: [UtilHiddenScrollComponent, UtilComponentLoaderComponent],
    exports: [UtilHiddenScrollComponent, UtilComponentLoaderComponent]
})
export class UtilModule {
    static forRoot(): ModuleWithProviders<UtilModule> {
        return {
            ngModule: UtilModule,
            providers: [
                UtilService,
                UtilUrlService
            ]
        };
    }

    static forChild(): ModuleWithProviders<UtilModule> {
        return {
            ngModule: UtilModule
        };
    }
}
