import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    DetailedMessageViewModel,
    ICreateMessageViewModel,
    IDetailedMessageViewModel,
    ISimpleMessageViewModel,
    ISkipTakeResultObjectOfDetailedMessageViewModel,
    IUpdateMessageViewModel,
    SimpleMessageViewModel,
    SkipTakeResultObjectOfDetailedMessageViewModel,
} from '../../api/services';
import { Helpers } from '../util/helpers';
import { BaseService } from './base-service';

@Injectable()
export class MessageService extends BaseService {

    getMessage(id: number): Observable<IDetailedMessageViewModel> {
        return this.http.get(this.apiBaseUrl('/NotificationMessage/GetMessageById'), {
            params: {
                id: id.toString()
            }
        }).pipe(
            map(DetailedMessageViewModel.fromJS),
            map(this.fixUtcDates)
        );
    }

    getMessages(): Observable<ISkipTakeResultObjectOfDetailedMessageViewModel> {
        return this.http.get(this.apiBaseUrl('/NotificationMessage/Search')).pipe(
            map(SkipTakeResultObjectOfDetailedMessageViewModel.fromJS),
            map(list => {
                if (list.results) {
                    list.results = list.results.map(this.fixUtcDates);
                }
                return list;
            })
        );
    }

    getActiveMessages(): Observable<ISimpleMessageViewModel[]> {
        return this.http.get<object[]>(this.apiBaseUrl('/NotificationMessage/GetActiveMessages')).pipe(
            map(x => x.map(SimpleMessageViewModel.fromJS))
        );
    }

    updateMessage(message: ICreateMessageViewModel | IUpdateMessageViewModel): Observable<DetailedMessageViewModel> {
        if (this.isUpdateMessage(message)) {
            return this.http.put(this.apiBaseUrl('/NotificationMessage/UpdateMessage'), message).pipe(
                map(DetailedMessageViewModel.fromJS),
                map(this.fixUtcDates)
            );
        } else {
            return this.http.post(this.apiBaseUrl('/NotificationMessage/CreateMessage'), message).pipe(
                map(DetailedMessageViewModel.fromJS),
                map(this.fixUtcDates)
            );
        }
    }

    deleteMessage(id: number): Observable<boolean> {
        return this.http.delete(this.apiBaseUrl('/NotificationMessage/DeleteMessage'), {
            params: {
                id: id.toString()
            }
        }).pipe(map(() => true));
    }

    isUpdateMessage(message: IUpdateMessageViewModel | ICreateMessageViewModel): message is IUpdateMessageViewModel {
        return (message as IUpdateMessageViewModel).messageId !== undefined;
    }

    fixUtcDates = (message: DetailedMessageViewModel): DetailedMessageViewModel => {
        message.startDate = message.startDate && Helpers.utcToLocal(message.startDate);
        message.endDate = message.endDate && Helpers.utcToLocal(message.endDate);
        return message;
    }

    constructor(
        private http: HttpClient
    ) {
        super();
    }
}
