import { isPlatformBrowser } from '@angular/common';
import { ElementRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class ElementRenderer {
    constructor(@Inject(PLATFORM_ID) private platformId: object) {}

    invokeElementMethod(eleRef: ElementRef, method: string, args?: any[]) {
        if (isPlatformBrowser(this.platformId)) {
            (eleRef.nativeElement[method] as any).apply(
                eleRef.nativeElement,
                args || []
            );
        }
    }
}
