import { Component } from '@angular/core';

import { MenuService } from '../services/menu.service';
import { CategoryMenuLevelComponent } from './category-menu-level.component';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'iv-category-menu',
    template: `
        <nav role="navigation" class="category-menu">
            <iv-category-menu-level [category]="category" *ngFor="let category of (menuService.getCategoryMenu() | async)?.categories"></iv-category-menu-level>
        </nav>
    `,
    standalone: true,
    imports: [NgFor, CategoryMenuLevelComponent, AsyncPipe]
})
export class CategoryMenuComponent {
    constructor(public menuService: MenuService) { }
}
