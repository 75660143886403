import { IRealTimeInboundCallEvent } from 'Client/api/services';

export enum EventSourceReadyState {
    CONNECTING = 0,
    OPEN = 1,
    CLOSED = 2
}

export type EventSourceEventType = 'Error' | 'Success';

export interface CallEventMessage {
    type: EventSourceEventType;
    readyState: EventSourceReadyState;
    message?: IRealTimeInboundCallEvent;
    error?: string;
}

export type PresenceStatus =
    'Available'
    | 'Away'
    | 'Busy'
    | 'Break'
    | 'Meal'
    | 'Meeting'
    | 'Training'
    | 'Offline'
    | 'OutOfOffice'
    | 'Idle'
    | 'Unknown';

export enum PresenceStatusEnum {
    Available = 'Available',
    Away = 'Away',
    Busy = 'Busy',
    Break = 'Break',
    Meal = 'Meal',
    Meeting = 'Meeting',
    Training = 'Training',
    Offline = 'Offline',
    OutOfOffice = 'OutOfOffice',
    Idle = 'Idle',
    Unknown = 'Unknown'
}

export enum PresenceTypeEnum {
    User = 'User',
    System = 'System'
}

export interface CallerPresenceStatusDefinition {
    id: string;
    label: string;
    status: PresenceStatus;
}

export interface CallerPresenceStatus {
    id: string;
    message: string;
    status: PresenceStatus;
}

export interface CallerPresence {
    id: string;
    label: string;
    message: string;
    status: PresenceStatus;
}
