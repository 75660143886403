import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Subject, ReplaySubject } from 'rxjs';

@Injectable()
export class RouteService {
    private _activeRoute = new ReplaySubject<ActivatedRouteSnapshot>(1);
    activeRoute$ = this._activeRoute.asObservable();

    private _secondaryRoutes = new Subject<ActivatedRouteSnapshot>();
    secondaryRoutes$ = this._secondaryRoutes.asObservable();

    private _persistedSnapshots: ActivatedRouteSnapshot[] = [];

    isFirstLoad = true;

    constructor(
        private router: Router
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof ActivationEnd) {
                this._persistedSnapshots.push(event.snapshot);
            }
            else if (event instanceof NavigationEnd) {
                this.isFirstLoad = false;
                const primaryOutlets = this._persistedSnapshots.filter(snapshot => snapshot.outlet === 'primary');
                const secondaryOutlets = this._persistedSnapshots.filter(snapshot => snapshot.outlet !== 'primary');

                if (primaryOutlets.length) {
                    const snapshot = primaryOutlets.shift();
                    if (snapshot) {
                        this._activeRoute.next(snapshot);
                    }
                }

                secondaryOutlets.forEach(outlet => this._secondaryRoutes.next(outlet));

                this._persistedSnapshots.splice(0);
            }
        });
    }
}
