import { DatePipe, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { filter, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { IActualCallDay } from '../../api/services';
import { pending, trash } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CallListAdminService } from '../services/call-list-admin.service';
import { DialogService } from '../shared/dialog.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
    selector: 'iv-administration-calllistadmin-planning',
    template: `
        <a *ngIf="states.canCreateDay" class="category-menu__link" routerLinkActive="category-menu__link_active" [routerLink]="['', { outlets: { dialog: ['edit-call-day', 'new']} }]">
            ${Translations.callListAdmin.navigation.addCallDay}
        </a>
        <a *ngIf="states.canEditDay" class="category-menu__link" routerLinkActive="category-menu__link_active" [routerLink]="['', { outlets: { dialog: ['edit-call-day', 'edit']} }]">
            ${Translations.callListAdmin.navigation.editCallDay}
        </a>
        <a *ngIf="states.canNotify" class="category-menu__link" (click)="notify()">
            ${Translations.callListAdmin.navigation.notifyFourthTrys}
        </a>
        <a *ngIf="states.canCloseDay" class="category-menu__link" routerLinkActive="category-menu__link_active" [routerLink]="['', { outlets: { dialog: ['end-day']} }]">
            ${Translations.callListAdmin.navigation.endDay}
        </a>
        <a *ngIf="states.canCloseFaxDay" class="category-menu__link" routerLinkActive="category-menu__link_active" [routerLink]="['', { outlets: { dialog: ['end-fax-day']} }]">
            ${Translations.callListAdmin.navigation.endFaxDay}
        </a>
        <div class="category-menu__link" routerLinkActive="category-menu__link_active" *ngFor="let day of days | async">
            <a [routerLink]="['', 'planning', day.callDate | date: 'yyyy-MM-dd']">{{ day.callDate | date: 'shortDate' }}</a>
            <button *ngIf="day.canBeDeleted" type="button" class="edit-icon" (click)="deleteCallDay(day.callDate)" title="${Translations.callListAdmin.deleteCallDay.deleteBtn}">${trash}</button>
        </div>
        <div class="alt-theme" *ngIf="states.isFetching">
            <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="2" [diameter]="20"></mat-progress-spinner>
        </div>
        <button class="call-list-admin__refresh" type="button" (click)="callListAdminService.getPlanningdays()"><i class="icon">${pending}</i> ${Translations.callListAdmin.navigation.refresh}</button>
    `,
    standalone: true,
    imports: [NgIf, RouterLinkActive, RouterLink, NgFor, MatProgressSpinner, AsyncPipe, DatePipe]
})
export class AdministrationCalllistadminPlanningComponent implements OnInit {

    days: Observable<IActualCallDay[]>;

    states = {
        isFetching: true,
        canCreateDay: true,
        canEditDay: false,
        canCloseDay: false,
        canCloseFaxDay: false,
        canNotify: false
    };

    constructor(
        public callListAdminService: CallListAdminService,
        private dialogService: DialogService,
        private date: DatePipe
    ) { }

    ngOnInit() {
        this.days = this.callListAdminService.getPlanningdays().pipe(
            tap(days => {
                this.states.isFetching = true;
                this.states.canCreateDay = true;
                this.states.canEditDay = false;
                this.states.canCloseDay = false;
                this.states.canCloseFaxDay = false;
                this.states.canNotify = false;
                const today = days.find(x => x.isToday);
                if (today) {
                    this.states.canCreateDay = false;
                    this.states.canEditDay = !today.callDayClosed;
                    this.states.canCloseDay = !today.callDayClosed;
                    this.states.canCloseFaxDay = !today.faxDayClosed;
                    this.states.canNotify = today.canNotifyFourthTrys;
                }
                this.states.isFetching = false;
            })
        );
    }

    notify() {
        this.dialogService.confirm(Translations.callListAdmin.notifyFourthTrys.confirm).afterClosed().pipe(
            switchMap(x => x
                ? this.callListAdminService.notifyFourthTrys()
                : EMPTY
            )
        ).subscribe(
            () => {
                this.callListAdminService.getPlanningdays();
                this.dialogService.showSnackMessage({ message: Translations.callListAdmin.notifyFourthTrys.messageOk });
            },
            (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
        );
    }

    deleteCallDay(callDate: Date) {
        this.dialogService.confirm(Translations.callListAdmin.deleteCallDay.confirm).afterClosed().pipe(
            filter((res: boolean) => res),
            switchMapTo(this.callListAdminService.deleteCallDay(this.date.transform(callDate, 'yyyy-MM-dd')!))
        ).subscribe(() => {
            this.dialogService.showSnackMessage({ message: Translations.callListAdmin.deleteCallDay.messageOk });
            this.callListAdminService.getPlanningdays();
        }, () => this.dialogService.showSnackMessage({ message: Translations.callListAdmin.deleteCallDay.messageNotOk }));
    }
}
