import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';

import { CategoryMenuModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { BaseService } from './base-service';
import { CategoryMenuViewModel, CategoryNodeViewModel, CategoryTreeViewModel } from './menu-models';
import { Observable, ReplaySubject, forkJoin } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable()
export class MenuService extends BaseService {
    private menu: CategoryMenuViewModel;
    private _categoryMenu = new ReplaySubject<CategoryMenuViewModel>(1);
    categoryMenu$ = this._categoryMenu.asObservable();

    constructor(private http: HttpClient, private userService: UserService) {
        super();

        const menuPathUrl = this.apiBaseUrl('/menu/GetCategoryMenuPaths');

        this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                return forkJoin([
                    this.http.get(menuPathUrl, { params: { filter: 'discounts', deliveryDate } }).pipe(
                        map(CategoryMenuModel.fromJS),
                        map(menu => {
                            let subCategories;

                            if (menu.categories && menu.categories.length === 1 && menu.categories[0].name === 'CategoryPaths') {
                                subCategories = menu.categories[0].subCategories;
                            } else {
                                subCategories = menu.categories;
                            }

                            const discountNode = CategoryNodeViewModel.fromDto({
                                id: Translations.shop.paths.discountCategory.path,
                                name: Translations.shop.paths.discountCategory.title,
                                numFound: 0,
                                subCategories: subCategories || []
                            }, true);

                            return discountNode;
                        })
                    ),
                    this.http.get(menuPathUrl, { params: { deliveryDate } }).pipe(
                        map(CategoryMenuModel.fromJS),
                        map(x => CategoryMenuViewModel.fromDto(x)),
                        map((categoryPath: any) => {
                            // Bubbling out the tree since the structure is different than the old request
                            if (categoryPath.categories.length === 1 && categoryPath.categories[0].name === 'CategoryPaths') {
                                return {
                                    categories: categoryPath.categories[0].subCategories
                                };
                            }
                            return categoryPath;
                        })
                    )
                ]).pipe(
                    map(menu => {
                        const model: CategoryMenuViewModel = {
                            categories: [menu[0]].concat(menu[1].categories)
                        };

                        return model;
                    }));
            })
        ).subscribe(menu => {
            this._categoryMenu.next(menu);
            this.menu = menu;
        });
    }

    getCategoryMenu(): Observable<CategoryMenuViewModel> {
        return this.categoryMenu$;
    }

    getCategoryTree(categorySegments: string[]): Observable<CategoryTreeViewModel> {
        return this._categoryMenu.pipe(
            map(menu => {
                let nodes = menu.categories;
                const tree = new CategoryTreeViewModel();
                let count = 1;
                for (const categoryName of categorySegments) {
                    const node = this.getCategoryNode(categoryName, nodes);
                    if (node === undefined) {
                        throw new Error('Node not found');
                    }
                    tree.breadcrumb.push({
                        id: node.id,
                        name: node.name
                    });
                    tree.node = node;
                    if (count <= categorySegments.length) {
                        if (node.subCategories !== undefined) {
                            nodes = node.subCategories;
                        } else {
                            throw new Error('End of menu reached');
                        }
                    }
                    count++;
                }
                return tree;
            })
        );
    }

    getCategoryNode(path: string, nodes: CategoryNodeViewModel[]): CategoryNodeViewModel | undefined {
        return nodes.filter(node => node.path === path)[0];
    }

    getCategoryPath(categoryPath: string): CategoryNodeViewModel[] | undefined {
        if (this.menu) {

            return this.menu.categories
                .map(node => this.findTreePath(node.subCategories, categoryPath))
                .find(node => node.length > 0 ? true : false);

        }

        return undefined;
    }

    private findTreePath(list: CategoryNodeViewModel[], url: string): CategoryNodeViewModel[] {
        const treePathList: CategoryNodeViewModel[] = [];

        const walk = (current: CategoryNodeViewModel[], currentUrl: string) => {

            current.forEach((item: CategoryNodeViewModel) => {

                if (!item.subCategories || (item.subCategories && item.subCategories.length === 0)) {
                    if (item.path && item.id === currentUrl) {
                        treePathList.push(item);
                    }
                } else if (item && item.id === currentUrl) {
                    treePathList.push(item);
                } else {
                    walk(item.subCategories, currentUrl);
                }
            });

            return treePathList;
        };

        return walk(list, url) || [];
    }
}
