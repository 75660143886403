import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle
} from '@angular/material/dialog';

import { Translations } from '../../translations/translations';
import { FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IDialogForm } from './dialog-interfaces';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'iv-dialog-call-status-form',
    template: `
        <div class="dialog-call-status-form alt-theme dialog">
            <h3 mat-dialog-title *ngIf="matData.title">{{ matData.title }}</h3>

            <mat-dialog-content class="dialog-call-status-form__content">
                <p *ngIf="matData.content">{{ matData.content }}</p>
                <ng-container *ngIf="!isFetching; else fetching">
                    <div class="alert" *ngIf="matData?.alert?.text" [ngClass]="'alert-' + matData.alert.type">
                        {{ matData.alert.text }}
                    </div>

                    <form *ngIf="form" [formGroup]="form" class="alt-theme form" autocomplete="off">
                        <iv-form-builder-element
                            *ngFor="let input of inputs" [form]="form"
                            [input]="input">
                        </iv-form-builder-element>
                    </form>
                </ng-container>

                <ng-template #fetching>
                    <div class="center-content alt-theme">
                        <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3"
                                              [diameter]="60"></mat-progress-spinner>
                    </div>
                </ng-template>
            </mat-dialog-content>

            <mat-dialog-actions class="dialog__actions" *ngIf="form">
                <button
                    type="submit"
                    mat-raised-button
                    [disabled]="!form.valid"
                    [mat-dialog-close]="form.value"
                    color="primary">
                    {{ matData.actionText }}
                </button>
                <button
                    type="button"
                    mat-raised-button
                    color="accent"
                    mat-dialog-close>
                    ${Translations.form.actions.cancel}
                </button>
            </mat-dialog-actions>
        </div>
    `,
    standalone: true,
    imports: [NgIf, MatDialogTitle, MatDialogContent, NgClass, FormsModule, ReactiveFormsModule, NgFor, FormBuilderElementComponent, MatProgressSpinner, MatDialogActions, MatButton, MatDialogClose]
})
export class DialogCallStatusFormComponent implements OnInit {
    isFetching = false;
    form: UntypedFormGroup;

    public inputs: FormBuilderTypes[];

    constructor(
        private formBuilder: FormBuilderService,
        @Inject(MAT_DIALOG_DATA) public matData: IDialogForm
    ) {
    }

    ngOnInit() {
        this.inputs = this.matData.inputs || [];
        this.form = this.formBuilder.toFormGroup(this.inputs);
    }
}
