/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, ElementRef, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';

import { DragService } from './../drag.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[dragtarget]',
    standalone: true
})
export class DragTargetDirective implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() dropComplete = new EventEmitter<any>();

    /* This is a "drop-guard" function */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() canDrop: (dragger: any) => boolean = () => true;

    constructor(
        private ele: ElementRef,
        private dragService: DragService,
        private ngZone: NgZone
    ) { }

    ngOnInit() {
        this.ele.nativeElement.addEventListener(
            'drop',
            (ev: Event) => {
                if (!this.canDrop(this.dragService.dragger)) {
                    return;
                }
                ev.preventDefault();
                this.ele.nativeElement.classList.remove('draghover');
                this.dragService.element.classList.remove('drag-disallowed');
                this.dropComplete.emit(this.dragService.dragger);
            },
            false
        );

        this.ngZone.runOutsideAngular(() => {
            this.ele.nativeElement.addEventListener(
                'dragover',
                (ev: Event) => {
                    if (!this.canDrop(this.dragService.dragger)) {
                        return;
                    }
                    ev.preventDefault();
                },
                false
            );

            this.ele.nativeElement.addEventListener(
                'dragenter',
                () => {
                    if (!this.canDrop(this.dragService.dragger)) {
                        this.dragService.element.classList.add('drag-disallowed');
                        return;
                    }
                    this.ele.nativeElement.dataset.length = this.dragService.dragger['duration'];
                    this.ele.nativeElement.classList.add('draghover');
                },
                false
            );
            this.ele.nativeElement.addEventListener(
                'dragleave',
                () => {
                    this.ele.nativeElement.classList.remove('draghover');
                    this.dragService.element.classList.remove('drag-disallowed');
                },
                false
            );
        });
    }
}
