import { ICategoryMenuModel, ICategoryMenuNode } from '../../api/services';
import { Translations } from '../../translations/translations';
import { Helpers } from '../util/helpers';

export class CategoryHelper {
    static generateUrl(...segments: (string | undefined)[] ): string {
        const categoryPath = segments
            .filter(x => !!x)
            .join('/');

        return `/${Translations.shop.paths.shopPage.path}/${categoryPath}`;
    }

}

export class CategoryNodeViewModel {
    id: string;
    name: string;
    path: string;
    url: string;
    isDiscount: boolean;
    subCategories: CategoryNodeViewModel[] = [];

    static fromDto(categoryMenuNode: ICategoryMenuNode, isDiscount = false, parentPath?: string): CategoryNodeViewModel {
        const model = new CategoryNodeViewModel();
        if (categoryMenuNode.id) {
            model.id = categoryMenuNode.id;
            model.name = categoryMenuNode.name ? categoryMenuNode.name : '';
            model.path = categoryMenuNode.id ? Helpers.CleanUrlSegment(categoryMenuNode.id) : '';
            const fullPath = (parentPath ? parentPath + '/' : '') + model.path;
            let filteredPath;

            if (fullPath.indexOf('categorypath') !== -1) {
                filteredPath = fullPath.replace('categorypaths/', '');
            } else {
                filteredPath = fullPath;
            }

            const discountPath = isDiscount ? '/' + Translations.shop.paths.discountCategory.path : '';
            model.url = `/${Translations.shop.paths.shopPage.path}/${filteredPath}`;
            model.isDiscount = isDiscount;
            model.subCategories = categoryMenuNode.subCategories ? categoryMenuNode.subCategories.map(x => CategoryNodeViewModel.fromDto(x, isDiscount, fullPath)) : [];
        }

        return model;
    }
}

export class CategoryMenuViewModel {
    categories: CategoryNodeViewModel[] = [];

    static fromDto(categoryMenuModel: ICategoryMenuModel, isDiscount = false): CategoryMenuViewModel {
        const model = new CategoryMenuViewModel();
        model.categories = categoryMenuModel.categories ? categoryMenuModel.categories.map(x => CategoryNodeViewModel.fromDto(x, isDiscount)) : [];

        return model;
    }
}

export class CategoryBreadcrumbSegment {
    id: string;
    name: string;
}

export class CategoryTreeViewModel {
    breadcrumb: CategoryBreadcrumbSegment[] = [];
    node: CategoryNodeViewModel;
}
