import { computed, Directive, inject, input } from '@angular/core';
import { CallsService } from './calls.service';

@Directive({
    selector: '[ivCallerPresence]',
    standalone: true,
    host: {
        '[class.caller-presence]': 'true',
        '[class]': 'this.callerStatusChanges()'
    }
})
export class CallerPresenceDirective {

    public isCallerProfile = input.required<boolean>();
    private callsService: CallsService = inject(CallsService);
    callerStatusChanges = computed(() => {
        const callerStatus = this.callsService.getCallerStatus();
        const prefix = 'caller-presence__';
        const defaultState = 'offline';
        const newClass = (callerStatus?.status?.toLowerCase() ?? defaultState).replace(' ', '-');
        return `${prefix}${newClass}`;
    });
}
