// TODO: this should be normalized with the new roles done by @William Tourinho
// KOMMUNE = COMMUNE
// BESTILLER = ORDER
// ADMIN = ADMIN
// PDA = PDA
// LOGS = LOGS
// EXTRAORDER = EXTRA ORDER
// ADGANG = ACCESS
// CALLADMIN = CALL ADMIN
// KUNDECENTER = CUSTOMER SERVICE

export type IUserRoles
    = 'ADMIN'
    | 'Anonymous'
    | 'Citizen'
    | 'CALLADMIN'
    | 'KUNDECENTER'
    | 'PDA'
    | 'KOMMUNE'
    | 'BESTILLER'
    | 'SUPERUSER';

interface IUserRoleKeyToValue extends Record<string, IUserRoles> { }

interface IUserIdentities extends Record<string, IUserRoles[]> { }

export const ROLES: IUserRoleKeyToValue = {
    Admin: 'ADMIN',
    Anonymous: 'Anonymous',
    Order: 'BESTILLER',
    Citizen: 'Citizen',
    CallListAdmin: 'CALLADMIN',
    CustomerService: 'KUNDECENTER',
    HomeCarer: 'PDA',
    MunicipalityAdmin: 'KOMMUNE',
    SuperUser: 'SUPERUSER',
};

export const IDENTITIES: IUserIdentities = {
    admin: [ROLES.Admin],
    pendingCalls : [ROLES.Admin, ROLES.CustomerService, ROLES.CallListAdmin],
    adminAndCustomerService: [ROLES.Admin, ROLES.CustomerService, ROLES.Order, ROLES.CallListAdmin],
    customerCenter: [ROLES.Admin, ROLES.CustomerService, ROLES.Order, ROLES.MunicipalityAdmin, ROLES.CallListAdmin, ROLES.SuperUser],
    pda: [ROLES.HomeCarer],
    callList: [ROLES.Order],
    citizenDetails: [ROLES.Admin, ROLES.CustomerService, ROLES.Order],
    municipalityPortal: [ROLES.Admin, ROLES.CustomerService, ROLES.MunicipalityAdmin, ROLES.SuperUser],
    municipalityAdmin: [ROLES.Admin, ROLES.CustomerService, ROLES.Order],
    editUsers: [ROLES.Admin, ROLES.CustomerService, ROLES.SuperUser, ROLES.Order],
    editOwnPassword: [ROLES.SuperUser, ROLES.MunicipalityAdmin, ROLES.Citizen],
    searchNemlig: [ROLES.Admin, ROLES.CustomerService, ROLES.MunicipalityAdmin],
    stockView: [ROLES.Admin, ROLES.CustomerService, ROLES.MunicipalityAdmin],
    basketOptions: [ROLES.Admin, ROLES.CustomerService]
};

export const USER_IDENTITIES: IUserIdentities = {
    userAdministrationRoles: [ROLES.Admin, ROLES.Order, ROLES.CallListAdmin, ROLES.CustomerService, ROLES.MunicipalityAdmin]
};

export const BASKETIDENTITIES: IUserIdentities = {
    admin: IDENTITIES.adminAndCustomerService,
    municipality: [ROLES.HomeCarer, ROLES.MunicipalityAdmin],
    citizen: [ROLES.Citizen]
};
