import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'iv-commerce-product-list-page',
    template: `
    <router-outlet></router-outlet>
    `,
    standalone: true,
    imports: [RouterOutlet]
})
export class CommerceProductListPageComponent {
}
