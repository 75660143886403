import { DatePipe, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { EMPTY, firstValueFrom } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { CitizenTransferReason, ITransferViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { DialogService } from './dialog.service';
import { TranslationPipe } from './translation.pipe';
import { ProgressButtonComponent } from './progress-button.component';
import { MatButton } from '@angular/material/button';

export class TransferDetails {
    citizenName: string;
    transfer: ITransferViewModel;
}

@Component({
    selector: 'iv-transfer-details-dialog',
    template: `
        <aside class="transfer-details-dialog dialog alt-theme">
            <mat-dialog-content>
                <h2 mat-dialog-title>{{ '${Translations.global.transferDetails.header}' | translation : details.citizenName }}</h2>
                <p>{{ '${Translations.global.transferDetails.text}' | translation: formatTransferReason() }}</p>
            </mat-dialog-content>
            <mat-dialog-actions class="dialog__actions">
                <button mat-raised-button type="button" color="primary" mat-dialog-close>
                    ${Translations.global.btnAcknowledge}
                </button>
                <iv-progress-button
                    buttonType="button"
                    color="warn"
                    [callback]="cancelTransfer"
                    *ngIf="details.transfer.transferCanBeCanceled">
                    ${Translations.global.transferDetails.submitCancelBtn}
                </iv-progress-button>
            </mat-dialog-actions>
        </aside>
    `,
    standalone: true,
    imports: [MatDialogContent, MatDialogTitle, MatDialogActions, MatButton, MatDialogClose, NgIf, ProgressButtonComponent, TranslationPipe]
})
export class TransferDetailsDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public details: TransferDetails,
        private dialogRef: MatDialogRef<TransferDetailsDialogComponent>,
        private citizenService: CitizenService,
        private dialogService: DialogService,
        private date: DatePipe
    ) {
    }

    cancelTransfer = (): Promise<boolean> => {
        return firstValueFrom(this.dialogService.confirm(Translations.replaceTokens(Translations.global.transferDetails.confirmText, this.details.citizenName)).afterClosed().pipe(
            switchMap(res => res
                ? this.citizenService.cancelCitizenTransfer(this.details.transfer.transferedCustomerId!.toString())
                : EMPTY
            ),
            tap({
                next: () => {
                    this.dialogService.showSnackMessage({message: Translations.replaceTokens(Translations.global.transferDetails.messageOk, this.details.citizenName)});
                    this.dialogRef.close(true);
                },
                error: (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            })
        ));
    }

    formatTransferReason(): string {
        let actionText = Translations.global.transferReasons[CitizenTransferReason[this.details.transfer.transferReason]];

        if (this.details.transfer.transferFromDate) {
            actionText += ' (' + this.date.transform(this.details.transfer.transferFromDate, 'shortDate');

            if (this.details.transfer.transferToDate) {
                actionText += ' - ' + this.date.transform(this.details.transfer.transferToDate, 'shortDate');
            }

            actionText += ')';
        }

        return actionText;
    }
}
