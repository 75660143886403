import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';

import {
    Citizen,
    CitizenAddressViewmodel,
    CitizenApproveViewModel,
    CitizenBasicInfoViewmodel,
    CitizenDraftViewModel,
    CitizenEditRecordViewModel,
    CitizenWithoutOrder,
    CreateTemporaryCitizenViewModel,
    CustomerOverviewItem,
    DetailedCitizenViewModel,
    DriverMessage,
    IAddDriverMessagesViewModel,
    ICitizen,
    ICitizenAddressViewmodel,
    ICitizenApproveViewModel,
    ICitizenBasicInfoViewmodel,
    ICitizenDraftViewModel,
    ICitizenEditRecordViewModel,
    ICitizenPayment,
    ICitizensWithoutOrderFilterViewModel,
    ICitizenTransferViewModel,
    ICitizenWithoutOrder,
    ICreateExtraOrderCitizenModel,
    ICustomerOverviewItem,
    IDeleteDriverMessagesViewModel,
    IDetailedCitizenViewModel,
    IDriverMessage,
    IResignationReason,
    ISearchResultModelOfCitizenSearchViewModel,
    IShowCitizenFilterViewModel,
    ISkipTakeResultObjectOfCitizenWithOrderInfoViewModel,
    ISkipTakeResultObjectOfCustomerLedgerViewModel,
    ISkipTakeResultObjectOfHistoryItem,
    ISkipTakeResultObjectOfShowCitizenModel,
    ITemporaryCitizenActivationViewModel,
    ITemporaryCitizenInactivationViewModel,
    ITemporaryCitizenViewModel,
    ITemporaryUnSubsribeCitizenViewModel,
    IUpdateTemporaryCitizenViewModel,
    ResignationReason,
    SearchResultModelOfCitizenSearchViewModel,
    SkipTakeResultObjectOfCitizenWithOrderInfoViewModel,
    SkipTakeResultObjectOfCustomerLedgerViewModel,
    SkipTakeResultObjectOfHistoryItem,
    SkipTakeResultObjectOfShowCitizenModel,
    TemporaryCitizenViewModel,
    UpdateTemporaryCitizenViewModel,
    CallCitizenExportListFilter, IUpdateDriverMessagesViewModel,
} from '../../api/services';
import { BaseService } from './base-service';
import { DatePipe } from '@angular/common';

export enum CitizenHistoryFilter {
    All,
    Calls,
    Orders,
    Notes,
    Changes
}

export interface ICitizenBaseSearch {
    text: string;
    skip: number;
    take: number;
}

export interface ICitizenSearch extends ICitizenBaseSearch {
    onlyResigned: boolean;
}

export interface ICitizenSearchHomecarer extends ICitizenBaseSearch {
    date: string;
}

@Injectable()
export class CitizenService extends BaseService {
    private _search: Subject<ICitizenSearch> = new Subject();
    search$: Observable<ISearchResultModelOfCitizenSearchViewModel>;

    private _searchHomecarer: Subject<ICitizenSearchHomecarer> = new Subject();
    searchHomecarer$: Observable<ISkipTakeResultObjectOfCitizenWithOrderInfoViewModel>;

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe
    ) {
        super();

        this.search$ = this._search.pipe(
            switchMap(({ text, skip, take, onlyResigned }) => {
                if (text) {
                    return this.http.get(this.apiBaseUrl('/Citizen/Search'), { params: { text, skip: skip.toString(), take: take.toString(), onlyResigned: onlyResigned.toString() } }).pipe(
                        catchError(() => {
                            return EMPTY;
                        }),
                        map(SearchResultModelOfCitizenSearchViewModel.fromJS)
                    );
                }

                return of(new SearchResultModelOfCitizenSearchViewModel({ numFound: 0, start: 0 }));
            }), share());

        this.searchHomecarer$ = this._searchHomecarer.pipe(
            switchMap(({ text, skip, take, date }) => {
                return this.http.get(this.apiBaseUrl('/Citizen/GetCitizensForHomeCarer'), { params: { text, skip: skip.toString(), take: take.toString(), date } }).pipe(
                    catchError(() => {
                        return EMPTY;
                    }),
                    map(SkipTakeResultObjectOfCitizenWithOrderInfoViewModel.fromJS)
                );
            }), share());
    }

    getCitizen(citizenNo: string): Observable<ICitizenBasicInfoViewmodel> {
        return this.http.get(this.apiBaseUrl('/Citizen/GetBasicCitizenInfo'), {
            params: {
                citizenNo
            }
        }).pipe(map(CitizenBasicInfoViewmodel.fromJS));
    }

    getCitizenDetails(citizenNo: string): Observable<IDetailedCitizenViewModel> {
        return this.http.get(this.apiBaseUrl('/Citizen/GetCitizenDetails'), {
            params: {
                citizenNo
            }
        }).pipe(map(DetailedCitizenViewModel.fromJS));
    }

    getCitizenSpecialInformation(citizenNo: string): Observable<string[]> {
        return this.http.get(this.apiBaseUrl('/Citizen/GetCitizenSpecialInformation'), {
            params: {
                citizenNo
            }
        }).pipe(map(x => x as string[]));
    }

    getCitizenHistory(skip: number, take: number, customerNo: string, filter = CitizenHistoryFilter.All): Observable<ISkipTakeResultObjectOfHistoryItem> {
        let endpoint = '';

        switch (filter) {

            case CitizenHistoryFilter.All:
                endpoint = 'GetAllHistoryItems';
                break;

            case CitizenHistoryFilter.Calls:
                endpoint = 'GetCallsHistoryItems';
                break;

            case CitizenHistoryFilter.Orders:
                endpoint = 'GetOrderHistoryItems';
                break;

            case CitizenHistoryFilter.Notes:
                endpoint = 'GetNotesHistoryItems';
                break;

            case CitizenHistoryFilter.Changes:
                endpoint = 'GetChangesHistoryItems';
                break;
        }

        return this.http.get(this.apiBaseUrl(`/Citizen/${endpoint}`), {
            params: {
                skip: skip.toString(),
                take: take.toString(),
                customerNo
            }
        }).pipe(map(SkipTakeResultObjectOfHistoryItem.fromJS));
    }

    getCitizenTemporaryAddress(customerNo: string): Observable<ICitizenAddressViewmodel[]> {
        return this.http.get<ICitizenAddressViewmodel[]>(this.apiBaseUrl('/Citizen/GetCitizenTemporaryAddresses'), {
            params: {
                customerNo
            }
        }).pipe(map(result => result.map(CitizenAddressViewmodel.fromJS)));
    }

    getCustomerLedgerRecords(customerNo: string, skip: number, take: number): Observable<ISkipTakeResultObjectOfCustomerLedgerViewModel> {
        return this.http.get<object[]>(this.apiBaseUrl('/Citizen/GetCustomerLedgerRecords'), {
            params: {
                customerNo,
                skip: skip.toString(),
                take: take.toString()
            }
        }).pipe(map(SkipTakeResultObjectOfCustomerLedgerViewModel.fromJS));
    }

    getResignationReasons(): Observable<IResignationReason[]> {
        return this.http.get<object[]>(this.apiBaseUrl('/ResignationReason/GetResignationReasons')).pipe(
            map(x => x.map(ResignationReason.fromJS))
        );
    }

    getCitizenEditRecordById(editId: string, customerNo: string): Observable<ICitizenEditRecordViewModel> {
        return this.http.get(this.apiBaseUrl('/Citizen/GetCitizenEditRecordById'), { params: { editId, customerNo } }).pipe(
            map(CitizenEditRecordViewModel.fromJS)
        );
    }

    getDriverMessages(customerNo: string): Observable<IDriverMessage[]> {
        return this.http.get<any[]>(this.apiBaseUrl('/Citizen/GetDriverMessage'), { params: { customerNo } }).pipe(
            map(x => x.map(DriverMessage.fromJS))
        );
    }

    addDriverMessage(data: IAddDriverMessagesViewModel): Observable<boolean> {
        return this.http.post<object>(this.apiBaseUrl('/Citizen/AddDriverMessage'), data).pipe(
            map(() => true)
        );
    }

    updateDriverMessage(data: IUpdateDriverMessagesViewModel): Observable<boolean> {
        return this.http.post<object>(this.apiBaseUrl('/Citizen/UpdateDriverMessage'), data).pipe(
            map(() => true)
        );
    }

    deleteDriverMessage(data: IDeleteDriverMessagesViewModel): Observable<boolean> {
        return this.http.post<object>(this.apiBaseUrl('/Citizen/DeleteDriverMessage'), data).pipe(
            map(() => true)
        );
    }

    createExtraOrder(data: ICreateExtraOrderCitizenModel): Observable<boolean> {
        return this.http.post(this.apiBaseUrl('/Citizen/CreateExtraOrder'), data).pipe(
            map(() => true)
        );
    }

    submitCitizen(citizen: CreateTemporaryCitizenViewModel | UpdateTemporaryCitizenViewModel): Observable<ITemporaryCitizenViewModel> {
        if (citizen instanceof CreateTemporaryCitizenViewModel) {
            return this.createCitizen(citizen);
        }
        else {
            return this.updateCitizen(citizen);
        }
    }

    createCitizen(createTemporaryCitizen: CreateTemporaryCitizenViewModel): Observable<ITemporaryCitizenViewModel> {
        return this.http.post(this.apiBaseUrl('/Citizen/CreateCitizen'), createTemporaryCitizen).pipe(
            map(TemporaryCitizenViewModel.fromJS)
        );
    }

    updateCitizen(updateTemporaryCitizen: IUpdateTemporaryCitizenViewModel): Observable<ITemporaryCitizenViewModel> {
        return this.http.put(this.apiBaseUrl('/Citizen/UpdateCitizen'), updateTemporaryCitizen).pipe(
            map(TemporaryCitizenViewModel.fromJS)
        );
    }

    updateCitizenPayment(citizenPayment: ICitizenPayment): Observable<ITemporaryCitizenViewModel> {
        return this.http.put(this.apiBaseUrl('/Citizen/UpdateCitizenPayment'), citizenPayment).pipe(
            map(TemporaryCitizenViewModel.fromJS)
        );
    }

    updateCitizenNotes(note: string | undefined): Observable<ITemporaryCitizenViewModel> {
        return this.http.put(this.apiBaseUrl('/Citizen/UpdateCustomerCardInformation'), {customerCardInformation: note}).pipe(
            map(TemporaryCitizenViewModel.fromJS)
        );
    }

    activateCitizen(data: ITemporaryCitizenActivationViewModel): Observable<boolean> {
        return this.http.put(this.apiBaseUrl('/Citizen/ActivateCitizen'), data).pipe(
            map(() => true)
        );
    }

    inactivateCitizen(data: ITemporaryCitizenInactivationViewModel): Observable<boolean> {
        return this.http.put(this.apiBaseUrl('/Citizen/InactivateCitizen'), data).pipe(
            map(() => true)
        );
    }

    unsubscribeCitizen(data: ITemporaryUnSubsribeCitizenViewModel): Observable<boolean> {
        return this.http.put(this.apiBaseUrl('/Citizen/UnSubscribeCitizen'), data).pipe(
            map(() => true)
        );
    }

    /**
     * Get all the citizens pending for approval
     *
     * @param {(number | number[])} [municipalities=0]
     * @param {(number | number[])} [districts=0]
     * @param {(number | number[])} [subdistricts=0]
     * @param {number} [actionType=0]
     * @param {string} [customerNo='']
     * @returns {Observable<CitizenDraftViewModel[]>}
     * @memberof MunicipalityService
     */
    getPendingApprovalItems(
        municipalities: number | number[] = 0,
        districts: number | number[] = 0,
        subdistricts: number | number[] = 0,
        actionType: number = 0,
        customerNo: string = ''
    ): Observable<ICitizenDraftViewModel[]> {

        const params: any = {};

        if (municipalities) {
            params.municipalities = municipalities;
        }

        if (districts) {
            params.districts = districts;
        }

        if (subdistricts) {
            params.subdistricts = subdistricts;
        }

        if (actionType && actionType !== 0) {
            params.actionType = actionType;
        }

        if (customerNo) {
            params.customerNo = customerNo;
        }

        return this.http.get<object[]>(this.apiBaseUrl('/Citizen/GetPendingApprovalItems'), {
            params: new HttpParams({
                fromObject: params
            })
        }).pipe(map(data => data.map(CitizenDraftViewModel.fromJS)));
    }


    /**
     * This should approve the citizen changes for notes, inactive and unsubscriber citizens;
     *
     * @param {string} customerNo
     * @param {number} [citizenDraftId=0]
     * @param {number} [noteId=0]
     * @returns {Observable<boolean>}
     * @memberof MunicipalityService
     */
    approveCitizenChanges(customerNo: string, citizenDraftId: number = 0, noteId: number = 0): Observable<boolean> {

        const params: ICitizenApproveViewModel = {} as CitizenApproveViewModel;

        if (customerNo && customerNo !== '') {
            params.customerNo = customerNo;
        }

        if (citizenDraftId && citizenDraftId !== 0) {
            params.citizenDraftId = citizenDraftId;
        }

        if (noteId && noteId !== 0) {
            params.noteId = noteId;
        }

        return this.http.put<boolean>(this.apiBaseUrl('/Citizen/ApproveCitizenChanges'), params);
    }

    /**
     * This should reject the citizen changes for notes, inactive and unsubscriber citizens;
     *
     * @param {string} customerNo
     * @param {number} [citizenDraftId=0]
     * @param {number} [noteId=0]
     * @returns {Observable<boolean>}
     * @memberof MunicipalityService
     */
    rejectCitizenChanges(customerNo: string, citizenDraftId: number = 0, noteId: number = 0): Observable<boolean> {

        const params: ICitizenApproveViewModel = {} as CitizenApproveViewModel;

        if (customerNo && customerNo !== '') {
            params.customerNo = customerNo;
        }

        if (citizenDraftId && citizenDraftId !== 0) {
            params.citizenDraftId = citizenDraftId;
        }

        if (noteId && noteId !== 0) {
            params.noteId = noteId;
        }

        return this.http.put<boolean>(this.apiBaseUrl('/Citizen/RejectCitizenChanges'), params);
    }

    /**
     * Get overview of the citizen
     * @param {number | string | string[] | number[]} municipalities
     * @param {number | string | string[] | number[]} districts
     * @param {number | string | string[] | number[]} subDistricts
     * @returns {Observable<ICustomerOverviewItem[]>}
     */
    getCitizenOverview(
        municipalities: number | string | string[] | number[] = 0,
        districts: number | string | string[] | number[] = 0,
        subDistricts: number | string | string[] | number[] = 0,
    ): Observable<ICustomerOverviewItem[]> {

        const params: any = {};

        if (municipalities && municipalities.toString().length > 0) {
            params.municipalitiyId = municipalities;
        }

        if (districts && districts.toString().length > 0) {
            params.districtId = districts;
        }

        if (subDistricts && subDistricts.toString().length > 0) {
            params.subDistrictId = subDistricts;
        }

        return this.http.get<object[]>(this.apiBaseUrl('/Citizen/GetCustomerOverview'), {
            params: new HttpParams({
                fromObject: params
            })
        }).pipe(
            map(data => data.map(CustomerOverviewItem.fromJS))
        );
    }

    /**
     * Get all citizens by citizenType and day of the week
     *
     * @param {IShowCitizenFilterViewModel} data
     * @returns {Observable<ISkipTakeResultObjectOfShowCitizenModel>}
     * @memberof MunicipalityService
     */
    showCitizens(data: IShowCitizenFilterViewModel): Observable<ISkipTakeResultObjectOfShowCitizenModel> {

        const params: any = {};

        if (data.municipalities) {
            params.municipalities = data.municipalities;
        }

        if (data.districts && data.districts.length > 0) {
            params.districts = data.districts;
        }

        if (data.subDistricts && data.subDistricts.length > 0) {
            params.subDistricts = data.subDistricts;
        }

        if (data.dayOfWeekNo && data.dayOfWeekNo > 0) {
            params.dayOfWeekNo = data.dayOfWeekNo;
        }

        if (data.customerSubTypes && data.customerSubTypes.length > 0) {
            params.customerSubTypes = data.customerSubTypes;
        }

        if (data.skip && data.skip > 0) {
            params.skip = data.skip;
        }

        if (data.take && data.take > 0) {
            params.take = data.take;
        }

        return this.http.get<object>(this.apiBaseUrl('/Citizen/ShowCitizens'), {
            params: new HttpParams({
                fromObject: params
            })
        }).pipe(map(SkipTakeResultObjectOfShowCitizenModel.fromJS));
    }

    /**
     * Get all the citizens without orders
     *
     * @param {ICitizensWithoutOrderFilterViewModel} data
     * @returns {Observable<ICitizenWithoutOrder[]>}
     * @memberof MunicipalityService
     */
    getCitizenStatus(data: ICitizensWithoutOrderFilterViewModel): Observable<ICitizenWithoutOrder[]> {

        const params: any = {};

        if (data.municipalities && data.municipalities.toString().length > 0) {
            params.municipalities = data.municipalities;
        }

        if (data.districts && data.districts.toString().length > 0) {
            params.districts = data.districts;
        }

        if (data.subDistricts && data.subDistricts.toString().length > 0) {
            params.subDistricts = data.subDistricts;
        }

        return this.http.get<object[]>(this.apiBaseUrl('/Citizen/GetCitizensWithoutOrders'), {
            params: new HttpParams({
                fromObject: params
            })
        }).pipe(map(result => result.map(CitizenWithoutOrder.fromJS)));
    }

    getCitizenCpr(citizenNo: string): Observable<string> {
        return this.http.get(this.apiBaseUrl('/Citizen/GetCitizenCpr'), {
            responseType: 'text',
            params: { citizenNo }
        });
    }

    /**
     * Apply and transfer reason to the current citizen
     *
     * @param {ICitizenTransferViewModel} data
     * @returns {Observable<boolean>}
     * @memberof MunicipalityService
     */
    transferCitizen(data: ICitizenTransferViewModel): Observable<boolean> {
        return this.http.post<boolean>(this.apiBaseUrl('/Citizen/TransferCitizen'), data);
    }

    cancelCitizenTransfer(transferId: string): Observable<boolean> {
        return this.http.delete<boolean>(this.apiBaseUrl('/Citizen/CancelCitizenTransfer'), { params: { transferId } });
    }

    getSearch(settings: ICitizenSearch) {
        this._search.next(settings);
    }

    getCitizensForHomecarer(settings: ICitizenSearchHomecarer) {
        this._searchHomecarer.next(settings);
    }

    getCitizenByCustomerNo(customerNo: string): Observable<ICitizen> {
        return this.http.get(this.apiBaseUrl('/Citizen/GetCitizenByCustomerNo'), { params: { customerNo } }).pipe(
            map(Citizen.fromJS)
        );
    }

    getCitizensForHomeCarerDays(): Observable<Date[]> {
        return this.http.get<Date[]>(this.apiBaseUrl('/Citizen/GetCitizensForHomeCarerDays')).pipe(
            map(days => days.map(day => new Date(day)))
        );
    }

    getUrlExportCitizensWithoutOrders(municipalities: string[], customerSubTypes: string[], fromDate?: Date, callRecordsTime?: CallCitizenExportListFilter): string {
        const model: any = {
            municipalities,
            customerSubTypes
        };

        if (fromDate) {
            model.fromDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
        }

        if (callRecordsTime) {
            model.callRecordsTime = callRecordsTime;
        }

        const params = new HttpParams({ fromObject: model });

        return this.apiBaseUrl('/Citizen/ExportCitizensWithoutOrders?' + params.toString());
    }

    getUrlExportRingborgersCitizensWithoutOrders(municipalities: string[], fromDate?: Date) {
        const model: any = {
            municipalities
        };

        if (fromDate) {
            model.fromDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
        }

        const params = new HttpParams({ fromObject: model });

        return this.apiBaseUrl('/Citizen/ExportRingborgersCitizensWithoutOrders?' + params.toString());
    }
}
