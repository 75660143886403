import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { CitizenReceivedMethod, ITemporaryUnSubsribeCitizenViewModel } from '../../api/services';
import { check, cross } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import {
    FormBuilderDatepicker,
    FormBuilderOption,
    FormBuilderRadio,
    FormBuilderTextarea,
    FormBuilderTextInput,
    FormBuilderTypes,
} from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { DialogService } from '../shared/dialog.service';
import { Helpers } from '../util/helpers';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { MatButton } from '@angular/material/button';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';
import { MatStepper, MatStepperIcon, MatStep, MatStepperNext } from '@angular/material/stepper';
import { UtilHiddenScrollComponent } from '../util/util-hidden-scroll.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'iv-dialog-options',
    template: `
        <div class="dialog-options">
            <h3 mat-dialog-title>${Translations.administration.citizen.unsubscribePrompt.header}</h3>

            <form [formGroup]="form" (submit)="onSubmit()" *ngIf="form && !isFetching; else fetching">
                <div class="dialog-options__content">
                    <iv-util-hidden-scroll height="100%">
                        <mat-vertical-stepper formArrayName="formArray" #stepper="matVerticalStepper">
                            <ng-template matStepperIcon="edit">
                                ${check}
                            </ng-template>

                            <ng-template matStepperIcon="done">
                                ${check}
                            </ng-template>

                            <ng-template matStepperIcon="error">
                                ${cross}
                            </ng-template>

                            <mat-step label="Vælg grund" formGroupName="0" [stepControl]="formArray.get([0])">
                                <iv-form-builder-element [verticalAsGrid]="true" [form]="formArray.get([0])" [input]="input" *ngFor="let input of inputsFirst"></iv-form-builder-element>

                                <div *ngIf="formArray">
                                    <button type="button" mat-raised-button color="primary" matStepperNext [disabled]="formArray.get([0])!.invalid">${Translations.administration.citizen.inactivationPrompt.form.nextStep}</button>
                                </div>
                            </mat-step>

                            <mat-step label="${Translations.administration.citizen.inactivationPrompt.form.step2}" formGroupName="1" [stepControl]="formArray.get([1])">
                                <iv-form-builder-element [form]="formArray.get([1])" [input]="input" *ngFor="let input of inputsSecond"></iv-form-builder-element>
                            </mat-step>
                        </mat-vertical-stepper>
                    </iv-util-hidden-scroll>
                </div>

                <mat-dialog-actions class="dialog__actions alt-theme" *ngIf="form">
                    <iv-progress-button
                        buttonType="submit"
                        [disabled]="form.invalid"
                        [loadingState]="isLoading"
                        mat-raised-button
                        color="primary">
                            ${Translations.administration.citizen.unsubscribePrompt.submit}
                    </iv-progress-button>
                    <button
                        type="button"
                        mat-button
                        mat-dialog-close>
                            ${Translations.form.actions.cancel}
                    </button>
                </mat-dialog-actions>
            </form>

            <ng-template #fetching>
                <div class="center-content alt-theme">
                    <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                </div>
            </ng-template>
        </div>
    `,
    standalone: true,
    imports: [MatDialogTitle, NgIf, FormsModule, ReactiveFormsModule, UtilHiddenScrollComponent, MatStepper, MatStepperIcon, MatStep, NgFor, FormBuilderElementComponent, MatButton, MatStepperNext, MatDialogActions, ProgressButtonComponent, MatDialogClose, MatProgressSpinner]
})
export class CitizenUnsubscribeDialogComponent implements OnInit, OnDestroy {

    inputsFirst: FormBuilderTypes[];
    inputsSecond: FormBuilderTypes[];
    form: UntypedFormGroup;
    formArray: UntypedFormArray;

    isFetching = true;
    isLoading = false;

    private unsubscribe = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public customerNo: string,
        private dialogRef: MatDialogRef<CitizenUnsubscribeDialogComponent>,
        private dialogService: DialogService,
        private formBuilder: FormBuilderService,
        private citizenService: CitizenService
    ) { }

    ngOnInit() {

        this.citizenService.getResignationReasons().pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(
            reasons => {
                this.inputsFirst = [
                    new FormBuilderRadio({
                        name: 'causeId',
                        required: true,
                        options: reasons.map<FormBuilderOption>(x => ({ value: x.resignationReasonCode!, label: x.resignationReasonDescription! }))
                    }),
                    new FormBuilderTextarea({
                        name: 'comment',
                        label: Translations.administration.citizen.unsubscribePrompt.comment,
                        hints: [
                            { text: Translations.administration.citizen.unsubscribePrompt.commentHint }
                        ],
                        validation: [Validators.maxLength(80)]
                    })
                ];

                const formFirst = this.formBuilder.toFormGroup(this.inputsFirst);

                this.inputsSecond = [
                    new FormBuilderDatepicker({
                        label: Translations.administration.citizen.inactivationPrompt.form.receivedDate,
                        name: 'receivedDate',
                        required: true
                    }),
                    new FormBuilderRadio({
                        label: Translations.administration.citizen.inactivationPrompt.form.receivedMethod,
                        name: 'receivedMethod',
                        required: true,
                        options: [
                            {
                                label: Translations.administration.citizen.inactivationPrompt.form.receivedVocally,
                                value: CitizenReceivedMethod.Vocally
                            },
                            {
                                label: Translations.administration.citizen.inactivationPrompt.form.receivedWritten,
                                value: CitizenReceivedMethod.Written
                            }
                        ]
                    }),
                    new FormBuilderTextInput({
                        label: Translations.administration.citizen.inactivationPrompt.form.receivedFrom,
                        name: 'receivedFrom',
                        required: true
                    })
                ];

                const formSecond = this.formBuilder.toFormGroup(this.inputsSecond);

                this.formArray = new UntypedFormArray([formFirst, formSecond]);

                this.form = new UntypedFormGroup({
                    formArray: this.formArray
                });

                this.isFetching = false;
            },
            () => this.isFetching = false
            );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onSubmit(): void {
        this.isLoading = true;

        const values = this.form.value;
        const { causeId, comment } = values.formArray[0];
        const { receivedDate, receivedMethod, receivedFrom }: { receivedDate: Date, receivedMethod: CitizenReceivedMethod, receivedFrom: string } = values.formArray[1];

        Helpers.localToUtcDate(receivedDate);

        const model: ITemporaryUnSubsribeCitizenViewModel = {
            customerNo: this.customerNo,
            causeId,
            comment,
            receivedDate,
            receivedFrom,
            receivedMethod
        };

        this.citizenService.unsubscribeCitizen(model).pipe(
            takeUntil(this.unsubscribe),
            finalize(() => this.isLoading = false)
        ).subscribe(
            () => {
                this.dialogService.showSnackMessage({ message: Translations.administration.citizen.unsubscribePrompt.messageOk });
                this.dialogRef.close(true);
            },
            (err: IntervareHttpErrorResponse) => this.dialogService.showSnackMessage({ message: err.validationErrors.join(', ') })
            );

    }
}
