import { ViewportScroller } from '@angular/common';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';

@Directive({
    selector: '[appInterceptLinks]',
    standalone: true
})
export class InterceptLinksDirective {
    private excludedPathRegex?: RegExp;
    private activeDialogId?: string;

    constructor(private element: ElementRef, private router: Router, private viewportScroller: ViewportScroller) { }

    /**
     * Support scroll in dialog/dialog-overlay
     * Pass in the dialog element id, if used to scroll to and anchor in dialog/dialog-overlay
     */
    @Input()
    set dialogId(id: string) {
        this.activeDialogId = id;
    }

    /**
     * Set the paths either by a string or a regex that should not be considered when parsing clicks
     */
    @Input()
    set exclude(paths: string | string[] | RegExp) {
        if (paths instanceof RegExp) {
            this.excludedPathRegex = paths;
        } else if (typeof paths === 'string') {
            this.excludedPathRegex = new RegExp(paths);
        } else {
            this.excludedPathRegex = new RegExp(paths.join('|'));
        }
    }

    /**
     * Event handler to intercept link clicks.
     */
    @HostListener('click', ['$event'])
    public onClick($event: MouseEvent): boolean {
        // Find the linkElement that was clicked
        let linkElement: HTMLAnchorElement | undefined = this.findClickedLinkElement($event.target as HTMLAnchorElement);

        // Stop if not found.
        if (!linkElement) {
            return true;
        }

        const urlObj = this.getUrlForRouter(linkElement);
        linkElement = undefined;
        // Stop if urlObj is undefined.
        if (!urlObj) {
            return true;
        }

        // If you are still here, the router will take over and do the navigation.
        return this.navigate(urlObj);
    }

    /**
     * Navigate to any internal url that is not excluded by the [exclude] input.
     */
    private navigate(url: URL): boolean {
        // Remove # from url hash and convert URLSearchParams to angular compatible Params object.
        const strippedUrlHash: string | undefined = url.hash ? url.hash.replace('#', '') : undefined;
        const params: Params = {};
        url.searchParams.forEach((value, key) => (params[key] = value));
        const extras: NavigationExtras = {
            fragment: strippedUrlHash,
            queryParams: params
        };

        // When anchor linking to an id the pathname will always be '/' because of "<base href="/">" in index.html.
        if (url.hash && url.pathname === '/' && strippedUrlHash) {
            // If interceptLink is used in an dialog and the id of it has been passed.
            if (this.activeDialogId) {
                return this.scrollToAnchorInDialog(strippedUrlHash, this.activeDialogId);
            }

            // If it's the same hash, the scroll effect will not work, so use viewportScroller.scrollToAnchor instead.
            if (url.hash === location.hash) {
                this.viewportScroller.scrollToAnchor(strippedUrlHash);
            } else {
                this.router.navigate([], extras);
            }
        } else {
            // Do regular angular navigation using router.
            this.router.navigate([url.pathname], extras);
        }

        return false;
    }

    /**
     * Recursively find anchor element of whatever was clicked (e.g. if the link has a child that was clicked).
     * Stops looking when reaching directive element.
     */
    private findClickedLinkElement(linkElement: HTMLElement | null): HTMLAnchorElement | undefined {
        // If linkElement is falsy -> Return undefined.
        if (!linkElement) {
            return;
        }

        // Found link -> Return it if it has a href otherwise undefined.
        if (linkElement instanceof HTMLAnchorElement) {
            return linkElement.href ? linkElement : undefined;
        }

        // Reached directive element (and not an anchor) -> Return undefined.
        if (linkElement === this.element.nativeElement) {
            return;
        }

        // Try with parent element recursively.
        return this.findClickedLinkElement(linkElement.parentElement);
    }

    /**
     * Determine if link can be handled by angular router and return URL object if true.
     */
    private getUrlForRouter(linkElement: HTMLAnchorElement): URL | undefined {
        // Eject if link has download attribute.
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(linkElement.download)) {
            return;
        }

        // Eject if links have target _blank or _parent (_self, _top and undefined/'' are not ejected).
        if (linkElement.target && (linkElement.target === '_blank' || linkElement.target === '_parent')) {
            return;
        }

        // Create URL object (needs polyfill for IE11 e.g. https://www.npmjs.com/package/url-polyfill-light).
        const urlObj = new URL(linkElement.href);

        // Eject if link points to another domain (external link).
        if (urlObj.hostname !== location.hostname) {
            return;
        }

        // Eject if current path matches excludedPathRegex.
        if (this.excludedPathRegex && this.excludedPathRegex.test(urlObj.pathname)) {
            return;
        }

        // Eject if link is an anchor link for the current page.
        if (urlObj.pathname === location.pathname && urlObj.hash) {
            return;
        }

        return urlObj;
    }

    /**
     * Perform anchor scrolling within an open dialog.
     */
    private scrollToAnchorInDialog(anchorId: string, dialogId: string): boolean {
        let dialogElement: HTMLElement | undefined = document.getElementById(dialogId) as HTMLElement;
        if (dialogElement) {
            let anchorTarget: HTMLElement | undefined;
            let targetById: HTMLElement | undefined = document.getElementById(anchorId) as HTMLElement;
            let targetsByName: NodeListOf<HTMLElement> | undefined = document.getElementsByName(anchorId) as NodeListOf<HTMLElement>;

            if (targetById) {
                anchorTarget = targetById;
            } else if (targetsByName) {
                anchorTarget = targetsByName[0];
            }

            if (anchorTarget) {
                anchorTarget.scrollIntoView(true);
                dialogElement = undefined;
                anchorTarget = undefined;
                targetById = undefined;
                targetsByName = undefined;
                return false;
            }
        }
        dialogElement = undefined;
        return true;
    }
}
