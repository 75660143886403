import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    CauseViewModel,
    ICauseViewModel,
    INoteCreateModel,
    INoteViewModel,
    ISubCauseViewModel,
    NoteViewModel,
    SubCauseViewModel,
} from '../../api/services';
import { BaseService } from './base-service';

@Injectable()
export class NoteService extends BaseService {

    getCauses(): Observable<ICauseViewModel[]> {
        return this.http.get<object[]>(this.apiBaseUrl('/note/GetCauses')).pipe(
            map(result => result.map(CauseViewModel.fromJS))
        );
    }

    getSubCauses(id: string): Observable<ISubCauseViewModel[]> {
        return this.http.get<object[]>(this.apiBaseUrl('/note/GetSubCauses'), {
            params: {
                id
            }
        }).pipe(map(result => result.map(SubCauseViewModel.fromJS)));
    }

    getUserFields(id: number, isSubSubCause: boolean): Observable<string[]> {
        return this.http.get<string[]>(this.apiBaseUrl('/note/GetUserFields'), {
            params: {
                id: id.toString(),
                isSubSubCause: isSubSubCause.toString()
            }
        });
    }

    getCustomerNote(id: string): Observable<INoteViewModel> {
        return this.http.get(this.apiBaseUrl('/note/GetCustomerNote'), {
            params: {
                id
            }
        }).pipe(map(NoteViewModel.fromJS));
    }

    createNote(data: INoteCreateModel): Observable<INoteViewModel> {
        return this.http.post(this.apiBaseUrl('/note/CreateNote'), data).pipe(
            map(NoteViewModel.fromJS)
        );
    }

    constructor(
        private http: HttpClient
    ) {
        super();
    }

}
