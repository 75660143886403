import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { FormBuilderSelect, FormBuilderTextInput, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { Translations } from '../../translations/translations';
import { MunicipalityService } from '../municipality/municipality.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { CreateUserViewModel, IUser } from '../../api/services';
import { of, Subject } from 'rxjs';
import { USER_IDENTITIES } from '../user/user-roles';
import { Helpers } from '../util/helpers';
import { FormBuilderValidators } from '../form-builder/validators/form-builder-validators';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { DialogService } from '../shared/dialog.service';
import { InternalUserService } from '../services/internal-user.service';
import { Location, NgIf, NgFor } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { MatButton } from '@angular/material/button';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';

type FormStateMode = 'edit' | 'create';

@Component({
    selector: 'iv-administration-create-user',
    template: `
      <div class="administration-create-user">
          <ng-container *ngIf="userDataForm; else fetching">
              <form class="administration-create-user__form alt-theme form" *ngIf="userDataForm;" [formGroup]="userDataForm" autocomplete="off" (submit)="onSubmit()">
                  <iv-form-builder-element [form]="userDataForm" [input]="input" *ngFor="let input of userDataInputs"></iv-form-builder-element>

                  <div class="administration-create-user__actions">
                      <button class="administration-create-user__btn administration-create-user__btn--go-back"
                         mat-raised-button
                         color="accent"
                         type="button" (click)="onBack()"
                      >
                          {{ Translations.forms.labels.municipality.back }}
                      </button>
                      <button *ngIf="mode !== 'edit'" class="administration-create-user__btn administration-create-user__btn--reset" mat-raised-button color="warn" type="button" (click)="onReset()">
                          {{ Translations.forms.labels.municipality.clearForm }}
                      </button>
                      <div class="administration-create-user__spacer"></div>
                      <iv-progress-button class="administration-create-user__btn administration-create-user__btn--create" [loadingState]="loading" color="primary" [buttonType]="'button'" (action)="onSubmit()" >
                          {{ mode === 'edit' ? Translations.forms.labels.municipality.updateUser : Translations.forms.labels.municipality.createUser }}
                      </iv-progress-button>
                      <iv-progress-button *ngIf="mode !== 'edit'" class="administration-create-user__btn administration-create-user__btn--create" [loadingState]="loading" color="primary" [buttonType]="'button'" (action)="onSubmit(true)">
                          {{ Translations.forms.labels.municipality.createAnotherUser }}
                      </iv-progress-button>
                  </div>
              </form>
          </ng-container>
      </div>
      <ng-template #fetching>
          <div class="administration-create-user__spinner">
              <mat-spinner diameter="48"></mat-spinner>
          </div>
      </ng-template>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, FormBuilderElementComponent, MatButton, ProgressButtonComponent, MatProgressSpinner]
})
export class AdministrationCreateUserComponent implements OnInit, OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();
    private readonly numMaxOfChars: number = 2;
    public readonly Translations = Translations;

    private user: Partial<IUser>;

    public mode: FormStateMode = 'create';
    public userDataInputs: FormBuilderTypes[] = [];

    public loading = false;
    public userDataForm: FormGroup;

    constructor(private readonly formBuilder: FormBuilderService,
                private readonly municipalityService: MunicipalityService,
                private readonly internalUserService: InternalUserService,
                private readonly activatedRoute: ActivatedRoute,
                private readonly dialogService: DialogService,
                private readonly cd: ChangeDetectorRef,
                private readonly location: Location
    ) {
    }

    ngOnInit(): void {
        const userId = this.activatedRoute.snapshot.paramMap.get('username');
        if (userId) {
            this.mode = 'edit';
        }

        this.internalUserService.getAllRoles()
            .pipe(
                tap(roles => this.buildNewUserForm(this.mode, roles)),
                switchMap(() => userId ? this.municipalityService.getUser(userId) : of(null)),
                takeUntil(this.unsubscribe$)
            ).subscribe(user => this.patchForm(user));

    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onSubmit = (createAnother: boolean = false): void => {
        if (!this.userDataForm) {
            return;
        }

        if (this.userDataForm.invalid) {
            this.userDataForm.markAllAsTouched();
            this.userDataForm.updateValueAndValidity();
            return;
        }

        const user: CreateUserViewModel = {...(this.userDataForm?.value || {})};
        user.username = this.userDataForm?.get('username')?.value;

        this.loading = true;
        this.municipalityService.createOrUpdateUser(user, this.mode === 'edit')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(_ => {
                this.loading = false;

                if (this.mode === 'edit') {
                    this.dialogService.showSnackMessage({
                        message: Translations.forms.labels.municipality.userUpdated,
                        action: Translations.forms.labels.municipality.snackbarDismiss
                    });
                } else {
                    this.dialogService.showSnackMessage({
                        message: Translations.forms.labels.municipality.userCreated,
                        action: Translations.forms.labels.municipality.snackbarDismiss
                    });
                }

                this.cd.markForCheck();

                if (createAnother) {
                    this.onReset();
                    return;
                }

                this.location.back();

            }, (err: IntervareHttpErrorResponse) => {
                this.dialogService.showValidationResult(err.validationErrors);
                this.loading = false;
                this.cd.markForCheck();
            });
    }

    onBack = (): void => {
        this.location.back();
    }

    private patchForm(user: IUser | null): void {
        if (!user) {
            return;
        }

        this.userDataForm?.patchValue({
            username: user.username,
            name: user.name,
            email: user.email,
            securityRoles: user.securityRoles,
            district: user.districtId
        });

        this.loading = false;
        this.cd.markForCheck();
    }

    private buildNewUserForm(mode: FormStateMode, roles: string[]): void {

        this.userDataInputs = [
            new FormBuilderTextInput({
                name: 'username',
                label: Translations.forms.labels.municipality.username,
                required: true,
                readonly: mode === 'edit',
                condition: () => mode === 'create',
                validation: [Validators.required, Validators.minLength(this.numMaxOfChars)],
                asyncValidation: [FormBuilderValidators.uniqueUsernameValidator(this.internalUserService, this.user?.username)]
            }),
            new FormBuilderSelect({
                name: 'securityRoles',
                label: Translations.forms.labels.municipality.securityRoles,
                multiple: true,
                required: true,
                options: (roles || USER_IDENTITIES.userAdministrationRoles).map(role => ({value: role, label: Helpers.capitalizeFirstLetter(role)}))
            }),
            new FormBuilderTextInput({
                name: 'name',
                label: Translations.forms.labels.municipality.name,
                required: true
            }),
            new FormBuilderTextInput({
                name: 'email',
                label: Translations.forms.labels.municipality.email,
                validation: [Validators.email]
            })
        ];

        this.userDataForm = this.formBuilder.toFormGroup(this.userDataInputs);
        this.cd.markForCheck();
    }


    onReset(): void {
        this.userDataForm.reset({ username: '', securityRoles: [], name: '', email: '', districtId: ''});
    }
}
