import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { ISkipTakeResultObjectOfHistoryItem } from '../../api/services';
import { note } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CitizenHistoryFilter, CitizenService } from '../services/citizen.service';
import { UnderlinePipe } from '../shared/underline.pipe';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CitizenHistoryListComponent } from './citizen-history-list.component';
import { CitizenNoteFormComponent } from './citizen-note-form.component';
import { NgIf } from '@angular/common';
import { MatTabGroup, MatTab } from '@angular/material/tabs';

interface IFilterChange {
    skip: number;
    take: number;
    filter: CitizenHistoryFilter;
}

@Component({
    selector: 'iv-citizen-details-notes',
    template: `
        <article class="notes">
            <mat-tab-group color="accent" (selectedIndexChange)="onTabChange($event)">
                <mat-tab label="${Translations.administration.citizenHistory.tabs.all}"></mat-tab>
                <mat-tab label="${Translations.administration.citizenHistory.tabs.calls}"></mat-tab>
                <mat-tab label="${Translations.administration.citizenHistory.tabs.orders}"></mat-tab>
                <mat-tab label="${Translations.administration.citizenHistory.tabs.notes}"></mat-tab>
                <mat-tab label="${Translations.administration.citizenHistory.tabs.changes}"></mat-tab>
            </mat-tab-group>

            <section class="notes__new-note">
                <button class="notes__new-note-btn" (click)="expandNewNote($event)">
                    <span class="icon">${note}</span>
                    <span class="text" [innerHTML]="'${Translations.administration.citizenHistory.newNoteBtn}' | underline"></span>
                </button>

                <iv-citizen-note-form class="notes__new-note-form" *ngIf="newNote" (create)="onNewNote()"></iv-citizen-note-form>
            </section>

            <section class="notes__list">
                <iv-citizen-history-list
                    *ngIf="notes; else fetching"
                    [customerNo]="customerNo"
                    [historyList]="notes!.results"
                ></iv-citizen-history-list>

                <ng-template #fetching>
                    <div class="spinner center-content alt-theme">
                        <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                    </div>
                </ng-template>
            </section>

            <mat-paginator
                *ngIf="notes"
                [length]="notes!.numFound"
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                [showFirstLastButtons]="true"
                (page)="onPageChange($event)">
            </mat-paginator>
        </article>
    `,
    standalone: true,
    imports: [MatTabGroup, MatTab, NgIf, CitizenNoteFormComponent, CitizenHistoryListComponent, MatProgressSpinner, MatPaginator, UnderlinePipe]
})
export class CitizenDetailsNotesComponent implements OnInit, OnDestroy {

    @Input() customerNo: string;
    @Input() newNote = false;
    notes?: ISkipTakeResultObjectOfHistoryItem;
    pageSizeOptions = [10, 50, 100];
    pageIndex = 0;
    pageSize = this.pageSizeOptions[0];
    currentHistoryFilter = CitizenHistoryFilter.All;

    private unsubscribe = new Subject<void>();

    private filterChanges = new Subject<IFilterChange>();

    constructor(
        private citizenService: CitizenService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.filterChanges.pipe(
            tap(() => this.notes = undefined),
            switchMap(changes => this.citizenService.getCitizenHistory(changes.skip, changes.take, this.customerNo, changes.filter)),
            takeUntil(this.unsubscribe)
        ).subscribe(notes => this.notes = notes);

        this.filterChanges.next({
            skip: this.pageIndex * this.pageSize,
            take: this.pageSize,
            filter: this.currentHistoryFilter
        });

        this.newNote = this.route.snapshot.queryParamMap.get('notes') === 'new';
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onTabChange(tabIndex: number) {
        this.currentHistoryFilter = (tabIndex as CitizenHistoryFilter);
        this.pageIndex = 0;

        this.filterChanges.next({
            skip: this.pageIndex * this.pageSize,
            take: this.pageSize,
            filter: this.currentHistoryFilter
        });
    }

    onPageChange(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        this.filterChanges.next({
            skip: event.pageIndex * event.pageSize,
            take: event.pageSize,
            filter: this.currentHistoryFilter
        });
    }

    onNewNote() {
        this.newNote = false;
        this.pageIndex = 0;
        this.filterChanges.next({ skip: 0, take: this.pageSize, filter: this.currentHistoryFilter });
    }

    @HostListener('document:keydown.shift.control.n', ['$event'])
    expandNewNote(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        this.newNote = !this.newNote;
    }

}
