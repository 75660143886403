import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { IActualCallDayDetailed, IActualCallDayInterval, ICallListViewModel, Timeslot } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IPlanningDayDetails, ITimeSlot } from '../services/call-list-admin.service';
import { BaseService } from './base-service';

export interface ICalendarConfig {
    intervals: number;
    start: number;
    end: number;
}

export const defaultCalendarConfig: ICalendarConfig = {
    intervals: 4,
    start: 7,
    end: 16
};

export class ICol {
    title: string;
    data: IEvent[];
    id: number;
    slots: ISlot[];
    selected?: boolean;
}

export interface ISlot {
    hour: number;
    min: number;
    blocked: boolean;
    events?: IEvent[];
}

export interface IEvent extends IActualCallDayInterval {
    dayOfWeekNo?: number;
    color?: string;
    selected?: boolean;
    disableDrag?: boolean;
    isPause?: boolean;
}

@Injectable()
export class CalendarService extends BaseService {
    timeslots: ITimeSlot[] = [];

    constructor(
        private http: HttpClient,
        private date: DatePipe
    ) {
        super();
        this.getTimeslots();
    }

    async getTimeslots() {
        await this.http.get<object[]>(this.apiBaseUrl('/CallListAdmin/GetTimeslots')).pipe(
            map(x => x.map(Timeslot.fromJS))
        ).subscribe(timeslots => this.timeslots = timeslots);
    }

    actualCallDayToPlanningDay(date: string, actualCallDay: IActualCallDayDetailed[]): IPlanningDayDetails {
        const vm: IPlanningDayDetails = {
            id: date,
            cols: actualCallDay.map<ICol>(col => ({
                title: col.title || '',
                id: col.callerProfileId,
                data: col.intervals || [],
                slots: []
            })),
            title: this.date.transform(date, 'longDate') || ''
        };

        this._calculateSlots(vm.cols);

        return vm;
    }

    weekPlanToCols(weekplan: ICallListViewModel): ICol[] {
        const cols = Translations.callListAdmin.weekDays
            .slice(1)
            .map<ICol>((weekday, index) => {
                return {
                    title: weekday,
                    slots: [],
                    id: index + 1, // since 1-based with monday as 1
                    data: !weekplan.intervals
                        ? []
                        : weekplan.intervals.filter(event => event.dayOfWeekNo === index + 1)
                };
            });

        this._calculateSlots(cols);

        return cols;
    }

    private _calculateSlots(cols: ICol[]): ICol[] {
        return cols.map(col => {
            col.slots = [];
            let time = defaultCalendarConfig.start;
            const endTime = defaultCalendarConfig.end;
            let hasEvent = 0;
            let events: IEvent[] | undefined;
            while (time <= endTime) {
                for (let i = 0; i < defaultCalendarConfig.intervals; i++) {
                    const min = (60 / defaultCalendarConfig.intervals) * i;
                    events = col.data.filter(data => data.startingHour === time && data.startingMinutes === min);

                    hasEvent = events.length
                        ? events.map(event => event.duration).reduce((a: number, b: number) => a + b, 0) / (60 / defaultCalendarConfig.intervals)
                        : hasEvent
                            ? Math.max(hasEvent - 1, 0)
                            : 0;

                    if (events.length) {
                        events = events.map(event => {
                            const timeslot: ITimeSlot = this.timeslots.find(slot => slot.timeslotId === event!.timeslotId) || { duration: 0, timeslotId: 0, timeslotType: 'N' };
                            return {
                                ...event,
                                disableDrag: false,
                                isPause: timeslot.timeslotType === 'P',
                                color: event.allocation && (event.allocation >= event.capacity)
                                    ? 'red'
                                    : 'green',
                                selected: false
                            };
                        });
                    }

                    col.slots.push({
                        hour: time,
                        min,
                        blocked: !!hasEvent,
                        events: events.length ? events : undefined
                    });
                }
                time++;
            }
            hasEvent = 0;
            return col;
        });
    }
}
