import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {
    AbstractControl,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormGroup,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { Translations } from 'Client/translations/translations';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '../shared/dialog.service';
import { Helpers } from '../util/helpers';

import { FormBuilderTypes, FormElementState, RadioGroupSeparatorKey } from './form-builder-element.model';
import { FormBuilderService } from './form-builder.service';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormBuilderElementsCityComponent } from './elements/form-builder-elements-city.component';
import { FormBuilderElementsRichtextComponent } from './elements/form-builder-elements-richtext.component';
import { FormBuilderElementsMunicipalityComponent } from './elements/form-builder-elements-municipality.component';
import { AsyncPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
    selector: 'iv-form-builder-element',
    template: `
        <ng-container [formGroup]="form" [ngSwitch]="input.controlType">
            <iv-form-builder-elements-municipality
                [formControlName]="input.name"
                *ngSwitchCase="'municipality'"
                [multipleDistricts]="input.multipleDistricts"
                [multipleSubdistricts]="input.multipleSubdistricts"
                [label]="input.label"
                [allowEmptyValue]="input.allowEmptyValue"
                [filterDontUse]="input.filterDontUse"
                [required]="isRequired"
            >
            </iv-form-builder-elements-municipality>

            <iv-form-builder-elements-richtext
                [formControlName]="input.name"
                *ngSwitchCase="'richtext'"
                [options]="input.richtextOptions"
                [required]="isRequired"
            >
            </iv-form-builder-elements-richtext>

            <iv-form-builder-elements-city
                [formControlName]="input.name"
                *ngSwitchCase="'city'"
                [label]="input.label"
                [required]="isRequired"
                [state]="state"
            >
            </iv-form-builder-elements-city>

            <div class="form__element" *ngSwitchCase="'checkbox'">
                <mat-checkbox [formControlName]="input.name" color="primary"
                >{{ input.label }} <span *ngIf="isRequired">*</span></mat-checkbox
                >
                <mat-error *ngIf="form.controls[input.name].errors && form.controls[input.name].dirty"
                >{{ errorMessage(form.controls[input.name].errors) }}
                </mat-error>
            </div>

            <mat-form-field class="form__element" *ngSwitchCase="'text'">
                <mat-label>{{ input.label }}</mat-label>
                <input
                    matInput
                    [type]="input.type"
                    [required]="isRequired"
                    [readonly]="input.readonly"
                    [value]="input.value"
                    [formControlName]="input.name"
                />
                <mat-hint *ngFor="let hint of input.hints" [align]="hint.align">{{ hint.text }}</mat-hint>

                <mat-error *ngIf="form.controls[input.name].errors && form.controls[input.name].dirty"
                >{{ errorMessage(form.controls[input.name].errors) }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="form__element" *ngSwitchCase="'password'">
                <mat-label>{{ input.label }}</mat-label>
                <input
                    matInput
                    [type]="showPassword ? 'text' : 'password'"
                    [required]="isRequired"
                    [value]="input.value"
                    [autocomplete]="input.autocomplete ? input.autocomplete : 'off'"
                    [formControlName]="input.name"
                />
                <button matSuffix mat-icon-button aria-label="Clear" (click)="showPassword = !showPassword">
                    <mat-icon *ngIf="!showPassword">visibility</mat-icon>
                    <mat-icon *ngIf="showPassword">visibility_off</mat-icon>
                </button>
                <mat-hint *ngFor="let hint of input.hints" [align]="hint.align">{{ hint.text }}</mat-hint>
                <mat-error *ngIf="form.controls[input.name].errors && form.controls[input.name].dirty"
                >{{ errorMessage(form.controls[input.name].errors) }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="form__element" *ngSwitchCase="'textarea'">
                <mat-label>{{ input.label }}</mat-label>
                <textarea
                    matInput
                    [required]="isRequired"
                    [formControlName]="input.name"
                    [innerHtml]="input.value"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="4"
                    cdkAutosizeMaxRows="10"
                ></textarea>
                <mat-hint *ngFor="let hint of input.hints" [align]="hint.align">{{ hint.text }}</mat-hint>
                <mat-error *ngIf="form.controls[input.name].errors && form.controls[input.name].dirty">
                    {{ errorMessage(form.controls[input.name].errors) }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="form__element" *ngSwitchCase="'date'" (click)="picker.open()">
                <mat-label>{{ input.label }}</mat-label>
                <input
                    matInput
                    [value]="input.value"
                    [matDatepickerFilter]="input.filter"
                    [required]="isRequired"
                    [matDatepicker]="picker"
                    [formControlName]="input.name"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.controls[input.name].errors && form.controls[input.name].dirty">
                    {{ errorMessage(form.controls[input.name].errors) }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="form__element" *ngSwitchCase="'select'">
                <mat-label>{{ input.label }}</mat-label>
                <mat-select
                    [required]="isRequired"
                    [formControlName]="input.name"
                    [multiple]="input.multiple"
                >
                    <mat-option *ngFor="let option of input.options | async" [value]="option.value">
                        {{ option.label }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls[input.name].errors && form.controls[input.name].dirty">
                    {{ errorMessage(form.controls[input.name].errors) }}
                </mat-error>
            </mat-form-field>

            <div
                class="form__element form__radio form__radio--vertical"
                [ngClass]="{
                    'form__radio--vertical-grid': verticalAsGrid
                }"
                *ngSwitchCase="'radio'"
            >
                <mat-label id="form-radio-label" *ngIf="input.label"
                >{{ input.label }}<span *ngIf="isRequired">*</span></mat-label
                >

                <mat-radio-group
                    [required]="isRequired"
                    [formControlName]="input.name"
                    aria-labelledby="form-radio-label"
                >

                    @for (option of (input.options | async); track option.value) {

                        @if (option.value === RadioGroupSeparatorKey) {
                            <div> {{ option.label }}</div>
                        } @else {
                            <mat-radio-button
                                class="form__radio-button"
                                [value]="option.value"
                                color="primary"
                                [ngClass]="{
                                   'form__radio-button--as-sub-option': option?.asSubOption
                                }"
                            >
                                {{ option.label }}
                            </mat-radio-button>
                        }
                    }

                </mat-radio-group>
                <mat-error *ngIf="form.controls[input.name].errors && form.controls[input.name].dirty">
                    {{ errorMessage(form.controls[input.name].errors) }}
                </mat-error>
            </div>

            <mat-form-field class="form__element" *ngSwitchCase="'chip-list'">
                <mat-label>{{ input.label }}</mat-label>
                <mat-chip-grid #chipList>
                    <mat-chip-row
                        *ngFor="let value of input.value"
                        [removable]="true"
                        (removed)="removeChipListValue(value)"
                    >
                        {{ value }}
                        <span matChipRemove *ngIf="true">x</span>
                    </mat-chip-row>
                    <input
                        type="email"
                        [required]="isRequired"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        matChipInputAddOnBlur="true"
                        (matChipInputTokenEnd)="addChipListValue($event)"
                    />
                </mat-chip-grid>
            </mat-form-field>

            <div class="form__element" *ngSwitchCase="'separator'">
                <hr />
            </div>

        </ng-container>
    `,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgSwitch,
        NgSwitchCase,
        FormBuilderElementsMunicipalityComponent,
        FormBuilderElementsRichtextComponent,
        FormBuilderElementsCityComponent,
        MatCheckbox,
        NgIf,
        MatError,
        MatFormField,
        MatLabel,
        MatInput,
        NgFor,
        MatHint,
        MatIconButton,
        MatSuffix,
        MatIcon,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatDatepicker,
        MatSelect,
        MatOption,
        NgClass,
        MatRadioGroup,
        MatRadioButton,
        MatChipGrid,
        MatChipRow,
        MatChipRemove,
        MatChipInput,
        AsyncPipe,
        CdkTextareaAutosize
    ]
})
export class FormBuilderElementComponent implements OnInit, OnChanges, OnDestroy {
    @Input() input: FormBuilderTypes;
    @Input() form: UntypedFormGroup;
    @Input() verticalAsGrid: boolean = false;
    @Input() state: FormElementState;

    isRequired = false;

    isVisible = true;

    separatorKeysCodes = [ENTER, COMMA, SPACE];

    public showPassword = false;
    errorMessage = FormBuilderService.getErrorMessage;
    public readonly RadioGroupSeparatorKey = RadioGroupSeparatorKey;
    private visible$?: Subscription;
    private formControl: AbstractControl;
    private defaultValidators: ValidatorFn[] = [];
    private unsubscribe = new Subject<void>();

    constructor(private elementRef: ElementRef, private dialogService: DialogService) {
    }

    ngOnInit() {

        if (!this.input.name || this.input.name === 'separator') {
            this.form.removeControl(this.input.name);
            return;
        }

        (this.elementRef.nativeElement as HTMLElement).classList.add('form-builder-element__' + this.input.name);
        this.formControl = this.form.controls[this.input.name];

        if (this.input.validation) {
            this.defaultValidators = [...this.input.validation];
        }

        if (this.input.requiredAsync) {
            this.input
                .requiredAsync(this.formControl)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(isRequired => {
                    this.defaultValidators = [...(this.input.validation || [])];

                    if (isRequired) {
                        this.defaultValidators.push(Validators.required);
                    }

                    this.isRequired = isRequired;
                    this.formControl.setValidators(this.defaultValidators);
                    this.formControl.updateValueAndValidity();
                });
        } else {
            this.isRequired = this.input.required || false;
        }

        this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
            if (this.input.valueChanged) {
                this.input.valueChanged(value, this.form);
            }

            // Refresh sync conditions
            this.shouldShow();
        });

        // Emit initial value
        if (this.input.valueChanged && typeof this.input.value !== 'undefined') {
            this.input.valueChanged(this.input.value);
        }

        // execute the first time the validation for visibility;
        this.shouldShow();
        this.shouldShowAsync();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.input) {
            this.setAsyncValidators();
        }

        const stateValue: FormElementState = changes['state']?.currentValue;
        if (stateValue === 'reset') {
            this.formControl.markAsPristine();
            this.formControl.markAsUntouched();
        }
    }

    ngOnDestroy() {
        if (this.visible$) {
            this.visible$.unsubscribe();
            this.visible$ = undefined;
        }

        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    // eslint-disable-next-line
    removeChipListValue(value: any) {
        const index = this.input.value.indexOf(value);

        if (index >= 0) {
            this.input.value.splice(index, 1);
        }
    }

    addChipListValue(event: MatChipInputEvent) {
        const { chipInput, value } = event;
        const trimmedValue = value.trim();

        if (!trimmedValue) {
            return;
        }

        if (Helpers.isValidEmail(trimmedValue)) {
            this.input.value.push(trimmedValue);

            if (chipInput) {
                chipInput.clear();
            }
        } else {
            this.dialogService.showSnackMessage({
                message: Translations.forms.validation.email
            });
        }
    }

    private setAsyncValidators(): void {
        const control = this.form.get(this.input.name);
        if (!control) {
            return;
        }

        const asyncValidators = this.input.asyncValidation;
        if (asyncValidators?.length) {
            control.setAsyncValidators(asyncValidators);
        } else {
            control.clearAsyncValidators();
        }
        control.updateValueAndValidity();
    }

    /**
     * Refresh sync conditions
     */
    private shouldShow() {
        if (!this.input.condition) {
            return;
        }

        if (!(this.input.condition instanceof Observable)) {
            this.isVisible = this.input.condition(this.form);
            this.setDisabledState();
        }
    }

    /**
     * Subscribe to async conditions only
     */
    private shouldShowAsync() {
        if (!this.input.condition) {
            return;
        }

        if (this.input.condition instanceof Observable) {
            this.input.condition.pipe(takeUntil(this.unsubscribe)).subscribe(condition => {
                this.isVisible = condition;
                this.setDisabledState();
            });
        }
    }

    private setDisabledState() {
        setTimeout(() => {
            if (this.isVisible) {
                if (this.formControl.status === 'DISABLED') {
                    this.formControl.enable();
                }
            } else if (this.formControl.status !== 'DISABLED') {
                this.formControl.disable({ onlySelf: true });
            }
        });
    }

}
