/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMapTo, take, takeUntil } from 'rxjs/operators';

import {
    ActualCallDay,
    ActualCallDayDetailed,
    AddCitizenToIntervalResponse,
    Caller,
    CallerDaySummaryViewModel,
    CallListViewModel,
    CloseCallDayOutput,
    CustomerSubType,
    IActualCallDay,
    IAddCitizenToIntervalResponse,
    IAddIntervalModel,
    ICaller,
    ICallerCreateModel,
    ICallerDaySummaryViewModel,
    ICallListViewModel,
    ICitizensToIntervalModel,
    ICloseCallDayOutput,
    ICloseCallDayViewModel,
    ICreateCallListModel,
    IDeleteCallRecordsModel,
    IMoveCitizensOnActualCallDayModel,
    IPendingCalls,
    ISimpleCitizen,
    ISimpleCitizenDetail,
    ISimpleUserModel,
    IUnassignedCitizensPerDayOfWeek,
    IUpdateCallerCapacityModel,
    IUpdateCallListModel,
    IUpdateIntervalModel,
    PendingCalls,
    SimpleCitizen,
    SimpleCitizenDetail,
    SimpleUserModel,
    UnassignedCitizensPerDayOfWeek,
    WeekDays
} from '../../api/services';
import { UtilService } from '../util/util.service';
import { CalendarService, ICol } from './/calendar.service';
import { BaseService } from './base-service';

export interface IPlanningDayDetails {
    title: string;
    id: string;
    cols: ICol[];
}

export interface ICallListDetails {
    title: string;
    id: number;
    cols: ICol[];
}

export interface ITimeSlot {
    timeslotId: number;
    duration: number;
    timeslotType: string;
}

@Injectable()
export class CallListAdminService extends BaseService implements OnDestroy {
    actualCallDays$ = new ReplaySubject<IActualCallDay[]>(1);
    callLists$ = new ReplaySubject<ICallListViewModel[]>(1);
    callers$ = new ReplaySubject<ICaller[]>(1);

    private unsubscribeS: Subject<void> = new Subject();

    constructor(private http: HttpClient, private calendarService: CalendarService, private utilService: UtilService) {
        super();
    }

    ngOnDestroy() {
        this.unsubscribeS.next();
        this.unsubscribeS.complete();
    }

    //#region Functions
    assignNewCitizens(): Observable<boolean> {
        return this.http.post<boolean>(this.apiBaseUrl('/CallListAdmin/AutomaticAllocation'), {});
    }

    getCallcount(type: CustomerSubType, day: WeekDays): Observable<number> {
        return this.http.get<number>(this.apiBaseUrl('/CallListAdmin/GetCustomerCountByDayOfWeekAndType'), {
            params: {
                dayOfWeek: day.toString(),
                customerType: type.toString()
            }
        });
    }

    getPendingCallsList(date?: Date): Observable<IPendingCalls[]> {
        let params;
        if (date) {
            params = { params: { date: this.utilService.dateToString(date) } };
        }

        return this.http
            .get<IPendingCalls[]>(this.apiBaseUrl('/CallListAdmin/GetPendingCallsList'), { params })
            .pipe(map(x => x.map(PendingCalls.fromJS)));
    }

    //#endregion

    //#region Planning

    getPlanningDayDetails(date: string): Observable<IPlanningDayDetails> {
        return this.http.get<any[]>(this.apiBaseUrl('/CallListAdmin/GetActualCallDay'), { params: { date } }).pipe(
            map(x => x.map(ActualCallDayDetailed.fromJS)),
            map(x => this.calendarService.actualCallDayToPlanningDay(date, x))
        );
    }

    getPlanningDaySummary(
        callerProfileId: number,
        callDayId: string,
        intervalId?: number
    ): Observable<ICallerDaySummaryViewModel> {
        return this.http
            .get(this.apiBaseUrl('/CallListAdmin/GetCallerProfileSummary'), {
                params: {
                    callerProfileId: callerProfileId.toString(),
                    executionDay: callDayId,
                    intervalId: intervalId ? intervalId.toString() : ''
                }
            })
            .pipe(map(CallerDaySummaryViewModel.fromJS));
    }

    getPlanningdays(quantity = 5): Observable<IActualCallDay[]> {
        this.http
            .get<object[]>(this.apiBaseUrl('/CallListAdmin/GetLastActualCallDaysDates'), {
                params: { quantity: quantity.toString() }
            })
            .pipe(
                map(x => x.map(ActualCallDay.fromJS)),
                takeUntil(this.unsubscribeS)
            )
            .subscribe(x => this.actualCallDays$.next(x));

        return this.actualCallDays$;
    }

    addCitizens(intervalId: number, customerIds: number[]): Observable<IAddCitizenToIntervalResponse> {
        const model: ICitizensToIntervalModel = {
            intervalId,
            customerIds
        };
        return this.http
            .post(this.apiBaseUrl('/CallListAdmin/AddUnassignedCitizensToActualCallDayInterval'), model)
            .pipe(map(AddCitizenToIntervalResponse.fromJS));
    }

    moveCitizens(
        targetIntervalId: number,
        callRecordIdsToMove: number[],
        targetCallerProfileId: any
    ): Observable<IAddCitizenToIntervalResponse> {
        const model: IMoveCitizensOnActualCallDayModel = {
            targetIntervalId,
            callRecordIdsToMove,
            targetCallerProfileId
        };
        return this.http
            .put(this.apiBaseUrl('/CallListAdmin/MoveCitizens'), model)
            .pipe(map(AddCitizenToIntervalResponse.fromJS));
    }

    removeCitizensFromPlanningDayInterval(callRecordIds: number[]): Observable<boolean> {
        const model: IDeleteCallRecordsModel = {
            callRecordIds
        };
        return this.http.post<boolean>(this.apiBaseUrl('/CallListAdmin/DeleteCallRecords'), model);
    }

    assignCitizensForCallday(): Observable<boolean> {
        return this.http.post<boolean>(this.apiBaseUrl('/CallListAdmin/DistributeUnassignedCitizens'), {});
    }

    getCitizensForCallday(date: string): Observable<ISimpleCitizenDetail[]> {
        return this.http
            .get<object[]>(this.apiBaseUrl('/CallListAdmin/GetUnassignedCitizensForActualCallDay'), {
                params: { date }
            })
            .pipe(map(x => x.map(SimpleCitizenDetail.fromJS)));
    }

    saveCallDay(callDay?: string, extraDay?: string): Observable<boolean> {
        const params = new HttpParams({
            fromObject: {
                extraDay: extraDay || ''
            }
        });

        if (callDay) {
            // Update
            return this.http
                .put<void>(this.apiBaseUrl('/CallListAdmin/EditActualCallDay'), '', { params })
                .pipe(map(() => true));
        }

        return this.http
            .post<void>(this.apiBaseUrl('/CallListAdmin/CreateActualCallDay'), '', { params })
            .pipe(map(() => true));
    }

    deleteCallDay(date: string): Observable<boolean> {
        return this.http.delete<boolean>(this.apiBaseUrl('/CallListAdmin/DeleteActualCallDay'), { params: { date } });
    }

    endDay(callDay: ICloseCallDayViewModel): Observable<ICloseCallDayOutput> {
        return this.http
            .post(this.apiBaseUrl('/CallListAdmin/CloseCallDay'), callDay)
            .pipe(map(CloseCallDayOutput.fromJS));
    }

    endFaxDay(): Observable<boolean> {
        return this.http.post(this.apiBaseUrl('/CallListAdmin/CloseFaxDay'), {}).pipe(map(() => true));
    }

    notifyFourthTrys(): Observable<boolean> {
        return this.http.put(this.apiBaseUrl('/CallListAdmin/Notify4thTrys'), {}).pipe(map(() => true));
    }

    //#endregion

    //#region Call Lists

    getCitizensForCallList(quantity = 50): Observable<IUnassignedCitizensPerDayOfWeek[]> {
        return this.http
            .get<object[]>(this.apiBaseUrl('/CallListAdmin/GetUnassignedCitizensByPreferedDayOfWeek'), {
                params: { quantity: quantity.toString() }
            })
            .pipe(map(x => x.map(UnassignedCitizensPerDayOfWeek.fromJS)));
    }

    addCitizensToCallListInterval(
        intervalId: number,
        customerIds: number[]
    ): Observable<IAddCitizenToIntervalResponse> {
        const model: ICitizensToIntervalModel = {
            intervalId,
            customerIds
        };

        return this.http
            .post(this.apiBaseUrl('/CallListAdmin/AddCitizensToInterval'), model)
            .pipe(map(AddCitizenToIntervalResponse.fromJS));
    }

    getCitizensForCallListInterval(eventId: number): Observable<ISimpleCitizen[]> {
        return this.http
            .get<object[]>(this.apiBaseUrl('/CallListAdmin/GetCitizensOfInterval'), {
                params: { intervalId: eventId.toString() }
            })
            .pipe(map(x => x.map(SimpleCitizen.fromJS)));
    }

    updateEvent(
        intervalId: number,
        timeSlotId: number,
        startingHour: number,
        startingMinute: number,
        dayOfWeekNo: number,
        callListId: number
    ) {
        const model: IUpdateIntervalModel = {
            intervalId,
            timeSlotId,
            startingHour,
            startingMinute,
            dayOfWeekNo
        };
        return this.http
            .put(this.apiBaseUrl('/CallListAdmin/UpdateInterval'), model)
            .pipe(switchMapTo(this.getCallListDetails(callListId)));
    }

    removeCitizensFromInterval(intervalId: number, customerIds: number[], callListId: number) {
        const model: ICitizensToIntervalModel = {
            intervalId,
            customerIds
        };
        return this.http
            .put<boolean>(this.apiBaseUrl('/CallListAdmin/RemoveCitizensFromInterval'), model)
            .pipe(switchMapTo(this.getCallListDetails(callListId)));
    }

    addInterval(addInterval: IAddIntervalModel) {
        return this.http
            .post(this.apiBaseUrl('/CallListAdmin/AddInterval'), addInterval)
            .pipe(switchMapTo(this.getCallListDetails(addInterval.callListId)));
    }

    deleteEvent(eventId: number, callListId: number) {
        return this.http
            .delete<boolean>(this.apiBaseUrl('/CallListAdmin/DeleteInterval'), {
                params: { intervalId: eventId.toString() }
            })
            .pipe(switchMapTo(this.getCallListDetails(callListId)));
    }

    getCallLists(): Observable<ICallListViewModel[]> {
        this.http
            .get<any[]>(this.apiBaseUrl('/CallListAdmin/GetAllCallLists'))
            .pipe(
                map(x => x.map(CallListViewModel.fromJS)),
                takeUntil(this.unsubscribeS)
            )
            .subscribe(x => this.callLists$.next(x));

        return this.callLists$;
    }

    getCallList(id: number): Observable<ICallListViewModel> {
        return this.http
            .get<any[]>(this.apiBaseUrl('/CallListAdmin/GetCallList'), { params: { id: id.toString() } })
            .pipe(map(x => CallListViewModel.fromJS(x)));
    }

    getCallListDetails(id: number): Observable<ICallListDetails> {
        return this.getCallList(id).pipe(
            map<ICallListViewModel, ICallListDetails>(callList => ({
                title: callList.name || '',
                id: callList.callListId,
                cols: this.calendarService.weekPlanToCols(callList)
            }))
        );
    }

    saveCallList(callList: IUpdateCallListModel | ICreateCallListModel): Observable<ICallListViewModel> {
        if ((callList as IUpdateCallListModel).callListId) {
            // UPDATE when exists (has callListId)
            return this.http
                .put(this.apiBaseUrl('/CallListAdmin/UpdateCallList'), callList)
                .pipe(map(() => callList as IUpdateCallListModel));
        }
        // otherwise CREATE
        return this.http
            .post(this.apiBaseUrl('/CallListAdmin/CreateCallList'), callList)
            .pipe(map(CallListViewModel.fromJS));
    }

    deleteCallList(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.apiBaseUrl('/CallListAdmin/DeleteCallList'), {
            params: { id: id.toString() }
        });
    }

    //#endregion

    //#region Callers

    getCaller(id: string): Observable<ICaller> {
        return this.http
            .get(this.apiBaseUrl('/CallListAdmin/GetCaller'), {
                params: {
                    id
                }
            })
            .pipe(map(x => Caller.fromJS(x)));
    }

    getCallers(): Observable<ICaller[]> {
        this.http
            .get<any[]>(this.apiBaseUrl('/CallListAdmin/getCallers'))
            .pipe(
                map(x => x.map(Caller.fromJS)),
                takeUntil(this.unsubscribeS)
            )
            .subscribe(x => this.callers$.next(x));

        return this.callers$;
    }

    saveCaller(caller: ICallerCreateModel | ICaller): Observable<ICaller> {
        if ((caller as ICaller).callerProfileId) {
            // UPDATE when exists (has callerProfileId)
            return this.http
                .put(this.apiBaseUrl('/CallListAdmin/UpdateCaller'), caller)
                .pipe(map(() => caller as ICaller));
        }
        // otherwise CREATE
        return this.http.post(this.apiBaseUrl('/CallListAdmin/CreateCaller'), caller).pipe(map(Caller.fromJS));
    }

    deleteCaller(id: number): Observable<boolean> {
        return this.http.delete<boolean>(this.apiBaseUrl('/CallListAdmin/DeleteCaller'), {
            params: {
                id: id.toString()
            }
        });
    }

    getUsers(id?: number): Observable<ISimpleUserModel[]> {
        // We optionally add the id to also include a specific user to the list
        // (so we can have the current selected in the collection).
        return this.http
            .get<any[]>(this.apiBaseUrl('/CallListAdmin/GetUsersWithoutCallerProfile'), {
                params: {
                    ownUserId: id ? id.toString() : '0'
                }
            })
            .pipe(map(x => x.map(SimpleUserModel.fromJS)));
    }

    updateCapacity(callerProfileId: number, capacity: number, callDayId: string) {
        const date = new Date(callDayId);
        const model: IUpdateCallerCapacityModel = {
            callerProfileId,
            capacity,
            date
        };
        return this.http
            .put<boolean>(this.apiBaseUrl('/CallListAdmin/UpdateCallerCapacity'), model)
            .pipe(switchMapTo(this.getPlanningDayDetails(callDayId)));
    }

    //#endregion
}
