import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { AsteriskPipe } from './asterisk.pipe';
import { FormBuilderElementsCityComponent } from './elements/form-builder-elements-city.component';
import { FormBuilderElementsMunicipalityComponent } from './elements/form-builder-elements-municipality.component';
import { FormBuilderElementsRichtextComponent } from './elements/form-builder-elements-richtext.component';
import { FormBuilderElementComponent } from './form-builder-element.component';
import { FormBuilderService } from './form-builder.service';

@NgModule({
    imports: [CommonModule, MaterialUiModule, FormBuilderElementsMunicipalityComponent,
        FormBuilderElementsRichtextComponent,
        FormBuilderElementComponent,
        FormBuilderElementsCityComponent,
        AsteriskPipe],
    exports: [
        FormBuilderElementsMunicipalityComponent,
        FormBuilderElementsRichtextComponent,
        FormBuilderElementComponent
    ],
    providers: [FormBuilderService, DatePipe]
})
export class FormBuilderModule {}
