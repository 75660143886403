import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, EMPTY, fromEvent, merge, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IBasketRowViewModel } from '../../api/services';
import { check, trash } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { AnimationState } from '../app-state';
import { BasketService } from '../services/basket.service';
import { ProductService } from '../services/product.service';
import { DialogService } from '../shared/dialog.service';
import { ElementRenderer } from '../shared/element-renderer.service';
import { UserService } from '../user/user.service';
import { UtilService } from '../util/util.service';
import { getReservationStatus } from './commerce-utils';
import { isEnter, isMinus, isNotArrowKeys, isNotEnterBackspaceDelete, isNotNumberKeys, isNotShiftTab, isNotTab, isPlus, } from './keycode-helper';
import { TranslationPipe } from '../shared/translation.pipe';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'iv-commerce-addtobasket',
    template: `
        @if (product) {
            <div class="commerce-addtobasket"
                 [ngClass]="{ 'commerce-addtobasket_compact-mode' : compactMode, 'commerce-addtobasket_simple-mode' : simpleMode && quantityInBasket !== 0, 'commerce-addtobasket_minibasket-mode' : mode === 'minibasket', 'commerce-addtobasket__sold-out': compactMode && !canBeReserved }">
                <div class="commerce-addtobasket__quantity-overlay" [@fadeInOut]="animationState"
                     (click)="toggleAnimationState()"></div>

                <div class="commerce-addtobasket__quantity-container" [@flyInOut]="animationState">
                    <div class="alt-theme"
                         *ngIf="(compactMode && (mode === 'basket' || mode === 'minibasket')) && quantityInBasket !== 0">
                        <button type="button" class="commerce-addtobasket__remove" mat-mini-fab color="accent"
                                (click)="remove()" [tabindex]="animationState === 'close' ? -1 : 1">${trash}
                        </button>
                    </div>

                    <div class="commerce-addtobasket__quantity-controls"
                         [class.commerce-addtobasket__quantity-controls_disabled]="!canBeReserved"
                         [class.commerce-addtobasket__quantity-controls_has-focus]="hasFocus">
                        <button type="button"
                                class="commerce-addtobasket__decrement"
                                mat-mini-fab
                                color="primary"
                                [disabled]="!canBeReserved"
                                (click)="decrement()"
                                [tabindex]="animationState === 'close' ? -1 : 1"
                                [attr.aria-label]="'${Translations.commerce.addtobasket.removeOneAlt}' | translation: product.name">
                            -
                        </button>
                        <input class="commerce-addtobasket__quantity"
                               #quantityField
                               type="tel"
                               maxlength="4"
                               *ngIf="isSkeleton || !product.isQuantitySoldOut"
                               [disabled]="!canBeReserved"
                               [(ngModel)]="quantity"
                               (ngModelChange)="onModelChange()"
                               (keydown)="onKeyDown($event)"
                               (keyup.enter)="onEnter()"
                               (focus)="onFocus()"
                               (blur)="onBlur()"
                               [tabindex]="animationState === 'close' ? -1 : 1"
                               [attr.aria-label]="'${Translations.commerce.addtobasket.qtyFieldAlt}' | translation: product.name">

                        <button type="button"
                                class="commerce-addtobasket__increment"
                                mat-mini-fab
                                color="primary"
                                [disabled]="!canBeReserved"
                                (click)="increment()"
                                [tabindex]="animationState === 'close' ? -1 : 1"
                                [attr.aria-label]="'${Translations.commerce.addtobasket.addOneAlt}' | translation: product.name">
                            +
                        </button>

                        <div class="commerce-addtobasket__quantity-in-basket"
                             *ngIf="quantityInBasket !== 0 && !compactMode && !simpleMode">
                            ${check} {{ '${Translations.commerce.addtobasket.inBasket}' | translation: quantityInBasket }}
                        </div>
                    </div>
                </div>

                <button #addButton type="button" class="commerce-addtobasket__button" mat-raised-button color="primary"
                        [disabled]="!product.id || !isLoggedIn || product.isQuantitySoldOut"
                        (click)="compactMode ? updateBasket() : undefined"
                        *ngIf="(compactMode && quantityInBasket === 0 || simpleMode && quantityInBasket === 0) || !compactMode && !simpleMode"
                        tabindex="-1">
                    <ng-container *ngIf="isSkeleton || !product.isQuantitySoldOut; else temporarilySoldout">
                        <ng-container *ngIf="!shouldReplaceProduct">${Translations.commerce.addtobasket.buy}
                        </ng-container>
                        <ng-container *ngIf="shouldReplaceProduct">
                            ${Translations.commerce.reservation.replaceButtonLabel}
                        </ng-container>
                    </ng-container>

                    <ng-template #temporarilySoldout>
                        ${Translations.commerce.addtobasket.temporarilySoldout}
                    </ng-template>
                </button>

                <button type="button" mat-raised-button
                        *ngIf="compactMode && quantityInBasket !== 0 && canBeReserved"
                        class="commerce-addtobasket__button-quantity"
                        color="secondary"
                        [disabled]="!product.id || !isLoggedIn || product.isQuantitySoldOut"
                        (click)="toggleAnimationState()" tabindex="-1">
                    {{ quantityInBasket }}
                </button>

                <div class="alt-theme"
                     *ngIf="mode === 'basket' && quantityInBasket !== 0 && !compactMode || (mode === 'minibasket' && !canBeReserved) || (compactMode  && !canBeReserved)">
                    <button type="button" class="commerce-addtobasket__remove"
                            [ngClass]="{ 'commerce-addtobasket__remove__sold-out' : compactMode && !canBeReserved }"
                            mat-mini-fab color="accent"
                            (click)="remove()"
                            [attr.aria-label]="'${Translations.commerce.addtobasket.removeBtnAlt}' | translation: product.name">
                        ${trash}
                    </button>
                </div>
            </div>
        }
    `,
    animations: [
        trigger('flyInOut', [
            state('open', style({
                transform: 'translateX(0)'
            })),
            state('close', style({
                transform: 'translateX(100%)'
            })),
            transition('close <=> open', animate('200ms ease-in-out')),
            transition('* => inactive', style({
                transform: 'translateX(0)'
            }))
        ]),
        trigger('fadeInOut', [
            state('open', style({
                zIndex: '2',
                opacity: '1'
            })),
            state('close', style({
                zIndex: '-1',
                opacity: '0'
            })),
            transition('close <=> open', animate('200ms ease-in-out')),
            transition('* => inactive', style({
                zIndex: '-1',
                opacity: '0'
            }))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgIf, MatMiniFabButton, FormsModule, MatButton, TranslationPipe]
})
export class CommerceAddtobasketComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
    @Input() product: IBasketRowViewModel;
    @Input() mode?: 'minibasket' | 'basket' | 'details';
    @Input() isFirst?: boolean;

    quantity = '1';
    quantityInBasket = 0;
    hasFocus = false;
    compactMode = false;
    simpleMode = false;
    isLoggedIn = false;
    isSkeleton = true;
    shouldReplaceProduct = false;
    canBeReserved = true;

    animationState = AnimationState.Inactive;

    @ViewChild('quantityField') quantityField: ElementRef;
    @ViewChild('addButton') addButton: MatButton;

    private unsubscribe: Subject<void> = new Subject();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private timeout: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private clickTimeout: any;
    private max: number = Translations.commerce.addtobasket.qtyWarningRules.max;
    private viewHasBeenChecked = false;
    private shouldFocusAfterView = false;
    private alreadyShowAlert = false;
    private hadChanged = false;
    private handleEventsSubscription?: Subscription;
    private originalProduct?: string;

    constructor(
        private cd: ChangeDetectorRef,
        private basketService: BasketService,
        private utilService: UtilService,
        private userService: UserService,
        private dialogService: DialogService,
        private productService: ProductService,
        private renderer: ElementRenderer,
        private activatedRoute: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        if (this.mode === 'minibasket') {
            this.compactMode = true;
            this.animationState = AnimationState.Close;
        } else if (this.mode === 'basket' || this.mode === 'details') {
            this.simpleMode = true;
        }

        // if the product doesn't have the hasReservation property or
        // hasReservation is true, it should act as a normal product item
        if (this.product?.reservation) {
            const {showReservationErrors, isNotAvailable} = getReservationStatus(this.product.reservation);
            this.canBeReserved = showReservationErrors ? !isNotAvailable : true;
        }

        // check if the mode is details and we have the original product to
        // be replaced by the new one
        if (this.mode === 'details') {
            const {originalProduct} = this.activatedRoute.snapshot.queryParams;
            this.originalProduct = originalProduct;
            this.shouldReplaceProduct = !!originalProduct;
        }

        combineLatest([this.basketService.basket$, this.utilService.deviceType$]).pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(data => {
            const basket = data[0];
            const device = data[1];

            if (basket && basket !== 'empty' && basket !== 'impersonate' && basket.rows) {
                const quantity = basket.rows.filter(row => row.id === this.product.id).map(row => row.quantity);
                this.quantityInBasket = quantity.length !== 0 ? quantity[0] : 0;
            }

            if (basket && basket === 'empty') {
                this.quantityInBasket = 0;
            }

            if (this.mode !== 'minibasket' && this.mode !== 'details') {
                this.compactMode = device === 'mobile';

                if (this.compactMode && this.animationState === AnimationState.Inactive) {
                    this.animationState = AnimationState.Close;
                } else if (!this.compactMode) {
                    this.animationState = AnimationState.Inactive;
                }
            }

            if (this.compactMode) {
                this.quantity = this.quantityInBasket.toString();
            } else if (this.simpleMode) {
                this.quantity = this.quantityInBasket !== 0 ? this.quantityInBasket.toString() : '1';
            }

            // check if the quantity in the basket is bigger than max
            // if it is, the alert was already shown
            this.alreadyShowAlert = this.quantityInBasket >= this.max;

            this.cd.markForCheck();
        });

        if (this.isFirst) {
            this.productService.productInFocus.pipe(
                takeUntil(this.unsubscribe)
            ).subscribe(() => {
                if (this.viewHasBeenChecked) {
                    // Try to set focus now
                    this._setFocus();
                } else {
                    // Delay focus in case view is not rendered yet
                    this.shouldFocusAfterView = true;
                }
            });
        }

        this.userService.isLoggedIn.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
            this.cd.markForCheck();
        });
    }

    ngOnChanges() {
        this.isSkeleton = Object.keys(this.product).length === 0;
        this.viewHasBeenChecked = false;
    }

    ngAfterViewChecked() {
        if (!this.viewHasBeenChecked && this.shouldFocusAfterView) {
            this._setFocus();
            this.shouldFocusAfterView = false;
        }

        this.viewHasBeenChecked = true;

        this.handleEvents();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onKeyDown(event: KeyboardEvent) {
        // eslint-disable-next-line deprecation/deprecation
        const keyCode = event.keyCode;

        if (this.product.id && this.isLoggedIn && !this.product.isQuantitySoldOut) {
            if (isEnter(keyCode)) {
                event.preventDefault();
                this.hadChanged = true;
                this.updateBasket();
            } else if (isPlus(keyCode)) {
                event.preventDefault();
                this.increment();
            } else if (isMinus(keyCode)) {
                event.preventDefault();
                this.decrement();
            } else if (isNotNumberKeys(keyCode) && isNotEnterBackspaceDelete(keyCode) && isNotArrowKeys(keyCode) && isNotTab(keyCode) || isNotShiftTab(keyCode, event.shiftKey) || event.altKey) {
                event.preventDefault();
            }
        }
    }

    onEnter() {
        this.productService.focusSearch();
    }

    onFocus() {
        this.hasFocus = true;

        if (this.compactMode) {
            this._clearDelay();
        }

        if (!this.compactMode && this.quantityField && this.quantityField.nativeElement) {
            this.renderer.invokeElementMethod(this.quantityField, 'setSelectionRange', [0, this.quantityField.nativeElement.value.length]);
        }
    }

    onBlur() {
        this.hasFocus = false;
        if (!this.quantity || this.quantity === '0') {
            this._resetQuantityInput();
        }

        if (this.compactMode) {

            if (this.animationState === AnimationState.Open) {
                this._toggleAnimationAfterDelay();
            } else {
                this._clearDelay();
            }


        }
    }

    onModelChange() {
        this.hadChanged = true;
    }

    updateBasket() {
        if (this.compactMode) {
            this.toggleAnimationState();
        }

        const quantity = parseInt(this.quantity);

        // check if the inserted number is really a number and
        // is not, use the quantity already in the basket
        if (isNaN(quantity)) {
            return;
        }

        if (quantity === 0 && this.quantityInBasket === 0 && this.compactMode) {
            this.increment();
        } else {
            this._addtobasket(quantity);
            this._resetQuantityInput();
        }

        this.hadChanged = false;
    }

    increment() {
        if (this.compactMode || this.simpleMode) {
            this.quantity = (parseInt(this.quantity) + 1).toString();
        }

        this.quantityInBasket++;
        this._addtobasket(this.quantityInBasket, true);
    }

    decrement() {
        if (this.quantityInBasket !== 0) {
            if (this.compactMode || this.simpleMode) {
                this.quantity = (parseInt(this.quantity) - 1).toString();
            }

            this.quantityInBasket--;
            this._addtobasket(this.quantityInBasket, true);
        }
    }

    remove() {
        this.quantity = '0';
        this._addtobasket(parseInt(this.quantity));
    }

    toggleAnimationState() {
        if (this.animationState === AnimationState.Open) {
            this.animationState = AnimationState.Close;

            this._clearDelay();

            if (this.quantityInBasket !== parseInt(this.quantity)) {
                this._addtobasket(parseInt(this.quantity));
            }
        } else if (this.animationState === AnimationState.Close) {
            this.animationState = AnimationState.Open;

            if (this.compactMode) {
                this._toggleAnimationAfterDelay();
            }

        }

        this.cd.markForCheck();
    }

    private handleEvents() {
        // check if we are in compact mode (mobile)
        // if yes and we have the handleEventsSubscription should be removed;
        if (this.compactMode) {
            if (this.handleEventsSubscription) {
                this.handleEventsSubscription.unsubscribe();
                this.handleEventsSubscription = undefined;
            }
            return;
        }

        // if we already have the events subscription don't add more
        if (!this.handleEventsSubscription) {
            if (this.mode === 'basket' || this.addButton && this.quantityField) {
                const blurEvent = this.quantityField ? fromEvent(this.quantityField.nativeElement, 'blur') : EMPTY;
                const clickEvent = this.addButton ? fromEvent(this.addButton._elementRef.nativeElement, 'click') : EMPTY;

                let alreadyChanged = false;

                this.handleEventsSubscription = merge(blurEvent, clickEvent).pipe(
                    takeUntil(this.unsubscribe)
                ).subscribe(event => {
                    if (event instanceof FocusEvent && this.hadChanged) {
                        this.updateBasket();
                        alreadyChanged = true;
                    } else if (event instanceof MouseEvent && !this.hadChanged && !alreadyChanged) {
                        alreadyChanged = false;
                        this.updateBasket();
                    } else if (this.hadChanged) {
                        this.hadChanged = false;
                        this.updateBasket();
                    }
                });
            }
        }

    }

    private _addtobasket(quantity: number, delay: boolean = false) {
        if (this.compactMode) {
            this._toggleAnimationAfterDelay();
        }

        // should show a warning every time the quantity exceed the max allowed
        this._showQuantityWarning(quantity);

        // update the quantity in the basket with new quantity;
        this.quantityInBasket = quantity;

        clearTimeout(this.clickTimeout);
        this.clickTimeout = setTimeout(() => {
            // replace the original product for the suggested/alternative one
            // we should set to zero the original product
            if (this.originalProduct) {
                this.basketService.addToBasket(this.originalProduct, 0);
            }

            // should update the product quantities
            this.basketService.addToBasket(this.product.id!, quantity);
        }, delay ? 1000 : 0);

        // Notification for product lists
        if (!this.mode && this.utilService.deviceType$.getValue() === 'mobile') {
            this.dialogService.showSnackMessage({message: Translations.commerce.addtobasket.messageOk});
        }
    }

    private _toggleAnimationAfterDelay() {
        if (this.utilService.isBrowser()) {
            this._clearDelay();

            this.timeout = setTimeout(() => {
                this.toggleAnimationState();
                this.cd.markForCheck();
            }, 3500);
        }
    }

    private _clearDelay() {
        if (this.utilService.isBrowser()) {
            clearTimeout(this.timeout);
        }
    }

    private _setFocus() {
        if (!this.compactMode && this.quantityField && this.quantityField.nativeElement) {
            this.renderer.invokeElementMethod(this.quantityField, 'focus');
        }
    }

    private _resetQuantityInput() {
        if (!this.simpleMode && !this.compactMode) {
            this.quantity = '1';
        }
    }

    /**
     * Shows a dialog message if one of the following rules is hit:
     *
     * #1: Quantity exceeds amount of 10 in a single action
     *
     * @private
     * @param {number} quantity
     */
    private _showQuantityWarning(quantity: number): void {
        // if the quantity is lower than the max warning should reset the flag and next time
        // the quantity is bigger thant the max should show again the alert
        if (quantity <= this.max) {
            this.alreadyShowAlert = false;
        }

        // check if the quantity is bigger than the max warning and if the alert was already shown;
        if (quantity > this.max && !this.alreadyShowAlert) {
            this.alreadyShowAlert = true;
            const msg = Translations.replaceTokens(Translations.commerce.addtobasket.qtyWarningRules.moreThanTen, this.max);
            this.dialogService.showMessage(msg)
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => this.productService.focusSearch());
        }

    }
}
