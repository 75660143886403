export const isEnter = (keyCode: number): boolean => {
    return keyCode === 13;
};

export const isPlus = (keyCode: number): boolean => {
    return keyCode === 107;
};

export const isMinus = (keyCode: number): boolean => {
    return keyCode === 109;
};

export const isNotNumberKeys = (keyCode: number): boolean => {
    return !(keyCode >= 48 && keyCode <= 57 || keyCode >= 96 && keyCode <= 105);
};

export const isNotEnterBackspaceDelete = (keyCode: number): boolean => {
    return !(keyCode === 13 || keyCode === 8 || keyCode === 46);
};

export const isNotArrowKeys = (keyCode: number): boolean => {
    return !(keyCode >= 37 && keyCode <= 40);
};

export const isNotTab = (keyCode: number): boolean => {
    return keyCode !== 9;
};

export const isNotShiftTab = (keyCode: number, shift: boolean): boolean => {
    return keyCode !== 9 && shift;
};
