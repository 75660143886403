import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SkipTakeResultObjectOfSimpleTemporaryCitizen, SimpleTemporaryCitizen } from '../../api/services';
import { BaseService } from './base-service';

@Injectable()
export class FailedSyncsService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    getCitizenSyncs(skip: number, take: number): Observable<SkipTakeResultObjectOfSimpleTemporaryCitizen> {
        return this.http
            .get(this.apiBaseUrl('/Citizen/GetFailedSyncs'), {
                params: {
                    skip: skip.toString(),
                    take: take.toString()
                }
            })
            .pipe(map(SkipTakeResultObjectOfSimpleTemporaryCitizen.fromJS));
    }

    getCitizenSync(id: number): Observable<string> {
        return this.http
            .get(this.apiBaseUrl('/Citizen/GetFailedSync'), {
                params: {
                    syncRecordId: id.toString()
                }
            })
            .pipe(
                map(SimpleTemporaryCitizen.fromJS),
                map(sync => sync.syncResultMessage || '')
            );
    }

    getUserSync(id: string): Observable<string> {
        return this.http
            .get<SimpleTemporaryCitizen>(this.apiBaseUrl('/Citizen/GetFailedSync'), {
                params: {
                    syncRecordId: id
                }
            })
            .pipe(
                map(SimpleTemporaryCitizen.fromJS),
                map(sync => sync.syncResultMessage || '')
            );
    }
}
