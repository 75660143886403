import { Pipe, PipeTransform } from '@angular/core';
import { CustomerSubType } from '../../api/services';
import { Translations } from '../../translations/translations';

@Pipe({
    name: 'customerType',
    standalone: true
})
export class CustomerTypePipe implements PipeTransform {
    transform(customerType: CustomerSubType): string {
        return Translations.global.citizenTypes[CustomerSubType[customerType]];
    }
}
