import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { LayoutCookieWarningComponent } from './layout-cookie-warning.component';
import { LayoutFooterComponent } from './layout-footer.component';
import { LayoutNavigationDeviceComponent } from './layout-navigation-device.component';
import { LayoutNavigationComponent } from './layout-navigation.component';

@NgModule({
    imports: [SharedModule, LayoutNavigationComponent, LayoutFooterComponent, LayoutCookieWarningComponent, LayoutNavigationDeviceComponent],
    exports: [LayoutNavigationComponent, LayoutFooterComponent, LayoutCookieWarningComponent, LayoutNavigationDeviceComponent]
})
export class LayoutModule { }
