import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryMenuComponent } from './category-menu.component';
import { ServicesModule } from '../services/services.module';
import { CategoryMenuLevelComponent } from './category-menu-level.component';
import { RouterModule } from '@angular/router';
import { CategoryMenuLevelDeviceComponent } from './category-menu-level-device.component';
import { MaterialUiModule } from '../material-ui/material-ui.module';

@NgModule({
    imports: [
        CommonModule,
        ServicesModule,
        RouterModule,
        MaterialUiModule,
        CategoryMenuComponent, CategoryMenuLevelComponent, CategoryMenuLevelDeviceComponent
    ],
    exports: [
        CategoryMenuComponent
    ],
})
export class MenuModule { }
