import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    GenesysSettingsViewModel,
    IGenesysSettingsViewModel,
    IPaymentSettings,
    PaymentSettings,
    SystemVersionSetting
} from '../../api/services';
import { BaseService } from './base-service';

@Injectable()
export class SettingsService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    getPaymentSettings(): Observable<IPaymentSettings> {
        return this.http.get(this.apiBaseUrl('/Settings/GetPaymentSettings')).pipe(map(PaymentSettings.fromJS));
    }

    isStockServiceActivated(): Observable<boolean> {
        return this.http.get<boolean>(this.apiBaseUrl('/Settings/IsStockServiceActivated'));
    }

    setStockServiceActivation(isActivated: boolean): Observable<boolean> {
        return this.http
            .put<void>(this.apiBaseUrl('/Settings/SetStockServiceActivation'), { isActivated })
            .pipe(map(() => isActivated));
    }

    IsStockServiceErrorHandlingActivated(): Observable<boolean> {
        return this.http.get<boolean>(this.apiBaseUrl('/Settings/IsStockServiceErrorHandlingActivated'));
    }

    setStockServiceErrorHandling(isActivated: boolean): Observable<boolean> {
        return this.http
            .put<void>(this.apiBaseUrl('/Settings/SetStockServiceErrorHandling'), { isActivated })
            .pipe(map(() => isActivated));
    }

    getCurrentEnvironment(): Observable<string> {
        return this.http.get(this.apiBaseUrl('/Helper/GetCurrentEnvironment'), { responseType: 'text' });
    }

    setPdfEmailJobActivationStatus(isActivated: boolean): Observable<boolean> {
        return this.http
            .put<void>(this.apiBaseUrl('/Settings/SetPdfEmailJobActivationStatus'), { isActivated })
            .pipe(map(() => isActivated));
    }

    IsPdfEmailJobActivated(): Observable<boolean> {
        return this.http.get<boolean>(this.apiBaseUrl('/Settings/IsPdfEmailJobActivated'));
    }

    getVersion(): Observable<string> {
        return this.http.get<SystemVersionSetting>(this.apiBaseUrl('/Settings/GetSystemVersion')).pipe(
            map(SystemVersionSetting.fromJS),
            map(version => version.versionNumber ?? 'N/A')
        );
    }

    getGenesysSettings(): Observable<IGenesysSettingsViewModel> {
        return this.http
            .get<GenesysSettingsViewModel>(this.apiBaseUrl('/Settings/GetGenesysSettings'))
            .pipe(map(GenesysSettingsViewModel.fromJS));
    }
}
