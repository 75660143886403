import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { EMPTY, firstValueFrom, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CitizenStatus, ExtraOrderReason, IDetailedCitizenViewModel } from '../../api/services';
import { pending } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CitizenService } from '../services/citizen.service';
import { IDialogOption } from '../shared/dialog-interfaces';
import { DialogService } from '../shared/dialog.service';
import { RecievedDialogComponent } from '../shared/recieved-dialog.component';
import { RouteService } from '../shared/route.service';
import { CitizenCreditNoteDialogComponent } from './citizen-credit-note-dialog.component';
import { CitizenInactivateDialogComponent } from './citizen-inactivate-dialog.component';
import { CitizenUnsubscribeDialogComponent } from './citizen-unsubscribe-dialog.component';
import { ChunkPipe } from '../shared/chunk.pipe';
import { CitizenDetailsInfoComponent } from './citizen-details-info.component';
import { CitizenDetailsDebtorpostsComponent } from './citizen-details-debtorposts.component';
import { CitizenDetailsCreditnotesComponent } from './citizen-details-creditnotes.component';
import { CommerceOrdersComponent } from '../commerce/commerce-orders.component';
import { CitizenDetailsNotesComponent } from './citizen-details-notes.component';
import { UtilHiddenScrollComponent } from '../util/util-hidden-scroll.component';
import { MatButton } from '@angular/material/button';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'iv-citizen-details',
    template: `
        <article class="dialog citizen-details" *ngIf="citizen">
            <nav class="citizen-details__menu">
                <header class="citizen-details__header">
                    <h3>
                        {{ citizen.name }}
                        <span
                            role="status"
                            class="icon"
                            [class.sync-status-error]="citizen.errorDuringSync"
                            [title]="syncStatusMsg"
                            *ngIf="!citizen.isSync"
                        >
                            ${pending}
                        </span>
                    </h3>
                    <span class="citizen-details__subheader">{{ citizen.phoneNumber | chunk }}</span>
                </header>
                <ul class="citizen-details__menu-list">
                    <li>
                        <a [routerLink]="[{ outlets: { dialog: [ 'citizen', citizen.customerNumber ] } } ]"
                           [queryParams]="{ citizenDetails: null }"
                           [ngClass]="{'active': !citizenDetails}">${Translations.administration.citizen.menuInfo}</a>
                    </li>
                    <li>
                        <a [routerLink]="[{ outlets: { dialog: [ 'citizen', citizen.customerNumber ] } } ]"
                           [queryParams]="{ citizenDetails: 'notes' }"
                           [ngClass]="{'active': citizenDetails === 'notes'}">${Translations.administration.citizen.menuNotes}</a>
                    </li>
                    <li>
                        <a [routerLink]="[{ outlets: { dialog: [ 'citizen', citizen.customerNumber ] } } ]"
                           [queryParams]="{ citizenDetails: 'orders' }"
                           [ngClass]="{'active': citizenDetails === 'orders'}">${Translations.administration.citizen.menuOrders}</a>
                    </li>
                    <li>
                        <a [routerLink]="[{ outlets: { dialog: [ 'citizen', citizen.customerNumber ] } } ]"
                           [queryParams]="{ citizenDetails: 'creditnotes' }"
                           [ngClass]="{'active': citizenDetails === 'creditnotes'}">${Translations.administration.citizen.menuCreditNotes}</a>
                    </li>
                    <li>
                        <a [routerLink]="[{ outlets: { dialog: [ 'citizen', citizen.customerNumber ] } } ]"
                           [queryParams]="{ citizenDetails: 'debtorposts' }"
                           [ngClass]="{'active': citizenDetails === 'debtorposts'}">${Translations.administration.citizen.menuDebtorPosts}</a>
                    </li>
                </ul>
                <div class="citizen-details__menu-actions">
                    <iv-progress-button class="citizen-details__menu-actions-button" buttonType="button" color="accent"
                                        [callback]="citizenCreateExtraOrder" width="full"
                                        *ngIf="citizen.status !== citizenStatus.Resigned">
                        ${Translations.administration.citizen.extraOrderPrompt.actionOpen}
                    </iv-progress-button>
                    <button mat-raised-button class="citizen-details__menu-actions-button" type="button" color="accent"
                            (click)="citizenCreateCreditNote()">
                        ${Translations.administration.citizenCreditNotes.actionOpen}
                    </button>
                    <iv-progress-button class="citizen-details__menu-actions-button" buttonType="button"
                                        color="{{ citizen.status === citizenStatus.Inactive ? 'warn' : 'accent' }}"
                                        [callback]="citizenActivation.bind(this, citizen.status === citizenStatus.Active)"
                                        *ngIf="citizen.status !== citizenStatus.Resigned">{{ citizen.status === citizenStatus.Inactive ? '${Translations.administration.citizen.inactivationPrompt.stop}' : '${Translations.administration.citizen.inactivationPrompt.start}' }}
                    </iv-progress-button>
                    <iv-progress-button class="citizen-details__menu-actions-button" buttonType="button" color="accent"
                                        [callback]="citizenUnsubscribe"
                                        *ngIf="citizen.status !== citizenStatus.Resigned">
                        ${Translations.administration.citizen.unsubscribePrompt.actionOpen}
                    </iv-progress-button>
                </div>
            </nav>
            <section class="citizen-details__content">
                <iv-util-hidden-scroll height="100%">
                    <ng-container [ngSwitch]="citizenDetails">
                        <iv-citizen-details-notes [customerNo]="citizen.customerNumber"
                                                  *ngSwitchCase="'notes'"></iv-citizen-details-notes>
                        <iv-commerce-orders *ngSwitchCase="'orders'"></iv-commerce-orders>
                        <iv-citizen-details-creditnotes *ngSwitchCase="'creditnotes'"></iv-citizen-details-creditnotes>
                        <iv-citizen-details-debtorposts [customerNo]="citizen.customerNumber"
                                                        *ngSwitchCase="'debtorposts'"></iv-citizen-details-debtorposts>
                        <iv-citizen-details-info [citizen]="citizen" *ngSwitchDefault></iv-citizen-details-info>
                    </ng-container>
                </iv-util-hidden-scroll>
            </section>
        </article>
    `,
    standalone: true,
    imports: [NgIf, RouterLink, NgClass, ProgressButtonComponent, MatButton, UtilHiddenScrollComponent, NgSwitch, NgSwitchCase, CitizenDetailsNotesComponent, CommerceOrdersComponent, CitizenDetailsCreditnotesComponent, CitizenDetailsDebtorpostsComponent, NgSwitchDefault, CitizenDetailsInfoComponent, ChunkPipe]
})
export class CitizenDetailsComponent implements OnInit, OnDestroy {
    citizenDetails: string;
    citizenStatus = CitizenStatus;
    syncStatusMsg = Translations.administration.citizenQuickView.pendingChanges;

    private unsubscribe = new Subject<void>();
    private currentTitle: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public citizen: IDetailedCitizenViewModel,
        private route: ActivatedRoute,
        private dialogService: DialogService,
        private citizenService: CitizenService,
        private routeService: RouteService,
        private title: Title
    ) {
    }

    ngOnInit() {
        this.currentTitle = this.title.getTitle();

        this.route.queryParamMap.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(params => {
            this._setTitle();
            this.citizenDetails = params.get('citizenDetails') || '';

            //  check the citizen sync status and add show error on the title if we have one.
            if (!this.citizen.isSync && this.citizen.lastSyncResult) {
                this.syncStatusMsg = this.citizen.lastSyncResult || '';
            }
        });

        this.routeService.activeRoute$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => this._setTitle());
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.title.setTitle(this.currentTitle);
    }

    citizenActivation = (inactivate: boolean): Promise<boolean> => {
        if (inactivate) {
            const dialogRef = this.dialogService.openDialogWithComponent(CitizenInactivateDialogComponent, {
                data: this.citizen.customerNumber
            });

            return firstValueFrom(dialogRef.afterClosed().pipe(
                switchMap((res: boolean) => res ?
                    this.citizenService.getCitizenDetails(this.citizen.customerNumber!)
                    : EMPTY
                ),
                tap(citizen => this.citizen = citizen),
                map(() => true),
                takeUntil(this.unsubscribe)
            ))
        } else {
            return firstValueFrom(this.dialogService.openDialogWithComponent(RecievedDialogComponent).afterClosed().pipe(
                filter(form => !!form),
                map(form => ({
                    customerNo: this.citizen.customerNumber!,
                    receivedDate: form.ReceivedDate,
                    receivedMethod: form.ReceivedMethod,
                    receivedFrom: form.ReceivedFrom
                })),
                switchMap(form => this.citizenService.activateCitizen(form)),
                switchMap(() => this.citizenService.getCitizenDetails(this.citizen.customerNumber!)),
                tap(citizen => this.citizen = citizen),
                map(() => true),
                takeUntil(this.unsubscribe)
            ))
        }
    }

    citizenUnsubscribe = (): Promise<boolean> => {
        this.citizenService.getResignationReasons().pipe(
            map(reasons => reasons.map<IDialogOption>(x => ({value: x, label: x.resignationReasonDescription!}))),
            takeUntil(this.unsubscribe)
        );

        const dialogRef = this.dialogService.openDialogWithComponent(CitizenUnsubscribeDialogComponent, {
            data: this.citizen.customerNumber,
            maxWidth: '500px',
        });

        return firstValueFrom(dialogRef.afterClosed().pipe(
            switchMap((res: boolean) => res ?
                this.citizenService.getCitizenDetails(this.citizen.customerNumber!)
                : EMPTY
            ),
            tap(citizen => this.citizen = citizen),
            map(() => true),
            takeUntil(this.unsubscribe)
        ))
    }

    citizenCreateExtraOrder = (): Promise<boolean> => {
        const dialogRef = this.dialogService.showOptions({
            data: {
                title: Translations.administration.citizen.extraOrderPrompt.title,
                options: Object.values(ExtraOrderReason).filter(x => typeof x === 'string').map<IDialogOption>(x => ({
                    value: x,
                    label: Translations.administration.citizen.extraOrderPrompt.extraOrderReasonsTranslations[x]
                })),
                actionText: Translations.administration.citizen.extraOrderPrompt.actionText,
                isSmallerContent: true,
            },
        });

        return firstValueFrom(dialogRef.afterClosed().pipe(
            switchMap(res => res ?
                this.citizenService.createExtraOrder({customerNumber: this.citizen.customerNumber, reason: res})
                : EMPTY
            ),
            tap({
                next: () => this.dialogService.showSnackMessage({message: Translations.replaceTokens(Translations.administration.citizen.extraOrderPrompt.messageOk, this.citizen.name)}),
                error: () => this.dialogService.showSnackMessage({message: Translations.replaceTokens(Translations.administration.citizenQuickView.citizenActionError, this.citizen.name)})
            }),
            takeUntil(this.unsubscribe)
        ));
    }

    citizenCreateCreditNote(): void {
        this.dialogService.openDialogWithComponent(CitizenCreditNoteDialogComponent, {
            disableClose: true,
            width: '1000px',
            maxWidth: '1000px'
        });
    }

    private _setTitle(): void {
        this.title.setTitle(Translations.global.title + ' - ' + this.citizen.name);
    }
}
