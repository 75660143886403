import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'underline',
    standalone: true
})
export class UnderlinePipe implements PipeTransform {
    transform(text: string): SafeHtml {
        return this.sanitize.bypassSecurityTrustHtml(`<span class="underline">${text[0]}</span>${text.substring(1)}`);
    }

    constructor(
        private sanitize: DomSanitizer
    ) { }
}
