import { Pipe, PipeTransform } from '@angular/core';
import { ISubDistrict } from '../../api/services';

@Pipe({
    name: 'subDistrictNames',
    standalone: true
})
export class SubDistrictNamesPipe implements PipeTransform {

    /**
     * Helper to generate the label of the users subDistricts, with an ellipsis if needed
     * @param {ISubDistrict[]} subDistricts
     * @param {boolean} ellipsis
     * @returns {string}
     *
     */
    transform(subDistricts: ISubDistrict[], ellipsis: boolean = false): string {
        if (!subDistricts) {
              return '';
          }

        const names = subDistricts.map(subDistrict => subDistrict.subDistrictName).join(', ');

        return ellipsis && subDistricts.length > 1
            ? names.slice(0, subDistricts[0].subDistrictName!.length + 5) + '...'
            : names;
    }
}
