import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class UtilUrlService {

    constructor(private location: Location) { }

    setState(params: string | HttpParams): void {
        const processedQueryParams = params.toString();
        if (processedQueryParams) {
            this.location.replaceState(this.pathname(), processedQueryParams);
        }
    }

    pathname(): string {
        return this.location.path().split('?')[0];
    }

    searchPath(): HttpParams {
        const path = this.location.path().split('?');
        const searchPath = path.length > 1 ? path[1] : '';
        return new HttpParams({ fromString: searchPath });
    }
}
