import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IComponentLoaderOptions } from './component-loader';

@Component({
    selector: 'iv-util-component-loader',
    template: '',
    standalone: true
})
export class UtilComponentLoaderComponent implements OnInit, OnDestroy {
    @Input() input: IComponentLoaderOptions<unknown>;
    @Output() output = new EventEmitter();

    private unsubscribe = new Subject<void>();

    constructor(private viewContainer: ViewContainerRef) {
    }

    ngOnInit() {

        if (!this.input?.component) {
            console.warn('No component was defined');
            return;
        }

        const component = this.viewContainer.createComponent(this.input.component);
        (component.instance as any)['data'] = this.input.data as any;

        if ((component.instance as any)['output']) {
            (component.instance as any)['output'].pipe(
                takeUntil(this.unsubscribe)
            ).subscribe((data: any) => this.output.emit(data));
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
