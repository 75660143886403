import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ICreditNoteHistoryViewModel, ICreditNoteViewModel, IOrderHistoryViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { CommerceSummaryComponent } from './commerce-summary.component';
import { CommerceOrdersRowsComponent } from './commerce-orders-rows.component';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, AsyncPipe } from '@angular/common';

type InvoiceObs = Observable<IOrderHistoryViewModel | ICreditNoteViewModel | ICreditNoteHistoryViewModel>;
type InvoiceInput = IOrderHistoryViewModel | ICreditNoteViewModel | ICreditNoteHistoryViewModel | InvoiceObs;

@Component({
    selector: 'iv-commerce-invoice',
    template: `
        <section class="invoice" *ngIf="invoice$ | async as order">
            <header class="invoice__header" [ngSwitch]="isCreditNote(order)">
                <ng-container *ngSwitchCase="true">
                    <h2>${Translations.commerce.invoice.creditNoteHeader}</h2>
                    <div class="subtitle">
                        <strong>${Translations.commerce.invoice.creditNoteHeader}:</strong> {{ order.creditNo }}
                    </div>
                    <div class="subtitle">
                        <strong>${Translations.commerce.invoice.creditedInvoiceHeader}:</strong> {{ order.orderNo }}
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <h2>${Translations.commerce.invoice.invoiceHeader}</h2>
                    <div class="subtitle">
                        <strong>${Translations.commerce.invoice.invoiceHeader}:</strong> {{ order.orderNo }}
                    </div>
                </ng-container>
            </header>
            <iv-commerce-orders-rows
                [priceAdjustmentInvoiceMode]="priceAdjustmentMode"
                [rows]="order.rows"
            ></iv-commerce-orders-rows>
            <iv-commerce-summary [data]="order"></iv-commerce-summary>
        </section>
    `,
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, CommerceOrdersRowsComponent, CommerceSummaryComponent, AsyncPipe]
})
export class CommerceInvoiceComponent implements OnInit {
    @Input() invoice: InvoiceInput;
    @Input() priceAdjustmentMode: boolean = false;
    invoice$: InvoiceObs;

    ngOnInit() {
        this.invoice$ = this.invoice instanceof Observable ? this.invoice : of(this.invoice);
    }

    isCreditNote(
        invoice: IOrderHistoryViewModel | ICreditNoteViewModel | ICreditNoteHistoryViewModel
    ): invoice is ICreditNoteViewModel {
        return !!(invoice as ICreditNoteViewModel).creditNo;
    }
}
