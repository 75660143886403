/*
'Unspecified': 'Uangivet',
'IMPACT_QTDIFF': 'You have tried to buy {0} but we only have {1} available',
'IMPACT_QT_MISMATCH': 'The quantity doesn\'t match with the current reservation',
'IMPACT_FAIL': 'Something is not right, please try again later',
'IMPACT_RESERVATION_NOT_FOUND': 'The reservation was not found',
'IMPACT_RESERVATION_EXTENDED_FAIL': 'The reservation extending failed',
'R003': 'The reservation was not found and cannot be changed.',
'R005': 'Internal error, this has been reported to the support team.',
'R104': 'It is not possible to handle the reservation because it has expired',
'R105': 'Sorry, there are not enough items available! Please select the replacement product or remove this one',
'R106': 'Reservations cannot be merged because there is an internal error with the fields.'
*/

export enum ReservationErrorCodeType {
    Unspecified = 'Unspecified',
    IMPACT_QTDIFF = 'IMPACT_QTDIFF',
    IMPACT_QT_MISMATCH = 'IMPACT_QT_MISMATCH',
    IMPACT_FAIL = 'IMPACT_FAIL',
    IMPACT_RESERVATION_NOT_FOUND = 'IMPACT_RESERVATION_NOT_FOUND',
    IMPACT_RESERVATION_EXTENDED_FAIL = 'IMPACT_RESERVATION_EXTENDED_FAIL',
    R003 = 'R003',
    R005 = 'R005',
    R104 = 'R104',
    R105 = 'R105',
    R106 = 'R106',
}

export interface IReservationStatus {
    isNotAvailable: boolean;
    hasWarning: boolean;
    canBeReserved: boolean;
    showReservationErrors: boolean;
}

export interface IRowsReservationStatus {
    reservationHasErrors: boolean;
    showReservationErrors: boolean;
}

