import { Component, HostBinding, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'iv-ellipsis',
    template: `
        <span matTooltip="{{ text }}">
            {{ text }}
        </span>
	`,
    standalone: true,
    imports: [MatTooltip],
})

export class EllipsisComponent {
    @HostBinding('class.ellipsis') @Input() text: string = "";
}
