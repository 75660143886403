import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '../shared/dialog.service';
import { IDialogComponentSettings } from './dialog-interfaces';

@Component({
    selector: 'iv-dialog-outlet',
    template: '',
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class DialogOutletComponent implements OnInit, OnDestroy {
    private unsubscribeS$: Subject<void> = new Subject();

    constructor(private dialogService: DialogService, private route: ActivatedRoute, private router: Router, private location: Location) { }

    ngOnInit() {
        this.route.data.pipe(takeUntil(this.unsubscribeS$)).subscribe(data => {
            if (data.dialogComponent) {
                this.openDialog(
                    data.dialogComponent,
                    data.fullscreen !== undefined ? data.fullscreen : true,
                    data.nomargin !== undefined ? data.nomargin : false,
                    data.width !== undefined ? data.width : undefined,
                    data.maxWidth !== undefined ? data.maxWidth : undefined,
                    data.height !== undefined ? data.height : undefined,
                    data.maxHeight !== undefined ? data.maxHeight : undefined,
                    data.queryParamsHandling ? data.queryParamsHandling : 'preserve',
                    data.data
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    openDialog(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dialogComponent: any,
        mobileFullscreen = true,
        noMargin = false,
        width?: string,
        maxWidth?: string,
        height?: string,
        maxHeight?: string,
        queryParamsHandling?: 'merge' | 'preserve',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data?: any
    ) {
        window.setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const dialogSettings: IDialogComponentSettings<any> = {
                data,
                mobileFullscreen,
                noMargin,
                width,
                maxWidth,
                height,
                maxHeight
            };

            const ref = this.dialogService.openDialogWithComponent(dialogComponent, dialogSettings);

            ref.afterClosed()
                .pipe(takeUntil(this.unsubscribeS$))
                .subscribe(() => {
                    /**
                     * NOTE: INT-2011 This serves as a workaround for the issue where two dialogs are opened simultaneously (triggered from HTML in the product description).
                     * The first dialog is closed by the canActivateFn in redirect-product.guard.ts, and as a result, the intended navigation is not executed.
                    */
                    const fromRedirect = this.location.getState() as { redirectFromProduct?: boolean };
                    if (fromRedirect?.redirectFromProduct) {
                        this.location.replaceState(this.location.path() ?? "", undefined, { redirectFromProduct: undefined });
                        return;
                    }

                    this.router.navigate(
                        [
                            // eslint-disable-next-line no-null/no-null
                            { outlets: { dialog: null } }
                        ],
                        {
                            queryParamsHandling
                        }
                    );
                });
        }, 0);
    }
}
