import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormControl, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { takeUntil, tap, switchMap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { IAllowedDeliveryDate } from './../../api/services';
import { BasketService } from './../services/basket.service';
import { Translations } from './../../translations/translations';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'iv-commerce-delivery-date',
    template: `
        <div class="commerce-delivery-date__date-picker"
            [class.commerce-delivery-date__date-picker--compact]="isCompact"
            [class.commerce-delivery-date__date-picker--fetching]="!preferredDeliveryDates?.length || !control"
            >
            <ng-container *ngIf="preferredDeliveryDates && control; else fetching">
                <mat-form-field  class="commerce-delivery-date__picker"
                    [class.commerce-delivery-date__picker--full-width]="!showDeliveryDateButtons">
                    <mat-label>${Translations.commerce.basket.deliveryDate}</mat-label>
                    <input [formControl]="control" matInput [matDatepickerFilter]="deliveryDateFilter" [matDatepicker]="picker" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="control.invalid && control.errors.required">${Translations.commerce.basket.validation.deliveryDate.required}</mat-error>
                    <mat-error *ngIf="control.invalid && control.errors.matDatepickerFilter">${Translations.commerce.basket.validation.deliveryDate.notAvailable}</mat-error>
                </mat-form-field>

                <div class="commerce-delivery-date__preferred-delivery" *ngIf="showDeliveryDateButtons && preferredDeliveryDates.length">
                    <div class="commerce-delivery-date__preferred-delivery-text">${Translations.commerce.basket.preferredDeliveryDays}</div>

                    <button mat-raised-button type="button"
                        color="primary"
                        class="commerce-delivery-date__preferred-delivery-btn"
                        (click)="setDeliveryDate($event, preferredDeliveryDate)"
                        *ngFor="let preferredDeliveryDate of preferredDeliveryDates">
                        {{ preferredDeliveryDate | date : 'shortDate' }}
                    </button>
                </div>

            </ng-container>
        </div>

        <ng-template #fetching>
            <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="30"></mat-progress-spinner>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatFormField, MatLabel, MatInput, FormsModule, MatDatepickerInput, ReactiveFormsModule, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, NgFor, MatButton, MatProgressSpinner, DatePipe]
})
export class CommerceDeliveryDateComponent implements OnInit, OnDestroy {
    private readonly unsubscribe: Subject<void> = new Subject();

    @Input() controlName: string;
    @Input() control: FormControl;
    @Input() deliveryDates: IAllowedDeliveryDate[];
    @Input() showDeliveryDateButtons = true;
    @Input() isCompact = false;

    preferredDeliveryDates: Date[];

    constructor(
        private cd: ChangeDetectorRef,
        private basketService: BasketService) {
    }

    ngOnInit(): void {
        if (!this.deliveryDates) {
            this.getDeliveryDates()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(this.getPreferredDeliveryDates.bind(this));
            return;
        }

        this.getPreferredDeliveryDates(this.deliveryDates);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    deliveryDateFilter = (date: Date | null): boolean => {
        if (!date) {
            return false;
        }

        return this.deliveryDates.filter(deliveryDate => {
            deliveryDate.date.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);
            return deliveryDate.date.toUTCString() === date.toUTCString();
        }).length > 0;
    }

    setDeliveryDate(event: MouseEvent, preferredDeliveryDate: Date) {
        event.preventDefault();
        if (this.control && preferredDeliveryDate !== this.control.value) {
            this.control.setValue(preferredDeliveryDate);
        }
    }

    private getDeliveryDates(): Observable<IAllowedDeliveryDate[]> {
        return this.basketService.getAllowedDeliveryDates();
    }

    private getPreferredDeliveryDates(deliveryDates: IAllowedDeliveryDate[]): void {
        this.deliveryDates = deliveryDates;
        this.preferredDeliveryDates = deliveryDates.filter(deliveryDate => deliveryDate.isPreferredDeliveryDay).map(deliveryDate => deliveryDate.date);
        this.setFormControl();
        this.cd.markForCheck();
    }

    private setFormControl(): void {
        const preferredDeliveryDate = this.deliveryDates.filter(deliveryDate => deliveryDate.isSelected)[0].date;
        if (!this.control) {
            this.control = new UntypedFormControl(preferredDeliveryDate);

            this.control.valueChanges
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(this.setBasketDeliveryDate.bind(this));
        }
    }

    private setBasketDeliveryDate(value: Date): void {
        if (this.control.invalid) {
            return;
        }

        this.basketService.setDeliveryDate({ deliveryDate: value })
            .pipe(
                tap(basket => this.basketService._nextBasket(basket)),
                switchMap(() => this.getDeliveryDates()),
                takeUntil(this.unsubscribe)
            )
            .subscribe(this.getPreferredDeliveryDates.bind(this));
    }
}
