import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';

import { ISubCauseViewModel, ISubSubCauseViewModel } from '../../api/services';
import { DialogService } from '../shared/dialog.service';
import { IDefaultRouteData } from '../shared/route-data';
import { NotesAdminCreateSubSubCauseComponent } from './notes-admin-create-subsubcause.component';
import { Translations } from '../../translations/translations';
import { pen } from '../../scripts/generated/icons';
import { NgIf, NgFor } from '@angular/common';
import { AccordionComponent } from '../shared/accordion.component';

@Component({
    selector: 'iv-notes-admin-subcause',
    template: `
        <iv-accordion (open)="accordionOpen = true" (close)="accordionOpen = false">
            <span class="category-menu__link" [class.category-menu__link_active]="accordionOpen" accordion-title>
                {{ subCause.subCauseName }}
                <a [routerLink]="['', { outlets: { dialog: ['${Translations.intervare.paths.notesAdmin.path}', 'subCause', subCause.causeId, subCause.subCauseId]} }]" class="edit-icon" (click)="onEdit($event)">${pen}</a>
            </span>
            <div class="category-menu__level" accordion-content>
                <a class="category-menu__link" [routerLink]="['', { outlets: { dialog: ['${Translations.intervare.paths.notesAdmin.path}', 'subSubCause', subCause.subCauseId, ''] } }]" routerLinkActive="category-menu__link_active">${Translations.intervare.notesAdmin.navigation.createSubSubcause}</a>
                <ng-container *ngIf="accordionOpen">
                    <div class="category-menu__link" routerLinkActive="category-menu__link_active" *ngFor="let subSubCause of subCause.subSubCauses">
                        {{ subSubCause.subSubCauseName }}
                        <a [routerLink]="['', { outlets: { dialog: ['${Translations.intervare.paths.notesAdmin.path}', 'subSubCause', subCause.subCauseId, subSubCause.subSubCauseId]} }]" class="edit-icon">${pen}</a>
                    </div>
                </ng-container>
            </div>
        </iv-accordion>
    `,
    standalone: true,
    imports: [AccordionComponent, RouterLink, RouterLinkActive, NgIf, NgFor]
})
export class NotesAdminSubcauseComponent {
    @Input() subCause: ISubCauseViewModel;
    accordionOpen = false;

    constructor(
        private route: ActivatedRoute,
        private dialog: DialogService
    ) { }

    onEdit(event: Event) {
        event.stopPropagation();
    }
}
