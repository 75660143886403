import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy, signal } from '@angular/core';
import { Params } from '@angular/router';
import { IGenesysSettingsViewModel, IUserViewModel } from 'Client/api/services';
import { IDENTITIES, IUserRoles } from 'Client/app/user/user-roles';
import { UtilUrlService } from 'Client/app/util/util-url.service';
import platformClient from 'purecloud-platform-client-v2';
import { BehaviorSubject, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Translations } from '../../../translations/translations';
import {
    CallerPresenceStatus,
    CallerPresenceStatusDefinition,
    PresenceStatus,
    PresenceStatusEnum,
    PresenceTypeEnum
} from '../calls/calls.models';
import { SettingsService } from '../settings.service';
import { getPresenceDefinitionsStatus, matchPresenceStatusFromEvent } from './genesys.helper';
import { GenesysPresence, GenesysState, State } from './genesys.model';

@Injectable({
    providedIn: 'root'
})
export class GenesysService implements OnDestroy {
    public conversationsApiInstance: platformClient.ConversationsApi;
    private readonly genesysTokenKey = 'genesys_token';
    private readonly genesysLocale = 'da';
    private userPresenceDefinitions = signal<platformClient.Models.OrganizationPresenceDefinition[]>([]);
    private client: platformClient.ApiClientClass;
    private tokensApiInstance: platformClient.TokensApi;
    private presenceApiInstance: platformClient.PresenceApi;
    private notificationsApiInstance: platformClient.NotificationsApi;
    private authTokenBS = new BehaviorSubject<string>('');
    private conversationSettingsBS = new BehaviorSubject<IGenesysSettingsViewModel>({
        listOfAllowedPhoneNumbers: [],
        useListOfAllowedPhoneNumbers: false
    });
    public conversationSettings$ = this.conversationSettingsBS.asObservable();
    private unsubscribeS = new Subject<void>();
    private genesysStateRS = new ReplaySubject<GenesysState>(1);
    private currentUser: platformClient.Models.User;
    private genesysPresencesSignal = signal<GenesysPresence>({
        presenceDefinition: {
            systemPresence: PresenceStatusEnum.Unknown
        }
    });
    public genesysPresences = this.genesysPresencesSignal.asReadonly();
    private callStatusSocket?: WebSocket;
    private presenceTopic = signal<string>('');

    constructor(
        private settingsService: SettingsService,
        private utilUrlService: UtilUrlService,
        private location: Location
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribeS.next();
        this.unsubscribeS.complete();
    }

    init(user?: IUserViewModel): void {
        const isCustomerServiceUser = user?.roles?.some(x =>
            IDENTITIES.adminAndCustomerService.includes(x as IUserRoles)
        );

        if (!isCustomerServiceUser || !user?.hasCallerProfile) {
            this.genesysStateRS.next({ state: State.IDLE });
            return;
        }

        if (!this.client) {
            console.log('GenesysService.init()');
            this.genesysStateRS.next({ state: State.LOADING, message: 'Loading Genesys' });
            this.getSettings();
            this.client = platformClient.ApiClient.instance;
            this.conversationsApiInstance = new platformClient.ConversationsApi();
            this.tokensApiInstance = new platformClient.TokensApi();
            this.presenceApiInstance = new platformClient.PresenceApi();
            this.notificationsApiInstance = new platformClient.NotificationsApi();
            this.client.setEnvironment('https://api.mypurecloud.de');
            this.loadToken();
        }
    }

    checkAuthentication(params: Params, user?: IUserViewModel) {
        if (!user?.hasCallerProfile) {
            return of(false);
        }

        const token = params[this.genesysTokenKey];

        if (token) {
            this.storeToken(token);
            this.clearGenesysToken(params);
            return of(true);
        }

        return this.validateCurrentToken().pipe(
            map(isValid => {
                if (isValid) {
                    this.genesysStateRS.next({ state: State.READY, message: 'Genesys Ready' });
                    this.getUserPresence();
                    this.clearGenesysToken(params);
                    return true;
                } else {
                    this.genesysStateRS.next({ state: State.ERROR, message: 'Error validating token' });
                    if (user?.hasCallerProfile) {
                        console.warn('Genesys token is invalid, re-authenticating');
                        this.authenticate();
                    }
                    return false;
                }
            })
        );
    }

    authenticate() {
        const url = environment.production
            ? `${location.protocol}//${location.host}/Genesys/Login`
            : 'https://localhost:5001/Genesys/Login';
        location.href = url;
    }

    disconnect() {
        localStorage.removeItem(this.genesysTokenKey);

        if (!this.callStatusSocket) {
            return;
        }

        this.callStatusSocket.removeEventListener('message', this.onMessage.bind(this));
        this.callStatusSocket.removeEventListener('error', this.onError.bind(this));
        this.callStatusSocket.close();
        this.callStatusSocket = undefined;
    }

    getSettings() {
        this.settingsService
            .getGenesysSettings()
            .pipe(takeUntil(this.unsubscribeS))
            .subscribe(res => {
                this.conversationSettingsBS.next(res);
            });
    }

    getUserPresenceDefinitions(): CallerPresenceStatusDefinition[] {
        // INT-2165: These should not be possible to pick in the picker: -Inaktiv and Offline
        return this.userPresenceDefinitions()
            .filter(
                x =>
                    x.systemPresence !== PresenceStatusEnum.Offline &&
                    x.systemPresence !== PresenceStatusEnum.Idle &&
                    (x.systemPresence !== PresenceStatusEnum.Busy || x.type !== PresenceTypeEnum.System)
            )
            .map(presence => ({
                id: presence.id ?? '',
                label: presence.languageLabels[this.genesysLocale] ?? '',
                status: presence.systemPresence as PresenceStatus
            }));
    }

    async setUserPresence(presenceStatus: CallerPresenceStatus): Promise<GenesysPresence | void> {
        if (!this.currentUser?.id) {
            return Promise.reject(
                new Error(Translations.callListAdmin.callerPresence.callerPresenceErrorMessages.userNotFound)
            );
        }

        if (!presenceStatus) {
            return Promise.reject(
                new Error(Translations.callListAdmin.callerPresence.callerPresenceErrorMessages.noPresenceStatus)
            );
        }

        const body = {
            message: '',
            presenceDefinition: {
                id: presenceStatus.id,
                systemPresence: getPresenceDefinitionsStatus(presenceStatus.id, this.getUserPresenceDefinitions())
            }
        };

        if (presenceStatus.message) {
            body['message'] = presenceStatus.message;
        }

        return this.presenceApiInstance
            .patchUserPresencesPurecloud(this.currentUser.id, body)
            .then(data => {
                this.genesysPresencesSignal.set(data);
                return data;
            })
            .catch(err => Promise.reject(new Error(err)));
    }

    public getSystemPreferenceLabel(systemPresence: PresenceStatus): string {
        return this.userPresenceDefinitions().find(x => x.systemPresence === systemPresence && x.type === PresenceTypeEnum.System)?.languageLabels[this.genesysLocale] ?? systemPresence;
    }

    private async getUserPresence(): Promise<GenesysPresence | void> {
        const user = await this.getUser();
        const definitions = await this.presenceApiInstance.getPresenceDefinitions0({
            localeCode: this.genesysLocale
        });


        this.userPresenceDefinitions.set(definitions.entities || []);

        this.currentUser = user;

        if (!user?.id) {
            return Promise.reject(
                new Error(Translations.callListAdmin.callerPresence.callerPresenceErrorMessages.userNotFound)
            );
        }

        if (user.outOfOffice?.active) {
            const outOfOfficeData = this.getOutOfficePresenceStatus(user);

            this.genesysPresencesSignal.set(outOfOfficeData);

            return Promise.resolve(outOfOfficeData);
        }
        // Get a user's Genesys Cloud presence.
        return this.presenceApiInstance
            .getUserPresencesPurecloud(user?.id)
            .then(async data => {
                this.genesysPresencesSignal.set(data);
                return data;
            })
            .then(() => {
                this.connectCallStatusSocket();
            });
    }

    private async connectCallStatusSocket() {
        console.log('connecting call status socket...');

        const userId = this.currentUser.id;

        if (!userId) {
            console.warn('User ID not found, unable to subscribe to call status events');
            return;
        }

        try {
            const presenceTopic = this.getUserTopicUrl(userId);
            this.presenceTopic.set(presenceTopic);

            // Create a channel to receive notifications
            const channel = await this.notificationsApiInstance.postNotificationsChannels();

            if (channel?.id && channel.connectUri) {
                // Subscribe to the presence topic
                await this.notificationsApiInstance.postNotificationsChannelSubscriptions(channel.id, [
                    { id: presenceTopic }
                ]);

                const webSocket = new WebSocket(channel.connectUri);

                webSocket.addEventListener('message', this.onMessage.bind(this));
                webSocket.addEventListener('error', this.onError.bind(this));

                this.callStatusSocket = webSocket;
            }
        } catch (err) {
            console.error('Failed to subscribe to presence', err);
        }
    }

    private getUserTopicUrl(userId: string): string {
        return `v2.users.${userId}.presence`;
    }

    private onMessage = async (event: MessageEvent) => {
        const message = JSON.parse(event.data);

        if (message.topicName === this.presenceTopic() && message?.eventBody?.presenceDefinition) {
            console.warn('call status socket on message', message);

            const presenceDefinition = message?.eventBody?.presenceDefinition;

            const user = await this.getUser();
            if (user.outOfOffice?.active) {
                console.warn('User is out of office');

                const outOfOfficeData = this.getOutOfficePresenceStatus(user);

                this.genesysPresencesSignal.set(outOfOfficeData);
                return;
            }

            if (
                this.genesysPresencesSignal().presenceDefinition?.id === presenceDefinition.id &&
                this.genesysPresencesSignal().presenceDefinition?.systemPresence ===
                presenceDefinition.systemPresence &&
                this.genesysPresencesSignal().message === message?.eventBody?.message
            ) {
                console.warn('Status already set', presenceDefinition.systemPresence);
                return;
            }

            console.warn('Status not set', this.userPresenceDefinitions());

            if (this.userPresenceDefinitions().find(x => x.id === presenceDefinition.id)) {
                console.warn('Status known', presenceDefinition.systemPresence, presenceDefinition.id);
                const status = matchPresenceStatusFromEvent(presenceDefinition.systemPresence);
                if (status) {
                    this.genesysPresencesSignal.set({
                        message: message?.eventBody?.message || '',
                        presenceDefinition: {
                            id: presenceDefinition.id,
                            systemPresence: status
                        }
                    });
                }
            } else {
                console.warn('Status unknown', presenceDefinition.systemPresence, presenceDefinition.id);
            }
        }
    };

    private onError = (event: Event) => {
        console.warn('call status socket on error', event);
    };

    private clearGenesysToken(params: Params) {
        if (params[this.genesysTokenKey]) {
            const httpParams = new HttpParams({ fromObject: {} });
            const pathname = this.utilUrlService.pathname();
            this.location.replaceState(pathname, httpParams.toString());
        }
    }

    private loadToken() {
        try {
            const token = localStorage.getItem(this.genesysTokenKey);

            if (token) {
                this.authTokenBS.next(token);
                this.client.setAccessToken(token);
                this.genesysStateRS.next({ state: State.READY, message: 'Genesys Ready' });
            }
        } catch (e) {
            console.warn('Error loading token from storage', e);
            this.genesysStateRS.next({ state: State.ERROR, message: 'Error loading token from storage' });
        }
    }

    private storeToken(token: string) {
        try {
            localStorage.setItem(this.genesysTokenKey, token);
            if (token) {
                this.authTokenBS.next(token);
                this.client.setAccessToken(token);
                this.genesysStateRS.next({ state: State.READY, message: 'Genesys Ready' });
            }
        } catch (e) {
            console.warn('Error loading token from storage', e);
            this.genesysStateRS.next({ state: State.ERROR, message: 'Error loading token from storage' });
        }
    }

    private validateCurrentToken(): Observable<boolean> {
        if (!this.authTokenBS.value) {
            return of(false);
        }

        return from(this.tokensApiInstance.headTokensMe()).pipe(
            map(() => true),
            catchError(err => {
                console.warn('Error validating token', err);
                return of(false);
            })
        );
    }

    private getUser(): Promise<platformClient.Models.User> {
        const apiInstance = new platformClient.UsersApi();
        return apiInstance.getUsersMe({ expand: ['presence', 'outOfOffice'] });
    }

    private getOutOfficePresenceStatus(user: platformClient.Models.User): GenesysPresence {
        return {
            ...user.presence,
            message: Translations.callListAdmin.callerPresence.outOfOffice?.message,
            presenceDefinition: {
                id: user.presence?.presenceDefinition?.id,
                systemPresence: PresenceStatusEnum.OutOfOffice
            }
        };
    }
}
