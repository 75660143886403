import platformClient from 'purecloud-platform-client-v2';

export enum State {
    LOADING = 'loading',
    READY = 'ready',
    ERROR = 'error',
    IDLE = 'idle'
}

export interface GenesysState {
    state: State;
    message?: string;
}

export interface GenesysPresence extends platformClient.Models.UserPresence {
}

export interface GenesysConversationErrorDetails {
    status?: number;
    code?: string;
    message?: string;
    messageWithParams?: string;
    messageParams?: { [key: string]: string };
    contextId?: string;
    uri?: string;
}
