import { Router, RouterLink } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ISearchResultModelOfCitizenSearchViewModel, WeekDays } from '../../api/services';
import { Translations } from '../../translations/translations';
import { CitizenService, ICitizenSearch } from '../services/citizen.service';
import { UtilHiddenScrollComponent } from '../util/util-hidden-scroll.component';
import { UtilUrlService } from '../util/util-url.service';
import { UtilService } from '../util/util.service';
import { HighlightPipe } from '../shared/highlight.pipe';
import { CustomerTypePipe } from '../shared/customer-type.pipe';
import { AddressPipe } from '../shared/address.pipe';
import { ChunkPipe } from '../shared/chunk.pipe';
import { TranslationPipe } from '../shared/translation.pipe';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { CitizenSearchFieldComponent } from '../search/citizen-search-field.component';

@Component({
    selector: 'iv-administration-citizen-search',
    template: `
        <div class="administration-citizen-search">
            <div class="administration-citizen-search__top">
                <h4 class="administration-citizen-search__search-head">${Translations.administration.citizenSearch.byParameters}</h4>

                <iv-citizen-search-field [text]="searchSettings.text" placeholder="${Translations.administration.citizenSearch.forCustomer}" (searched)="onSearchInput($event)" (entered)="onSearchEntered($event)"></iv-citizen-search-field>

                <div class="administration-citizen-search__filter">
                    <mat-checkbox (change)="search(true)" [(ngModel)]="searchSettings.onlyResigned">${Translations.administration.citizenSearch.filter.resignedOnly}</mat-checkbox>
                </div>

                <div class="administration-citizen-search__num-found">
                    {{ '${Translations.administration.citizenSearch.numFound}' | translation : citizenList ? citizenList.numFound : 0 }}
                </div>
            </div>

            <div class="administration-citizen-search__middle">
                <iv-util-hidden-scroll height="100%" #scrollContainer>
                    <ng-container *ngIf="!states.fetching; else fetching">
                        <div class="administration-citizen-search__citizens" *ngIf="citizenList">
                            <a class="administration-citizen-search__citizen" [routerLink]="['', { outlets: { admin: ['citizen', citizen.customerNo, '']} }]" *ngFor="let citizen of citizenList.results">
                                <h4 class="administration-citizen-search__citizen-name">
                                    <span [innerHTML]="citizen.firstName | highlight : searchSettings.text"></span>&nbsp;<span [innerHTML]="citizen.middleName | highlight : searchSettings.text"></span>&nbsp;<span [innerHTML]="citizen.lastName | highlight : searchSettings.text"></span>
                                </h4>
                                <div class="administration-citizen-search__citizen-row">
                                    <span [innerHTML]="citizen.customerNo | highlight : searchSettings.text"></span>&nbsp;·&nbsp;<span [innerHTML]="citizen.customerSubType | customerType | highlight : searchSettings.text"></span> <ng-container *ngIf="citizen.specialStatus">· <strong><span [innerHTML]="citizen.specialStatus.statusText | highlight : searchSettings.text"></span>: <span [innerHTML]="citizen.specialStatus.statusDate | highlight : searchSettings.text"></span></strong></ng-container>
                                </div>
                                <div class="administration-citizen-search__citizen-row">
                                    <span [innerHTML]="citizen | address | highlight : searchSettings.text"></span>
                                </div>
                                <div class="administration-citizen-search__citizen-row">
                                    <span [innerHTML]="citizen.municipalityName | highlight : searchSettings.text"></span>
                                </div>
                                <div class="administration-citizen-search__citizen-row">
                                    <span [innerHTML]="citizen.districtName | highlight : searchSettings.text"></span>
                                </div>
                                <div class="administration-citizen-search__citizen-row">
                                    <span [innerHTML]="citizen.subDistrictName | highlight : searchSettings.text"></span>
                                </div>
                                <div class="administration-citizen-search__citizen-row">
                                    ${Translations.administration.citizenSearch.telephone} <span [innerHTML]="citizen.phoneNumber | chunk | highlight : (searchSettings.text | chunk)"></span><ng-container *ngIf="citizen.mobileNumber">&nbsp;·&nbsp;${Translations.administration.citizenSearch.mobile} <span [innerHTML]="citizen.mobileNumber | chunk | highlight : (searchSettings.text | chunk)"></span></ng-container>
                                </div>
                                <div class="administration-citizen-search__citizen-row" *ngIf="citizen.orderDayOfWeekNos && citizen.orderDayOfWeekNos.length">
                                    ${Translations.administration.citizenSearch.orderDays} <strong>{{ formatDays(citizen.orderDayOfWeekNos) }}</strong>
                                </div>
                                <div class="administration-citizen-search__citizen-row" *ngIf="citizen.deliveryDayOfWeekNos && citizen.deliveryDayOfWeekNos.length">
                                    ${Translations.administration.citizenSearch.deliveryDays} <strong>{{ formatDays(citizen.deliveryDayOfWeekNos) }}</strong>
                                </div>
                            </a>
                        </div>
                    </ng-container>

                    <ng-template #fetching>
                        <div class="center-content alt-theme">
                            <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                        </div>
                    </ng-template>
                </iv-util-hidden-scroll>
            </div>

            <mat-paginator #paginator class="administration-citizen-search__bottom" *ngIf="citizenList && citizenList.numFound > searchSettings.take" showFirstLastButtons="true" [hidePageSize]="true" [length]="citizenList.numFound" [pageSize]="searchSettings.take" [pageIndex]="searchSettings.skip / searchSettings.take" (page)="onPaginatorChange($event)"></mat-paginator>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CitizenSearchFieldComponent, MatCheckbox, FormsModule, UtilHiddenScrollComponent, NgIf, NgFor, RouterLink, MatProgressSpinner, MatPaginator, TranslationPipe, ChunkPipe, AddressPipe, CustomerTypePipe, HighlightPipe]
})
export class AdministrationCitizenSearchComponent implements OnInit, OnDestroy {
    @ViewChild('scrollContainer', { static: true }) scrollContainer: UtilHiddenScrollComponent;
    @ViewChild('paginator') paginator?: MatPaginator;
    citizenList: ISearchResultModelOfCitizenSearchViewModel | undefined;
    searchSettings: ICitizenSearch = {
        text: '',
        skip: 0,
        take: 10,
        onlyResigned: false
    };
    states = {
        fetching: false
    };

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private cd: ChangeDetectorRef,
        private citizenService: CitizenService,
        private utilService: UtilService,
        private urlService: UtilUrlService,
        private router: Router
    ) { }

    ngOnInit() {
        this.citizenService.search$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(
            listResult => {
                this.citizenList = listResult;
                this.states.fetching = false;
                this.cd.markForCheck();
            },
            () => {
                this.states.fetching = false;
                this.cd.markForCheck();
            }
        );

        const params = this.urlService.searchPath();

        if (params.has('text') && !!params.get('text')) {
            this.searchSettings.text = params.get('text') || '';

            if (params.has('onlyResigned')) {
                this.searchSettings.onlyResigned = !!params.get('onlyResigned');
            }

            if (params.has('skip')) {
                this.searchSettings.skip = +(params.get('skip') || 0);
            }

            if (params.has('take')) {
                this.searchSettings.take = +(params.get('take') || 10);
            }

            this.search();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    search(resetPage = false) {
        if (this.paginator && resetPage) {
            this.paginator.firstPage();
        }
        this.states.fetching = true;
        this.citizenList = undefined;

        if (this.searchSettings.text) {
            const params: any = {};

            Object.entries(this.searchSettings).forEach(x => {
                if (x[1] && (Array.isArray(x[1]) ? x[1].length : !!x[1])) {
                    params[x[0]] = x[1];
                }
            });

            const httpParams = new HttpParams({ fromObject: params });

            this.urlService.setState(httpParams);
        } else {
            this.urlService.setState('');
        }

        this.citizenService.getSearch(this.searchSettings);
    }

    onSearchInput(text: string) {
        this.utilService.scrollElementTo(this.scrollContainer.outer.nativeElement, 0);
        this.searchSettings.text = text;
        this.searchSettings.skip = 0;
        this.search(true);
    }

    onPaginatorChange(event: PageEvent) {
        this.utilService.scrollElementTo(this.scrollContainer.outer.nativeElement, 0);
        this.searchSettings.skip = event.pageIndex * this.searchSettings.take;
        this.search();
    }

    formatDays(days: WeekDays[]): string {
        return days.map(x => Translations.global.weekDayNos[x]).join(', ');
    }

    onSearchEntered(pressed: boolean) {
        setTimeout(() => {
            if (!this.states.fetching && pressed && this.citizenList && this.citizenList.results) {
                this.router.navigate(['', { outlets: { admin: ['citizen', this.citizenList.results[0].customerNo, '']} }]);
            }
        }, 200);
    }
}
