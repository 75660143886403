import { CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommaDashPipe } from '../shared/comma-dash.pipe';
import { SharedModule } from '../shared/shared.module';
import { CommerceAddtobasketComponent } from './commerce-addtobasket.component';
import { CommerceBasketCatalogItemDialogComponent } from './commerce-basket-catalog-item-dialog.component';
import { CommerceBasketItemReservationComponent } from './commerce-basket-item-reservation.component';
import { CommerceBasketItemComponent } from './commerce-basket-item.component';
import { CommerceBasketComponent } from './commerce-basket.component';
import { CommerceCataloguesComponent } from './commerce-catalogues.component';
import { CommerceDeliveryDateComponent } from './commerce-delivery-date.component';
import { CommerceInvoiceComponent } from './commerce-invoice.component';
import { CommerceLabelsComponent } from './commerce-labels.component';
import { CommerceMinibasketItemComponent } from './commerce-minibasket-item.component';
import { CommerceMinibasketComponent } from './commerce-minibasket.component';
import { CommerceOrderComponent } from './commerce-order.component';
import { CommerceOrdersRowsComponent } from './commerce-orders-rows.component';
import { CommerceOrdersComponent } from './commerce-orders.component';
import { CommerceProductDeclarationsComponent } from './commerce-product-declarations.component';
import { CommerceProductDetailsComponent } from './commerce-product-details.component';
import { CommerceProductFilterPageComponentQuickLinks } from './commerce-product-filter-page-quick-links.component';
import { CommerceProductFilterPageComponent } from './commerce-product-filter-page.component';
import { CommerceProductListHomeComponent } from './commerce-product-list-home.component';
import { CommerceProductListItemComponent } from './commerce-product-list-item.component';
import { CommerceProductListPageComponent } from './commerce-product-list-page.component';
import { CommerceProductListComponent } from './commerce-product-list.component';
import { CommerceReceiptComponent } from './commerce-receipt.component';
import { CommerceRoutingModule } from './commerce-routing.module';
import { CommerceSummaryComponent } from './commerce-summary.component';
import { CommerceToggleFavoriteComponent } from './commerce-toggle-favorite.component';
import { NewTotalPricePipe } from './pipes/new-total-price.pipe';
import { OrderQuantityPipe } from './pipes/order-quantity.pipe';
import { DontAllowNegativeValuesDirective } from './directives/dont-allow-negatives-values.directive';

@NgModule({
    imports: [SharedModule, CommerceRoutingModule, MatButtonToggleModule, CommerceProductListComponent,
        CommerceMinibasketComponent,
        CommerceProductListPageComponent,
        CommerceProductListHomeComponent,
        CommerceProductFilterPageComponent,
        CommerceProductDetailsComponent,
        CommerceBasketComponent,
        CommerceOrderComponent,
        CommerceOrdersComponent,
        CommerceProductListItemComponent,
        CommerceLabelsComponent,
        CommerceAddtobasketComponent,
        CommerceToggleFavoriteComponent,
        CommerceMinibasketItemComponent,
        CommerceProductDeclarationsComponent,
        CommerceBasketItemComponent,
        CommerceBasketCatalogItemDialogComponent,
        CommerceReceiptComponent,
        CommerceOrdersRowsComponent,
        CommerceSummaryComponent,
        CommerceInvoiceComponent,
        CommerceCataloguesComponent,
        CommerceProductFilterPageComponentQuickLinks,
        CommerceDeliveryDateComponent,
        CommerceBasketItemReservationComponent,
        OrderQuantityPipe,
        NewTotalPricePipe,
        DontAllowNegativeValuesDirective],
    providers: [DatePipe, CurrencyPipe, CommaDashPipe],
    exports: [
        CommerceMinibasketComponent,
        CommerceOrdersRowsComponent,
        CommerceSummaryComponent,
        CommerceOrdersComponent,
        CommerceInvoiceComponent,
        CommerceDeliveryDateComponent,
        CommerceBasketItemReservationComponent,
        CommerceLabelsComponent
    ]
})
export class CommerceModule {}
