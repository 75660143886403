import { UntypedFormGroup } from "@angular/forms";
import { IOrderHistoryViewModel, OrderHistoryRowViewModel } from "Client/api/services";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { IOrderReturnLine } from "../citizen-credit-note-dialog.component";
import { getOrdersRowQuantity } from "Client/app/commerce/helpers/orders.helper";

const LINE_TYPE_DEPOSIT = 'Deposit';

interface OrderLine {
    [x: string]: UntypedFormGroup
}

interface OrderValue {
    productNo: OrderHistoryRowViewModel['productNo'],
    quantityAvailable: number
}


export const watchOrdersWithBottleDeposit = (_order: IOrderHistoryViewModel, orderLines: OrderLine, unsubscribeS$: Subject<void>) => {
    const ordersWithoutBottleDeposit: OrderValue[] = getBottleDepositOrders(_order);
    const bottleDepositRelatedProduct: OrderValue[] = getBottleDepositOrders(_order, true);

    ordersWithoutBottleDeposit.forEach(order => {
        if (!order.productNo) {
            return;

        }

        orderLines[order.productNo].valueChanges.pipe(debounceTime(200), takeUntil(unsubscribeS$))
            .subscribe((value: IOrderReturnLine) => updateBottleDepositValue(order, bottleDepositRelatedProduct, orderLines, value));
    });

}

const updateBottleDepositValue = (order: OrderValue, bottleDepositRelatedProduct: OrderValue[], orderLines: OrderLine, value: IOrderReturnLine) => {
    const relatedProduct = bottleDepositRelatedProduct.find(bottleRelatesToProduct => bottleRelatesToProduct.productNo === order.productNo);

    if (!relatedProduct?.productNo) {
        return;
    }

    const relatedProductId = LINE_TYPE_DEPOSIT + relatedProduct.productNo;

    if (relatedProduct && orderLines[relatedProductId]) {
        if (!value.qty) {
            orderLines[relatedProductId].patchValue(getDefaultOrderLine());
            return;
        }

        const bottleDeposit = getOrderLine(relatedProduct, order, value);
        orderLines[relatedProductId].patchValue(bottleDeposit);
    }
}

const getBottleDepositOrders = (order: IOrderHistoryViewModel, isBottleRelatesToProduct = false): OrderValue[] => {
    if (!order.rows?.length) {
        return [];
    }
    return order.rows
        .filter(x => isBottleRelatesToProduct ? x.lineType === LINE_TYPE_DEPOSIT : x.lineType !== LINE_TYPE_DEPOSIT)
        .map(o => {
            const productNo = isBottleRelatesToProduct ? o?.bottleRelatesToProduct : o.productNo;
            return { productNo, quantityAvailable: getOrdersRowQuantity(o) };
        }) ?? []

}

const getDefaultOrderLine = (): IOrderReturnLine => ({ qty: 0, reason: "" })

const getOrderLine = (relatedProduct: OrderValue, order: OrderValue, value: IOrderReturnLine): IOrderReturnLine => {
    const bottleDepositPerUnit = Math.trunc(relatedProduct.quantityAvailable / order.quantityAvailable);
    const qty = bottleDepositPerUnit * value.qty;
    const reason = value.reason;

    return { qty, reason }
}
