import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo, switchMap, switchMapTo } from 'rxjs/operators';

import {
    CreditNoteHistoryViewModel,
    CreditNoteReturnReason,
    CreditNoteViewModel,
    ICreateCreditNoteModel,
    ICreditNoteHistoryViewModel,
    ICreditNoteReturnReason,
    ICreditNoteViewModel,
    IOrderCrudViewModel,
    IOrderHistoryViewModel,
    IPdaOrderStatisticsFilter,
    IPdfEmailDocumentModel,
    ISkipTakeResultObjectOfCreditNoteHistoryViewModel,
    ISkipTakeResultObjectOfOrderHistoryViewModel,
    ISubmitOrderModel,
    OrderHistoryViewModel,
    PdaOrderStatisticsMunicipality,
    SkipTakeResultObjectOfCreditNoteHistoryViewModel,
    SkipTakeResultObjectOfOrderHistoryViewModel,
    IEditingOrMergeOrderViewModel,
    EditingOrMergeOrderViewModel, OrderViewModel, IOrderViewModel,
} from '../../api/services';
import { AuthService } from './auth.service';
import { BaseService } from './base-service';

@Injectable()
export class OrderService extends BaseService {
    constructor(private http: HttpClient, private authService: AuthService) {
        super();
    }

    //#region Orders

    submitOrder(submitOrderData: ISubmitOrderModel): Observable<IOrderViewModel> {
        return this.http.post<IOrderViewModel>(this.apiBaseUrl('/order/SubmitOrder'), submitOrderData).pipe(
            switchMap((submitOrderData) => this.authService.getCurrentUser().pipe(mapTo(submitOrderData))),
            map(OrderViewModel.fromJS)
        );
    }

    getOrdersByCustomerNo(settings: { skip: number, take: number, customerNo?: string }): Observable<ISkipTakeResultObjectOfOrderHistoryViewModel> {
        return this.http.get(this.apiBaseUrl('/order/GetOrdersByCustomerNo'), {
            params: {
                skip: settings.skip.toString(),
                take: settings.take.toString(),
                customerNo: settings.customerNo || ''
            }
        }).pipe(map(SkipTakeResultObjectOfOrderHistoryViewModel.fromJS));
    }

    getOrderByNo(orderNo: string, includeBottleDeposit = false, assertSameCustomerId = false): Observable<IOrderHistoryViewModel> {
        return this.http.get(this.apiBaseUrl('/order/GetOrderByNo'), {
            params: {
                orderNo,
                includeBottleDeposit: includeBottleDeposit.toString(),
                assertSameCustomerId: assertSameCustomerId.toString()
            }
        }).pipe(
            map(OrderHistoryViewModel.fromJS)
        );
    }

    getOrderPdaStatistics(data: IPdaOrderStatisticsFilter): Observable<PdaOrderStatisticsMunicipality[]> {

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const params: any = {};

        if (data.municipalities) {
            params.municipalities = data.municipalities;
        }

        if (data.districts && data.districts.length > 0) {
            params.districts = data.districts;
        }

        if (data.subDistricts && data.subDistricts.length > 0) {
            params.subDistricts = data.subDistricts;
        }

        if (data.users && data.users.length > 0) {
            params.users = data.users;
        }

        if (data.dateFrom) {
            params.dateFrom = new Date(data.dateFrom).toISOString();
        }

        if (data.dateTo) {
            params.dateTo = new Date(data.dateTo).toISOString();
        }

        return this.http.get<PdaOrderStatisticsMunicipality[]>(this.apiBaseUrl('/order/PdaOrderStatistics'), {
            params: new HttpParams({
                fromObject: params
            })
        }).pipe(
            map(pdaOrderStats => pdaOrderStats.map(PdaOrderStatisticsMunicipality.fromJS))
        );
    }

    activateOrderEditingAndCopyToBasket(orderNo: string): Observable<boolean> {
        const crudModel: IOrderCrudViewModel = { orderNo: +orderNo };
        return this.http.post<void>(this.apiBaseUrl('/order/ActivateOrderEditingAndCopyToBasket'), crudModel).pipe(
            switchMapTo(this.authService.getCurrentUser()),
            map(() => true)
        );
    }

    cancelOrder(orderNo: string, reason: string = ''): Observable<boolean> {
        const crudModel: IOrderCrudViewModel = { orderNo: +orderNo, cancelReason: reason };
        return this.http.post<void>(this.apiBaseUrl('/order/CancelOrder'), crudModel).pipe(
            map(() => true)
        );
    }

    sendOrderEmail(model: IPdfEmailDocumentModel): Observable<boolean> {
        return this.http.post(this.apiBaseUrl('/order/SendOrderPdfByEmail'), model).pipe(
            map(() => true)
        );
    }

    getEditingAndMergeInfo(): Observable<IEditingOrMergeOrderViewModel> {
        return this.http.get(this.apiBaseUrl('/order/GetEditingAndMergeInfo')).pipe(
            map(EditingOrMergeOrderViewModel.fromJS)
        );
    }

    //#endregion

    //#region Creditnotes

    getCreditNotesByCustomerNo(settings: { skip: number, take: number, customerNo?: string }): Observable<ISkipTakeResultObjectOfCreditNoteHistoryViewModel> {
        return this.http.get(this.apiBaseUrl('/order/GetCreditNotesByCustomerNo'), {
            params: {
                skip: settings.skip.toString(),
                take: settings.take.toString(),
                customerNo: settings.customerNo || ''
            }
        }).pipe(map(SkipTakeResultObjectOfCreditNoteHistoryViewModel.fromJS));
    }

    getCreditNoteByNo(creditNo: string): Observable<ICreditNoteHistoryViewModel> {
        return this.http.get(this.apiBaseUrl('/order/GetCreditNoteByNo'), { params: { creditNo } }).pipe(
            map(CreditNoteHistoryViewModel.fromJS)
        );
    }

    createCreditNote(data: ICreateCreditNoteModel): Observable<ICreditNoteViewModel> {
        return this.http.post(this.apiBaseUrl('/order/CreateCreditNote'), data).pipe(
            map(CreditNoteViewModel.fromJS)
        );
    }

    getCreditReturnReasons(): Observable<ICreditNoteReturnReason[]> {
        return this.http.get<object[]>(this.apiBaseUrl('/CreditNoteReturnReason/GetCreditNoteReturnReasons')).pipe(
            map(causes => causes.map(CreditNoteReturnReason.fromJS))
        );
    }

    sendCreditNoteEmail(model: IPdfEmailDocumentModel): Observable<boolean> {
        return this.http.post(this.apiBaseUrl('/order/SendCreditNotePdfByEmail'), model).pipe(
            map(() => true)
        );
    }

    //#endregion
}
