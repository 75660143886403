export interface IAppState {
    customerCenter: boolean;
    showLeftMenu: boolean;
    showCookie: boolean;
    showCategory: AnimationState;
    showMenuTrigger: boolean;
    showMinibasket: boolean;
    menuTriggerState: boolean;
    width: number;
    device: string;
    title: string;
    pageTitle: string;
    mustChangePwd: boolean;
    adminMode: boolean;
    showImpersonated: boolean;
    homecarerMode: boolean;
}

export enum AnimationState {
    Open = 'open',
    Close = 'close',
    Inactive = 'inactive'
}
