import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
    ActualCallerCallList,
    IActualCallerCallList,
    ICallListBusyModel,
    ICallListNotAnswerModel,
    IntervalPlacement,
    ISimpleCallRecord,
    IUpdateCitizenProfileViewModel,
    PositionEnum
} from '../../api/services';
import { BaseService } from './base-service';

export interface ICallListDataModel {
    completedCallCount: number;
    incompleteCallCount: number;
    totalCallCount: number;
}

@Injectable()
export class CallListService extends BaseService {
    callListStatus$ = new ReplaySubject<ICallListDataModel>(1);

    constructor(private http: HttpClient) {
        super();
    }

    getCallList(): Observable<IActualCallerCallList> {
        return this.http.get(this.apiBaseUrl('/CallList/GetCallList')).pipe(
            map(ActualCallerCallList.fromJS),
            tap(callList => {
                let aggregatedCallList: ISimpleCallRecord[] = [];

                if (callList.intervals) {
                    callList.intervals.forEach(interval =>
                        interval.simpleCallRecords
                            ? (aggregatedCallList = aggregatedCallList.concat(interval.simpleCallRecords))
                            : false
                    );
                }

                const completedCallsListLen = aggregatedCallList.filter(x => x.isCallCompleted === true).length;

                this.callListStatus$.next({
                    completedCallCount: completedCallsListLen,
                    incompleteCallCount: aggregatedCallList.length - completedCallsListLen,
                    totalCallCount: aggregatedCallList.length
                });
            })
        );
    }

    scheduleNewCallTime(customerNo: string, intervalPlacement: IntervalPlacement): Observable<boolean> {
        const model: IUpdateCitizenProfileViewModel = {
            customerNo,
            intervalPlacement
        };
        return this.http.put<boolean>(this.apiBaseUrl('/CallListAdmin/UpdateCitizenProfile'), model);
    }

    citizenNoAnswer(callRecordId: number, moveCitizenTo = PositionEnum.BeginningOfNextInterval): Observable<boolean> {
        const model: ICallListNotAnswerModel = {
            callRecordId,
            moveCitizenTo
        };
        return this.http.post<boolean>(this.apiBaseUrl('/CallList/CitizenNoAnswer'), model);
    }

    citizenBusy(callRecordId: number, moveCitizenTo = PositionEnum.BeginningOfNextInterval): Observable<boolean> {
        const model: ICallListBusyModel = {
            callRecordId,
            moveCitizenTo
        };
        return this.http.post<boolean>(this.apiBaseUrl('/CallList/CitizenBusy'), model);
    }

    getTransferReasons(): Observable<string[]> {
        return this.http
            .get(this.apiBaseUrl('/CallList/CitizenTransferReasons'))
            .pipe(map(result => result as string[]));
    }

    logCitizenCall(customerNo: string, phoneNumber: string): Observable<void> {
        const model = {
            customerNo,
            phoneNumber
        };
        return this.http.post<void>(this.apiBaseUrl('/CallList/LogCitizenCall'), model);
    }
}
