import platformClient from 'purecloud-platform-client-v2';
import { Translations } from '../../../translations/translations';
import { CallerPresenceStatusDefinition, PresenceStatus, PresenceStatusEnum } from '../calls/calls.models';
import { GenesysPresence } from './genesys.model';

export const getPresenceDefinitionsStatus = (
    id: string,
    presenceDefinitions: CallerPresenceStatusDefinition[]
): PresenceStatus => {
    return presenceDefinitions.find(x => x.id === id)?.status as PresenceStatus;
};

export const outOfficePresenceStatus = (user: platformClient.Models.User): GenesysPresence => {
    return {
        ...user.presence,
        message: Translations.callListAdmin.callerPresence.outOfOffice?.message,
        presenceDefinition: {
            id: user.presence?.presenceDefinition?.id,
            systemPresence: PresenceStatusEnum.OutOfOffice
        }
    };
};

export const matchPresenceStatusFromEvent = (status: string): PresenceStatusEnum | undefined => {
    // Convert the input string to lowercase to ensure case-insensitivity
    const normalizedStatus = status.toLowerCase();

    // Loop through the enum values to find a match
    for (const key in PresenceStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(PresenceStatusEnum, key)) {
            const enumValue = PresenceStatusEnum[key as keyof typeof PresenceStatusEnum];
            if (enumValue.toLowerCase() === normalizedStatus) {
                return enumValue as PresenceStatusEnum;
            }
        }
    }

    // Return undefined if no match is found
    return undefined;
};

