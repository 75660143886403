import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { map } from 'rxjs/operators';
import { Handicap, IHandicap } from '../../api/services';

@Injectable()
export class HandicapService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    getHandicaps(): Observable<IHandicap[]> {
        return this.http.get<any[]>(this.apiBaseUrl('/handicap/GetHandicaps')).pipe(
            map(x => x.map(Handicap.fromJS))
        );
    }
}
