import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { FormBuilderTextInput } from './../form-builder/form-builder-element.model';

import { BasketViewModel, IOrderHistoryViewModel, OrderHistoryStatus } from '../../api/services';
import { environment } from '../../environments/environment';
import { cart, cross, pdf, pen } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CitizenCreditNoteDialogComponent } from '../citizen/citizen-credit-note-dialog.component';
import { CitizenPriceAdjustmentDialogComponent } from '../citizen/citizen-price-adjustment-dialog.component';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { BasketService } from '../services/basket.service';
import { OrderService } from '../services/order.service';
import { IDialogContentSettings, IDialogFormSettings } from '../shared/dialog-interfaces';
import { DialogService } from '../shared/dialog.service';
import { IDENTITIES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { Helpers } from '../util/helpers';
import { UtilService } from '../util/util.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CommerceSummaryComponent } from './commerce-summary.component';
import { CommerceOrdersRowsComponent } from './commerce-orders-rows.component';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'iv-commerce-order',
    template: `
        <article class="commerce-orders__order-content" *ngIf="order; else fetching">
            <header class="commerce-orders__order-header">
                <h2>${Translations.commerce.orders.header.title}</h2>
                <div class="subtitle">
                    <strong>${Translations.commerce.orders.header.orderNumber}:</strong> {{ order.orderNo }}
                </div>
                <div class="subtitle">
                    <strong>${Translations.commerce.orders.header.orderStatus}:</strong> {{ orderStatus }}
                </div>
            </header>

            <div class="commerce-orders__controls">
                <div class="commerce-orders__controls-container">
                    <ng-container *ngIf="order.rows.length">
                        <button
                            *ngIf="order.orderHistoryStatus !== OrderHistoryStatus.Ordered"
                            [disabled]="clicked"
                            class="commerce-orders__copy btn-with-icon btn-with-icon_last"
                            mat-raised-button
                            color="primary"
                            (click)="copy(order.orderNo)"
                        >
                            ${Translations.commerce.orders.controls.copy}
                            <span class="icon svg-icon">${cart}</span>
                        </button>

                        <ng-container *ngIf="order.orderHistoryStatus === OrderHistoryStatus.Ordered">
                            <button
                                class="commerce-orders__cancel-and-copy btn-with-icon btn-with-icon_last"
                                mat-raised-button
                                color="primary"
                                (click)="cancelAndCopy(order.orderNo)"
                            >
                                ${Translations.commerce.orders.controls.cancelAndCopy}
                                <span class="icon svg-icon">${pen}</span>
                            </button>

                            <button
                                class="commerce-orders__cancel btn-with-icon btn-with-icon_last"
                                mat-raised-button
                                color="warn"
                                (click)="cancel(order.orderNo)"
                            >
                                ${Translations.commerce.orders.controls.cancel} ${cross}
                            </button>
                        </ng-container>

                        <button
                            *ngIf="
                                isAdmin &&
                                (order.orderHistoryStatus === OrderHistoryStatus.Processing ||
                                    order.orderHistoryStatus === OrderHistoryStatus.Delivered)
                            "
                            class="commerce-orders__btn"
                            mat-raised-button
                            color="primary"
                            (click)="createCreditNote(order.orderNo)"
                        >
                            ${Translations.administration.citizenCreditNotes.actionOpen}
                        </button>

                        <button
                            *ngIf="
                                isAdmin &&
                                (order.orderHistoryStatus === OrderHistoryStatus.Processing ||
                                    order.orderHistoryStatus === OrderHistoryStatus.Delivered)
                            "
                            class="commerce-orders__btn"
                            mat-raised-button
                            color="accent"
                            (click)="createPriceAdjustment(order.orderNo)"
                        >
                            ${Translations.administration.citizenPriceAdjustment.actionOpen}
                        </button>
                    </ng-container>
                </div>

                <div class="commerce-orders__controls-container" *ngIf="isAdmin && order.hasPdf">
                    <a
                        class="commerce-orders__pdf icon"
                        href="${environment.apiBaseUrl}/api/Order/GetOrderPdf/?orderNo={{ order.orderNo }}"
                        target="_blank"
                        title="${Translations.commerce.creditNote.controls.view}"
                    >
                        ${pdf}
                    </a>

                    <form class="commerce-orders__form" (submit)="mailInvoice(order.orderNo)">
                        <mat-form-field>
                            <input
                                matInput
                                ngModel
                                type="email"
                                name="recipients"
                                [formControl]="recipients"
                                placeholder="${Translations.commerce.creditNote.controls.mail}"
                            />
                            <mat-error *ngIf="recipients.invalid">
                                ${Translations.commerce.creditNote.messages.emailError}
                            </mat-error>
                        </mat-form-field>
                        <iv-progress-button
                            class="commerce-orders__btn"
                            mat-raised-button
                            color="primary"
                            [loadingState]="states.sending"
                        >
                            ${Translations.commerce.creditNote.controls.mailSend}
                        </iv-progress-button>
                    </form>
                </div>
            </div>

            <iv-commerce-orders-rows
                [rows]="order.rows"
                [historyMode]="
                    order.orderHistoryStatus === OrderHistoryStatus.Delivered ||
                    order.orderHistoryStatus === OrderHistoryStatus.Processing ||
                    order.orderHistoryStatus === OrderHistoryStatus.Canceled
                "
            >
            </iv-commerce-orders-rows>

            <iv-commerce-summary
                *ngIf="order.rows.length"
                class="commerce-orders__summary"
                [data]="order"
            ></iv-commerce-summary>
        </article>
        <ng-template #fetching>
            <div class="commerce-orders-rows__unavailable alt-theme">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="2"
                    [diameter]="40"
                ></mat-progress-spinner>
            </div>
        </ng-template>
    `,
    standalone: true,
    imports: [NgIf, MatButton, FormsModule, MatFormField, MatInput, ReactiveFormsModule, MatError, ProgressButtonComponent, CommerceOrdersRowsComponent, CommerceSummaryComponent, MatProgressSpinner]
})
export class CommerceOrderComponent implements OnInit, OnDestroy {
    @Input()
    orderNo?: string;

    @Output()
    update = new EventEmitter<void>();

    order?: IOrderHistoryViewModel;
    orderStatus = '';
    isAdmin = false;
    clicked = false;

    states = {
        sending: false
    };
    recipients = new UntypedFormControl('', [Validators.email]);
    OrderHistoryStatus = OrderHistoryStatus;

    private _hasBasket: boolean;
    private unsubscribe = new Subject<void>();

    constructor(
        private orderService: OrderService,
        private basketService: BasketService,
        private dialogService: DialogService,
        private utilService: UtilService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.params
            .pipe(
                takeUntil(this.unsubscribe),
                filter(x => !!x.orderNo)
            )
            .subscribe(x => {
                this.orderNo = x.orderNo;
                this.getOrder(x.orderNo);
            });

        this.basketService.basket$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(basket => (this._hasBasket = basket instanceof BasketViewModel));

        this.userService.activeRoles.pipe(takeUntil(this.unsubscribe)).subscribe(userRoles => {
            this.isAdmin = userRoles.some(x => IDENTITIES.adminAndCustomerService.includes(x));
        });

        if (this.orderNo) {
            this.getOrder(this.orderNo);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getOrder(orderNo: string) {
        this.orderService
            .getOrderByNo(orderNo)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                orderViewModel => {
                    this.order = orderViewModel;
                    this.orderStatus =
                        Translations.commerce.orders.status[
                            OrderHistoryStatus[this.order.orderHistoryStatus].toString().toLowerCase()
                        ];
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }

    copy(orderNo: string) {
        if (this._hasBasket) {
            const dialogSettings: IDialogContentSettings = {
                data: {
                    header: Translations.commerce.orders.controls.copy,
                    content: Translations.commerce.orders.dialogText.copy,
                    buttons: {
                        button1: {
                            color: 'primary',
                            confirm: true,
                            text: Translations.global.btnAcknowledge
                        },
                        button2: {
                            color: 'accent',
                            confirm: false,
                            text: Translations.global.btnCancel
                        }
                    }
                },
                disableClose: true
            };

            this.dialogService
                .openDialog(dialogSettings)
                .afterClosed()
                .pipe(
                    takeUntil(this.unsubscribe),
                    switchMap(response => (response ? this.basketService.copyOrderToBasket(orderNo) : EMPTY))
                )
                .subscribe(
                    () => this._copySuccess(),
                    (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
                );
        } else {
            this.basketService
                .copyOrderToBasket(orderNo)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    () => this._copySuccess(),
                    (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
                );
        }
        this.clicked = true;
    }

    cancelAndCopy(orderNo: string) {
        const dialogSettings: IDialogContentSettings = {
            data: {
                header: Translations.commerce.orders.controls.cancelAndCopy,
                content: this._hasBasket
                    ? Translations.commerce.orders.dialogText.cancelAndCopy
                    : Translations.commerce.orders.dialogText.cancelAndCopyNoBasket,
                buttons: {
                    button1: {
                        color: 'primary',
                        confirm: true,
                        text: Translations.global.btnAcknowledge
                    },
                    button2: {
                        color: 'accent',
                        confirm: false,
                        text: Translations.global.btnCancel
                    }
                }
            },
            disableClose: true
        };

        this.dialogService
            .openDialog(dialogSettings)
            .afterClosed()
            .pipe(
                switchMap(response =>
                    response ? this.orderService.activateOrderEditingAndCopyToBasket(orderNo) : EMPTY
                ),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                response => {
                    if (response) {
                        // Due an issue on Angular (https://github.com/angular/angular/issues/15338)
                        // the overlay will not be closed but should be..
                        // for now, we will not close the overlay
                        // eslint-disable-next-line no-null/no-null
                        this.router.navigate([Translations.shop.paths.basketPage.path, { outlets: { dialog: null } }]);
                    }
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }

    cancel(orderNo: string) {
        const dialogSettings: IDialogFormSettings = {
            data: {
                title: Translations.commerce.orders.controls.cancel,
                content: Translations.commerce.orders.dialogText.cancel,
                inputs: this._cancelReasonForm(),
                actionText: Translations.global.btnAcknowledge
            }
        };

        this.dialogService
            .showForm(dialogSettings)
            .afterClosed()
            .pipe(
                switchMap(response =>
                    response
                        ? this.orderService.cancelOrder(
                              orderNo,
                              response && response.cancelReason ? String(response.cancelReason).trim() : ''
                          )
                        : EMPTY
                ),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                response => {
                    if (response) {
                        this.update.emit();
                        if (this.orderNo) {
                            this.getOrder(this.orderNo);
                        }
                    }
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }

    mailInvoice(documentNo: string): void {
        if (!this.recipients.valid || !Helpers.isValidEmail(this.recipients.value)) {
            this.recipients.setErrors({ email: true });
            return;
        }

        this.states.sending = true;

        this.orderService
            .sendOrderEmail({ documentNo, recipients: this.recipients.value })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                () => {
                    this.dialogService.showSnackMessage({
                        message: Translations.commerce.creditNote.messages.emailSent
                    });
                    this.states.sending = false;
                },
                (err: IntervareHttpErrorResponse) => {
                    this.dialogService.showValidationResult(err.validationErrors);
                    this.states.sending = false;
                }
            );
    }

    createCreditNote(orderNo: string): void {
        this.dialogService.openDialogWithComponent(CitizenCreditNoteDialogComponent, {
            width: '1000px',
            maxWidth: '1000px',
            data: orderNo
        });
    }

    createPriceAdjustment(orderNo: string): void {
        this.dialogService.openDialogWithComponent(CitizenPriceAdjustmentDialogComponent, {
            width: '1000px',
            maxWidth: '1000px',
            data: orderNo
        });
    }

    private _copySuccess(): void {
        this.utilService.deviceType$
            .pipe(first(), takeUntil(this.unsubscribe))
            .subscribe(device =>
                device === 'mobile' ? this.router.navigate([Translations.shop.paths.basketPage.path]) : false
            );
    }

    private _cancelReasonForm() {
        return [
            new FormBuilderTextInput({
                name: 'cancelReason',
                required: false,
                label: Translations.commerce.orders.dialogText.cancelReason
            })
        ];
    }
}
