import { Component, OnInit } from '@angular/core';

import { ICitizenQuickInfo } from '../../api/services';
import { Translations } from '../../translations/translations';
import { UserService } from '../user/user.service';
import { ChunkPipe } from '../shared/chunk.pipe';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
    selector: 'iv-administration-call-info',
    template: `
        @if (impersonatingUser$ | async; as impersonatingUser) {
            <div class="call-section">
                <h4 class="call-section-header">${Translations.administration.callSection.currentUser}</h4>
                <div class="call-section-info">
                    <h3 class="call-section-info-header"><a
                        [routerLink]="['', { outlets: { admin: ['citizen', impersonatingUser.customerNumber, ''] } }]">{{ impersonatingUser.name }}</a>
                    </h3>
                    <h4 class="call-section-info-header">{{ impersonatingUser.phoneNumber | chunk }}</h4>
                </div>
            </div>
        }
    `,
    standalone: true,
    imports: [RouterLink, ChunkPipe, AsyncPipe]
})
export class AdministrationCallInfoComponent implements OnInit {

    impersonatingUser$?: Observable<ICitizenQuickInfo | undefined>;

    constructor(
        private userService: UserService
    ) { }

    ngOnInit() {
        this.impersonatingUser$ = this.userService.isImpersonating;
    }
}
