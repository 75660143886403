/* eslint-disable no-case-declarations */
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { Translations } from '../../translations/translations';
import { FormBuilderTypes } from './form-builder-element.model';

export interface IFormValues {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

@Injectable()
export class FormBuilderService {
    constructor(private readonly datePipe: DatePipe) {}

    /**
     * Fetches and displays the correct error message from the ValidationErrors
     */
    static getErrorMessage(errors: ValidationErrors): string | null {
        // eslint-disable-next-line no-null/no-null
        if (errors === null) {
            // eslint-disable-next-line no-null/no-null
            return null;
        }

        const keys = Object.keys(errors);
        if (Translations.forms.validation[keys[0]]) {
            return Translations.forms.validation[keys[0]];
        }

        if (keys[0]) {
            return errors[keys[0]];
        }

        return Translations.forms.validation.default;
    }

    /**
     * Generate a {FormGroup} based on input of {FormBuilderTypes[]}
     */
    toFormGroup(inputs: FormBuilderTypes[]): UntypedFormGroup {
        const group = {};

        inputs.filter(input => input.type !== 'separator')
            .forEach(input => {
            input.validation = input.validation || [];

            if (input.required) {
                input.validation.push(Validators.required);
            }

            if (input.controlType === 'checkbox') {
                group[input.name] = new UntypedFormControl(
                    { value: input.value === true, disabled: input.disabled },
                    { validators: input.validation, updateOn: input.updateOn || 'change' }
                );
            } else {
                group[input.name] = new UntypedFormControl(
                    { value: input.value !== undefined ? input.value : '', disabled: input.disabled },
                    { validators: input.validation, updateOn: input.updateOn || 'change' }
                );
            }
        });

        return new UntypedFormGroup(group);
    }

    /* eslint-disable no-case-declarations */
    formToTable(inputs: FormBuilderTypes[], values: IFormValues, oldValues?: IFormValues) {
        return inputs
            .filter(input => (!input.excludeFromTable && input.condition) || input.controlType !== 'separator').map(input => {
                const value = values[input.name];
                const oldValue = oldValues ? oldValues[input.name] : undefined;

                let newTableValue = '';
                let oldTableValue = '';

                switch (input.controlType) {
                    case 'municipality':
                        let municipalityForm;

                        const selectedMunicipality = value.selectedMunicipality ? value.selectedMunicipality : '';
                        const selectedDistricts = value.selectedDistricts
                            ? typeof value.selectedDistricts === 'string'
                                ? value.selectedDistricts
                                : value.selectedDistricts.toString().split(',').join('\n')
                            : '';
                        const selectedSubdistricts = value.selectedSubdistricts
                            ? typeof value.selectedSubdistricts === 'string'
                                ? value.selectedSubdistricts
                                : value.selectedSubdistricts.toString().split(',').join('\n')
                            : '';

                        if (oldValue) {
                            const oldSelectedMunicipality = oldValue.selectedMunicipality
                                ? oldValue.selectedMunicipality
                                : '';
                            const oldSelectedDistricts = oldValue.selectedDistricts
                                ? typeof oldValue.selectedDistricts === 'string'
                                    ? oldValue.selectedDistricts
                                    : oldValue.selectedDistricts.toString().split(',').join('\n')
                                : '';
                            const oldSelectedSubdistricts = oldValue.selectedSubdistricts
                                ? typeof oldValue.selectedSubdistricts === 'string'
                                    ? oldValue.selectedSubdistricts
                                    : oldValue.selectedSubdistricts.toString().split(',').join('\n')
                                : '';
                            municipalityForm = [
                                {
                                    field: input.label.municipality,
                                    new: selectedMunicipality,
                                    old: oldSelectedMunicipality
                                },
                                { field: input.label.district, new: selectedDistricts, old: oldSelectedDistricts },
                                {
                                    field: input.label.subdistrict,
                                    new: selectedSubdistricts,
                                    old: oldSelectedSubdistricts
                                }
                            ];
                        } else {
                            municipalityForm = [
                                { field: input.label.municipality, new: selectedMunicipality },
                                { field: input.label.district, new: selectedDistricts },
                                { field: input.label.subdistrict, new: selectedSubdistricts }
                            ];
                        }

                        return municipalityForm;

                    case 'city':
                        let cityForm;

                        if (oldValue) {
                            cityForm = [
                                { field: input.label.zipCode, new: value.zipCode, old: oldValue.zipCode },
                                { field: input.label.city, new: value.city, old: oldValue.city }
                            ];
                        } else {
                            cityForm = [
                                { field: input.label.zipCode, new: value.zipCode },
                                { field: input.label.city, new: value.city }
                            ];
                        }

                        return cityForm;

                    case 'select':
                        if (input.options && input.options.value.length && value !== undefined && value !== '') {
                            if (input.multiple) {
                                newTableValue = input.options
                                    .getValue()
                                    .filter(y => value.includes(y.value))
                                    .map(z => z.label)
                                    .toString()
                                    .split(',')
                                    .join('\n');
                            } else {
                                const selectValue = input.options.getValue().filter(y => y.value === value);
                                if (selectValue.length) {
                                    newTableValue = selectValue[0].label;
                                }
                            }
                        }
                        if (input.options && input.options.value.length && oldValue !== undefined && oldValue !== '') {
                            if (input.multiple) {
                                oldTableValue = input.options
                                    .getValue()
                                    .filter(y => oldValue.includes(y.value))
                                    .map(z => z.label)
                                    .toString()
                                    .split(',')
                                    .join('\n');
                            } else {
                                const selectValue = input.options.getValue().filter(y => y.value === oldValue);
                                if (selectValue.length) {
                                    oldTableValue = selectValue[0].label;
                                }
                            }
                        }
                        break;

                    case 'checkbox':
                        newTableValue = value
                            ? Translations.municipality.citizenTable.yes
                            : Translations.municipality.citizenTable.no;
                        oldTableValue = oldValue
                            ? Translations.municipality.citizenTable.yes
                            : Translations.municipality.citizenTable.no;
                        break;

                    case 'date':
                        const date = this.datePipe.transform(value, 'd/M/yyyy');
                        const oldDate = this.datePipe.transform(oldValue, 'd/M/yyyy');
                        newTableValue = date ? date : '';
                        oldTableValue = oldDate ? oldDate : '';
                        break;

                    case 'text':
                        newTableValue = input.type === 'password' && value ? value.replace(/[\s\S]*/, '\u2022') : value;
                        oldTableValue =
                            input.type === 'password' && oldValue ? oldValue.replace(/[\s\S]*/, '\u2022') : oldValue;
                        break;

                    case 'radio':
                        if (input.options && value !== undefined && value !== '') {
                            newTableValue = input.options.getValue().filter(y => y.value === value)[0].label;
                        }
                        if (input.options && oldValue !== undefined && oldValue !== '') {
                            oldTableValue = input.options.getValue().filter(y => y.value === oldValue)[0].label;
                        }
                        break;

                case 'separator':
                    break;

                    default:
                        newTableValue = value;
                        oldTableValue = oldValue;
                        break;
                }

                return oldValue !== undefined
                    ? { field: input.label, new: newTableValue, old: oldTableValue }
                    : { field: input.label, new: newTableValue };
            });
    }
    /* eslint-enable no-case-declarations */
}
