import { Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UtilService } from './util.service';

@Component({
    selector: 'iv-util-hidden-scroll',
    template: `
        <div class="util-hidden-scroll" [class.util-hidden-scroll_active]="active" [class.scrollbox]="scrollbox" #outer>
            <div class="util-hidden-scroll__inner" #inner>
                <ng-content></ng-content>
            </div>
        </div>
    `,
    standalone: true
})
export class UtilHiddenScrollComponent implements OnInit, OnChanges, OnDestroy {
    @Input() active = true;
    @Input() height = 'auto';
    @Input() focus?: boolean;
    @Input() scrollbox: boolean;
    @ViewChild('outer', { read: ElementRef, static: true }) outer: ElementRef;
    @ViewChild('inner', { read: ElementRef, static: true }) inner: ElementRef;

    private _outer: HTMLElement;
    private _inner: HTMLElement;
    private _scrollbarWidth = 0;
    private unsubscribe: Subject<void> = new Subject();

    constructor(private utilService: UtilService, private ngZone: NgZone) { }

    ngOnInit() {
        this._outer = this.outer.nativeElement;
        this._inner = this.inner.nativeElement;

        this.utilService.scrollbarWidth$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(width => {
            this._scrollbarWidth = width;
            this._setStyling();
        });

        this.onWheel = this.onWheel.bind(this);
        this.keepInFocus = this.keepInFocus.bind(this);
        this.ngZone.runOutsideAngular(() => {
            this._outer.addEventListener('wheel', this.onWheel, { passive: true });
            this._inner.addEventListener('click', this.keepInFocus);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.active && changes.active.previousValue !== undefined || changes.height && changes.height.previousValue !== undefined) {
            this._setStyling();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        if (this._outer) {
            this._outer.removeEventListener('wheel', this.onWheel);
        }

        if (this._inner) {
            this._inner.removeEventListener('click', this.keepInFocus);
        }
    }

    onWheel(event: WheelEvent) {
        if (this.active && ((this._outer.scrollTop === (this._outer.scrollHeight - this._outer.clientHeight) && event.deltaY > 0) || (this._outer.scrollTop === 0 && event.deltaY < 0))) {
            if (event.cancelable) {
                event.preventDefault();
                }
        }
    }

    keepInFocus(event: MouseEvent) {
        if (this.focus) {
            const target = event.target as HTMLElement;
            const boundingTop = target.getBoundingClientRect().top - this._outer.getBoundingClientRect().top;
            if (boundingTop >= this._outer.clientHeight || boundingTop < 0) {
                this.utilService.scrollElementTo(this._outer, target.offsetTop < this._outer.clientHeight ? 0 : target.offsetTop);
            }
        }
    }

    private _setStyling() {
        if (this.active) {
            this._outer.style.height = this.height;
            this._inner.style.width = `calc(100% + ${this._scrollbarWidth}px)`;
        }
        else {
            this._outer.removeAttribute('style');
            this._inner.removeAttribute('style');
        }
    }
}
