import { Component, Inject } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Translations } from '../../translations/translations';
import { IChangeDeliveryDay } from '../services/administration-delivery.service';
import { MatButton } from '@angular/material/button';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'iv-administration-edit-delivery-date',
    template: `
        <div class="administration-delivery-date">
            <form #f="ngForm" (ngSubmit)="submitChanges(f)" novalidate>
                <div class="administration-delivery-date__id" hidden>
                    <mat-form-field>
                        <input matInput name="alternativeDeliveryDateId" [(ngModel)]="delivaryDateData.AlternativeDeliveryDateId" #alternativeDeliveryDateId="ngModel">
                    </mat-form-field>
                </div>
                <div class="administration-delivery-date__from">
                    <mat-form-field>
                        <input required matInput [matDatepicker]="fromDate" placeholder="${Translations.municipality.deliveryDateChange.fromDate}"
                            name="from"
                            [(ngModel)]="delivaryDateData.From"
                            #from="ngModel">
                        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                        <mat-datepicker #fromDate></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="administration-delivery-date__to">
                    <mat-form-field>
                        <mat-label>${Translations.municipality.deliveryDateChange.toDate}</mat-label>
                        <input required matInput [matDatepicker]="toDate"
                            name="to"
                            [(ngModel)]="delivaryDateData.To"
                            #to="ngModel">
                        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                        <mat-datepicker #toDate></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="administration-delivery-date__original-order-date">
                    <mat-form-field>
                        <input required matInput [matDatepicker]="originalOrderDateControl"
                            placeholder="${Translations.municipality.deliveryDateChange.originalOrderDate}"
                            name="originalOrderDate"
                            [(ngModel)]="delivaryDateData.OriginalOrderDate"
                            #originalOrder="ngModel">
                        <mat-datepicker-toggle matSuffix [for]="originalOrderDateControl"></mat-datepicker-toggle>
                        <mat-datepicker #originalOrderDateControl></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="administration-delivery-date__destination-order-date">
                    <mat-form-field>
                        <input required matInput [matDatepicker]="destinationOrderDateControl" placeholder="${Translations.municipality.deliveryDateChange.destinationOrderDate}"
                        name="destinationOrderDate"
                        [(ngModel)]="delivaryDateData.DestinationOrderDate"
                        #destinationOrder="ngModel">
                        <mat-datepicker-toggle matSuffix [for]="destinationOrderDateControl"></mat-datepicker-toggle>
                        <mat-datepicker #destinationOrderDateControl></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="administration-delivery-date__btn">
                    <button type="submit" mat-raised-button color="primary" [disabled]="!f.valid">
                        <span>${Translations.municipality.deliveryDateChange.editButton}</span>
                    </button>
                </div>
            </form>
        </div>
    `,
    standalone: true,
    imports: [FormsModule, MatFormField, MatInput, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, MatLabel, MatButton]
})
export class AdministrationEditDeliveryDateComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public delivaryDateData: IChangeDeliveryDay,
        private dialogRef: MatDialogRef<AdministrationEditDeliveryDateComponent>
    ) {}

    submitChanges(f: NgForm) {
        this.dialogRef.close(f.value);
    }
}
