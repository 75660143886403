import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UtilService } from '../util/util.service';
import { CategoryNodeViewModel } from '../services/menu-models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { back, cross, forward } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor, MatButton } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';

interface ICategoryMenuLevelDevice {
    categoryMenu: CategoryNodeViewModel[];
    parent: {
        name: string;
    };
    current: {
        name: string;
        url: string;
    };
}

@Component({
    selector: 'iv-category-menu-level-device',
    template: `
        <div class="category-menu-level-device">
            <ng-container *ngIf="!disableBack; else tabletHead">
                @if (data && (data.current || data.parent)) {
                    <button class="layout-navigation-device__item layout-navigation-device__item_center"
                            (click)="closeLevel()" mat-button>
                        <mat-icon class="layout-navigation-device__back">${back}</mat-icon>
                        ${Translations.layout.navigation.device.back} {{ data.parent.name }}
                    </button>

                    <a [routerLink]="data.current.url" routerLinkActive="layout-navigation-device__item_active"
                       [routerLinkActiveOptions]="{ exact: true }" class="layout-navigation-device__item" mat-button>
                        <span
                            class="category-menu-level-device__show-all">${Translations.layout.navigation.device.showAll}&nbsp;</span><span
                        class="category-menu-level-device__highlight">{{ data.current.name }}</span>
                    </a>
                }
            </ng-container>

            <ng-template #tabletHead>
                <div class="layout-navigation-device__head">
                    <a [routerLink]="data.current.url"
                       class="layout-navigation-device__item layout-navigation-device__item_head" mat-button>
                        {{ data.current.name }}
                    </a>

                    <button class="layout-navigation-device__close" (click)="closeLevel()" mat-button>${cross}</button>
                </div>
            </ng-template>

            <ng-container *ngFor="let category of data?.categoryMenu">
                <a [routerLink]="category.url" routerLinkActive="layout-navigation-device__item_active"
                   [routerLinkActiveOptions]="{ exact: true }" class="layout-navigation-device__item"
                   *ngIf="category.subCategories.length === 0; else hasChildren" mat-button>
                    {{ category.name }}
                </a>

                <ng-template #hasChildren>
                    <button class="layout-navigation-device__item layout-navigation-device__item_has-children"
                            (click)="newLevel(category)" mat-button>
                        {{ category.name }}
                        <mat-icon class="layout-navigation-device__forward">${forward}</mat-icon>
                    </button>
                </ng-template>
            </ng-container>
        </div>
    `,
    standalone: true,
    imports: [NgIf, MatButton, MatIcon, MatAnchor, RouterLinkActive, RouterLink, NgFor]
})
export class CategoryMenuLevelDeviceComponent implements OnInit, OnDestroy {
    @Input() data: ICategoryMenuLevelDevice;
    @Output() output = new EventEmitter();

    disableBack = false;

    private unsubscribe: Subject<void> = new Subject();

    constructor(public utilService: UtilService) {
    }

    ngOnInit() {
        this.utilService.deviceType$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(device => {
            this.disableBack = device === 'tablet' && this.data.current.url === Translations.shop.paths.shopPage.path;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    newLevel(category: CategoryNodeViewModel) {
        this.output.emit(category);
    }

    closeLevel() {
        this.output.emit();
    }
}
