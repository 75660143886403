import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilderTextInput, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { Translations } from '../../translations/translations';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { InternalUserService } from '../services/internal-user.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { IUserUsernameHistory } from '../../api/services';
import { municipalityportal } from '../../scripts/generated/icons';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';
import { NgFor, NgIf, NgStyle, AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'iv-administration-user-history',
    template: `
        <section class="administration-user-history">
            <form
                #citizensForm
                [formGroup]="form"
                class="administration-user-history__form alt-theme form"
                autocomplete="off"
            >
                <iv-form-builder-element
                    *ngFor="let input of inputs"
                    [form]="form"
                    [input]="input"
                ></iv-form-builder-element>

                <div class="administration-user-history__spinner">
                    <mat-spinner *ngIf="isFetching" diameter="34"></mat-spinner>
                </div>
            </form>


            <div class="administration-user-history__table">
                <div class="errors" *ngIf="validationErrors">
                    <h4 *ngFor="let error of validationErrors">{{ error }}</h4>
                </div>
            </div>

            <mat-accordion class="administration-user-history" multi hideToggle displayMode="flat" >
                <mat-expansion-panel expanded *ngFor="let user of userHistory$ | async">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <small>${Translations.municipality.viewUserHistory.labels.currentUsername}</small>
                            <h4>{{ user.currentUsername }}</h4>
                        </mat-panel-title>
                        <mat-panel-description>
                            <div class="administration-user-history__user">
                                <small>${Translations.municipality.viewUserHistory.labels.userId}</small>
                                <h4>{{ user.userId }}</h4>
                            </div>
                            <div class="administration-user-history__icon svg-icon">${municipalityportal}</div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-table *ngIf="user.usernameChangeRecords" [dataSource]="(user.usernameChangeRecords ?? [])"
                           [ngStyle]="{width: '100%'}">
                        <ng-container matColumnDef="index">
                            <mat-header-cell mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element; let i = index"> #{{ user?.usernameChangeRecords?.length - i}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="changedDate">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>${Translations.municipality.viewUserHistory.table.changedData}</mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element"> {{element.changedDate | date : "short" }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="newUsername">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>${Translations.municipality.viewUserHistory.table.newUsername}</mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element"> {{element.newUsername}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="oldUsername" >
                            <mat-header-cell mat-header-cell *matHeaderCellDef>${Translations.municipality.viewUserHistory.table.oldUsername}</mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element"> {{element.oldUsername}} </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </mat-expansion-panel>
            </mat-accordion>


        </section>
    `,
    styles: [],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor, FormBuilderElementComponent, NgIf, MatProgressSpinner, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatTable, NgStyle, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, AsyncPipe, DatePipe]
})
export class AdministrationUserHistoryComponent implements OnInit, OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();
    private filterUsers$: Subject<{ currentUsername: string}> = new Subject();

    public userHistory$: Observable<IUserUsernameHistory[]>;
    public inputs: FormBuilderTypes[] = [
        new FormBuilderTextInput({
            name: Translations.forms.labels.municipality.currentUsername.name,
            label: Translations.forms.labels.municipality.currentUsername.label
        })
    ];
    public validationErrors: string[] = [];
    public isFetching = false;
    public form: FormGroup = this.formBuilder.toFormGroup(this.inputs);
    public displayedColumns: string[] = ['index', 'changedDate', 'newUsername', 'oldUsername'];


    constructor(private readonly formBuilder: FormBuilderService,
                private internalUserService: InternalUserService) {}

    ngOnInit(): void {

        this.userHistory$ = this.filterUsers$.pipe(
            debounceTime(300),
            tap(v => {
                this.isFetching = true;
            }),
            switchMap(({currentUsername}) => this.internalUserService.getUsernameHistory(currentUsername).pipe(
                catchError(err => {
                    this.handleWithErrors(err);
                    return of([]);
                }))
            ),
            tap(_ => this.isFetching = false),
            takeUntil(this.unsubscribe$)
        );


        this.form.valueChanges.pipe(
            distinctUntilChanged(),
            filter(({currentUsername}) => {
                if (currentUsername?.length > 2) {
                    return true;
                }
                this.validationErrors = [];
                return false;
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe(value => {
            this.filterUsers$.next(value);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private handleWithErrors(err: IntervareHttpErrorResponse) {
        this.validationErrors = [];

        if (err.validationErrors && err.validationErrors.length > 0) {
            this.validationErrors = err.validationErrors;
        } else {
            this.validationErrors.push(err.error?.statusText);
        }

        this.isFetching = false;
    }
}
