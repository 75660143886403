import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Translations } from '../../translations/translations';
import { defaultCalendarConfig, ICol, IEvent, ISlot } from '../services/calendar.service';
import { IDragEvent } from './drag.service';
import { DragTargetDirective } from './directives/dragtarget.directive';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'iv-drag-slots-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <section class="drag-slots-calendar call-list-admin__calendar">
            <div class="times" *ngIf="cols.length">
                <div *ngFor="let slot of cols[0].slots" class="time">
                    <div *ngIf="slot.min === 0">{{ slot.hour }}</div>
                </div>
                <div *ngIf="cols.length" class="time">
                    <div>{{ cols[0].slots[cols[0].slots.length-1].hour+1 }}</div>
                </div>
            </div>
            <div class="columns">
                <div *ngFor="let col of cols" class="column">
                    <button class="title" (click)="onTitleClicked(col)" [ngClass]="{selected: col.selected}">{{ col.title }}</button>
                    <div *ngFor="let slot of col.slots"
                        class="slot"
                        dragtarget
                        [canDrop]="canDrop(slot, col)"
                        (dropComplete)="onDropComplete($event, slot, col)">

                        <button *ngFor="let event of slot.events"
                                class="booking booking-{{event.duration}} booking-{{event.color}}"
                                (click)="onEventClicked(event)"
                                [ngClass]="{selected: event.selected}">
                            {{ event.isPause ? '${Translations.callListAdmin.callListPage.intervalTab.breakBlock}' : (event.allocation ? event.allocation + ' / ' + event.capacity : '0') }}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    `,
    standalone: true,
    imports: [NgIf, NgFor, NgClass, DragTargetDirective]
})
export class DragSlotsCalendarComponent {
    @Output() dropped = new EventEmitter<IDragEvent>();
    @Output() titleClicked = new EventEmitter<ICol>();
    @Output() eventClicked = new EventEmitter<IEvent>();
    @Input() cols: ICol[] = [];

    onTitleClicked(col: ICol) {
        this.titleClicked.emit(col);
    }
    onEventClicked(event: IEvent) {
        this.eventClicked.emit(event);
    }

    canDrop(slot: ISlot, col: ICol) {
        return (dragger: any) => {
            // this is called alot and should be very efficient

            if (!dragger.duration) {
                /* Type guard so we can only drop events here */
                return false;
            }

            const draggersRequiredSlots = dragger.duration / (60 / defaultCalendarConfig.intervals);

            if (slot.blocked) {
                // fast exit
                return false;
            }

            const slotIndex = col.slots.indexOf(slot);

            // Check end of day bound
            if (col.slots.length < slotIndex + draggersRequiredSlots) {
                return false;
            }

            // check for colisions in coming slots
            for (let i = 1; i < draggersRequiredSlots; i++) {
                if (col.slots[slotIndex + i].blocked) {
                    return false;
                }
            }

            return true;
        };
    }

    onDropComplete(dragger: any, slot: ISlot, col: ICol) {
        this.dropped.emit({ value: dragger, where: { slot, col } });
    }

    constructor() { }
}
