import { Translations } from '../../translations/translations';
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ProductService } from '../services/product.service';
import { UserService } from '../user/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { favorite, favoriteInactive } from '../../scripts/generated/icons';
import { NgIf } from '@angular/common';

@Component({
    selector: 'iv-commerce-toggle-favorite',
    template: `
        <div class="commerce-toggle-favorite" *ngIf="show" (click)="toggleFavorite()">
            <span class="icon" *ngIf="isFavorite">${favorite}</span>
            <span class="icon" *ngIf="!isFavorite">${favoriteInactive}</span>
            <span class="commerce-toggle-favorite__text" *ngIf="text">${Translations.commerce.favoriteToggle.text}</span>
        </div>
    `,
    standalone: true,
    imports: [NgIf]
})
export class CommerceToggleFavoriteComponent implements OnInit, OnDestroy {
    @Input() productId: string;
    @Input() isFavorite: boolean;
    @Input() text: boolean;
    show = false;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private productService: ProductService, private userService: UserService, private cd: ChangeDetectorRef) { }

    ngOnInit() {
        this.userService.user$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(user => this.show = !!user);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    toggleFavorite() {
        this.productService.toggleFavorite({ productNo: this.productId }).pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(data => {
            this.isFavorite = data;
            this.cd.markForCheck();
        });
    }
}
