import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { IChangePasswordModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { FormBuilderValidators } from '../form-builder/validators/form-builder-validators';
import { AuthService } from '../services/auth.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { MatAnchor } from '@angular/material/button';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { MatInput } from '@angular/material/input';
import { MatError, MatFormField } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'iv-user-edit-password',
    template: `
        <article class="edit-password magic-width">
            <header class="edit-password__header">
                <h1>
                    <ng-container *ngIf="!voluntary">
                        ${Translations.pages.editPassword.title}
                    </ng-container>
                    <ng-container *ngIf="voluntary">
                        ${Translations.pages.editPassword.voluntaryTitle}
                    </ng-container>
                </h1>
            </header>

            <p *ngIf="!voluntary">${Translations.pages.editPassword.explanatoryText}</p>

            <ng-container *ngIf="!successState; else successBlock">

                <form class="form" [formGroup]="passwordForm" (ngSubmit)="onSubmit()"
                      aria-label="${Translations.pages.editPassword.formLabel}" novalidate>
                    <mat-form-field>
                        <input formControlName="password" matInput type="password"
                               placeholder="${Translations.pages.editPassword.passwordLabel}" required>
                    </mat-form-field>
                    <mat-form-field hintLabel="${Translations.pages.editPassword.newPasswordHelp}">
                        <input formControlName="newPassword" matInput type="password"
                               placeholder="${Translations.pages.editPassword.newPasswordLabel}" required>
                    </mat-form-field>
                    <mat-form-field>
                        <input formControlName="newPasswordConfirm" matInput type="password"
                               placeholder="${Translations.pages.editPassword.newPasswordConfirmLabel}" required>
                        <mat-error *ngIf="newPasswordConfirm && newPasswordConfirm.invalid">
                            ${Translations.pages.newPassword.newPasswordConfirmError}
                        </mat-error>
                    </mat-form-field>
                    <div role="status" class="error" *ngIf="errorMsg">{{ errorMsg }}</div>
                    <iv-progress-button class="login__btn" [loadingState]="loadingState" color="primary"
                                        [disabled]="passwordForm.invalid">
                        ${Translations.pages.editPassword.editPasswordSubmit}
                    </iv-progress-button>
                </form>

            </ng-container>
        </article>

        <ng-template #successBlock>
            <div class="success-feedback">

                <div class="success-feedback__text">
                    ${Translations.pages.editPassword.editPasswordSuccess}
                </div>

                <a [routerLink]="['', { outlets: { primary: null } }]" class="success-feedback__btn" mat-raised-button
                   color="primary">
                    ${Translations.pages.editPassword.goToFrontPage}
                </a>
            </div>
        </ng-template>
    `,
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatFormField, MatInput, MatError, ProgressButtonComponent, MatAnchor, RouterLink]
})
export class UserEditPasswordComponent implements OnInit, OnDestroy {
    passwordForm: UntypedFormGroup;
    voluntary = false;

    errorMsg: string;
    loadingState = false;
    successState = false;

    get newPasswordConfirm() {
        return this.passwordForm.get('newPasswordConfirm');
    }

    private unsubscribe = new Subject<void>();

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.route.data.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(data => this.voluntary = data.pageData && data.pageData.voluntary ? data.pageData.voluntary : false);

        this._createForm();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onSubmit() {
        this.errorMsg = '';

        if (this.passwordForm.valid) {
            this.loadingState = true;
            this.successState = false;

            const formModel = this.passwordForm.value;

            const changePasswordData: IChangePasswordModel = {
                currentPassword: formModel.password,
                newPassword: formModel.newPassword,
                newPasswordRetyped: formModel.newPasswordConfirm
            };

            this.authService.changePassword(changePasswordData).pipe(
                takeUntil(this.unsubscribe),
                finalize(() => this.loadingState = false)
            ).subscribe({
                next: () => this.successState = true,
                error: (err: IntervareHttpErrorResponse) => {
                    this.errorMsg = err.validationErrors.join(', ');
                }
            });
        }
    }

    private _createForm(): void {
        const newPasswordField = new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(6)
        ]);

        const newPasswordConfirmField = new UntypedFormControl('', [
            Validators.required,
            FormBuilderValidators.matchFieldValidator(newPasswordField)
        ]);

        this.passwordForm = new UntypedFormGroup({
            password: new UntypedFormControl('', [
                Validators.required
            ]),
            newPassword: newPasswordField,
            newPasswordConfirm: newPasswordConfirmField
        });

        this.passwordForm.valueChanges.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => this.errorMsg = '');

        newPasswordField.valueChanges.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => newPasswordConfirmField.updateValueAndValidity());
    }
}
