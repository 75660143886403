import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

import { Translations } from '../../translations/translations';
import { DialogComponent } from './dialog.component';
import { DialogConfirmComponent } from './dialog-confirm.component';
import { DialogFormComponent } from './dialog-form.component';
import {
    DialogContent,
    IDialogComponentSettings,
    IDialogContentSettings,
    IDialogFormSettings,
    IDialogOptionsSettings,
    IDialogSettings
} from './dialog-interfaces';
import { DialogOptionsComponent } from './dialog-options.component';
import { DialogCallStatusFormComponent } from './dialog-call-status-form.component';

@Injectable()
export class DialogService {

    constructor(
        protected dialog: MatDialog,
        protected snackBar: MatSnackBar
    ) {
    }

    public openDialog(settingObj: IDialogContentSettings) {
        return this.initDialog(DialogComponent, settingObj);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openDialogWithComponent<T = any>(component: ComponentType<any>, settingObj?: IDialogComponentSettings<T>) {
        return this.initDialog(component, settingObj);
    }

    public showMessage(content: DialogContent, header?: string, role: 'dialog' | 'alertdialog' = 'dialog') {
        const settings: IDialogContentSettings = {
            role,
            data: {
                content,
                header,
                buttons: {
                    button1: {
                        color: 'primary',
                        confirm: true,
                        text: Translations.global.btnAcknowledge
                    }
                }
            }
        };

        return this.openDialog(settings);
    }

    public showValidationResult(validationErrors: string[]) {
        if (validationErrors.length) {
            return this.showMessage(validationErrors.join('<br>'), Translations.global.errorMessages.header, 'alertdialog');
        }

        return undefined;
    }

    public showSnackMessage(settings: { message: string, action?: string, config?: MatSnackBarConfig }) {
        settings.action = settings.action || Translations.global.btnAcknowledge;
        settings.config = settings.config || {
            duration: 4000
        };
        return this.initSnackBar(settings.message, settings.action, settings.config);
    }

    public showOptions(settings: IDialogOptionsSettings) {
        return this.openDialogWithComponent(DialogOptionsComponent, settings);
    }

    public showForm(settings: IDialogFormSettings) {
        return this.openDialogWithComponent(DialogFormComponent, settings);
    }

    public showCallStatusForm(settings: IDialogFormSettings) {
        return this.openDialogWithComponent(DialogCallStatusFormComponent, settings);
    }


    public confirm(confirmText: string) {
        return this.openDialogWithComponent(DialogConfirmComponent, {
            data: confirmText
        });
    }

    public closeAllDialogs() {
        this.dialog.closeAll();
        this.snackBar.dismiss();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private initDialog<C extends ComponentType<any>>(component: C, settingObj?: IDialogSettings<any>): MatDialogRef<C> {
        const dialogConfig = settingObj ? settingObj : {};

        const panelClasses: string[] = [];

        if (dialogConfig.mobileFullscreen) {
            panelClasses.push('fullscreen_mobile');
        }

        if (dialogConfig.noMargin) {
            panelClasses.push('nomargin');
        }

        if (typeof dialogConfig.panelClass === 'string') {
            panelClasses.push(dialogConfig.panelClass);
        } else if (typeof dialogConfig.panelClass === 'object') {
            panelClasses.concat(dialogConfig.panelClass);
        }

        dialogConfig.panelClass = panelClasses;

        if (!dialogConfig.maxWidth) {
            dialogConfig.maxWidth = '940px';
        }

        return this.dialog.open(component, dialogConfig);
    }

    private initSnackBar(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.open(message, action, config);
    }
}
