import { BasketRowViewModel, IReservationViewModel } from '../../api/services';
import { IReservationStatus, IRowsReservationStatus, ReservationErrorCodeType } from './commerce.models';

export const getReservationStatus = (reservation: IReservationViewModel): IReservationStatus => {

    const showReservationErrors = reservation.showErrors || (reservation.showErrors === false && reservation.reservationErrorData?.code === ReservationErrorCodeType.IMPACT_QTDIFF);

    // because we receive from BE the hasReservation equal to false when the reservation fails,
    // and we want also control if we have the flag to not mess with other "basket-items"
    // which are not directly related with in the basket, like "Top products not in basket"
    const canBeReserved = reservation?.hasOwnProperty('hasReservation') && reservation.hasOwnProperty('reservationErrorData');

    // but if the product is not available to be reserved
    const isNotAvailable = !!reservation.reservationErrorData && !reservation.hasReservation && reservation.reservedQuantity === 0 && reservation.reservationErrorData?.code !== ReservationErrorCodeType.IMPACT_QTDIFF;
    const hasWarning = reservation.reservationErrorData?.code === ReservationErrorCodeType.IMPACT_QTDIFF;

    return { canBeReserved, hasWarning, isNotAvailable, showReservationErrors };
};


export const getRowsReservationStatus = (rows: BasketRowViewModel[]): IRowsReservationStatus => {
    let reservationHasErrors = false;
    let showReservationErrors = false;
    if (rows?.length) {
        reservationHasErrors = rows.some(row => (!!row.reservation?.reservationErrorData && !row.reservation?.hasReservation && row.reservation.reservationErrorData?.code !== ReservationErrorCodeType.IMPACT_QTDIFF));
        showReservationErrors = rows.every(row => row.reservation?.showErrors);
    }

    return { reservationHasErrors, showReservationErrors };
};
