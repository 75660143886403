import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    AvailabilityViewModel,
    CreateUserViewModel,
    District,
    IAvailabilityViewModel,
    IDistrict,
    IMunicipality,
    ISkipTakeResultObjectOfUser,
    IUser,
    IUserMunicipalityDistrictViewModel,
    Municipality,
    SkipTakeResultObjectOfUser,
    UpdateUserViewModel,
    User,
    UserMunicipalityDistrictViewModel,
    WeekDays,
} from '../../api/services';
import { BaseService } from '../services/base-service';

export interface IUserDefaultMunicipalities {
    municipality: number;
    districts: number | number[];
    subdistricts: number | number[];
}

@Injectable()
export class MunicipalityService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    /**
     * Gets the list of municipalities
     * @returns {Observable<IMunicipality[]>}
     */
    getMunicipalities(): Observable<IMunicipality[]> {
        return this.http.get<object[]>(this.apiBaseUrl('/Municipality/GetMunicipalities')).pipe(
            map(x => x.map(Municipality.fromJS))
        );
    }


    getMunicipalityDeliveryDays(municipalityId: number): Observable<WeekDays[]> {
        return this.http.get<WeekDays[]>(this.apiBaseUrl('/Municipality/GetMunicipalityDeliveryDays'), { params: { municipalityId: municipalityId.toString() } });
    }

    /**
     * Gets the list of districts in a municipality
     * @param {number} municipalityId
     * @returns {Observable<IDistrict[]>}
     */
    getDistricts(municipalityId: number): Observable<IDistrict[]> {
        return this.http.get<object[]>(this.apiBaseUrl('/District/GetDistricts'), {
            params: {
                municipalityId: municipalityId.toString()
            }
        }).pipe(map(x => x.map(District.fromJS)));
    }

    /**
     * Gets the user by its ID
     * @param {string} userId
     * @returns {Observable<object>}
     */
    getUser(userId: string): Observable<IUser> {
        return this.http.get(this.apiBaseUrl('/User/GetUserById'), {
            params: new HttpParams().set('id', userId.toString())
        }).pipe(map(User.fromJS));
    }

    /**
     * Gets the user by username
     * @param {string} username
     * @param {boolean} IncludeInactive
     * @returns {Observable<object>}
     */
    getUserByName(username: string, includeInactive = false): Observable<ISkipTakeResultObjectOfUser> {
        return this.http.get<ISkipTakeResultObjectOfUser>(this.apiBaseUrl('/User/GetMunicipalityUsers'), {
            params: new HttpParams().set('SearchText', username.toString()).set('IncludeInactive', includeInactive.toString())
        }).pipe(map(SkipTakeResultObjectOfUser.fromJS));
    }

    /**
     * Either creates or updates a user
     * @param {CreateUserViewModel | UpdateUserViewModel} data
     * @param {boolean} update
     * @returns {Observable<Object>}
     *
     */
    createOrUpdateUser(
        data: CreateUserViewModel | UpdateUserViewModel,
        update: boolean = false
    ) {
        if (update) {
            return this.http.put(this.apiBaseUrl('/User/UpdateUser'), data);
        } else {
            return this.http.post(this.apiBaseUrl('/User/CreateUser'), data);
        }
    }

    /**
     * Gets the list of users matching a filter
     * @param {number | string | string[] | number[]} municipalities
     * @param {number | string | string[] | number[]} districts
     * @param {number | string | string[] | number[]} subDistricts
     * @param {number} skip
     * @param {number} take
     * @param {boolean} IncludeInactive
     * @returns {Observable<Object>}
     */
    getUsers(
        municipalities: number | string | string[] | number[] = 0,
        districts: number | string | string[] | number[] = 0,
        subDistricts: number | string | string[] | number[] = 0,
        skip: number = 0,
        take: number = 0,
        includeInactive = false,
    ): Observable<ISkipTakeResultObjectOfUser> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const params: any = {};

        if (municipalities !== 0 && municipalities !== '') {
            params.municipalities = municipalities;
        }

        if (districts !== 0 && districts !== '') {
            params.districts = districts;
        }

        if (subDistricts !== 0 && subDistricts !== '') {
            params.subDistricts = subDistricts;
        }

        if (skip !== 0) {
            params.skip = skip.toString();
        }

        if (take !== 0) {
            params.take = take.toString();
        }

        if (includeInactive) {
            params.includeInactive = includeInactive;
        }

        return this.http.get<ISkipTakeResultObjectOfUser>(this.apiBaseUrl('/User/GetMunicipalityUsers'), {
            params: new HttpParams({
                fromObject: params
            })
        }).pipe(map(SkipTakeResultObjectOfUser.fromJS));
    }

    /**
     * Gets a users municipalities and district limits
     * @returns {Observable<Object>}
     */
    getUserMunicipalityAndDistricts(): Observable<IUserMunicipalityDistrictViewModel> {
        return this.http.get(this.apiBaseUrl('/User/GetUserMunicipalityAndDistricts'))
            .pipe(map(UserMunicipalityDistrictViewModel.fromJS));
    }

    getAvailabilities(dayOfWeek: number): Observable<IAvailabilityViewModel[]> {
        return this.http.get<object[]>(this.apiBaseUrl('/CallListAdmin/GetAvailabilities'), { params: { dayOfWeek: dayOfWeek.toString() } }).pipe(
            map(x => x.map(AvailabilityViewModel.fromJS))
        );
    }
}
