import { CommonModule, CurrencyPipe, PercentPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormBuilderModule } from '../form-builder/form-builder.module';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { MenuModule } from '../menu/menu.module';

import { ServicesModule } from '../services/services.module';
import { UtilModule } from '../util/util.module';
import { AccordionComponent } from './accordion.component';
import { AddressPipe } from './address.pipe';
import { ChunkPipe } from './chunk.pipe';
import { CommaDashPipe } from './comma-dash.pipe';
import { CustomerTypePipe } from './customer-type.pipe';
import { DialogConfirmComponent } from './dialog-confirm.component';
import { DialogFormComponent } from './dialog-form.component';
import { DialogOptionsComponent } from './dialog-options.component';
import { DialogOutletComponent } from './dialog-outlet.component';
import { DialogComponent } from './dialog.component';
import { DialogService } from './dialog.service';
import { DynamicTableComponent } from './dynamic-table.component';
import { ElementRenderer } from './element-renderer.service';
import { PageNotFoundComponent } from './page-not-found.component';
import { ProgressButtonComponent } from './progress-button.component';
import { RecievedDialogComponent } from './recieved-dialog.component';
import { RouteService } from './route.service';
import { TransferDetailsDialogComponent } from './transfer-details-dialog.component';
import { TransferReasonDialogService } from './transfer-reason-dialog.service';
import { TranslationPipe } from './translation.pipe';
import { UnderlinePipe } from './underline.pipe';
import { HighlightPipe } from './highlight.pipe';
import { InterceptLinksDirective } from './intercept-links.directive';
import { GetRegionNamePipe } from './get-region-name.pipe';
import { JoinSecurityRolesPipe } from './join-security-roles.pipe';
import { DistrictNamesPipe } from './district-names.pipe';
import { SubDistrictNamesPipe } from './subdistrict-names.pipe';
import { EllipsisComponent } from './ellipsis.component';

@NgModule({
    imports: [
    CommonModule,
    RouterModule,
    MaterialUiModule,
    MenuModule,
    UtilModule.forChild(),
    FormBuilderModule,
    ServicesModule,
    CommaDashPipe,
    ProgressButtonComponent,
    DialogComponent,
    PageNotFoundComponent,
    DialogOutletComponent,
    DialogOptionsComponent,
    DialogConfirmComponent,
    DynamicTableComponent,
    AccordionComponent,
    TranslationPipe,
    UnderlinePipe,
    DialogFormComponent,
    ChunkPipe,
    AddressPipe,
    CustomerTypePipe,
    RecievedDialogComponent,
    TransferDetailsDialogComponent,
    HighlightPipe,
    InterceptLinksDirective,
    GetRegionNamePipe,
    JoinSecurityRolesPipe,
    DistrictNamesPipe,
    SubDistrictNamesPipe,
    EllipsisComponent
],
    exports: [
    CommonModule,
    RouterModule,
    MaterialUiModule,
    MenuModule,
    UtilModule,
    FormBuilderModule,
    ServicesModule,
    DialogComponent,
    PageNotFoundComponent,
    DynamicTableComponent,
    DialogOutletComponent,
    DialogOptionsComponent,
    DialogConfirmComponent,
    ProgressButtonComponent,
    CommaDashPipe,
    AccordionComponent,
    TranslationPipe,
    UnderlinePipe,
    ChunkPipe,
    AddressPipe,
    CustomerTypePipe,
    HighlightPipe,
    RecievedDialogComponent,
    InterceptLinksDirective,
    GetRegionNamePipe,
    JoinSecurityRolesPipe,
    DistrictNamesPipe,
    SubDistrictNamesPipe,
    EllipsisComponent
]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                RouteService,
                CurrencyPipe,
                PercentPipe,
                DialogService,
                TransferReasonDialogService,
                ElementRenderer
            ]
        };
    }

    static forChild(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
