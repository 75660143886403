/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { CitizenStatus, CitizenTransferReason, CustomerSubType, ICitizenBasicInfoViewmodel } from '../../api/services';
import { busy, callAgain, pending, returnArrow, time, transfer } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { AuthService } from '../services/auth.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CallListService } from '../services/call-list.service';
import { CitizenService } from '../services/citizen.service';
import { DialogService } from '../shared/dialog.service';
import { TransferDetails, TransferDetailsDialogComponent } from '../shared/transfer-details-dialog.component';
import { TransferReasonDialogService } from '../shared/transfer-reason-dialog.service';
import { UserService } from '../user/user.service';
import { IDialogContentSettings } from '../shared/dialog-interfaces';
import { IUserRoles, ROLES } from '../user/user-roles';
import { GenesysConversationErrorDetails } from '../services/genesys/genesys.model';
import { CallsService } from '../services/calls/calls.service';
import { CustomerTypePipe } from '../shared/customer-type.pipe';
import { ChunkPipe } from '../shared/chunk.pipe';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { CitizenHistoryListComponent } from './citizen-history-list.component';
import { CitizenNoteFormComponent } from './citizen-note-form.component';
import { CitizenNotesFormComponent } from './citizen-notes-form.component';
import { CitizenSpecialinformationComponent } from './citizen-specialinformation.component';
import { MatMiniFabButton } from '@angular/material/button';
import { UtilHiddenScrollComponent } from '../util/util-hidden-scroll.component';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { EMPTY, firstValueFrom, Observable, of, Subject, switchMap } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'iv-citizen-summary',
    template: `
        <article class="citizen-summary">
            <ng-container *ngIf="citizen; else fetching">
                <iv-util-hidden-scroll class="citizen-summary__hidden-scroll" height="100%">
                    <div
                        class="citizen-summary__header-section citizen-summary__header-section_main citizen-summary__header-section_noborder alt-theme"
                    >
                        <header class="citizen-summary__header-info">
                            <h2 class="citizen-summary__user-name citizen-summary__header">
                                {{ citizen.name }}
                                <span
                                    role="status"
                                    class="icon"
                                    [class.sync-status-error]="citizen.errorDuringSync"
                                    [title]="syncStatusMsg"
                                    *ngIf="!citizen.isSync"
                                >
                                    ${pending}
                                </span>
                            </h2>
                            <h4 class="citizen-summary__subheader">
                                <button
                                    class="citizen-summary__call-btn"
                                    type="button"
                                    (click)="makeCall(citizen.phoneNumber, citizen.customerNumber, citizen.isBlocked)"
                                >
                                    {{ citizen.phoneNumber | chunk }}
                                </button>
                                <ng-container *ngIf="citizen.mobileNumber">
                                    ·
                                    <button
                                        class="citizen-summary__call-btn"
                                        type="button"
                                        (click)="
                                            makeCall(citizen.mobileNumber, citizen.customerNumber, citizen.isBlocked)
                                        "
                                    >
                                        {{ citizen.mobileNumber | chunk }}
                                    </button>
                                </ng-container>
                                · {{ citizen.customerType | customerType }}
                            </h4>
                        </header>
                        <button
                            mat-mini-fab
                            color="accent"
                            [routerLink]="['', { outlets: { admin: linkBack } }]"
                            aria-label="${Translations.layout.trigger.label}"
                        >
                            <div class="layout-navigation__menu-trigger-icon icon">${returnArrow}</div>
                        </button>
                    </div>

                    <ng-container *ngIf="citizen.hasRelatedInformation">
                        <div class="citizen-summary__header-section">
                            <h2 class="citizen-summary__header">
                                ${Translations.administration.citizenQuickView.specialInformation}
                            </h2>
                        </div>
                        <iv-citizen-specialinformation
                            class="citizen-summary__special-information"
                            [citizenNo]="citizen.customerNumber"
                            [show]="fromCallList"
                        ></iv-citizen-specialinformation>
                    </ng-container>

                    <iv-citizen-notes-form
                        [isCustomerService]="isCustomerService"
                        [note]="note"
                    ></iv-citizen-notes-form>

                    <div class="citizen-summary__customer-card">
                        <div class="citizen-summary__header-section">
                            <h2 class="citizen-summary__header citizen-summary__header_has-short-cut">
                                ${Translations.administration.citizenQuickView.customerCard}
                            </h2>
                            <a (click)="goToDetails($event)">
                                <h4 class="citizen-summary__link">
                                    ${Translations.administration.citizenQuickView.showCustomerCard}
                                </h4>
                            </a>
                        </div>
                        <dl class="citizen-summary__customer-card-list side-by-side">
                            <ng-container *ngIf="citizen.transferedCustomerId">
                                <dt>${Translations.administration.citizenQuickView.transferStatus.key}</dt>
                                <dd>
                                    ${Translations.administration.citizenQuickView.transferStatus.value} ({{
                                        formatTransferReason(citizen.transferReason)
                                    }})
                                </dd>
                            </ng-container>
                            <dt>${Translations.administration.citizen.number}</dt>
                            <dd>{{ citizen.customerNumber }}</dd>
                            <ng-container *ngIf="citizen.username">
                                <dt>${Translations.administration.citizen.username}</dt>
                                <dd>{{ citizen.username }}</dd>
                            </ng-container>
                            <dt>${Translations.global.textSet.anOrder}</dt>
                            <dd [innerHTML]="citizen.orderDateTime"></dd>
                            <dt>${Translations.global.textSet.delivery}</dt>
                            <dd [innerHTML]="citizen.deliveryDateTime"></dd>
                            <dt>${Translations.global.textSet.municipality}</dt>
                            <dd>{{ citizen.municipality }}</dd>
                            <dt>${Translations.global.textSet.district}</dt>
                            <dd>{{ citizen.district }}</dd>
                            <dt>${Translations.global.textSet.subDistrict}</dt>
                            <dd>{{ citizen.subDistrict }}</dd>
                            <dt>${Translations.global.textSet.latestBuy}</dt>
                            <dd>{{ citizen.lastBuyDate }}</dd>
                            <dt>${Translations.administration.citizen.deliveryDriver}</dt>
                            <dd>{{ citizen.driverName }}</dd>
                            <ng-container *ngIf="citizen.specialStatus">
                                <dt class="strong">${Translations.administration.citizen.customerStatus}</dt>
                                <dd class="strong">
                                    {{ citizen.specialStatus.statusText }}: {{ citizen.specialStatus.statusDate }}
                                </dd>
                            </ng-container>
                            <ng-container *ngIf="!citizen.allowReplacementProducts">
                                <dt class="strong warn">${Translations.administration.citizen.replacementProducts}</dt>
                                <dd class="strong warn">
                                    ${Translations.administration.citizen.replacementProductsValue}
                                </dd>
                            </ng-container>
                            <ng-container *ngIf="citizen.isBlocked">
                                <dt class="strong warn">${Translations.administration.citizen.isBlocked}</dt>
                                <dd class="strong warn">${Translations.administration.citizen.isBlockedValue}</dd>
                            </ng-container>
                            <ng-container *ngIf="citizen.orderNo">
                                <dt class="strong">${Translations.administration.citizen.hasOpenOrder}</dt>
                                <dd class="strong">
                                    <a
                                        [routerLink]="[
                                            '',
                                            '${Translations.shop.paths.ordersPage.path}',
                                            citizen.orderNo
                                        ]"
                                    >{{ citizen.orderNo }}</a
                                    >
                                </dd>
                            </ng-container>
                        </dl>
                    </div>

                    <div class="citizen-summary__history">
                        <div class="citizen-summary__header-section">
                            <h2 class="citizen-summary__header citizen-summary__header_has-short-cut">
                                ${Translations.administration.citizenQuickView.history}
                            </h2>
                            <a (click)="goToDetailsHistory($event)">
                                <h4 class="citizen-summary__link">
                                    ${Translations.administration.citizenQuickView.showHistory}
                                </h4>
                            </a>
                        </div>

                        <a (click)="expandNewNote($event)">
                            <h4 class="citizen-summary__link citizen-summary__link_has-short-cut">
                                ${Translations.administration.citizenQuickView.newNote}
                            </h4>
                        </a>

                        <iv-citizen-note-form *ngIf="newNote" (create)="onNewNote()"></iv-citizen-note-form>

                        <iv-citizen-history-list
                            [parseHTML]="true"
                            [historyList]="citizen.historyItems"
                            [customerNo]="citizenNo"
                        ></iv-citizen-history-list>
                    </div>
                </iv-util-hidden-scroll>

                <div
                    class="citizen-summary__actions"
                    *ngIf="
                        citizen.status === citizenStatus.Active &&
                        (citizen.transferedCustomerId ||
                            citizen.callRecordId ||
                            citizen.customerType !== customerSubType.Ringeborger ||
                            fromCallList)
                    "
                >
                    <div class="citizen-summary__header-section">
                        <h2 class="citizen-summary__header">${Translations.administration.citizenQuickView.actions}</h2>
                    </div>
                    <div class="citizen-summary__action-buttons">
                        <ng-container [ngSwitch]="!!citizen.transferedCustomerId">
                            <iv-progress-button
                                *ngSwitchCase="true"
                                buttonType="button"
                                class="citizen-summary__action-button btn-with-icon"
                                color="accent"
                                width="full"
                                [callback]="citizenTransferDetails"
                            >${transfer}${Translations.global.transferDetails.seeDetailsBtn}
                            </iv-progress-button
                            >
                            <iv-progress-button
                                *ngSwitchDefault
                                buttonType="button"
                                class="citizen-summary__action-button btn-with-icon"
                                color="accent"
                                width="full"
                                [callback]="citizenTransferCall"
                            >${transfer}${Translations.administration.citizenQuickView.transfer}
                            </iv-progress-button
                            >
                        </ng-container>
                        <ng-container *ngIf="fromCallList">
                            <iv-progress-button
                                buttonType="button"
                                class="citizen-summary__action-button btn-with-icon"
                                color="accent"
                                width="full"
                                [callback]="citizenNewCallTime"
                            >${time}${Translations.administration.citizenQuickView
                                .newCallAppointment}
                            </iv-progress-button
                            >
                            <iv-progress-button
                                buttonType="button"
                                class="citizen-summary__action-button btn-with-icon"
                                color="accent"
                                width="full"
                                [callback]="citizenNoResponse"
                            >${callAgain}${Translations.administration.citizenQuickView
                                .doesNotAnswer}
                            </iv-progress-button
                            >
                            <iv-progress-button
                                buttonType="button"
                                class="citizen-summary__action-button btn-with-icon"
                                color="accent"
                                width="full"
                                [callback]="citizenIsBusy"
                            >${busy}${Translations.administration.citizenQuickView.occupied}
                            </iv-progress-button
                            >
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <ng-template #fetching>
                <div class="center-content alt-theme">
                    <mat-progress-spinner
                        color="accent"
                        mode="indeterminate"
                        [strokeWidth]="3"
                        [diameter]="60"
                    ></mat-progress-spinner>
                </div>
            </ng-template>
        </article>
    `,
    standalone: true,
    imports: [NgIf, UtilHiddenScrollComponent, MatMiniFabButton, RouterLink, CitizenSpecialinformationComponent, CitizenNotesFormComponent, CitizenNoteFormComponent, CitizenHistoryListComponent, NgSwitch, NgSwitchCase, ProgressButtonComponent, NgSwitchDefault, MatProgressSpinner, ChunkPipe, CustomerTypePipe]
})
export class CitizenSummaryComponent implements OnInit, OnDestroy {
    citizen: ICitizenBasicInfoViewmodel;
    citizenNo: string;
    callRecordId?: number;
    linkBack: 'calllist' | 'customersearch' = 'customersearch';
    fromCallList = false;
    citizenStatus = CitizenStatus;
    customerSubType = CustomerSubType;
    newNote = false;
    syncStatusMsg = Translations.administration.citizenQuickView.pendingChanges;
    isCustomerService = false;
    note: string | undefined;
    hasCallerProfile = false;

    private canAssignCallTime: boolean;
    private unsubscribe = new Subject<void>();

    constructor(
        private transferReasonDialogService: TransferReasonDialogService,
        private _userService: UserService,
        private _authService: AuthService,
        private _callListService: CallListService,
        private callsService: CallsService,
        private _citizenService: CitizenService,
        private _dialogService: DialogService,
        private _route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this._route.paramMap
            .pipe(
                map(params => {
                    this.citizenNo = params.get('id') ?? '';
                    this.callRecordId = +(params.get('callRecordId') ?? 0);
                    this.fromCallList = !!this.callRecordId;
                    this.linkBack = this.fromCallList ? 'calllist' : 'customersearch';
                    return this.citizenNo;
                }),
                switchMap(citizenNo => (citizenNo ? this._citizenService.getCitizen(citizenNo) : EMPTY)),
                takeUntil(this.unsubscribe)
            )
            .subscribe(citizen => {
                this.citizen = citizen;
                if (!this.callRecordId) {
                    this.callRecordId = this.citizen.callRecordId ? this.citizen.callRecordId : 0;
                    this.fromCallList = !!this.callRecordId;
                }

                //  check the citizen sync status and add show error on the title if we have one.
                if (!this.citizen.isSync && citizen.lastSyncResult) {
                    this.syncStatusMsg = this.citizen.lastSyncResult || '';
                }

                this.note = this.citizen.customerCardInformation || '';
            });

        this._userService.user$.pipe(takeUntil(this.unsubscribe)).subscribe(user => {
            if (user && user.roles) {
                this.canAssignCallTime = user.impersonatedCitizenQuickInfo
                    ? user.impersonatedCitizenQuickInfo.assignNewCallTimeAllowed
                    : false;

                // check if the user have Customer Service role;
                const userRoles = user.roles as IUserRoles[];
                this.isCustomerService = userRoles.includes(ROLES.CustomerService);
                this.hasCallerProfile = user.hasCallerProfile ?? false;
            }
        });
    }

    @HostListener('document:keydown.shift.control.k', ['$event'])
    goToDetails(event: Event): void {
        this.router.navigate([
            '',
            {
                outlets: {
                    dialog: ['citizen', this.citizenNo]
                }
            }
        ]);
        if (event) {
            event.preventDefault();
        }
    }

    @HostListener('document:keydown.shift.control.h', ['$event'])
    goToDetailsHistory(event: Event, showNew = false): void {
        const queryParams: any = {
            citizenDetails: 'notes'
        };

        if (showNew) {
            queryParams.notes = 'new';
        }

        this.router.navigate(
            [
                '',
                {
                    outlets: {
                        dialog: ['citizen', this.citizenNo]
                    }
                }
            ],
            {
                queryParams
            }
        );
        if (event) {
            event.preventDefault();
        }
    }

    expandNewNote(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        this.newNote = !this.newNote;
    }

    onNewNote() {
        this.newNote = false;

        this._citizenService
            .getCitizen(this.citizenNo)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(citizen => (this.citizen.historyItems = citizen.historyItems));
    }

    citizenTransferCall = (): Promise<boolean> => {
        return firstValueFrom(this.transferReasonDialogService
            .open({
                customerNo: this.citizenNo,
                customerSubType: this.citizen.customerType,
                callRecordId: this.citizen.callRecordId || 0,
                noOrderDate: new Date(),
                dialogTitle: Translations.administration.citizenQuickView.transferDialog.chooseReasonForTransfer
            })
            .pipe(
                tap({
                    next: res => {
                        if (res) {
                            if (res.action !== 0) {
                                this._dialogService.showSnackMessage({
                                    message: Translations.replaceTokens(
                                        Translations.administration.citizenQuickView.transferDialog.messageOk,
                                        this.citizen.name
                                    )
                                });
                                this._navigateToCalllist();
                            } else {
                                this._dialogService.showSnackMessage({
                                    message:
                                    Translations.administration.citizenQuickView.transferDialog.messageAlreadyDone
                                });
                            }
                        }
                    },
                    error: () =>
                        this._dialogService.showSnackMessage({
                            message: Translations.replaceTokens(
                                Translations.administration.citizenQuickView.citizenActionError,
                                this.citizen.name
                            )
                        })
                }),
                map(res => res.success),
                takeUntil(this.unsubscribe)
            ))
    };

    citizenTransferDetails = (): Promise<boolean> => {
        const data: TransferDetails = {
            citizenName: this.citizen.name || '',
            transfer: this.citizen
        };

        return firstValueFrom(this._dialogService
            .openDialogWithComponent(TransferDetailsDialogComponent, {data})
            .afterClosed()
            .pipe(
                map(res => {
                    if (res) {
                        this.citizen.transferedCustomerId = 0;
                    }
                    return true;
                })
            ))
    };

    citizenNewCallTime = (): Promise<boolean> => {
        const dialogRef = this._dialogService.showOptions({
            data: {
                title: Translations.administration.citizenQuickView.newCallTimePrompt.title,
                placeholder: Translations.administration.citizenQuickView.newCallTimePrompt.placeholder,
                options: [
                    {value: 1, label: Translations.administration.citizenQuickView.newCallTimePrompt.options.start},
                    {value: 2, label: Translations.administration.citizenQuickView.newCallTimePrompt.options.mid},
                    {value: 3, label: Translations.administration.citizenQuickView.newCallTimePrompt.options.end}
                ],
                actionText: Translations.administration.citizenQuickView.newCallTimePrompt.actionText
            }
        });

        return firstValueFrom(dialogRef
            .afterClosed()
            .pipe(
                switchMap(selectedOption =>
                    selectedOption !== undefined
                        ? this._callListService.scheduleNewCallTime(this.citizenNo, selectedOption)
                        : EMPTY
                ),
                tap({
                    next: () =>
                        this._dialogService.showSnackMessage({
                            message: Translations.replaceTokens(
                                Translations.administration.citizenQuickView.newCallTimePrompt.messageOk,
                                this.citizen.name
                            )
                        }),
                    error: () =>
                        this._dialogService.showSnackMessage({
                            message: Translations.replaceTokens(
                                Translations.administration.citizenQuickView.citizenActionError,
                                this.citizen.name
                            )
                        })
                }),
                takeUntil(this.unsubscribe)
            ));
    };

    citizenNoResponse = (): Promise<boolean> => {
        if (!this.callRecordId) {
            return Promise.resolve(false);
        }

        return firstValueFrom(
            of(this.canAssignCallTime)
                .pipe(
                    switchMap(canAssignCallTime =>
                        canAssignCallTime
                            ? this._noResponsePrompt().pipe(
                                switchMap(selectedOption =>
                                    typeof selectedOption === 'number'
                                        ? this._callListService.citizenNoAnswer(this.callRecordId!, selectedOption)
                                        : EMPTY
                                )
                            )
                            : this._dialogService
                                .confirm(Translations.administration.citizenQuickView.noResponsePrompt.confirmDirectMove)
                                .afterClosed()
                                .pipe(
                                    switchMap((res: boolean) =>
                                        res ? this._callListService.citizenNoAnswer(this.callRecordId!) : EMPTY
                                    )
                                )
                    ),
                    tap({
                        next: result => {
                            if (result) {
                                this._dialogService.showSnackMessage({
                                    message: Translations.replaceTokens(
                                        Translations.administration.citizenQuickView.noResponsePrompt.messageOk,
                                        this.citizen.name
                                    )
                                });
                                this._authService.getCurrentUser(this.callRecordId);
                                this._navigateToCalllist();
                            }
                        },
                        error: () =>
                            this._dialogService.showSnackMessage({
                                message: Translations.replaceTokens(
                                    Translations.administration.citizenQuickView.citizenActionError,
                                    this.citizen.name
                                )
                            })
                    }),
                    takeUntil(this.unsubscribe)
                ))
    };

    citizenIsBusy = (): Promise<boolean> => {
        if (!this.callRecordId) {
            return Promise.resolve(true);
        }

        return firstValueFrom(
            of(this.canAssignCallTime)
                .pipe(
                    switchMap(canAssignCallTime =>
                        canAssignCallTime
                            ? this._noResponsePrompt().pipe(
                                switchMap(selectedOption =>
                                    typeof selectedOption === 'number'
                                        ? this._callListService.citizenBusy(this.callRecordId!, selectedOption)
                                        : EMPTY
                                )
                            )
                            : this._dialogService
                                .confirm(Translations.administration.citizenQuickView.noResponsePrompt.confirmDirectMove)
                                .afterClosed()
                                .pipe(
                                    switchMap((res: boolean) =>
                                        res ? this._callListService.citizenBusy(this.callRecordId!) : EMPTY
                                    )
                                )
                    ),
                    tap({
                        next: result => {
                            if (result) {
                                this._dialogService.showSnackMessage({
                                    message: Translations.replaceTokens(
                                        Translations.administration.citizenQuickView.noResponsePrompt.messageOk,
                                        this.citizen.name
                                    )
                                });
                                this._authService.getCurrentUser(this.callRecordId);
                                this._navigateToCalllist();
                            }
                        },
                        error: () =>
                            this._dialogService.showSnackMessage({
                                message: Translations.replaceTokens(
                                    Translations.administration.citizenQuickView.citizenActionError,
                                    this.citizen.name
                                )
                            })
                    }),
                    takeUntil(this.unsubscribe)
                ))
    };

    makeCall(citizenPhoneNumber: string, customerNumber: string, isBlocked: boolean): void {
        if (isBlocked) {
            const dialogSettings: IDialogContentSettings = {
                data: {
                    header: Translations.administration.callList.citizenBlocked.header,
                    content: Translations.administration.callList.citizenBlocked.text,
                    buttons: {
                        button1: {
                            color: 'primary',
                            confirm: true,
                            text: Translations.global.btnAcknowledge
                        }
                    }
                }
            };

            this._dialogService
                .openDialog(dialogSettings)
                .afterClosed()
                .pipe(
                    tap(() => this.showPreCallMessage()),
                    switchMap(() => this.callsService.callCitizen(customerNumber, citizenPhoneNumber)),
                    takeUntil(this.unsubscribe)
                )
                .subscribe({
                    next: () => this.showPostCallMessage(),
                    error: (err: IntervareHttpErrorResponse) => this._dialogService.showValidationResult(err.validationErrors)
                });
        } else {
            this.showPreCallMessage();
            this.callsService
                .callCitizen(customerNumber, citizenPhoneNumber)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe({
                    next: () => this.showPostCallMessage(),
                    error: (err: GenesysConversationErrorDetails) =>
                        this._dialogService.showValidationResult([err?.message ?? ''])
                });
        }
    }

    formatTransferReason(transferReason: CitizenTransferReason): string {
        return Translations.global.transferReasons[CitizenTransferReason[transferReason]];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _noResponsePrompt(): Observable<any> {
        return this._dialogService
            .showOptions({
                data: {
                    title: Translations.administration.citizenQuickView.noResponsePrompt.title,
                    placeholder: Translations.administration.citizenQuickView.noResponsePrompt.placeholder,
                    options: [
                        {value: 0, label: Translations.administration.citizenQuickView.noResponsePrompt.options.mid},
                        {value: 1, label: Translations.administration.citizenQuickView.noResponsePrompt.options.end},
                        {
                            value: 2,
                            label: Translations.administration.citizenQuickView.noResponsePrompt.options.startNext
                        },
                        {
                            value: 3,
                            label: Translations.administration.citizenQuickView.noResponsePrompt.options.endNext
                        }
                    ],
                    actionText: Translations.administration.citizenQuickView.noResponsePrompt.actionText
                }
            })
            .afterClosed();
    }

    private _navigateToCalllist(): void {
        this.router.navigate(['', {outlets: {admin: [this.linkBack]}}]);
    }

    private showPreCallMessage(): void {
        if (!this.hasCallerProfile) {
            return;
        }

        this._dialogService.showSnackMessage({
            message: Translations.administration.citizenQuickView.makeCall.preMessage
        });
    }

    private showPostCallMessage(): void {
        if (!this.hasCallerProfile) {
            return;
        }

        this._dialogService.showSnackMessage({
            message: Translations.administration.citizenQuickView.makeCall.postMessage
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
