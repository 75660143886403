import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, Observable, Subject, switchMap } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { IDetailedMessageViewModel, ISkipTakeResultObjectOfDetailedMessageViewModel, IUpdateMessageViewModel, } from '../../api/services';
import { Translations } from '../../translations/translations';
import { MessageService } from '../services/message.service';
import { DialogService } from '../shared/dialog.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { RouterLink } from '@angular/router';
import { MatAnchor } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'iv-administration-messages',
    template: `
        <section class="administration-messages">
            <ng-container *ngIf="messages$ | async as messages; else fetching">
                <div class="administration-messages__item" *ngFor="let message of messages.results">
                    <div class="administration-messages__text" [innerHTML]="message.textMessage"></div>
                    <div class="administration-messages__info">
                        <dl>
                            <dt>${Translations.intervare.messageAdmin.isActive}</dt>
                            <dd>
                                <mat-checkbox [checked]="message.isActive"
                                              (change)="activateMessage(message)"></mat-checkbox>
                            </dd>
                            <ng-container *ngIf="message.startDate">
                                <dt>${Translations.intervare.messageAdmin.startDate}</dt>
                                <dd>{{ message.startDate | date }}</dd>
                            </ng-container>
                            <ng-container *ngIf="message.endDate">
                                <dt>${Translations.intervare.messageAdmin.endDate}</dt>
                                <dd>{{ message.endDate | date }}</dd>
                            </ng-container>
                        </dl>
                        <div class="administration-messages__actions">
                            <a class="administration-messages__btn" [routerLink]="['edit', message.messageId]"
                               mat-raised-button color="accent">${Translations.intervare.messageAdmin.goToEditBtn}</a>
                            <iv-progress-button class="administration-messages__btn" buttonType="button" color="warn"
                                                [callback]="deleteMessage.bind(this, message.messageId)" width="full">
                                ${Translations.intervare.messageAdmin.deleteBtn}
                            </iv-progress-button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #fetching>
                <div class="center-content alt-theme">
                    <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3"
                                          [diameter]="60"></mat-progress-spinner>
                </div>
            </ng-template>
        </section>
    `,
    standalone: true,
    imports: [NgIf, NgFor, MatCheckbox, MatAnchor, RouterLink, ProgressButtonComponent, MatProgressSpinner, AsyncPipe, DatePipe]
})
export class AdministrationMessagesComponent implements OnInit, OnDestroy {

    messages$: Observable<ISkipTakeResultObjectOfDetailedMessageViewModel>;

    private unsubscribe = new Subject<void>();

    constructor(
        private messageService: MessageService,
        private dialogService: DialogService
    ) {
    }

    ngOnInit() {
        this._getMessages();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    activateMessage(message: IDetailedMessageViewModel) {
        const updateMessage = message as IUpdateMessageViewModel;
        updateMessage.isActive = !updateMessage.isActive;

        this.messageService.updateMessage(updateMessage)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(result => this.dialogService.showSnackMessage({
                message: result.isActive ? Translations.intervare.messageAdmin.activateMsg : Translations.intervare.messageAdmin.deactivateMsg
            }));
    }

    deleteMessage(messageId: number): Promise<boolean> {
        return firstValueFrom(this.dialogService.confirm(Translations.intervare.messageAdmin.confirmDeleteMsg).afterClosed().pipe(
            filter((res: boolean) => res),
            switchMap(() => this.messageService.deleteMessage(messageId)),
            tap(() => {
                this._getMessages();
                this.dialogService.showSnackMessage({message: Translations.intervare.messageAdmin.deleteMsg});
            }),
            takeUntil(this.unsubscribe)
        ));
    }

    private _getMessages() {
        this.messages$ = this.messageService.getMessages();
    }

}
