import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { Observable, of } from 'rxjs';
import {
    ISkipTakeResultObjectOfUser,
    IUserUsernameHistory,
    SkipTakeResultObjectOfUser,
    UserUsernameHistory
} from '../../api/services';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class InternalUserService extends BaseService {
    constructor(private readonly http: HttpClient) {
        super();
    }

    getAllRoles(): Observable<string[]> {
        return this.http.get<string[]>(this.apiBaseUrl('/User/GetAllRoles'));
    }

    getInternalUsers(searchText: string, skip: number = 0, take: number = 0, includeInactive: boolean = true): Observable<ISkipTakeResultObjectOfUser> {
        const params: Record<string, string | boolean> = { isUserSearchRequest: false };
        if (searchText) {
            params.searchText = searchText;
        }

        if (skip !== 0) {
            params.skip = skip.toString();
        }

        if (take !== 0) {
            params.take = take.toString();
        }

        if (includeInactive) {
            params.includeInactive = includeInactive;
        }

        return this.http
            .get<ISkipTakeResultObjectOfUser>(this.apiBaseUrl('/User/GetInternalUsers'), {
                params: new HttpParams({
                    fromObject: params
                })
            })
            .pipe(map(SkipTakeResultObjectOfUser.fromJS));
    }

    getUsernameHistory(currentUsername: string): Observable<IUserUsernameHistory[]> {
        return this.http
            .get<IUserUsernameHistory[]>(this.apiBaseUrl('/User/GetUsernameHistory'), { params: { currentUsername } })
            .pipe(map(users => users.map(user => UserUsernameHistory.fromJS(user))));
    }

    updateUsername(currentUsername: string, NewUsername: string): Observable<boolean> {
        return this.http
            .post(this.apiBaseUrl('/User/UpdateUserUsername'), null, {
                params: new HttpParams({
                    fromObject: { currentUsername, NewUsername }
                })
            })
            .pipe(
                map(response => {
                    return response as boolean;
                })
            );
    }

    checkUsernameExists(username: string): Observable<boolean> {
        return this.http.get<boolean>(this.apiBaseUrl('/User/CheckUsernameExists'), { params: { username } });
    }

    inactivateUser(username: string): Observable<boolean> {
        return this.http.post(this.apiBaseUrl('/User/InactivateUser'), null, { params: { username } }).pipe(
            map(response => {
                return response as boolean;
            })
        );
    }
}
