import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    finalize,
    map,
    share,
    startWith,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';

import {
    CitizenAddress,
    CitizenRelationId,
    CitizenRelative,
    CreateTemporaryCitizenViewModel,
    CustomerSubType,
    DayAndTime,
    DayAndTimeWithInterval,
    DeliveryFrequency,
    Gender,
    ICitizen,
    PrimaryUser,
    TimeOfDay,
    UpdateTemporaryCitizenViewModel,
    Visitation
} from '../../api/services';
import { check, pen } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import {
    FormBuilderCheckbox,
    FormBuilderChipList,
    FormBuilderCity,
    FormBuilderDatepicker,
    FormBuilderMunicipalityPicker,
    FormBuilderOption,
    FormBuilderSelect,
    FormBuilderTextarea,
    FormBuilderTextInput,
    FormBuilderTypes,
    FormElementState
} from '../form-builder/form-builder-element.model';
import { toFormOptions } from '../form-builder/form-builder-mappings';
import { FormBuilderService, IFormValues } from '../form-builder/form-builder.service';
import { FormBuilderValidators } from '../form-builder/validators/form-builder-validators';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { DriverService } from '../services/driver.service';
import { HandicapService } from '../services/handicap.service';
import { DialogService } from '../shared/dialog.service';
import { UserService } from '../user/user.service';
import { flatten } from '../util/helpers';
import { UtilService } from '../util/util.service';
import { ICitizenTableRow, MunicipalityCitizenTableComponent } from './municipality-citizen-table.component';
import { MunicipalityDeliveryHelpers } from './municipality-delivery-helpers';
import { MunicipalityService } from './municipality.service';
import {
    MatStep,
    MatStepLabel,
    MatStepper,
    MatStepperIcon,
    MatStepperNext,
    MatStepperPrevious
} from '@angular/material/stepper';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

interface ISteps<T> {
    info: T;
    relatives: T;
}

interface IFormCity {
    city: string;
    zipCode: string;
}

const steps = {
    0: 'info',
    1: 'relatives',
    2: 'activate',
    3: 'result'
};

@Component({
    selector: 'iv-municipality-subscribe-citizen',
    template: `
        <div class="municipality-subscribe-citizen">

            <mat-horizontal-stepper #stepper="matHorizontalStepper" linear (selectionChange)="selectionChange($event)"
                                    *ngIf="!fetching && (!editMode || editMode && impersonated); else unavailable">
                <ng-template matStepperIcon="edit">
                    ${pen}
                </ng-template>

                <ng-template matStepperIcon="done">
                    ${check}
                </ng-template>

                <mat-step [stepControl]="form.info" *ngIf="form.info" [editable]="editable">
                    <ng-template matStepLabel>${Translations.municipality.subscribeCitizen.steps.info}</ng-template>

                    <div class="municipality-subscribe-citizen__form-step">
                        <form class="municipality-subscribe-citizen__form alt-theme form" *ngIf="form.info"
                              [formGroup]="form.info">
                            <iv-form-builder-element [form]="form.info" [input]="input"
                                                     [state]="formElementState$ | async"
                                                     *ngFor="let input of inputs.info"></iv-form-builder-element>
                        </form>

                        <div class="municipality-subscribe-citizen__controls">
                            <button class="municipality-subscribe-citizen__next" matStepperNext mat-raised-button
                                    color="primary">${Translations.municipality.subscribeCitizen.next}
                            </button>
                        </div>
                    </div>
                </mat-step>

                <mat-step [stepControl]="form.relatives" *ngIf="form.relatives" [editable]="editable">
                    <ng-template matStepLabel>${Translations.municipality.subscribeCitizen.steps.relatives}
                    </ng-template>

                    <div class="municipality-subscribe-citizen__form-step">
                        <form class="municipality-subscribe-citizen__form alt-theme form" *ngIf="form.relatives"
                              [formGroup]="form.relatives">
                            <iv-form-builder-element [form]="form.relatives" [input]="input"
                                                     [state]="formElementState$ | async"
                                                     *ngFor="let input of inputs.relatives"></iv-form-builder-element>
                        </form>

                        <div class="municipality-subscribe-citizen__controls">
                            <button class="municipality-subscribe-citizen__previous" matStepperPrevious mat-button
                                    color="primary">${Translations.municipality.subscribeCitizen.previous}
                            </button>
                            <button class="municipality-subscribe-citizen__next" matStepperNext mat-raised-button
                                    color="primary">${Translations.municipality.subscribeCitizen.next}
                            </button>
                        </div>
                    </div>
                </mat-step>

                <mat-step [editable]="editable">
                    <ng-template matStepLabel>${Translations.municipality.subscribeCitizen.steps.activate}</ng-template>

                    <div class="municipality-subscribe-citizen__controls">
                        <button class="municipality-subscribe-citizen__previous" matStepperPrevious mat-button
                                color="primary">${Translations.municipality.subscribeCitizen.previous}
                        </button>
                        <button class="municipality-subscribe-citizen__next" (click)="submit(stepper)" mat-raised-button
                                color="primary">${Translations.municipality.subscribeCitizen.approve}
                        </button>
                    </div>

                    <iv-municipality-citizen-table [dataSource]="tableData$ | async"
                                                   [editMode]="editMode"></iv-municipality-citizen-table>

                    <div class="municipality-subscribe-citizen__controls">
                        <button class="municipality-subscribe-citizen__previous" matStepperPrevious mat-button
                                color="primary">${Translations.municipality.subscribeCitizen.previous}
                        </button>
                        <button class="municipality-subscribe-citizen__next" (click)="submit(stepper)" mat-raised-button
                                color="primary">${Translations.municipality.subscribeCitizen.approve}
                        </button>
                    </div>
                </mat-step>

                <mat-step>
                    <ng-template matStepLabel>${Translations.municipality.subscribeCitizen.steps.result}</ng-template>

                    <div class="municipality-subscribe-citizen__alert alert alert-success" role="status">
                        <ng-container *ngIf="editMode; else subscribeCitizen">
                            ${Translations.municipality.subscribeCitizen.result.edited}
                        </ng-container>
                        <ng-template #subscribeCitizen>
                            ${Translations.municipality.subscribeCitizen.result.activated}
                        </ng-template>

                        ${check}
                    </div>

                    <button type="button" mat-raised-button color="primary" (click)="reset()" *ngIf="!editMode">
                        ${Translations.municipality.subscribeCitizen.result.createAnother}
                    </button>

                    <iv-municipality-citizen-table [dataSource]="tableData$ | async"
                                                   [editMode]="editMode"></iv-municipality-citizen-table>
                </mat-step>
            </mat-horizontal-stepper>

            <ng-template #unavailable>
                <div class="municipality-subscribe-citizen__status alt-theme" *ngIf="impersonated">
                    <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3"
                                          [diameter]="60"></mat-progress-spinner>
                </div>

                <div class="municipality-subscribe-citizen__status" *ngIf="!impersonated">
                    ${Translations.commerce.basket.impersonate}
                </div>
            </ng-template>
        </div>
    `,
    standalone: true,
    imports: [NgIf, MatStepper, MatStepperIcon, MatStep, MatStepLabel, FormsModule, ReactiveFormsModule, NgFor, FormBuilderElementComponent, MatStepperNext, MatButton, MatStepperPrevious, MunicipalityCitizenTableComponent, MatProgressSpinner, AsyncPipe]
})
export class MunicipalitySubscribeCitizenComponent implements OnInit, OnDestroy {
    editable = true;
    editMode = false;
    fetching = true;
    impersonated = false;

    shouldSetPassword = false;
    oldCitizenType?: CustomerSubType;

    inputs: ISteps<FormBuilderTypes[]> = {
        info: [],
        relatives: []
    };

    form: ISteps<UntypedFormGroup | undefined> = {
        info: undefined,
        relatives: undefined
    };

    citizenTable: ISteps<ICitizenTableRow[]> = {
        info: [],
        relatives: []
    };

    oldValues: ISteps<IFormValues | undefined> = {
        info: undefined,
        relatives: undefined
    };

    municipality$ = new Subject<number | undefined>();

    specialInformationIds$ = new BehaviorSubject<FormBuilderOption[]>([]);
    drivers$ = new BehaviorSubject<FormBuilderOption[]>([]);
    driverInformationIds$ = new BehaviorSubject<FormBuilderOption[]>([]);

    availableDeliveryDays$ = new BehaviorSubject<number[]>([]);

    deliveryDays$ = new BehaviorSubject<FormBuilderOption[]>([]);
    orderDays$ = new BehaviorSubject<FormBuilderOption[]>([]);
    timeslots$ = new BehaviorSubject<FormBuilderOption[]>([]);

    biWeeklyDeliveryDays$ = new BehaviorSubject<FormBuilderOption[]>([]);
    biWeeklyOrderDays$ = new BehaviorSubject<FormBuilderOption[]>([]);
    biWeeklyTimeslots$ = new BehaviorSubject<FormBuilderOption[]>([]);

    usernameCondition$ = new BehaviorSubject<boolean>(false);
    passwordCondition$ = new BehaviorSubject<boolean>(false);

    @ViewChild('stepper') stepper: MatStepper;

    private formElementStateBS$ = new BehaviorSubject<FormElementState>('default');
    public formElementState$ = this.formElementStateBS$.asObservable();

    get customerSubtypeControl() {
        return this.form.info?.get(Translations.municipality.subscribeCitizen.info.customerSubType.name);
    }

    private _tableData = new Subject<ISteps<ICitizenTableRow[]>>();
    tableData$ = this._tableData.asObservable().pipe(map(x => new MatTableDataSource<ICitizenTableRow>(flatten(Object.values(x)))));

    private _scrollContainer = document.querySelector('#content > .util-hidden-scroll') as HTMLElement;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private utilService: UtilService,
        private formBuilder: FormBuilderService,
        private handicapService: HandicapService,
        private driverService: DriverService,
        private municipalityService: MunicipalityService,
        private citizenService: CitizenService,
        private route: ActivatedRoute,
        private userService: UserService,
        private dialogService: DialogService
    ) {
    }

    ngOnInit() {
        this.route.data.pipe(
            tap(data => {
                this.editMode = data.pageData.edit;
                this.fetching = true;
            }),
            filter(() => {
                if (!this.editMode) {
                    this._buildForms();
                }
                return this.editMode;
            }),
            switchMap(() => this.userService.isImpersonating),
            map(quickInfo => quickInfo ? quickInfo.customerNumber! : ''),
            tap(customerNumber => this.impersonated = !!customerNumber),
            filter(customerNumber => !!customerNumber),
            switchMap(customerNumber => this.citizenService.getCitizenByCustomerNo(customerNumber)),
            takeUntil(this.unsubscribe)
        ).subscribe(citizen => this._buildForms(citizen));

        this.municipality$.pipe(
            distinctUntilChanged(),
            takeUntil(this.unsubscribe)
        ).subscribe(municipalityId => this._getMunicipalityData(municipalityId));

        this.availableDeliveryDays$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => MunicipalityDeliveryHelpers.updateDeliveryDays(this.availableDeliveryDays$.getValue(), undefined, this.deliveryDays$, this.biWeeklyDeliveryDays$));

        this.handicapService.getHandicaps().pipe(
            map(x => x.map(y => toFormOptions(y.code, y.description))),
            takeUntil(this.unsubscribe)
        ).subscribe(options => this.specialInformationIds$.next(options));

        this.driverService.getDriverAdditionalInformation().pipe(
            map(x => x.map(y => toFormOptions(y.informationCode, y.informationDescription))),
            takeUntil(this.unsubscribe)
        ).subscribe(options => this.driverInformationIds$.next(options));

        this.formElementState$.pipe(
            filter((val) => val === 'reset'),
            map(() => this.stepper.reset()),
            map(() => this._reInitForms()),
            finalize(() => this.formElementStateBS$.next('default')),
            takeUntil(this.unsubscribe)
        ).subscribe();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectionChange(event: StepperSelectionEvent) {
        this.utilService.scrollElementTo(this._scrollContainer, 0);

        if (event.selectedIndex === 3) {
            this.editable = false;
        } else if (event.previouslySelectedStep.stepControl && event.previouslySelectedIndex < event.selectedIndex) {

            const step = steps[event.previouslySelectedIndex];
            const formValues = event.previouslySelectedStep.stepControl;

            this.citizenTable[step] = this.formBuilder.formToTable(this.inputs[step], formValues.value, this.oldValues[step]);

            if (step === 'info') {
                if (this.citizenTable && this.citizenTable['info']) {
                    const user = this.citizenTable['info'];
                    const username = user.find(property => property.field === Translations.municipality.subscribeCitizen.info.webUsername.name);
                    if (username && username.old) {
                        username.new = username.old;
                    }
                }
            }

            this._tableData.next(this.citizenTable);
        }
    }

    submit(stepper: CdkStepper): void {

        if (this.form.info && this.form.relatives) {
            if (this.oldValues && this.oldValues.info && this.oldValues.info.WebUsername) {
                this.form.info.value.WebUsername = this.oldValues.info.WebUsername;
            }

            if (!this._validateInfoForm()) {
                return;
            }

            const citizen = this.editMode ? UpdateTemporaryCitizenViewModel.fromJS(this.form.info.value) : CreateTemporaryCitizenViewModel.fromJS(this.form.info.value);

            citizen.citizenAddress = CitizenAddress.fromJS(this.form.info.value);
            citizen.citizenAddress.zipCode = this.form.info.controls[Translations.municipality.subscribeCitizen.info.city.name].value['zipCode'];
            citizen.citizenAddress.city = this.form.info.controls[Translations.municipality.subscribeCitizen.info.city.name].value['city'];

            citizen.invoiceEmailList = Array.isArray(this.form.info.value.InvoiceEmailList) ? this.form.info.value.InvoiceEmailList.join(',') : this.form.info.value.InvoiceEmailList;
            citizen.orderConfirmationEmailList = Array.isArray(this.form.info.value.OrderConfirmationEmailList) ? this.form.info.value.OrderConfirmationEmailList.join(',') : this.form.info.value.OrderConfirmationEmailList;

            citizen.citizenRelative = CitizenRelative.fromJS(this.form.relatives.value);
            citizen.citizenRelative.zipCode = this.form.relatives.controls[Translations.municipality.subscribeCitizen.relatives.city.name].value['zipCode'];
            citizen.citizenRelative.city = this.form.relatives.controls[Translations.municipality.subscribeCitizen.relatives.city.name].value['city'];

            citizen.subDistrictId = this.form.info.controls[Translations.municipality.subscribeCitizen.info.districts.name].value['subdistricts'];

            citizen.deliveryDayIds = [new DayAndTime({
                dayOfWeek: this.form.info.controls[Translations.municipality.subscribeCitizen.info.deliveryDay.name].value,
                timeOfDay: TimeOfDay.TwelvePm
            })];
            if (this.form.info.controls[Translations.municipality.subscribeCitizen.info.deliveryFrequencyId.name].value === DeliveryFrequency.BiWeekly) {
                citizen.deliveryDayIds.push(new DayAndTime({
                    dayOfWeek: this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyDeliveryDay.name].value,
                    timeOfDay: TimeOfDay.TwelvePm
                }));
            }

            citizen.orderDayIds = [new DayAndTimeWithInterval({
                dayOfWeek: this.form.info.controls[Translations.municipality.subscribeCitizen.info.orderDay.name].value,
                timeOfDay: this.form.info.controls[Translations.municipality.subscribeCitizen.info.timeslot.name].value ? this.form.info.controls[Translations.municipality.subscribeCitizen.info.timeslot.name].value : TimeOfDay.TwelvePm,
                intervalPlacement: 0
            })];
            if (this.form.info.controls[Translations.municipality.subscribeCitizen.info.deliveryFrequencyId.name].value === DeliveryFrequency.BiWeekly) {
                citizen.orderDayIds.push(new DayAndTimeWithInterval({
                    dayOfWeek: this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyOrderDay.name].value,
                    timeOfDay: this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyTimeslot.name].value ? this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyTimeslot.name].value : TimeOfDay.TwelvePm,
                    intervalPlacement: 0
                }));
            }

            citizen.citizenRelative!.relationID = citizen.citizenRelative!.relationID ? citizen.citizenRelative!.relationID : 0; // Handle optional enums as value on relationID

            if (citizen instanceof UpdateTemporaryCitizenViewModel) {
                citizen.customerNo = this.userService.user$.getValue()!.impersonatedCitizenQuickInfo!.customerNumber!;
            }

            if (this.editMode && citizen.customerSubType === CustomerSubType.TastSelvBorger) {
                // should get the user name because the form don't bring the field because is not visible or is disabled;
                citizen.webUsername = this.form.info.controls[Translations.municipality.subscribeCitizen.info.webUsername.name].value;

                if (!this.form.info.controls[Translations.municipality.subscribeCitizen.info.password.name].value && !this.form.info.controls[Translations.municipality.subscribeCitizen.info.confirmPassword.name].value) {
                    delete citizen.password;
                    delete citizen.confirmPassword;
                }
            }

            if (citizen.customerSubType !== CustomerSubType.TastSelvBorger) {
                // should delete this fields before submit..
                delete citizen.webUsername;
                delete citizen.password;
                delete citizen.confirmPassword;
            }

            this.citizenService.submitCitizen(citizen).pipe(
                takeUntil(this.unsubscribe)
            ).subscribe({
                next: () => stepper.next(),
                error: (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            });
        }
    }

    reset() {
        this.editable = true;
        this.citizenTable = {
            info: [],
            relatives: []
        };
        this._tableData.next(this.citizenTable);
        this.formElementStateBS$.next('reset');
    }

    private _buildForms(citizen?: ICitizen) {
        this.utilService.scrollElementTo(this._scrollContainer, 0);
        this.oldCitizenType = citizen ? citizen.customerSubType : undefined;
        if (!this._validateInfoForm()) {
            this._buildInfoForm(citizen);
        }
        this._buildRelativesForm(citizen);

        this.fetching = false;
    }

    private _reInitForms() {
        const defaultInfoValues = {
            [Translations.municipality.subscribeCitizen.info.districts.name]: {
                municipality: undefined,
                districts: undefined,
                subdistricts: undefined
            },
            [Translations.municipality.subscribeCitizen.info.customerSubType.name]: '',
            [Translations.municipality.subscribeCitizen.info.firstName.name]: '',
            [Translations.municipality.subscribeCitizen.info.middleName.name]: '',
            [Translations.municipality.subscribeCitizen.info.lastName.name]: '',
            [Translations.municipality.subscribeCitizen.info.streetName.name]: '',
            [Translations.municipality.subscribeCitizen.info.streetNumber.name]: '',
            [Translations.municipality.subscribeCitizen.info.addressFloor.name]: '',
            [Translations.municipality.subscribeCitizen.info.addressSide.name]: '',
            [Translations.municipality.subscribeCitizen.info.city.name]: '',
            [Translations.municipality.subscribeCitizen.info.cpr.name]: '',
            [Translations.municipality.subscribeCitizen.info.gender.name]: '',
            [Translations.municipality.subscribeCitizen.info.phoneNumber.name]: '',
            [Translations.municipality.subscribeCitizen.info.mobileNumber.name]: '',
            [Translations.municipality.subscribeCitizen.info.email.name]: '',
            [Translations.municipality.subscribeCitizen.info.invoiceEmailList.name]: '',
            [Translations.municipality.subscribeCitizen.info.orderConfirmationEmailList.name]: '',
            [Translations.municipality.subscribeCitizen.info.replacementProduct.name]: true,
            [Translations.municipality.subscribeCitizen.info.specialInformationIds.name]: [],
            [Translations.municipality.subscribeCitizen.info.driverId.name]: '',
            [Translations.municipality.subscribeCitizen.info.driverInformationIds.name]: [],
            [Translations.municipality.subscribeCitizen.info.keyForCustomer.name]: '',
            [Translations.municipality.subscribeCitizen.info.signUpDate.name]: '',
            [Translations.municipality.subscribeCitizen.info.dateForReceivingSignUp.name]: undefined,
            [Translations.municipality.subscribeCitizen.info.deliveryStart.name]: undefined,
            [Translations.municipality.subscribeCitizen.info.visitationId.name]: undefined,
            [Translations.municipality.subscribeCitizen.info.deliveryFrequencyId.name]: undefined,
            [Translations.municipality.subscribeCitizen.info.deliveryEnd.name]: undefined,
            [Translations.municipality.subscribeCitizen.info.orderDay.name]: undefined,
            [Translations.municipality.subscribeCitizen.info.biWeeklyDeliveryDay.name]: undefined,
            [Translations.municipality.subscribeCitizen.info.biWeeklyOrderDay.name]: undefined,
            [Translations.municipality.subscribeCitizen.info.webUsername.name]: '',
            [Translations.municipality.subscribeCitizen.info.password.name]: undefined,
            [Translations.municipality.subscribeCitizen.info.confirmPassword.name]: undefined
        };

        const defaultRelativeValues = {
            [Translations.municipality.subscribeCitizen.relatives.primaryUser.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.relationID.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.firstName.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.middleName.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.lastName.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.name2.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.streetName.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.streetNumber.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.addressFloor.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.addressSide.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.city.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.phoneNumber.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.mobileNumber.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.faxNumber.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.email.name]: '',
            [Translations.municipality.subscribeCitizen.relatives.comment.name]: ''
        };

        this.resetForm(defaultRelativeValues, this.form.relatives);
        this.resetForm(defaultInfoValues, this.form.info);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private resetForm(value: { [key: string]: any }, formGroup?: UntypedFormGroup) {
        if (!formGroup) {
            return;
        }

        formGroup.reset(value, { emitEvent: false });
        formGroup.markAsPristine();
        formGroup.markAsUntouched();
    }

    //#region Info

    private _buildInfoForm(citizen?: ICitizen) {
        const fullNameLabel = `${Translations.municipality.subscribeCitizen.info.firstName.label} + ${Translations.municipality.subscribeCitizen.info.middleName.label} + ${Translations.municipality.subscribeCitizen.info.lastName.label}`;

        this.inputs.info = [
            new FormBuilderMunicipalityPicker({
                name: Translations.municipality.subscribeCitizen.info.districts.name,
                valueChanged: (value) => {
                    this.municipality$.next(value?.municipality);

                    if (!citizen || !this.form.info) {
                        return;
                    }

                    if (citizen.municipalityId === value?.municipality &&
                        citizen.districtId === value?.districts &&
                        citizen.subDistrictId === value?.subdistricts) {
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.driverId.name].setValue(citizen.driverId);
                    } else {
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.driverId.name].setValue('');
                    }

                },
                required: true,
                value: citizen ? {
                    municipality: citizen.municipalityId,
                    districts: citizen.districtId,
                    subdistricts: citizen.subDistrictId,
                    selectedMunicipality: citizen.municipalityName,
                    selectedDistricts: citizen.districtName,
                    selectedSubdistricts: citizen.subDistrictName
                } : undefined,
                filterDontUse: !this.editMode
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.customerSubType.name,
                label: (citizen && citizen.isTemporaryHibernation) ? Translations.municipality.subscribeCitizen.info.customerSubType.label + ' (aktiver først kunden, før kundetypen kan ændres)' : Translations.municipality.subscribeCitizen.info.customerSubType.label,
                options: Object.values(CustomerSubType).filter(x => typeof x === 'string').map(x => toFormOptions(CustomerSubType[x], Translations.municipality.subscribeCitizen.customerSubTypeTranslations[x])),
                valueChanged: (value: CustomerSubType) => {

                    this.shouldSetPassword = this.oldCitizenType !== CustomerSubType.TastSelvBorger && value === CustomerSubType.TastSelvBorger;

                    // conditional to check if the customer type is "TastServBorger" but the citizen could not be already the "TastServBorger"
                    this.usernameCondition$.next(value === CustomerSubType.TastSelvBorger);

                    //INFO: INT-2108: enable password field if the customer type is "TastSelvBorger" in edit mode
                    this.passwordCondition$.next(value === CustomerSubType.TastSelvBorger && !this.editMode || value === CustomerSubType.TastSelvBorger);

                    if (this.form.info) {
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.deliveryEnd.name].updateValueAndValidity({ onlySelf: true });
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.timeslot.name].updateValueAndValidity({ onlySelf: true });

                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyDeliveryDay.name].updateValueAndValidity({ onlySelf: true });
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyOrderDay.name].updateValueAndValidity({ onlySelf: true });
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyTimeslot.name].updateValueAndValidity({ onlySelf: true });
                    }
                },
                validation: [Validators.required],
                required: true,
                value: citizen ? citizen.customerSubType : undefined,
                disabled: !!(citizen && citizen.isTemporaryHibernation)
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.firstName.name,
                label: Translations.municipality.subscribeCitizen.info.firstName.label,
                validation: [Validators.required, Validators.maxLength(30), FormBuilderValidators.valueLength(50, fullNameLabel, Translations.municipality.subscribeCitizen.info.middleName.name, Translations.municipality.subscribeCitizen.info.lastName.name)],
                required: true,
                value: citizen ? citizen.citizenAddress.firstName : '',
                updateOn: 'change'
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.middleName.name,
                label: Translations.municipality.subscribeCitizen.info.middleName.label,
                validation: [Validators.maxLength(30), FormBuilderValidators.valueLength(50, fullNameLabel, Translations.municipality.subscribeCitizen.info.firstName.name, Translations.municipality.subscribeCitizen.info.lastName.name)],
                value: citizen ? citizen.citizenAddress.middleName : '',
                updateOn: 'change'
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.lastName.name,
                label: Translations.municipality.subscribeCitizen.info.lastName.label,
                validation: [Validators.required, Validators.maxLength(30), FormBuilderValidators.valueLength(50, fullNameLabel, Translations.municipality.subscribeCitizen.info.firstName.name, Translations.municipality.subscribeCitizen.info.middleName.name)],
                required: true,
                value: citizen ? citizen.citizenAddress.lastName : '',
                updateOn: 'change'
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.streetName.name,
                label: Translations.municipality.subscribeCitizen.info.streetName.label,
                validation: [Validators.required, Validators.maxLength(FormBuilderValidators.addressFieldsValidationLimits.streetName)],
                required: true,
                value: citizen ? citizen.citizenAddress.streetName : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.streetNumber.name,
                label: Translations.municipality.subscribeCitizen.info.streetNumber.label,
                validation: [Validators.required, Validators.maxLength(FormBuilderValidators.addressFieldsValidationLimits.streetNumber)],
                required: true,
                value: citizen ? citizen.citizenAddress.streetNumber : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.addressFloor.name,
                label: Translations.municipality.subscribeCitizen.info.addressFloor.label,
                validation: [Validators.maxLength(FormBuilderValidators.addressFieldsValidationLimits.addressFloor)],
                value: citizen ? citizen.citizenAddress.addressFloor : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.addressSide.name,
                label: Translations.municipality.subscribeCitizen.info.addressSide.label,
                validation: [Validators.maxLength(FormBuilderValidators.addressFieldsValidationLimits.addressSide)],
                value: citizen ? citizen.citizenAddress.addressSide : ''
            }),
            new FormBuilderCity({
                name: Translations.municipality.subscribeCitizen.info.city.name,
                required: true,
                value: citizen ? {
                    zipCode: citizen.citizenAddress.zipCode,
                    city: citizen.citizenAddress.city
                } : undefined,
                valueChanged: (value: IFormCity) => {
                    if (!citizen || !this.form.info) {
                        return;
                    }

                    if (citizen.citizenAddress.zipCode === value.zipCode) {
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.driverId.name].setValue(citizen.driverId);
                    } else {
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.driverId.name].setValue('');
                    }
                }
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.cpr.name,
                label: Translations.municipality.subscribeCitizen.info.cpr.label,
                type: 'tel',
                required: true,
                validation: [Validators.required, FormBuilderValidators.cpr()],
                updateOn: 'change',
                value: citizen ? citizen.cPR : '',
                valueChanged: (value: string) => {
                    if (this.form.info && this.form.info.controls[Translations.municipality.subscribeCitizen.info.cpr.name].valid && value !== '') {
                        const genderControl = this.form.info.controls[Translations.municipality.subscribeCitizen.info.gender.name];

                        // check the last char of the CPR, because if it was an '*' should do nothing related with cpr number;
                        const char = value.slice(-1);
                        if (char !== '*') {
                            // eslint-disable-next-line no-bitwise
                            genderControl.setValue(parseInt(char) % 2 ^ 1);
                        }

                        // checking if the inserted number is equal to '0101010000' because if it is the user does not know the CPR number of the citizen
                        // we will update the gender field without the value, and make the user selecting manually
                        if (value === '0101010000') {
                            genderControl.setValue(-1);
                        }

                        // Update the form and mark as touched
                        genderControl.updateValueAndValidity({ onlySelf: true });
                    }
                }
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.gender.name,
                label: Translations.municipality.subscribeCitizen.info.gender.label,
                options: Object.values(Gender).filter(x => typeof x === 'string').map(x => toFormOptions(Gender[x], Translations.municipality.subscribeCitizen.genderTranslations[x])),
                validation: [Validators.required, Validators.pattern('[0-1]')],
                required: true,
                value: citizen ? citizen.gender : undefined
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.phoneNumber.name,
                label: Translations.municipality.subscribeCitizen.info.phoneNumber.label,
                validation: [Validators.minLength(8), Validators.maxLength(8)],
                requiredAsync: () => {
                    return this.customerSubtypeControl!.valueChanges.pipe(
                        startWith(this.customerSubtypeControl!.value),
                        map(value => value === CustomerSubType.Ringeborger)
                    );
                },
                value: citizen ? citizen.citizenAddress.phoneNumber : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.mobileNumber.name,
                label: Translations.municipality.subscribeCitizen.info.mobileNumber.label,
                validation: [Validators.minLength(8), Validators.maxLength(8)],
                value: citizen ? citizen.citizenAddress.mobileNumber : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.email.name,
                label: Translations.municipality.subscribeCitizen.info.email.label,
                validation: [Validators.email, Validators.maxLength(80)],
                type: 'email',
                requiredAsync: () => {
                    return this.customerSubtypeControl!.valueChanges.pipe(
                        startWith(this.customerSubtypeControl!.value),
                        map(value => value === CustomerSubType.TastSelvBorger)
                    );
                },
                value: citizen ? citizen.email : undefined,
                updateOn: 'change'
            }),
            new FormBuilderChipList({
                name: Translations.municipality.subscribeCitizen.info.invoiceEmailList.name,
                label: Translations.municipality.subscribeCitizen.info.invoiceEmailList.label,
                value: citizen ? citizen.invoiceEmailList : undefined,
                updateOn: 'change'
            }),
            new FormBuilderChipList({
                name: Translations.municipality.subscribeCitizen.info.orderConfirmationEmailList.name,
                label: Translations.municipality.subscribeCitizen.info.orderConfirmationEmailList.label,
                value: citizen ? citizen.orderConfirmationEmailList : undefined,
                updateOn: 'change'
            }),
            new FormBuilderCheckbox({
                name: Translations.municipality.subscribeCitizen.info.replacementProduct.name,
                label: Translations.municipality.subscribeCitizen.info.replacementProduct.label,
                value: citizen ? citizen.replacementProduct : true
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.specialInformationIds.name,
                label: Translations.municipality.subscribeCitizen.info.specialInformationIds.label,
                options: this.specialInformationIds$,
                multiple: true,
                value: citizen ? citizen.specialInformationIds : []
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.driverId.name,
                label: Translations.municipality.subscribeCitizen.info.driverId.label,
                options: this.drivers$,
                required: true,
                validation: [Validators.required],
                value: citizen ? citizen.driverId : undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.driverInformationIds.name,
                label: Translations.municipality.subscribeCitizen.info.driverInformationIds.label,
                options: this.driverInformationIds$,
                multiple: true,
                value: citizen ? citizen.driverInformationIds : []
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.keyForCustomer.name,
                label: Translations.municipality.subscribeCitizen.info.keyForCustomer.label,
                validation: [FormBuilderValidators.valueLength(20, Translations.municipality.subscribeCitizen.info.keyForCustomer.label)],
                value: citizen ? citizen.keyForCustomer : ''
            }),
            new FormBuilderDatepicker({
                name: Translations.municipality.subscribeCitizen.info.signUpDate.name,
                label: Translations.municipality.subscribeCitizen.info.signUpDate.label,
                required: true,
                validation: [Validators.required],
                value: citizen ? citizen.signUpDate : undefined
            }),
            new FormBuilderDatepicker({
                name: Translations.municipality.subscribeCitizen.info.dateForReceivingSignUp.name,
                label: Translations.municipality.subscribeCitizen.info.dateForReceivingSignUp.label,
                required: true,
                validation: [Validators.required],
                value: citizen ? citizen.dateForReceivingSignUp : undefined
            }),
            new FormBuilderDatepicker({
                name: Translations.municipality.subscribeCitizen.info.deliveryStart.name,
                label: Translations.municipality.subscribeCitizen.info.deliveryStart.label,
                required: true,
                validation: [Validators.required],
                value: citizen ? citizen.deliveryStart : undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.visitationId.name,
                label: Translations.municipality.subscribeCitizen.info.visitationId.label,
                options: Object.values(Visitation).filter(x => typeof x === 'string').map(x => toFormOptions(Visitation[x], Translations.municipality.subscribeCitizen.visitationTranslations[x])),
                valueChanged: () => this.form.info && this.form.info.controls[Translations.municipality.subscribeCitizen.info.deliveryEnd.name].updateValueAndValidity({ onlySelf: true }),
                required: true,
                value: citizen ? citizen.visitationId : undefined
            }),
            new FormBuilderDatepicker({
                name: Translations.municipality.subscribeCitizen.info.deliveryEnd.name,
                label: Translations.municipality.subscribeCitizen.info.deliveryEnd.label,
                condition: () => this.form.info ? this.form.info.controls[Translations.municipality.subscribeCitizen.info.visitationId.name].value === Visitation.Temporary : false,
                required: true,
                validation: [Validators.required],
                value: citizen?.deliveryEnd ?? undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.deliveryFrequencyId.name,
                label: Translations.municipality.subscribeCitizen.info.deliveryFrequencyId.label,
                options: Object.values(DeliveryFrequency).filter(x => typeof x === 'string').map(x => toFormOptions(DeliveryFrequency[x], Translations.municipality.subscribeCitizen.deliveryFrequencyTranslations[x])),
                valueChanged: (value: number) => {
                    if (this.form.info) {
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyDeliveryDay.name].updateValueAndValidity({ onlySelf: true });
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyOrderDay.name].updateValueAndValidity({ onlySelf: true });
                        this.form.info.controls[Translations.municipality.subscribeCitizen.info.biWeeklyTimeslot.name].updateValueAndValidity({ onlySelf: true });
                    }

                    if (value !== DeliveryFrequency.BiWeekly) {
                        MunicipalityDeliveryHelpers.updateDeliveryDays(this.availableDeliveryDays$.getValue(), undefined, this.deliveryDays$, this.biWeeklyDeliveryDays$);
                    }
                },
                required: true,
                validation: [Validators.required],
                value: citizen ? citizen.deliveryFrequencyId : undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.deliveryDay.name,
                label: Translations.municipality.subscribeCitizen.info.deliveryDay.label,
                options: this.deliveryDays$,
                valueChanged: (value: number) => {
                    MunicipalityDeliveryHelpers.updateDeliveryDays(this.availableDeliveryDays$.getValue(), value, this.biWeeklyDeliveryDays$);
                    MunicipalityDeliveryHelpers.updateOrderDays(value, this.orderDays$);
                },
                required: true,
                validation: [Validators.required],
                value: citizen && citizen.deliveryDayIds[0] ? citizen.deliveryDayIds[0].dayOfWeek : undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.orderDay.name,
                label: Translations.municipality.subscribeCitizen.info.orderDay.label,
                options: this.orderDays$,
                valueChanged: (value: number) => this._getTimeslots(value, this.timeslots$),
                required: true,
                validation: [Validators.required],
                value: citizen && citizen.orderDayIds[0] ? citizen.orderDayIds[0].dayOfWeek : undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.timeslot.name,
                label: Translations.municipality.subscribeCitizen.info.timeslot.label,
                condition: () => this.customerSubtypeControl?.value === CustomerSubType.Ringeborger,
                options: this.timeslots$,
                required: true,
                validation: [Validators.required],
                value: citizen && citizen.orderDayIds[0] ? citizen.orderDayIds[0].timeOfDay : undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.biWeeklyDeliveryDay.name,
                label: Translations.municipality.subscribeCitizen.info.biWeeklyDeliveryDay.label,
                condition: () => this.form.info ? this.form.info.controls[Translations.municipality.subscribeCitizen.info.deliveryFrequencyId.name].value === DeliveryFrequency.BiWeekly : false,
                options: this.biWeeklyDeliveryDays$,
                valueChanged: (value: number) => {
                    MunicipalityDeliveryHelpers.updateDeliveryDays(this.availableDeliveryDays$.getValue(), value, this.deliveryDays$);
                    MunicipalityDeliveryHelpers.updateOrderDays(value, this.biWeeklyOrderDays$);
                },
                required: true,
                validation: [Validators.required],
                value: citizen && citizen.deliveryDayIds[1] ? citizen.deliveryDayIds[1].dayOfWeek : undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.biWeeklyOrderDay.name,
                label: Translations.municipality.subscribeCitizen.info.biWeeklyOrderDay.label,
                condition: () => this.form.info ? this.form.info.controls[Translations.municipality.subscribeCitizen.info.deliveryFrequencyId.name].value === DeliveryFrequency.BiWeekly : false,
                options: this.biWeeklyOrderDays$,
                valueChanged: (value: number) => this._getTimeslots(value, this.biWeeklyTimeslots$),
                required: true,
                validation: [Validators.required],
                value: citizen && citizen.orderDayIds[1] ? citizen.orderDayIds[1].dayOfWeek : undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.info.biWeeklyTimeslot.name,
                label: Translations.municipality.subscribeCitizen.info.biWeeklyTimeslot.label,
                condition: () => this.form.info ? this.form.info.controls[Translations.municipality.subscribeCitizen.info.deliveryFrequencyId.name].value === DeliveryFrequency.BiWeekly && this.form.info.controls[Translations.municipality.subscribeCitizen.info.customerSubType.name].value === CustomerSubType.Ringeborger : false,
                options: this.biWeeklyTimeslots$,
                required: true,
                validation: [Validators.required],
                value: citizen && citizen.orderDayIds[1] ? citizen.orderDayIds[1].timeOfDay : undefined
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.webUsername.name,
                label: Translations.municipality.subscribeCitizen.info.webUsername.label,
                condition: this.usernameCondition$,
                validation: [Validators.required, Validators.maxLength(30)],
                required: true,
                type: 'text',
                value: citizen && citizen.webUsername ? citizen.webUsername : undefined
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.password.name,
                label: Translations.municipality.subscribeCitizen.info.password.label,
                condition: this.passwordCondition$,
                validation: !this.editMode ? [Validators.required, Validators.minLength(6)] : [Validators.minLength(6)],
                required: !this.editMode,
                valueChanged: () => this.form.info && this.form.info.controls[Translations.municipality.subscribeCitizen.info.confirmPassword.name].updateValueAndValidity(),
                type: 'password'
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.info.confirmPassword.name,
                label: Translations.municipality.subscribeCitizen.info.confirmPassword.label,
                condition: this.passwordCondition$,
                validation: !this.editMode ? [
                    Validators.required,
                    Validators.minLength(6),
                    FormBuilderValidators.matchFieldValidator(String(Translations.municipality.subscribeCitizen.info.password.name))
                ] : [
                    Validators.minLength(6),
                    FormBuilderValidators.matchFieldValidator(String(Translations.municipality.subscribeCitizen.info.password.name))
                ],
                required: !this.editMode,
                type: 'password'
            })
        ];

        this.form.info = this.formBuilder.toFormGroup(this.inputs.info);

        if (this.editMode) {
            this.oldValues.info = this.form.info.getRawValue();

            // Pre-validate all fields in edit mode
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Object.entries(this.form.info.controls).forEach(([_name, c]) => {
                c.markAsTouched();
                c.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            });
        }
    }

    private _validateInfoForm(): boolean {
        if (this.form.info) {
            const infoEmailField = this.form.info.value[Translations.municipality.subscribeCitizen.info.email.name];
            const isTastselvBorger = this.form.info.value[Translations.municipality.subscribeCitizen.info.customerSubType.name] === CustomerSubType.TastSelvBorger;
            if (!infoEmailField && isTastselvBorger) {
                this.dialogService.showMessage(Translations.forms.validation.emailRequired, undefined, 'alertdialog');
                return false;
            }

            const firstName = this.form.info.value[Translations.municipality.subscribeCitizen.info.firstName.name];
            const middleName = this.form.info.value[Translations.municipality.subscribeCitizen.info.middleName.name];
            const lastName = this.form.info.value[Translations.municipality.subscribeCitizen.info.lastName.name];
            const fullName = `${firstName} ${middleName} ${lastName}`;
            const fullNameLabel = `${Translations.municipality.subscribeCitizen.info.firstName.label} + ${Translations.municipality.subscribeCitizen.info.middleName.label} + ${Translations.municipality.subscribeCitizen.info.lastName.label}`;

            if (fullName.length > 50) {
                const errorMsg = Translations.replaceTokens(Translations.forms.staticValidation.valueLength, fullNameLabel, 50);
                this.dialogService.showMessage(errorMsg, undefined, 'alertdialog');
                return false;
            }

            return true;
        }

        return false;
    }

    private _getMunicipalityData(municipalityId?: number) {
        if (municipalityId) {
            this.driverService.getDrivers(municipalityId).pipe(
                takeUntil(this.unsubscribe)
            ).subscribe(drivers => this.drivers$.next(drivers.map(x => toFormOptions(x.id, x.driverName))));

            this.municipalityService.getMunicipalityDeliveryDays(municipalityId).pipe(
                takeUntil(this.unsubscribe)
            ).subscribe(days => this.availableDeliveryDays$.next(days));
        } else {
            this.drivers$.next([]);
            this.availableDeliveryDays$.next([]);
        }
    }

    private _getTimeslots(dayOfWeek: number, subject: Subject<FormBuilderOption[]>) {
        if (dayOfWeek) {
            this.municipalityService.getAvailabilities(dayOfWeek).pipe(
                takeUntil(this.unsubscribe),
                share()
            ).subscribe(timeslots => MunicipalityDeliveryHelpers.updateTimeslots(timeslots, subject));
        } else {
            subject.next([]);
        }
    }

    //#endregion

    //#region Relatives

    private _buildRelativesForm(citizen?: ICitizen) {
        this.inputs.relatives = [
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.relatives.primaryUser.name,
                label: Translations.municipality.subscribeCitizen.relatives.primaryUser.label,
                options: Object.values(PrimaryUser).filter(x => typeof x === 'string').map(x => toFormOptions(PrimaryUser[x], Translations.municipality.subscribeCitizen.primaryUserTranslations[x])),
                required: true,
                value: citizen ? citizen.primaryUser : undefined
            }),
            new FormBuilderSelect({
                name: Translations.municipality.subscribeCitizen.relatives.relationID.name,
                label: Translations.municipality.subscribeCitizen.relatives.relationID.label,
                options: Object.values(CitizenRelationId).filter(x => typeof x === 'string').map(x => x === 'Unspecified' ? toFormOptions(undefined, undefined) : toFormOptions(CitizenRelationId[x], Translations.municipality.subscribeCitizen.citizenRelationIdTranslations[x])),
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.relationID : undefined
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.firstName.name,
                label: Translations.municipality.subscribeCitizen.relatives.firstName.label,
                validation: [Validators.maxLength(30)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.firstName : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.middleName.name,
                label: Translations.municipality.subscribeCitizen.relatives.middleName.label,
                validation: [Validators.maxLength(30)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.middleName : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.lastName.name,
                label: Translations.municipality.subscribeCitizen.relatives.lastName.label,
                validation: [Validators.maxLength(30)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.lastName : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.name2.name,
                label: Translations.municipality.subscribeCitizen.relatives.name2.label,
                validation: [Validators.maxLength(50)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.name2 : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.streetName.name,
                label: Translations.municipality.subscribeCitizen.relatives.streetName.label,
                validation: [Validators.maxLength(33)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.streetName : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.streetNumber.name,
                label: Translations.municipality.subscribeCitizen.relatives.streetNumber.label,
                validation: [Validators.maxLength(4)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.streetNumber : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.addressFloor.name,
                label: Translations.municipality.subscribeCitizen.relatives.addressFloor.label,
                validation: [Validators.maxLength(3)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.addressFloor : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.addressSide.name,
                label: Translations.municipality.subscribeCitizen.relatives.addressSide.label,
                validation: [Validators.maxLength(7)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.addressSide : ''
            }),
            new FormBuilderCity({
                name: Translations.municipality.subscribeCitizen.relatives.city.name,
                value: citizen && citizen.citizenRelative ? {
                    zipCode: citizen.citizenRelative.zipCode,
                    city: citizen.citizenRelative.city
                } : undefined
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.phoneNumber.name,
                label: Translations.municipality.subscribeCitizen.relatives.phoneNumber.label,
                validation: [Validators.minLength(8), Validators.maxLength(8)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.phoneNumber : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.mobileNumber.name,
                label: Translations.municipality.subscribeCitizen.relatives.mobileNumber.label,
                validation: [Validators.minLength(8), Validators.maxLength(8)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.mobileNumber : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.faxNumber.name,
                label: Translations.municipality.subscribeCitizen.relatives.faxNumber.label,
                validation: [Validators.maxLength(30)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.faxNumber : ''
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.subscribeCitizen.relatives.email.name,
                label: Translations.municipality.subscribeCitizen.relatives.email.label,
                validation: [Validators.email, Validators.maxLength(80)],
                type: 'email',
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.email : ''
            }),
            new FormBuilderTextarea({
                name: Translations.municipality.subscribeCitizen.relatives.comment.name,
                label: Translations.municipality.subscribeCitizen.relatives.comment.label,
                validation: [Validators.maxLength(100)],
                value: citizen && citizen.citizenRelative ? citizen.citizenRelative.comment : ''
            })
        ];

        this.form.relatives = this.formBuilder.toFormGroup(this.inputs.relatives);
        if (this.editMode) {
            this.oldValues.relatives = this.form.relatives.getRawValue();
        }
    }

    //#endregion
}
