import { inject, Pipe, PipeTransform } from '@angular/core';
import { CitizenTransferReason, ICitizenStatusDraftViewModel } from '../../../api/services';
import { Translations } from '../../../translations/translations';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'processRowColumn',
    standalone: true
})
export class ProcessRowColumnPipe implements PipeTransform {

    private datePipe = inject(DatePipe);

    transform(column: string, row: ICitizenStatusDraftViewModel): string {
        if (column === 'statusFrom' || column === 'statusTo') {
            return this.datePipe.transform(row[column], 'shortDate') ?? row[column].toString();
        }

        if (column === 'createDate') {
            return this.datePipe.transform(row[column], 'short') ?? row[column].toString()
        }

        if (column === 'inactivityReason') {
            return Translations.global.transferReasons[CitizenTransferReason[row[column]]];
        }

        return row[column];
    }

}
