import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Translations } from '../../translations/translations';
import { RouteService } from '../shared/route.service';
import { IDENTITIES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { AdministrationCalllistadminUserlistComponent } from './administration-calllistadmin-userlist.component';
import { AdministrationCalllistadminCalllistListComponent } from './administration-calllistadmin-calllist-list.component';
import { AdministrationCalllistadminPlanningComponent } from './administration-calllistadmin-planning.component';
import { NotesAdminCausesComponent } from '../notes-admin/notes-admin-causes.component';
import { AccordionComponent } from '../shared/accordion.component';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { UtilHiddenScrollComponent } from '../util/util-hidden-scroll.component';

@Component({
    selector: 'iv-administration-intervare-menu',
    template: `
    <iv-util-hidden-scroll height="100vh">
        <nav class="category-menu" role="navigation">
            <div class="category-menu__level" *ngIf="access.messages">
                <a routerLinkActive="category-menu__link_active" routerLink="/${Translations.intervare.paths.messageAdmin.path}">
                    <h5 class="category-menu__header">${Translations.intervare.messageAdmin.menuTitle}</h5>
                </a>

                <div class="category-menu__level">
                    <a class="category-menu__link" routerLink="/${Translations.intervare.paths.messageAdmin.path}/edit" routerLinkActive="category-menu__link_active" [routerLinkActiveOptions]="{exact: true}">
                        ${Translations.intervare.messageAdmin.createMessage}
                    </a>
                    <a class="category-menu__link" routerLink="/${Translations.intervare.paths.messageAdmin.path}" routerLinkActive="category-menu__link_active" [routerLinkActiveOptions]="{exact: true}">
                        ${Translations.intervare.messageAdmin.list}
                    </a>
                </div>
            </div>

            <div class="category-menu__level">
                <a routerLinkActive="category-menu__link_active" routerLink="/${Translations.intervare.paths.creationError.path}">
                    <h5 class="category-menu__header">${Translations.intervare.paths.creationError.title}</h5>
                </a>
            </div>

            <iv-accordion class="category-menu__level" (open)="causesOpen = true" [opened]="causesOpen" *ngIf="access.notes">
                <h5 class="category-menu__header" accordion-title>${Translations.intervare.notesAdmin.menuTitle}</h5>
                <div class="category-menu__level" accordion-content>
                    <iv-notes-admin-causes *ngIf="causesOpen"></iv-notes-admin-causes>
                </div>
            </iv-accordion>

            <div class="category-menu__level" *ngIf="access.administration">
                <h5 class="category-menu__header">Administration</h5>

                <div class="category-menu__level">
                    <a class="category-menu__link" routerLinkActive="category-menu__link_active" routerLink="/${Translations.intervare.paths.settingsAdmin.path}">
                        ${Translations.intervare.paths.settingsAdmin.title}
                    </a>
                    <a class="category-menu__link" routerLinkActive="category-menu__link_active" routerLink="/${Translations.intervare.paths.weeklyOffersAdmin.path}">
                        ${Translations.intervare.weeklyOffersAdmin.menuTitle}
                    </a>
                </div>
            </div>

            <div class="category-menu__level" *ngIf="access.callListAdmin">
                <h5 class="category-menu__header">${Translations.callListAdmin.title}</h5>
                <iv-accordion class="category-menu__level">
                    <h5 class="category-menu__header" accordion-title>${Translations.callListAdmin.navigation.functions}</h5>
                    <div class="category-menu__level" accordion-content>
                        <a class="category-menu__link category-menu__link_disable" routerLinkActive="category-menu__link_active" [routerLink]="['', { outlets: { dialog: ['assigncitizens']} }]">
                            ${Translations.callListAdmin.navigation.assigncitizens}
                        </a>
                        <a class="category-menu__link" routerLinkActive="category-menu__link_active" [routerLink]="['', { outlets: { dialog: ['generate-list']} }]">
                            ${Translations.callListAdmin.navigation.generateLists}
                        </a>
                        <a class="category-menu__link" routerLinkActive="category-menu__link_active" [routerLink]="['', { outlets: { dialog: ['display-callcount']} }]">
                            ${Translations.callListAdmin.navigation.displayCallcount}
                        </a>
                        <a class="category-menu__link" routerLinkActive="category-menu__link_active" routerLink="/${Translations.intervare.paths.pendingCalls.path}">
                            ${Translations.callListAdmin.navigation.pendingCalls}
                        </a>
                    </div>
                </iv-accordion>
                <iv-accordion class="category-menu__level" (open)="planningOpen = true" [opened]="planningOpen">
                    <h5  class="category-menu__header" accordion-title>${Translations.callListAdmin.navigation.planning}</h5>
                    <div class="category-menu__level" accordion-content>
                        <iv-administration-calllistadmin-planning *ngIf="planningOpen"></iv-administration-calllistadmin-planning>
                    </div>
                </iv-accordion>
                <iv-accordion class="category-menu__level" (open)="callListOpen = true" [opened]="callListOpen">
                    <h5 class="category-menu__header" accordion-title>${Translations.callListAdmin.navigation.callLists}</h5>
                    <div class="category-menu__level" accordion-content>
                        <a class="category-menu__link" routerLinkActive="category-menu__link_active" [routerLink]="['', { outlets: { dialog: ['edit-call-list', 'new']} }]">
                            ${Translations.callListAdmin.navigation.addCallList}
                        </a>
                        <iv-administration-calllistadmin-calllist-list *ngIf="callListOpen"></iv-administration-calllistadmin-calllist-list>
                    </div>
                </iv-accordion>
                <iv-accordion class="category-menu__level" (open)="callersOpen = true" [opened]="callersOpen">
                    <h5 class="category-menu__header" accordion-title>${Translations.callListAdmin.navigation.callers}</h5>
                    <div class="category-menu__level" accordion-content>
                        <a class="category-menu__link" routerLinkActive="category-menu__link_active" [routerLink]="['', { outlets: { dialog: ['edit-caller', 'new']} }]">
                            ${Translations.callListAdmin.navigation.addCaller}
                        </a>
                        <iv-administration-calllistadmin-userlist *ngIf="callersOpen"></iv-administration-calllistadmin-userlist>
                    </div>
                </iv-accordion>
            </div>
        </nav>
    </iv-util-hidden-scroll>
    `,
    standalone: true,
    imports: [UtilHiddenScrollComponent, NgIf, RouterLinkActive, RouterLink, AccordionComponent, NotesAdminCausesComponent, AdministrationCalllistadminPlanningComponent, AdministrationCalllistadminCalllistListComponent, AdministrationCalllistadminUserlistComponent]
})
export class AdministrationIntervareMenuComponent implements OnInit, OnDestroy {
    callersOpen = false;
    callListOpen = false;
    planningOpen = false;
    causesOpen = false;

    access = {
        messages: false,
        notes: false,
        administration: false,
        callListAdmin: false
    };

    private unsubscribe = new Subject<void>();

    constructor(
        private routeService: RouteService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.routeService.activeRoute$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(route => {
            if (route.url.length) {
                switch (route.url[0].path) {
                    case Translations.intervare.paths.notesAdmin.path:
                        this.causesOpen = true;
                        break;
                    case 'planning':
                        this.planningOpen = true;
                        break;
                    case 'calllist':
                        this.callListOpen = true;
                        break;
                }
            }
        });

        this.userService.activeRoles.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(roles => {
            this.access.messages = this.access.notes = this.access.administration = roles.some(x => IDENTITIES.admin.includes(x));
            this.access.callListAdmin = roles.some(x => IDENTITIES.adminAndCustomerService.includes(x));
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
