import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { IForgotPasswordViewModel, ILoginCredentialsModel } from '../../api/services';
import { lock, returnArrow } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { AuthService } from '../services/auth.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { IDENTITIES, ROLES } from './user-roles';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'iv-login',
    template: `
        <section class="login">
            <div class="login__content">
                <ng-container *ngIf="!forgotpasswordState; else forgotPasswordTmpl">
                    <div class="login__form">
                        <form #loginForm="ngForm" (ngSubmit)="onLogin(loginForm)" aria-label="${Translations.pages.loginPage.formLabel}">
                            <mat-form-field>
                                <mat-label>${Translations.pages.loginPage.username}</mat-label>
                                <input name="userName" ngModel matInput type="text" required>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>${Translations.pages.loginPage.password}</mat-label>
                                <input name="password" ngModel matInput type="password" required>
                            </mat-form-field>
                            <div role="status" class="error" *ngIf="errorMsg">{{ errorMsg }}</div>
                            <div class="login__actions">
                                <p><a routerLink="" [queryParams]="{forgotPassword: '1'}">${Translations.pages.loginPage.forgotPasswordBtn}</a></p>
                                <iv-progress-button class="login__btn" [loadingState]="loadingState" color="primary">${Translations.pages.loginPage.loginBtn}</iv-progress-button>
                            </div>
                        </form>
                    </div>
                </ng-container>
                <ng-template #forgotPasswordTmpl>
                    <div class="login__forgot-password text-center">
                        <div class="login__forgot-password-form" *ngIf="!this.resetPasswordState; else resetPasswordBlock">
                            <div class="header-icon">${lock}</div>
                            <h3>${Translations.pages.loginPage.forgotPasswordTitle}</h3>
                            <span [innerHTML]="Translations.pages.loginPage.forgotPasswordSubtitle"></span>
                            <form #resetForm="ngForm" (ngSubmit)="onResetPassword(resetForm)" aria-label="${Translations.pages.loginPage.forgotPasswordFormAssistLabel}">
                                <mat-form-field>
                                    <input name="email" ngModel matInput type="email" placeholder="${Translations.pages.loginPage.forgotPasswordInput}" required>
                                </mat-form-field>
                                <div class="login__actions">
                                    <iv-progress-button class="login__btn" [loadingState]="loadingState" color="primary">${Translations.pages.loginPage.forgotPasswordSubmit}</iv-progress-button>
                                </div>
                                <div class="login__forgot-password-reset">
                                    <p>${Translations.pages.loginPage.forgotPasswordText}</p>
                                </div>
                            </form>
                        </div>
                        <ng-template #resetPasswordBlock>
                            <div class="login__forgot-password-reset" [innerHTML]="Translations.pages.loginPage.forgotPasswordResetText"></div>
                        </ng-template>
                        <a class="login__go-back icon" routerLink="" aria-label="${Translations.pages.loginPage.forgotPasswordGoBackBtn}" role="button">${returnArrow}</a>
                    </div>
                </ng-template>
            </div>
        </section>
    `,
    standalone: true,
    imports: [NgIf, FormsModule, MatFormField, MatLabel, MatInput, RouterLink, ProgressButtonComponent]
})
export class LoginComponent implements OnInit, OnDestroy {

    errorMsg = '';
    loadingState = false;
    forgotpasswordState = false;
    resetPasswordState = false;

    private unsubscribe = new Subject<void>();
    private stateChange = new Subject<void>();

    onLogin(f: NgForm) {
        this.errorMsg = '';

        if (f.valid) {
            this.loadingState = true;
            const loginCredentialsVm: ILoginCredentialsModel = {
                userName: f.value.userName,
                password: f.value.password
            };

            this.authService.login(loginCredentialsVm).pipe(
                takeUntil(this.stateChange),
                finalize(() => this.loadingState = false)
            ).subscribe(
                user => {
                    if (user.roles) {
                        if (IDENTITIES.pda.some(x => user.roles!.includes(x))) {
                            this.router.navigate(['pda']);
                        } else if (user.roles!.includes(ROLES.MunicipalityAdmin)) {
                            this.router.navigate([Translations.municipality.paths.citizenStatus.path]);
                        }
                    }
                },
                (err: IntervareHttpErrorResponse) => this.errorMsg = err.validationErrors.join(', ')
            );
        }
    }

    onResetPassword(f: NgForm) {
        if (f.valid) {
            this.loadingState = true;

            const forgotVm: IForgotPasswordViewModel = {
                email: f.value.email
            };

            this.authService.forgotPassword(forgotVm).pipe(
                takeUntil(this.stateChange),
                finalize(() => this.loadingState = false)
            ).subscribe(result => {
                this.resetPasswordState = result;
            });
        }
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(map => {
            const forgotPassword = map.get('forgotPassword');

            this.resetPasswordState = false;

            if (forgotPassword === '1') {
                this.forgotpasswordState = true;
            } else {
                this.forgotpasswordState = false;
            }

            // Remove any pending actions
            this.stateChange.next();
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.stateChange.next();
        this.stateChange.complete();
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute
    ) { }

    protected readonly Translations = Translations;
}
