import {enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection} from '@angular/core';

import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {appRoutes, AppRoutingModule} from './app/app-routing.module';
import {NotesAdminModule} from './app/notes-admin/notes-admin.module';
import {CallListAdminModule} from './app/call-list-admin/call-list-admin.module';
import {CommerceModule} from './app/commerce/commerce.module';
import {MunicipalityModule} from './app/municipality/municipality.module';
import {CitizenModule} from './app/citizen/citizen.module';
import {AdministrationModule} from './app/administration/administration.module';
import {PageModule} from './app/page/page.module';
import {MenuModule} from './app/menu/menu.module';
import {LayoutModule} from './app/layout/layout.module';
import {UserModule} from './app/user/user.module';
import {SharedModule} from './app/shared/shared.module';
import {UtilModule} from './app/util/util.module';
import {provideAnimations} from '@angular/platform-browser/animations';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {GlobalErrorHandler} from './app/global-error-handler';
import {Translations} from './translations/translations';
import {CURRENCY_CODE} from './app/app.locale_data';
import Raven from 'raven-js';
import {provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {provideRouter, withRouterConfig} from '@angular/router';

if (environment.production) {
    enableProdMode();

    Raven.config('https://bf9a1916e38d44ffbaeb08ccb32cb0d7@sentry.io/229169', {
        environment: environment.production ? 'production' : 'dev'
    }).install();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            UtilModule.forRoot(),
            SharedModule.forRoot(),
            UserModule.forRoot(),
            LayoutModule,
            MenuModule,
            PageModule,
            AdministrationModule,
            CitizenModule,
            MunicipalityModule,
            CommerceModule,
            CallListAdminModule,
            NotesAdminModule,
            AppRoutingModule
        ),
        provideRouter(appRoutes, withRouterConfig({onSameUrlNavigation: 'reload'})),
        provideHttpClient(withFetch(), withInterceptorsFromDi()),
        provideZoneChangeDetection({ignoreChangesOutsideZone: true}),
        {provide: LOCALE_ID, useValue: 'da'},
        {provide: CURRENCY_CODE, useValue: Translations.variables.currencyCode},
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        provideAnimations()
    ]
}).catch(err => console.log(err));
