import { Translations } from 'Client/translations/translations';
import { ChangeDetectionStrategy, Component, Input, HostBinding, ChangeDetectorRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { note, callAgain, busy } from './../../scripts/generated/icons';
import { IReservationViewModel, ProductListItemViewModel } from 'Client/api/services';
import { ReservationErrorCodeType } from './commerce.models';
import { TranslationPipe } from '../shared/translation.pipe';
import { RouterLink } from '@angular/router';
import { MatMiniFabAnchor, MatAnchor } from '@angular/material/button';
import { NgIf } from '@angular/common';


@Component({
    template: `
        <div class="commerce-basket-item-reservation">
            <div class="commerce-basket-item-reservation__wrapper"
                 [class.commerce-basket-item-reservation__wrapper--has-warning]="hasWarning"
                 [class.commerce-basket-item-reservation__wrapper--not-available]="notAvailable"
                 [class.commerce-basket-item-reservation__wrapper--is-mini-basket]="isMiniBasket">

                <div class="commerce-basket-item-reservation__content"
                [class.commerce-basket-item-reservation__content--is-mini-basket]="isMiniBasket">
                    <div class="commerce-basket-item-reservation__icon icon" aria-hidden="true">
                        <ng-container *ngIf="reservation.reservationErrorData?.code === reservationErrorCodeType.IMPACT_QTDIFF">${note}</ng-container>
                        <ng-container *ngIf="reservation.reservationErrorData?.code !== reservationErrorCodeType.IMPACT_QTDIFF">${busy}</ng-container>
                    </div>
                    <div class="commerce-basket-item-reservation__message">
                        {{ message | translation: reservation?.reservationErrorData?.initialBasketQuantity : quantity }}
                    </div>
                </div>

                <ng-container *ngIf="reservation.reservationErrorData?.code !== reservationErrorCodeType.IMPACT_QTDIFF">
                    <a *ngIf="replacementProduct && isMiniBasket"
                        class="commerce-basket-item-reservation__alternative-btn"
                        color="primary"
                        [routerLink]="['', { outlets: { dialog: ['product', replacementProduct?.id] } }]"
                        [queryParams]="{ originalProduct: productId }"
                        title="${Translations.commerce.reservation.alternativeButtonTitle}"
                        aria-label="${Translations.commerce.reservation.alternativeButtonTitle}"
                        mat-mini-fab>
                        <span class="commerce-basket-item-reservation__button-icon icon" aria-hidden="true">${callAgain}</span>
                    </a>

                    <a *ngIf="replacementProduct && !isMiniBasket"
                        class="commerce-basket-item-reservation__alternative-btn"
                        color="primary"
                        [routerLink]="['', { outlets: { dialog: ['product', replacementProduct?.id] } }]"
                        [queryParams]="{ originalProduct: productId }"
                        title="${Translations.commerce.reservation.alternativeButtonTitle}"
                        aria-label="${Translations.commerce.reservation.alternativeButtonTitle}"
                        mat-raised-button>
                        ${Translations.commerce.reservation.alternativeButtonLabel}
                    </a>
                </ng-container>
            </div>

        </div>
    `,
    selector: 'iv-commerce-basket-item-reservation',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatMiniFabAnchor, RouterLink, MatAnchor, TranslationPipe]
})

export class CommerceBasketItemReservationComponent implements OnInit, OnChanges {

    @HostBinding('class.commerce-basket-item-reservation--not-available') get notAvailable() { return !this.reservation.hasReservation && this.reservation.reservationErrorData?.code !== ReservationErrorCodeType.IMPACT_QTDIFF; }
    @HostBinding('class.commerce-basket-item-reservation--has-warning') get hasWarning() { return this.reservation.reservationErrorData?.code === ReservationErrorCodeType.IMPACT_QTDIFF; }

    @HostBinding('class.commerce-basket-item-reservation--is-mini-basket')
    @Input() isMiniBasket = false;

    @Input() productId = '';
    @Input() replacementProduct?: ProductListItemViewModel;
    @Input() quantity?: number;

    // if the property has reservation is true that
    // means the product was reserved with success
    @Input() reservation: IReservationViewModel;

    public reservationErrorCodeType = ReservationErrorCodeType;
    public message = '';

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.checkForReservation(this.reservation);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.reservation) {
            const { currentValue } = changes.reservation;
            this.checkForReservation(currentValue);
        }
    }

    private checkForReservation(reservation: IReservationViewModel): void {
        const { code } = reservation.reservationErrorData || {};
        if (code) {
            const hasProperty = Translations.global.reservation.errorCodes.hasOwnProperty(code);
            this.message = Translations.global.reservation.errorCodes[hasProperty ? code : 'IMPACT_FAIL'];
            this.cd.markForCheck();
        }
    }
}
