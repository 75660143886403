import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Translations } from '../../translations/translations';
import { AdministrationDeliveryService, IChangeDeliveryDay } from '../services/administration-delivery.service';
import { tableDetailExpand } from '../shared/dynamic-table.animations';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'iv-administration-history-delivery-date',
    template: `
        <div class="administration-history-delivery-date">
            <h2>${Translations.municipality.deliveryDateChangeHistory.title}</h2>
            <form [formGroup]="searchForm" class="administration-history-delivery-date__form">
                <div class="administration-history-delivery-date__from">
                    <mat-form-field>
                        <input
                            required
                            matInput
                            [matDatepicker]="startDatePicker"
                            placeholder="${Translations.municipality.deliveryDateChangeHistory.startDate}"
                            formControlName="startDate"
                        />
                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="administration-history-delivery-date__to">
                    <mat-form-field>
                        <input
                            required
                            matInput
                            [matDatepicker]="endDatePicker"
                            placeholder="${Translations.municipality.deliveryDateChangeHistory.endDate}"
                            formControlName="endDate"
                        />
                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </form>

            <div class="administration-history-delivery-date__data">
                <ng-container *ngIf="!isFetching; else fetching">
                    <ng-container *ngTemplateOutlet="deliveryListTpl; context: { data: filterList }"></ng-container>
                </ng-container>
            </div>
        </div>

        <!-- GENERIC TEMPLATES -->
        <ng-template #deliveryListTpl let-data="data">
            <table mat-table [dataSource]="data" style="width: 100%">
                <ng-container matColumnDef="From">
                    <th mat-header-cell *matHeaderCellDef>${Translations.municipality.deliveryDateChange.fromDate}</th>
                    <td mat-cell *matCellDef="let element">{{ formatDate(element.From) }}</td>
                </ng-container>
                <ng-container matColumnDef="To">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>
                        ${Translations.municipality.deliveryDateChange.toDate}
                    </th>
                    <td mat-cell class="align-right" *matCellDef="let element">{{ formatDate(element.To) }}</td>
                </ng-container>
                <ng-container matColumnDef="OriginalOrderDate">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>
                        ${Translations.municipality.deliveryDateChange.originalOrderDate}
                    </th>
                    <td mat-cell class="align-right" *matCellDef="let element">
                        {{ formatDate(element.OriginalOrderDate) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="DestinationOrderDate">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>
                        ${Translations.municipality.deliveryDateChange.destinationOrderDate}
                    </th>
                    <td mat-cell class="align-right" *matCellDef="let element">
                        {{ formatDate(element.DestinationOrderDate) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="LastEdit">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>
                        ${Translations.municipality.deliveryDateChange.lastEdited}
                    </th>
                    <td mat-cell class="align-right" *matCellDef="let element">
                        {{ formatDate(element.LastModifiedDate) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="LastModifiedBy">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>
                        ${Translations.municipality.deliveryDateChange.editedBy}
                    </th>
                    <td mat-cell class="align-right" *matCellDef="let element">{{ element.LastModifiedBy }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </ng-template>

        <ng-template #emptyTpl> ${Translations.municipality.deliveryDateChangeHistory.emptyHistoryDates} </ng-template>

        <ng-template #fetching>
            <div class="center-content alt-theme">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="3"
                    [diameter]="60"
                ></mat-progress-spinner>
            </div>
        </ng-template>
    `,
    animations: [tableDetailExpand],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatInput, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, NgIf, NgTemplateOutlet, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatProgressSpinner]
})
export class AdministrationHistoryDeliveryDateComponent implements OnInit, OnDestroy {
    deliveryList: IChangeDeliveryDay[];
    filterList: IChangeDeliveryDay[];
    hasHistoryLogs = false;
    displayedColumns: string[] = [
        'From',
        'To',
        'OriginalOrderDate',
        'DestinationOrderDate',
        'LastEdit',
        'LastModifiedBy'
    ];

    searchForm: UntypedFormGroup;
    isFetching = true;
    private unsubscribe: Subject<void> = new Subject();

    constructor(private administrationDeliveryService: AdministrationDeliveryService) {
        const todayDate = new Date();
        const startDate = new Date(todayDate.getFullYear() - 1, todayDate.getMonth(), todayDate.getDate());
        const endDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());

        this.searchForm = new UntypedFormGroup({
            startDate: new UntypedFormControl(startDate),
            endDate: new UntypedFormControl(endDate)
        });
    }

    ngOnInit(): void {
        this.searchForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
            this.filterDeliveryDates(value.startDate, value.endDate);
        });

        this.getDeliveryList();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getDeliveryList() {
        this.administrationDeliveryService
            .getDeliveryList(true)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                list => {
                    this.isFetching = false;
                    this.hasHistoryLogs = !!Object.keys(list).length;
                    this.deliveryList = list;

                    this.searchForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
                },
                () => {
                    this.isFetching = false;
                    this.hasHistoryLogs = false;
                }
            );
    }

    formatDate(date: string) {
        return new Date(date).toLocaleDateString(Translations.variables.dateFormat);
    }

    filterDeliveryDates(startDate: Date, endDate: Date) {
        if (this.deliveryList) {
            this.filterList = this.deliveryList
                .filter(delivery => {
                    const date1 = new Date(delivery.From);
                    const date2 = new Date(delivery.To);
                    return date1.getTime() >= startDate.getTime() && date2.getTime() <= endDate.getTime();
                })
                .sort(this.originalOrder);

            this.hasHistoryLogs = !!this.filterList.length;
        }
    }

    // This is to avoid the keyvalue pipe make it's own order, this will force the original order
    // we put on the object
    originalOrder = (a: IChangeDeliveryDay, b: IChangeDeliveryDay): number => {
        const aDate = new Date(a.From);
        const bDate = new Date(b.From);
        if (aDate.getTime() < bDate.getTime()) {
            return 1;
        }
        if (aDate.getTime() > bDate.getTime()) {
            return -1;
        }
        return 0;
    };
}
