import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import {
    AllowedDeliveryDate,
    BasketViewModel,
    IAddCatalogItemToBasketModel,
    IAddToBasketModel,
    IAllowedDeliveryDate,
    IBasketViewModel,
    IOrderCrudViewModel,
    IProductListViewModel,
    ISetDeliveryDateViewModel,
    IUserViewModel,
    ProductListViewModel,
} from '../../api/services';
import { Translations } from '../../translations/translations';
import { DialogService } from '../shared/dialog.service';
import { UserService } from '../user/user.service';
import { BaseService, IntervareHttpErrorResponse } from './base-service';

interface IUpdateProductSubjects {
    [key: string]: Subject<number>;
}

export type BasketStates = IBasketViewModel | 'impersonate' | 'empty' | undefined;

@Injectable()
export class BasketService extends BaseService {
    private _basket = new BehaviorSubject<BasketStates>(undefined);
    basket$ = this._basket.asObservable();

    isEmpty = true;

    private _updateProductSubjects: IUpdateProductSubjects = {};

    private user: IUserViewModel | undefined;

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private dialogService: DialogService,
        private datePipe: DatePipe
    ) {
        super();

        this.userService.user$.subscribe(user => {
            this.user = user;
            this._updateBasket();
            Object.entries(this._updateProductSubjects).forEach(x => x[1].unsubscribe());
            this._updateProductSubjects = {};
        });
    }

    // If update of basket want to be forced, getCurrentUser on the userService instead
    private _updateBasket() {

        this.isEmpty = true;

        if (this.user) {
            if (this.user.basketId !== 0) {
                this._getBasket();
            }
            else {
                this.user.roles && this.user.roles.indexOf('Citizen') !== -1 || this.user.impersonatedCitizenQuickInfo ? this._basket.next('empty') : this._basket.next('impersonate');
            }
        }
        else {
            this._basket.next(undefined);
        }
    }

    addToBasket(productNo: string, newQuantity: number): void {
        if (!this._updateProductSubjects[productNo]) {
            this._updateProductSubjects[productNo] = new Subject();
            this._updateProductSubjects[productNo].pipe(
                map<number, IAddToBasketModel>(quantity => ({ productNo, quantity })),
                switchMap(basketData => this.http.put(this.apiBaseUrl('/basket/AddToBasket'), basketData).pipe(
                    catchError((err: IntervareHttpErrorResponse) => {
                        this.dialogService.showValidationResult(err.validationErrors);
                        return EMPTY;
                    })
                )),
                map(BasketViewModel.fromJS)
            ).subscribe(basket => this._nextBasket(basket));
        }

        this._updateProductSubjects[productNo].next(newQuantity);
    }

    addCatalogItemToBasket(catalogItemNo: string, quantity: number): void {
        const basketData: IAddCatalogItemToBasketModel = {
            catalogItemNo,
            quantity
        };

        this.http.put(this.apiBaseUrl('/basket/AddCatalogItemToBasket'), basketData).pipe(
            map(BasketViewModel.fromJS),
            catchError((err: IntervareHttpErrorResponse) => {
                this.dialogService.showValidationResult(err.validationErrors);
                return EMPTY;
            })
        ).subscribe(basket => this._nextBasket(basket));
    }

    clearBasket() {
        this.http.put(this.apiBaseUrl('/basket/ClearBasket'), {}).pipe(
            map(BasketViewModel.fromJS)
        ).subscribe(basket => this._nextBasket(basket), (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors));
    }

    getAllowedDeliveryDates(): Observable<IAllowedDeliveryDate[]> {
        return this.http.get(this.apiBaseUrl('/basket/GetAllowedDeliveryDates')).pipe(
            map(allowedDeliveryDates => (allowedDeliveryDates as any[]).map(AllowedDeliveryDate.fromJS))
        );
    }

    copyOrderToBasket(orderNo: string) {
        const crudModel: IOrderCrudViewModel = { orderNo: +orderNo };
        return this.http.post(this.apiBaseUrl('/basket/CopyOrderToBasket'), crudModel).pipe(
            map(BasketViewModel.fromJS),
            tap(basket => this._nextBasket(basket), (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors))
        );
    }

    private _getBasket() {
        this.http.get(this.apiBaseUrl('/basket/GetBasket')).pipe(
            map(BasketViewModel.fromJS)
        ).subscribe(basket => this._nextBasket(basket), (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors));
    }

    _nextBasket(basket: IBasketViewModel) {
        if (basket.errorMessages) {
            this.dialogService.showValidationResult(basket.errorMessages);
        }

        try {
            const now = new Date().getTime();
            const storageKey = 'latestOrderTime';
            let stored = sessionStorage.getItem(storageKey);
            if (stored && (new Date(now).getDate() !== new Date(Date.parse(stored)).getDate())) {
                sessionStorage.removeItem(storageKey);
                stored = sessionStorage.getItem(storageKey);
            }
            if (stored) {
                if (now > Date.parse(stored)) {
                    this.dialogService.showMessage(
                        Translations.commerce.basket.latestOrderTime.expiredText,
                        Translations.commerce.basket.latestOrderTime.expiredHeader
                    ).afterClosed().toPromise().then(() => sessionStorage.removeItem(storageKey));
                }
            }
            else {
                if (now >= basket.latestOrderWarningTime.getTime() && now <= basket.latestOrderTime.getTime()) {
                    this.dialogService.showMessage(
                        Translations.replaceTokens(Translations.commerce.basket.latestOrderTime.text, this.datePipe.transform(basket.latestOrderTime, 'shortTime')),
                        Translations.commerce.basket.latestOrderTime.header
                    ).afterClosed().toPromise().then(() => sessionStorage.setItem(storageKey, basket.latestOrderTime.toUTCString()));
                }
            }
        }
        catch { }

        if (basket.deliveryDate) {
            this.userService.updateDeliveryDate(basket.deliveryDate);
        }

        if (basket.rows && basket.rows.length) {
            // save the status of the basket
            this.isEmpty = false;

            this._basket.next(basket);
        }
        else {
            // save the status of the basket
            this.isEmpty = true;

            this._basket.next('empty');
        }
    }

    getTopNotInBasket(): Observable<IProductListViewModel> {
        return this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                return this.http.get(this.apiBaseUrl('/basket/TopNotInBasket'), {
                    params: {
                        deliveryDate,
                        take: '3'
                    }
                }).pipe(map(ProductListViewModel.fromJS));
            })
        );
    }

    setDeliveryDate(setDeliveryDateViewModel: ISetDeliveryDateViewModel) {
        return this.http.post(this.apiBaseUrl('/Basket/SetDeliveryDate'), setDeliveryDateViewModel).pipe(
            map(BasketViewModel.fromJS)
        );
    }
}
