import { DatePipe, NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CallListAdminService } from '../services/call-list-admin.service';
import { DialogService } from '../shared/dialog.service';
import { UtilService } from '../util/util.service';
import { MatButton } from '@angular/material/button';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'iv-edit-call-day',
    template: `
        <form (submit)="onSubmit()" #form="ngForm">
            <h1 mat-dialog-title>{{ editMode ? '${Translations.callListAdmin.editCallDay.edit}' : '${Translations.callListAdmin.editCallDay.add}'}}${Translations.callListAdmin.editCallDay.header}</h1>
            <mat-dialog-content class="low-density-dialog-content">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="${Translations.callListAdmin.editCallDay.callDayDate}"
                        [value]="today | date : 'shortDate'"
                        disabled
                        name="callDayDate"
                    />
                </mat-form-field>
                <br />
                <mat-checkbox [checked]="true" name="callInAdvanceAllowed" disabled
                    >${Translations.callListAdmin.editCallDay.callInAdvanceAllowed}</mat-checkbox
                >
                <br />
                <mat-checkbox [(ngModel)]="includeExtraDay" name="includeExtraDay"
                    >${Translations.callListAdmin.editCallDay.includeExtraDay}</mat-checkbox
                >
                <br />
                <mat-form-field *ngIf="includeExtraDay">
                    <input
                        matInput
                        [matDatepicker]="picker"
                        placeholder="${Translations.callListAdmin.editCallDay.moveCallsFrom}"
                        [(ngModel)]="includeExtraDayDate"
                        name="includeExtraDayDate"
                        required
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </mat-dialog-content>
            <mat-dialog-actions class="alt-theme">
                <button mat-raised-button color="primary" [disabled]="!form.valid">
                    ${Translations.callListAdmin.editCallDay.okBtn}
                </button>
                <button mat-raised-button type="button" mat-dialog-close>
                    ${Translations.callListAdmin.editCallDay.cancelBtn}
                </button>
            </mat-dialog-actions>
        </form>
    `,
    standalone: true,
    imports: [FormsModule, MatDialogTitle, MatDialogContent, MatFormField, MatInput, MatCheckbox, NgIf, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, MatDialogActions, MatButton, MatDialogClose, DatePipe]
})
export class EditCallDayComponent implements OnInit, OnDestroy {
    id?: string;
    editMode = false;
    today = new Date();
    includeExtraDay = false;
    includeExtraDayDate?: Date;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: string,
        private dialogService: DialogService,
        private callListAdminService: CallListAdminService,
        private utilService: UtilService,
        private router: Router,
        private date: DatePipe
    ) {}

    ngOnInit() {
        // initial data is always from call-day.resolver (either specific day or "new")
        if (this.data !== 'new') {
            this.id = this.data;
            this.editMode = true;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    onSubmit() {
        this.callListAdminService
            .saveCallDay(
                this.id,
                this.includeExtraDay
                    ? this.date.transform(this.includeExtraDayDate, 'yyyy-MM-dd') || undefined
                    : undefined
            )
            .pipe(takeUntil(this.unsubscribeS$))
            .subscribe(
                () => {
                    if (this.utilService.isBrowser()) {
                        this.router
                            // eslint-disable-next-line no-null/no-null
                            .navigate([{ outlets: { dialog: null } }])
                            // eslint-disable-next-line no-self-assign
                            .then(() => (location.href = location.href));
                    }
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }
}
