import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgFor, NgIf, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

// Translation
import { Translations } from '../../translations/translations';

// Interfaces
import { IPdaOrderStatisticsFilter, IPdaOrderStatisticsMunicipality, ISkipTakeResultObjectOfUser, IUser, PdaOrderStatisticsDistrict, PdaOrderStatisticsMunicipality } from '../../api/services';

import { HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { excel } from '../../scripts/generated/icons';
import { FormBuilderDatepicker, FormBuilderMunicipalityOutput, FormBuilderMunicipalityPicker, FormBuilderOption, FormBuilderSelect, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { toFormOptions } from '../form-builder/form-builder-mappings';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { OrderService } from '../services/order.service';
import { IDENTITIES, IUserRoles, ROLES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { UtilService } from '../util/util.service';
import { IUserDefaultMunicipalities, MunicipalityService } from './municipality.service';
import { DynamicTableComponent } from '../shared/dynamic-table.component';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatAnchor } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';

@Component({
    selector: 'iv-municipality-pda-statistics',
    template: `
        <section class="municipality-pda-statistics" *ngIf="!isFetching; else fetching">
            <form *ngIf="form" [formGroup]="form" class="alt-theme form" autocomplete="off">
                <iv-form-builder-element
                    [form]="form"
                    [input]="input"
                    *ngFor="let input of inputs"
                ></iv-form-builder-element>
            </form>

            <div class="errors" *ngIf="validationErrors">
                <h4 *ngFor="let error of validationErrors">{{ error }}</h4>
            </div>

            <div class="municipality-pda-statistics__loading" *ngIf="isLoadingResults">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="2"
                    [diameter]="30"
                ></mat-progress-spinner>
            </div>

            <ng-container
                *ngIf="!isLoadingResults && pdaOrderStatistics && pdaOrderStatistics.length >= 1; else noResults"
            >
                <a
                    href="${environment.apiBaseUrl}/api/order/ExportPdaOrderStatistics?{{ params }}"
                    target="_blank"
                    class="btn-with-icon btn-with-icon_last"
                    mat-raised-button
                    color="primary"
                >
                    ${Translations.municipality.common.exportToExcel}<span class="icon">${excel}</span>
                </a>

                <mat-accordion class="municipality-pda-statistics__expansion">
                    <mat-expansion-panel
                        [expanded]="pdaOrderStatistics.length === 1"
                        *ngFor="let item of pdaOrderStatistics"
                        hideToggle="true"
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title
                            >{{ item.name }} (${Translations.municipality.pdaStatistics.table.totalOrders}:
                                {{ item.totalOrders }})
                            </mat-panel-title
                            >
                            <mat-panel-description>
                                <span class="municipality-pda-statistics__expansion-pda">
                                    ${Translations.municipality.pdaStatistics.PDA}
                                    ${Translations.municipality.pdaStatistics.table.numberOfPdaOrders}:
                                    {{ item.pdaOrders }} |
                                    ${Translations.municipality.pdaStatistics.table.numberOfTransfers}:
                                    {{ item.pdaTransfers }} |
                                    ${Translations.municipality.pdaStatistics.table.totalOfPdaOrdersAndTransfers}:
                                    {{ item.totalOfPdaOrdersAndTransfers }} |
                                    ${Translations.municipality.pdaStatistics.table.percentageOfPdaOrders}:
                                    {{ item.percentageOfPdaOrders | percent }}
                                </span>
                                <span class="municipality-pda-statistics__expansion-fax">
                                    ${Translations.municipality.pdaStatistics.FAX}
                                    ${Translations.municipality.pdaStatistics.table.numberOfFaxOrders}:
                                    {{ item.faxOrders }} |
                                    ${Translations.municipality.pdaStatistics.table.customerServiceTransfers}:
                                    {{ item.customerServiceTransfers }} |
                                    ${Translations.municipality.pdaStatistics.table.percentageFaxOrders}:
                                    {{ item.percentageFaxOrders | percent }}
                                </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <iv-dynamic-table
                            [templateRef]="tpl"
                            [data]="item.children"
                            [columns]="displayedColumns"
                        ></iv-dynamic-table>
                    </mat-expansion-panel>
                </mat-accordion>
            </ng-container>
        </section>

        <ng-template #tpl let-row>
            <div class="mat-row" [@detailExpand]>
                <iv-dynamic-table [data]="row.children" [columns]="displayedColumns"></iv-dynamic-table>
            </div>
        </ng-template>

        <ng-template #noResults>
            <span *ngIf="!isLoadingResults">${Translations.municipality.common.noResults}</span>
        </ng-template>

        <ng-template #fetching>
            <div class="municipality-pda-statistics__loading">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="3"
                    [diameter]="60"
                ></mat-progress-spinner>
            </div>
        </ng-template>
    `,
    animations: [
        trigger('detailExpand', [
            state('void', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
            state('*', style({height: '*', visibility: 'visible'})),
            transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, FormBuilderElementComponent, MatProgressSpinner, MatAnchor, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, DynamicTableComponent, PercentPipe]
})
export class MunicipalityPdaStatisticsComponent implements OnInit, OnDestroy {
    isFetching = true;
    params: string;

    form: UntypedFormGroup;
    inputs: FormBuilderTypes[];
    validationErrors: string[] = [];
    isLoadingResults = false;

    formUsersOptions = new BehaviorSubject<FormBuilderOption[]>([]);

    displayedColumns = [
        {
            columnDef: 'name',
            header: Translations.municipality.pdaStatistics.table.name,
            cell: (row: IPdaOrderStatisticsMunicipality) => row.name
        },
        {
            columnDef: 'numberOfPdaOrders',
            header:
                Translations.municipality.pdaStatistics.PDA +
                ' ' +
                Translations.municipality.pdaStatistics.table.numberOfPdaOrders,
            cell: (row: IPdaOrderStatisticsMunicipality) => row.pdaOrders
        },
        {
            columnDef: 'numberOfTransfers',
            header:
                Translations.municipality.pdaStatistics.PDA +
                ' ' +
                Translations.municipality.pdaStatistics.table.numberOfTransfers,
            cell: (row: IPdaOrderStatisticsMunicipality) => row.pdaTransfers
        },
        {
            columnDef: 'totalOfPdaOrdersAndTransfers',
            header:
                Translations.municipality.pdaStatistics.PDA +
                ' ' +
                Translations.municipality.pdaStatistics.table.totalOfPdaOrdersAndTransfers,
            cell: (row: IPdaOrderStatisticsMunicipality) => row.totalOfPdaOrdersAndTransfers
        },
        {
            columnDef: 'percentageOfPdaOrdersAndTransfers',
            header: Translations.municipality.pdaStatistics.table.percentageOfPdaOrders,
            cell: (row: IPdaOrderStatisticsMunicipality) => this.percentPipe.transform(row.percentageOfPdaOrders)
        },
        {
            columnDef: 'numberOfFaxOrders',
            header:
                Translations.municipality.pdaStatistics.FAX +
                ' ' +
                Translations.municipality.pdaStatistics.table.numberOfFaxOrders,
            cell: (row: IPdaOrderStatisticsMunicipality) => row.faxOrders
        },
        {
            columnDef: 'customerServiceTransfers',
            header:
                Translations.municipality.pdaStatistics.FAX +
                ' ' +
                Translations.municipality.pdaStatistics.table.customerServiceTransfers,
            cell: (row: IPdaOrderStatisticsMunicipality) => row.customerServiceTransfers
        },
        {
            columnDef: 'percentageFaxOrders',
            header: Translations.municipality.pdaStatistics.table.percentageFaxOrders,
            cell: (row: IPdaOrderStatisticsMunicipality) => this.percentPipe.transform(row.percentageFaxOrders)
        },
        {
            columnDef: 'totalOrders',
            header: Translations.municipality.pdaStatistics.table.totalOrders,
            cell: (row: IPdaOrderStatisticsMunicipality) => row.totalOrders
        }
    ];

    pdaOrderStatistics: PdaOrderStatisticsDistrict[];

    private unsubscribe = new Subject();
    private _scrollContainer: HTMLElement | Window =
        (document.querySelector('#content > .util-hidden-scroll') as HTMLElement) || window;

    // eslint-disable-next-line no-prototype-builtins
    isExpansionDetailRow = (index: number, row: PdaOrderStatisticsMunicipality) => row.hasOwnProperty('detailRow');

    constructor(
        private percentPipe: PercentPipe,
        private userService: UserService,
        private orderService: OrderService,
        private formBuilder: FormBuilderService,
        private municipalityService: MunicipalityService,
        private utilService: UtilService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.utilService.scrollElementTo(this._scrollContainer, 0);
        // Get the user roles
        this.userService.user$
            .pipe(
                map(user => {
                    const defaultMunicipalites: IUserDefaultMunicipalities = {
                        municipality: 0,
                        districts: [],
                        subdistricts: []
                    };

                    if (user && user.roles && user.roles.indexOf(ROLES.MunicipalityAdmin) !== -1) {
                        defaultMunicipalites.municipality = user.municipalities ? user.municipalities[0] : 0;
                        defaultMunicipalites.districts = user.districts ? user.districts : 0;
                        defaultMunicipalites.subdistricts = user.subDistricts ? user.subDistricts : [];
                    }

                    return defaultMunicipalites;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(results => {
                // Check if we have query params and fill or not the form;
                this._buildForm(results);
            });

        this.form?.valueChanges
            .pipe(
                distinctUntilChanged(),
                tap(() => {
                    this.validationErrors = [];
                    this.isLoadingResults = true;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const params: Record<string, string | string[]> = {};
                    if (this.form.value.districts.municipality) {
                        params.municipalities = this.form.value.districts.municipality;
                    }
                    if (this.form.value.districts.districts && this.form.value.districts.districts.length > 0) {
                        params.districts = this.form.value.districts.districts;
                    }
                    if (this.form.value.districts.subdistricts && this.form.value.districts.subdistricts.length > 0) {
                        params.subDistricts = this.form.value.districts.subdistricts;
                    }
                    if (this.form.value.users && this.form.value.users.length > 0) {
                        params.users = this.form.value.users;
                    }
                    if (this.form.value.dateFrom) {
                        params.dateFrom = new Date(this.form.value.dateFrom).toISOString();
                    }
                    if (this.form.value.dateTo) {
                        params.dateTo = new Date(this.form.value.dateTo).toISOString();
                    }
                    this.params = new HttpParams({fromObject: params}).toString();
                }),
                switchMap(value => this._getUsersByDistrict(value.districts)),
                switchMap((users: ISkipTakeResultObjectOfUser) => {
                    this._updateFormUsers(users.results);

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const formResult: any = this.form.value;

                    const formValue: IPdaOrderStatisticsFilter = {
                        municipalities: formResult.districts.municipality,
                        districts: formResult.districts.districts,
                        subDistricts: formResult.districts.subdistricts,
                        users: formResult.users,
                        dateFrom: formResult.dateFrom,
                        dateTo: formResult.dateTo
                    };

                    return this._getStatistics(formValue);
                }),
                catchError((err, caught) => {
                    this.handleWithErrors(err);
                    return caught;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                {
                    next: (statistics: PdaOrderStatisticsDistrict[]) => {
                        this.isLoadingResults = false;
                        this.isFetching = false;
                        this.pdaOrderStatistics = statistics;

                        this.cd.markForCheck();
                    },
                    error: (err: IntervareHttpErrorResponse) => {
                        this.handleWithErrors(err);
                    }
                }
            );

        this.isFetching = false;
    }

    ngOnDestroy() {
        this.unsubscribe.next({});
        this.unsubscribe.complete();
    }

    private handleWithErrors(err: IntervareHttpErrorResponse) {
        this.validationErrors = [];

        if (err.validationErrors && err.validationErrors.length > 0) {
            this.validationErrors = err.validationErrors;
        } else {
            this.validationErrors.push(err.error.statusText);
        }

        this.isLoadingResults = false;
        this.pdaOrderStatistics = [];
        this.cd.markForCheck();
    }

    private _getUsersByDistrict(
        municipalities: FormBuilderMunicipalityOutput
    ): Observable<ISkipTakeResultObjectOfUser> {
        if (
            municipalities.municipality &&
            municipalities.municipality > 0 &&
            municipalities.districts &&
            !Number.isNaN(municipalities.districts) &&
            (municipalities.districts as number) > 0
        ) {
            return this.municipalityService.getUsers(
                municipalities.municipality,
                municipalities.districts,
                municipalities.subdistricts,
                0,
                9999
            );
        }

        return of<ISkipTakeResultObjectOfUser>({numFound: 0, results: []});
    }

    private _updateFormUsers(users: IUser[] = []): void {
        this.formUsersOptions.next(users.map(user => toFormOptions(user.userId, user.name)));
    }

    private _getStatistics(params: IPdaOrderStatisticsFilter): Observable<PdaOrderStatisticsDistrict[]> {
        if (params.dateFrom && params.dateTo) {
            return this.orderService.getOrderPdaStatistics(params);
        }

        return of<PdaOrderStatisticsDistrict[]>([]);
    }

    private _buildForm(municipalities?: IUserDefaultMunicipalities) {
        const roles = this.userService.user$.getValue()!.roles as IUserRoles[];

        this.inputs = [
            new FormBuilderDatepicker({
                name: 'dateFrom',
                label: Translations.municipality.common.dateFrom,
                required: true
            }),
            new FormBuilderDatepicker({
                name: 'dateTo',
                label: Translations.municipality.common.dateTo,
                required: true
            }),
            new FormBuilderMunicipalityPicker({
                name: 'districts',
                multipleDistricts: true,
                multipleSubdistricts: true,
                allowEmptyValue: roles.some(x => IDENTITIES.adminAndCustomerService.includes(x)),
                value: municipalities,
                required: false
            }),
            new FormBuilderSelect({
                name: 'users',
                label: Translations.municipality.pdaStatistics.users,
                multiple: true,
                options: this.formUsersOptions
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }
}
