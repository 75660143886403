import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CustomerSubType } from '../../api/services';
import { Translations } from '../../translations/translations';
import { UserService } from '../user/user.service';
import { NgIf } from '@angular/common';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { UtilHiddenScrollComponent } from '../util/util-hidden-scroll.component';

@Component({
    selector: 'iv-municipality-admin-menu',
    template: `
        <iv-util-hidden-scroll height="100vh">
            <nav class="category-menu" role="navigation">
                <div class="category-menu__level">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.municipality.paths.subscribeCitizen.path}">
                        ${Translations.municipality.paths.subscribeCitizen.title}
                    </a>
                </div>
                <div class="category-menu__level">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.municipality.paths.editCitizen.path}">
                        ${Translations.municipality.paths.editCitizen.title}
                    </a>
                </div>
                <div class="category-menu__level" *ngIf="isTastSelv">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.municipality.paths.editPasswordForCitizen.path}">
                        ${Translations.municipality.paths.editPasswordForCitizen.title}
                    </a>
                </div>
                <div class="category-menu__level">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.municipality.paths.citizenDriverMessages.path}">
                        ${Translations.municipality.paths.citizenDriverMessages.title}
                    </a>
                </div>
                <!-- According to INT-1435 -->
                <!-- <div class="category-menu__level">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.municipality.paths.temporaryAddress.path}">
                        ${Translations.municipality.paths.temporaryAddress.title}
                    </a>
                </div> -->
                <div class="category-menu__level">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.municipality.paths.citizenPayment.path}">
                        ${Translations.municipality.paths.citizenPayment.title}
                    </a>
                </div>
                <div class="category-menu__level">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.shop.paths.discountPapers.path}">
                        ${Translations.shop.paths.discountPapers.title}
                    </a>
                </div>
                <div class="category-menu__level">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.municipality.paths.deliveryDate.path}">
                        ${Translations.municipality.paths.deliveryDate.title}
                    </a>
                </div>
                <div class="category-menu__level">
                    <a class="category-menu__link"
                       routerLinkActive="category-menu__link_active"
                       routerLink="/${Translations.municipality.paths.createInternalUser.path}">
                        ${Translations.municipality.paths.createInternalUser.title}
                    </a>
                </div>
                <div class="category-menu__level">
                    <a class="category-menu__link"
                       routerLinkActive="category-menu__link_active"
                       routerLink="/${Translations.municipality.paths.listInternalUsers.path}">
                        ${Translations.municipality.paths.listInternalUsers.title}
                    </a>
                </div>
                <div class="category-menu__level">
                    <a class="category-menu__link"
                       routerLinkActive="category-menu__link_active"
                       routerLink="/${Translations.municipality.paths.userHistory.path}">
                        ${Translations.municipality.paths.userHistory.title}
                    </a>
                </div>
            </nav>
        </iv-util-hidden-scroll>
    `,
    standalone: true,
    imports: [UtilHiddenScrollComponent, RouterLinkActive, RouterLink, NgIf]
})
export class MunicipalityAdminMenuComponent implements OnInit, OnDestroy {
    isTastSelv = false;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.userService.isImpersonating.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(quickInfo => this.isTastSelv = quickInfo ? quickInfo.customerSubType === CustomerSubType.TastSelvBorger : false);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
