import { Pipe, PipeTransform } from '@angular/core';

import { Translations } from '../../translations/translations';

@Pipe({
    name: 'translation',
    standalone: true
})
export class TranslationPipe implements PipeTransform {
    transform(translation: string, ...tokens: any[]): string {
        return Translations.replaceTokens(translation, ...tokens);
    }
}
