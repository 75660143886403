import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'chunk',
    standalone: true
})
export class ChunkPipe implements PipeTransform {
    transform(value: string | undefined, n = 2): string {
        if (value === undefined) {
            return '';
        }

        const ret: string[] = [];

        for (let i = 0; i < value.length; i += n) {
            ret.push(value.substring(i, i + n));
        }

        return ret.join(' ');
    }
}
