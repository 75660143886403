import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includesDataValue',
    pure: false,
    standalone: true
})
export class IncludesDataValuePipe implements PipeTransform {
    public transform(data: unknown[], value: string | number): boolean {

        if (!value || !data) {
            return false;
        }

        if (Array.isArray(data) && data.length > 0) {
            if (typeof value === 'number') {
                return data.includes(value);
            } else {
                return data.includes(value);
            }
        }
        return false;
    }
}
