import { Directive, HostBinding, Input, OnInit } from '@angular/core';

import { ISimpleCallRecord, ISimpleCitizenDetail } from '../../../api/services';

@Directive({
    selector: '[ivCitizenColor]',
    standalone: true
})
export class CitizenColorDirective implements OnInit {

    @Input('ivCitizenColor')
    citizen?: ISimpleCitizenDetail | ISimpleCallRecord;

    @HostBinding('style.color')
    color?: string;

    ngOnInit() {
        if (this.citizen) {
            if (this.citizen.isMoved) {
                this.color = 'red';
            }

            else if (this.citizen.isBiWeekly) {
                this.color = 'green';
            }

            else if (this.citizen.isPreferedDay === false) {
                this.color = 'blue';
            }
        }
    }
}
