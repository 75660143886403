import { AsyncPipe, JsonPipe, LowerCasePipe, NgIf } from '@angular/common';
import { Component, computed, OnDestroy, OnInit, Signal } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatChip } from '@angular/material/chips';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import {
    brugeradmin,
    callerList,
    logOut,
    municipalityportal,
    nemligLogo,
    search,
    settings,
    stock
} from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { AuthService } from '../services/auth.service';
import { CallListService, ICallListDataModel } from '../services/call-list.service';
import { CallerPresenceDirective } from '../services/calls/caller-presence.directive';
import { CallerPresence, PresenceStatus, PresenceStatusEnum } from '../services/calls/calls.models';
import { CallsService } from '../services/calls/calls.service';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { TranslationPipe } from '../shared/translation.pipe';
import { IDENTITIES, IUserRoles, ROLES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { UtilHiddenScrollComponent } from '../util/util-hidden-scroll.component';
import { AdministrationCallInfoComponent } from './administration-call-info.component';

@Component({
    selector: 'iv-administration-menu',
    template: `
        <div class="administration-section">
            <div class="administration-menu">
                <iv-administration-call-info *ngIf="canImpersonate"></iv-administration-call-info>

                <ul class="administration-menu__list">
                    <iv-util-hidden-scroll class="administration-menu__hidden-scroll" height="100%">
                        <li *ngIf="callListAccess" class="administration-menu__list-item">
                            <a
                                mat-button
                                class="administration-menu__list-item-link"
                                routerLinkActive="active"
                                [routerLink]="[{ outlets: { admin: 'calllist' } }]"
                                title="${Translations.administration.menu.callList}"
                            >
                                <div class="administration-menu__list-item-icon" aria-hidden="true">${callerList}</div>
                                <h4 class="administration-menu__list-item-text">
                                    ${Translations.administration.menu.callList}
                                </h4>
                                <ng-container *ngIf="callListStatus$ | async as status">
                                    <mat-chip
                                        *ngIf="status.incompleteCallCount"
                                        class="administration-menu__list-item-call-count"
                                        color="warn"
                                        selected="true"
                                    >
                                        {{ status.incompleteCallCount }}/{{ status.totalCallCount }}
                                    </mat-chip>
                                </ng-container>
                            </a>
                        </li>
                        <li *ngIf="customerSearchAccess" class="administration-menu__list-item">
                            <a
                                mat-button
                                class="administration-menu__list-item-link"
                                routerLinkActive="active"
                                [routerLink]="[{ outlets: { admin: ['customersearch'] } }]"
                                title="${Translations.administration.menu.searchCustomer}"
                            >
                                <div class="administration-menu__list-item-icon" aria-hidden="true">${search}</div>
                                <h4 class="administration-menu__list-item-text">
                                    ${Translations.administration.menu.searchCustomer}
                                </h4>
                            </a>
                        </li>
                        <li *ngIf="municipalityPortalAccess" class="administration-menu__list-item">
                            <a
                                mat-button
                                class="administration-menu__list-item-link"
                                routerLinkActive="active"
                                [routerLink]="[{ outlets: { admin: ['municipalityportal'] } }]"
                                title="${Translations.administration.menu.municipilityportal}"
                            >
                                <div class="administration-menu__list-item-icon" aria-hidden="true">
                                    ${municipalityportal}
                                </div>
                                <h4 class="administration-menu__list-item-text">
                                    ${Translations.administration.menu.municipilityportal}
                                </h4>
                            </a>
                        </li>
                        <li *ngIf="municipalityAdminAccess" class="administration-menu__list-item">
                            <a
                                mat-button
                                class="administration-menu__list-item-link"
                                routerLinkActive="active"
                                [routerLink]="[{ outlets: { admin: ['municipalityadmin'] } }]"
                                title="${Translations.administration.menu.municipalityadmin}"
                            >
                                <div class="administration-menu__list-item-icon" aria-hidden="true">${brugeradmin}</div>
                                <h4 class="administration-menu__list-item-text">
                                    ${Translations.administration.menu.municipalityadmin}
                                </h4>
                            </a>
                        </li>
                        <li *ngIf="searchNemligAccess" class="administration-menu__list-item">
                            <a
                                mat-button
                                class="administration-menu__list-item-link"
                                routerLinkActive="active"
                                [routerLink]="[{ outlets: { admin: ['nemlig'] } }]"
                                title="${Translations.administration.menu.searchNemlig}"
                            >
                                <div class="administration-menu__list-item-icon" aria-hidden="true">${nemligLogo}</div>
                                <h4 class="administration-menu__list-item-text">
                                    ${Translations.administration.menu.searchNemlig}
                                </h4>
                            </a>
                        </li>
                        <li *ngIf="stockViewAccess" class="administration-menu__list-item">
                            <a
                                mat-button
                                class="administration-menu__list-item-link"
                                routerLinkActive="active"
                                [routerLink]="[{ outlets: { admin: ['stock'] } }]"
                                title="${Translations.administration.menu.stock}"
                            >
                                <div class="administration-menu__list-item-icon" aria-hidden="true">${stock}</div>
                                <h4 class="administration-menu__list-item-text">
                                    ${Translations.administration.menu.stock}
                                </h4>
                            </a>
                        </li>
                        <li *ngIf="intervareAccess" class="administration-menu__list-item">
                            <a
                                mat-button
                                class="administration-menu__list-item-link"
                                routerLinkActive="active"
                                [routerLink]="[{ outlets: { admin: ['intervare'] } }]"
                                title="${Translations.administration.menu.intervare}"
                            >
                                <div class="administration-menu__list-item-icon" aria-hidden="true">${settings}</div>
                                <h4 class="administration-menu__list-item-text">
                                    ${Translations.administration.menu.intervare}
                                </h4>
                            </a>
                        </li>
                        <li class="administration-menu__list-item">
                            <a
                                mat-button
                                class="administration-menu__list-item-link"
                                title="${Translations.administration.menu.logOut}"
                                (click)="logoutUser()"
                            >
                                <div class="administration-menu__list-item-icon" aria-hidden="true">${logOut}</div>
                                <h4 class="administration-menu__list-item-text">
                                    ${Translations.administration.menu.logOut}
                                </h4>
                            </a>
                        </li>
                    </iv-util-hidden-scroll>
                </ul>

                <div class="administration-menu__profile">
                    <div
                        class="administration-menu__profile-img-wrapper"
                        aria-hidden="true"
                        ivCallerPresence
                        [isCallerProfile]="isCallerProfile"
                        [matTooltip]="callerStatus()?.message"
                    >
                        <img
                            class="administration-menu__profile-img"
                            [alt]="profileName"
                            srcset="{{ profileImage }} 1x, {{ profileImageHd }} 2x"
                            [src]="profileImage"
                            width="100"
                            height="100"
                            loading="eager"
                        />
                    </div>
                    <h3
                        class="administration-menu__profile-name"
                        aria-label="${Translations.administration.ariaProfileName}"
                    >
                        {{ profileName }}
                    </h3>

                    @if (isCallerProfile) {
                        <div class="administration-menu__profile-status">
                            @if (callerStatus(); as status) {
                                <iv-progress-button
                                    buttonType="button"
                                    color="accent"
                                    [callback]="changeCallerStatus.bind(this, status.status, status.id)"
                                >
                                    @if (status.status === PresenceStatusEnum.Unknown) {
                                        {{ '${Translations.callListAdmin.callerPresence.gettingStatus}' }}
                                    } @else {
                                        {{
                                            '${Translations.callListAdmin.callerPresence.currentStatusLabel}'
                                                | translation : status.label
                                        }}
                                    }
                                </iv-progress-button>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    `,
    standalone: true,
    imports: [
        NgIf,
        AdministrationCallInfoComponent,
        UtilHiddenScrollComponent,
        MatAnchor,
        RouterLinkActive,
        RouterLink,
        MatChip,
        AsyncPipe,
        CallerPresenceDirective,
        TranslationPipe,
        ProgressButtonComponent,
        MatTooltip,
        LowerCasePipe,
        JsonPipe
    ]
})
export class AdministrationMenuComponent implements OnInit, OnDestroy {
    callListAccess = false;
    customerSearchAccess = false;
    municipalityPortalAccess = false;
    searchNemligAccess = false;
    stockViewAccess = false;
    intervareAccess = false;
    municipalityAdminAccess = false;
    canImpersonate = false;
    isCallerProfile: boolean = false;
    profileImage = '';
    profileImageHd = '';
    profileName = '';
    callListStatus$: Observable<ICallListDataModel>;
    callerStatus: Signal<CallerPresence> = computed(() => {
        const caller = this.callsService.getCallerStatus();
        return {
            id: caller?.id ?? '',
            message: caller?.message ?? '',
            label:
                caller.status === 'OutOfOffice'
                    ? Translations.callListAdmin.callerPresence.status.OutOfOffice
                    : this.callsService.getPresenceStatusDefinitions().find(x => x.id === caller?.id)?.label || '',
            status: (caller?.status ?? 'Offline') as PresenceStatus
        };
    });
    protected readonly Translations = Translations;
    protected readonly PresenceStatusEnum = PresenceStatusEnum;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private authService: AuthService,
        private _callListService: CallListService,
        private router: Router,
        private userService: UserService,
        public callsService: CallsService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        combineLatest([this.userService.user$, this.activatedRoute.queryParams])
            .pipe(
                switchMap(([user, queryParams]) => {
                    if (user && user.roles) {
                        const userRoles = user.roles as IUserRoles[];
                        if (userRoles.some(x => IDENTITIES.callList.includes(x))) {
                            return this.callsService.checkCallStatus(queryParams, user).pipe(map(() => user));
                        }
                    }

                    return of(user);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(user => {
                if (user && user.roles) {
                    const userRoles = user.roles as IUserRoles[];

                    this.callListAccess = userRoles.some(x => IDENTITIES.callList.includes(x));
                    this.municipalityPortalAccess = userRoles.some(x => IDENTITIES.municipalityPortal.includes(x));
                    this.municipalityAdminAccess = userRoles.some(x => IDENTITIES.municipalityAdmin.includes(x));
                    this.searchNemligAccess = userRoles.some(x => IDENTITIES.searchNemlig.includes(x));
                    this.stockViewAccess = userRoles.some(x => IDENTITIES.stockView.includes(x));
                    this.intervareAccess =
                        this.customerSearchAccess =
                            this.canImpersonate =
                                userRoles.some(x => IDENTITIES.adminAndCustomerService.includes(x));

                    // Disabled until further notice
                    this.searchNemligAccess = false;
                    this.stockViewAccess = false;

                    this.profileImage = this.generateProfileImage(user.gravatarHash || 'default');
                    this.profileImageHd = this.generateProfileImage(user.gravatarHash || 'default', 200);
                    this.profileName = user.name!;
                    this.isCallerProfile = user.hasCallerProfile;

                    // eslint-disable-next-line no-useless-escape
                    const testOutlet = /\(.*?admin\:.*?\)/;
                    if (window && window.location && !testOutlet.test(window.location.pathname)) {
                        // check if the user have Customer Service role;
                        const isCustomerService = userRoles.includes(ROLES.CustomerService);

                        let adminRoute = 'calllist';
                        if (!this.callListAccess) {
                            adminRoute = 'municipalityportal';
                        }

                        if (this.callListAccess && isCustomerService) {
                            adminRoute = 'customersearch';
                        }

                        this.router.navigate([{ outlets: { admin: adminRoute } }]);
                    }
                }
            });

        this.callListStatus$ = this._callListService.callListStatus$.pipe(
            takeUntil(this.unsubscribe),
            catchError(() => of({ completedCallCount: 0, incompleteCallCount: 0, totalCallCount: 0 }))
        );
    }

    generateProfileImage(gravatarHash: string, size = 100): string {
        return `https://www.gravatar.com/avatar/${gravatarHash}?d=mm&r=g&s=${size}`;
    }

    logoutUser() {
        this.authService
            .logout()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.router.navigate(['/']);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    changeCallerStatus = async (status: PresenceStatusEnum, id: string): Promise<boolean> => {
        return await this.callsService.setCallerStatus(status, id);
    };
}
