import { OrderHistoryRowViewModel } from 'Client/api/services';
import { IPriceAdjustmentOrderReturnLine } from 'Client/app/citizen/citizen-price-adjustment-dialog.component';

export const getOrdersRowQuantity = (row: OrderHistoryRowViewModel): number => {
    return row?.quantity !== row?.quantityDelivered ? (row?.quantityDelivered ?? row?.quantity) || 0 : row?.quantity;
};

export const getNewTotalPrice = (
    formGroup: Record<string, IPriceAdjustmentOrderReturnLine>,
    row: OrderHistoryRowViewModel
): number => {
    const productId = row.bottleRelatesToProduct ? row.lineType + row.bottleRelatesToProduct : row.productNo;

    if (!productId || !formGroup[productId]?.newUnitPrice) {
        return 0;
    }

    return Math.max(0, formGroup[productId]?.newUnitPrice * getOrdersRowQuantity(row));
};
