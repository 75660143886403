import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { ICitizenQuickInfo, IUserViewModel } from '../../api/services';
import { UtilService } from '../util/util.service';
import { IUserRoles } from './user-roles';

@Injectable()
export class UserService {
    user$ = new BehaviorSubject<IUserViewModel | undefined>(undefined);
    isLoggedIn = new ReplaySubject<boolean>(1);
    activeRoles = new ReplaySubject<IUserRoles[]>(1);
    isImpersonating = new ReplaySubject<ICitizenQuickInfo | undefined>(1);
    deliveryDate$ = new ReplaySubject<string>(1);

    private _userWasPreviously: IUserViewModel | undefined;
    private _hasBeenSetOnce = false;

    constructor(private utilService: UtilService) {}

    setUser(user: IUserViewModel | undefined): void {
        if (this._hasBeenSetOnce && this._userWasPreviously === user) {
            return;
        }

        this.user$.next(user);

        if (user !== undefined) {
            this.isLoggedIn.next(true);
            this.activeRoles.next(user.roles as IUserRoles[]);
            this.isImpersonating.next(user.impersonatedCitizenQuickInfo);

            if (user.nextDeliveryDate) {
                this.updateDeliveryDate(user.nextDeliveryDate);
            }
        } else {
            this.isLoggedIn.next(false);
            this.activeRoles.next([]);
            this.isImpersonating.next(undefined);
            this.updateDeliveryDate(new Date());
            if (this.utilService.isBrowser() && window.sessionStorage) {
                sessionStorage.removeItem('orderDate');
            }
        }

        this._userWasPreviously = user;
        this._hasBeenSetOnce = true;
    }

    updateDeliveryDate(date: Date) {
        const dateStr = this.utilService.dateToString(date);
        this.deliveryDate$.next(dateStr);
    }

    storeOrderDate(date: Date) {
        if (this.utilService.isBrowser() && window.sessionStorage) {
            const currentTime = date.toISOString();
            sessionStorage.setItem('orderDate', currentTime);
        }
    }

    clearOrderDate() {
        if (this.utilService.isBrowser() && window.sessionStorage) {
            sessionStorage.removeItem('orderDate');
        }
    }
}
