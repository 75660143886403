import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption, MatOptgroup } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { IDistrict, IMunicipality } from '../../../api/services';
import { Translations } from '../../../translations/translations';
import { MunicipalityService } from '../../municipality/municipality.service';
import { FormBuilderMunicipalityOutput } from '../form-builder-element.model';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'iv-form-builder-elements-municipality',
    template: `
        <mat-form-field class="form__element">
            <mat-label>{{ label.municipality }}</mat-label>
            <mat-select
                (selectionChange)="onMunicipalityChange($event.value, false, $event.source)"
                [value]="inputValue.municipality"
                [required]="required"
                [disabled]="isDisabled">

                <mat-option *ngIf="!required && allowEmptyValue" [value]="0">${Translations.forms.labels.municipality.none}</mat-option>

                <mat-option *ngFor="let municipality of municipalities | async" [value]="municipality.id">
                    {{ municipality.municipalityName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="form__element">
            <mat-label>{{ label.district }}</mat-label>
            <mat-select
                (selectionChange)="onDistrictChange($event.value, false, $event.source)"
                [disabled]="(districts | async)?.length < 1 || isDisabled"
                [multiple]="multipleDistricts"
                [value]="inputValue.districts"
                [required]="required">

                <mat-option *ngFor="let district of districts | async" [value]="district.id">
                    {{ district.districtName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="form__element">
            <mat-label>{{ label.subdistrict }}</mat-label>
            <mat-select
                (selectionChange)="onSubdistrictChange($event)"
                [disabled]="(subdistricts | async)?.length < 1 || isDisabled"
                [multiple]="multipleSubdistricts"
                [value]="inputValue.subdistricts"
                [required]="required">

                <mat-optgroup *ngFor="let district of subdistricts | async" [label]="district.districtName || ''">
                    <mat-option *ngFor="let subdistrict of district.subDistricts" [value]="subdistrict.id">
                        {{ subdistrict.subDistrictName }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormBuilderElementsMunicipalityComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [MatFormField, MatLabel, MatSelect, NgIf, MatOption, NgFor, MatOptgroup, AsyncPipe]
})
export class FormBuilderElementsMunicipalityComponent implements ControlValueAccessor, OnInit, OnDestroy {
    municipalities: Observable<IMunicipality[]>;
    districts: BehaviorSubject<IDistrict[]> = new BehaviorSubject<IDistrict[]>([]);
    subdistricts: Subject<IDistrict[]> = new Subject<IDistrict[]>();

    @Input() multipleDistricts = false;
    @Input() multipleSubdistricts = false;
    @Input() required = false;
    @Input() label: { municipality: string, district: string, subdistrict: string } = { municipality: '', district: '', subdistrict: '' };
    @Input() allowEmptyValue = false;
    @Input() filterDontUse = false;

    isDisabled = false;

    get inputValue() {
        return this._inputValue;
    }

    set inputValue(value) {
        if (value) {
            this._inputValue = value;
        }
        else {
            this._inputValue = {
                municipality: undefined,
                districts: this.multipleDistricts ? [] : undefined,
                subdistricts: this.multipleSubdistricts ? [] : undefined,
                selectedMunicipality: '',
                selectedDistricts: [],
                selectedSubdistricts: []
            };
        }
    }

    private _inputValue: FormBuilderMunicipalityOutput = {
        municipality: undefined,
        districts: this.multipleDistricts ? [] : undefined,
        subdistricts: this.multipleSubdistricts ? [] : undefined,
        selectedMunicipality: '',
        selectedDistricts: this.multipleDistricts ? [] : undefined,
        selectedSubdistricts: this.multipleSubdistricts ? [] : undefined
    };

    private readonly unsubscribe = new Subject<void>();

    constructor(private readonly municipalityService: MunicipalityService) { }

    ngOnInit(): void {
        this.municipalities = this.municipalityService.getMunicipalities().pipe(
            tap(() => {
                if (this.inputValue.municipality) {
                    this.onMunicipalityChange(this.inputValue.municipality, true);
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    // eslint-disable-next-line
    propagateChange = (_: any) => { };
    propagateTouched = () => { };

    // eslint-disable-next-line
    writeValue(value: any) {
        this.inputValue = value;
    }

    // eslint-disable-next-line
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    // eslint-disable-next-line
    registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onMunicipalityChange(value: number, firstLoad = false, source?: MatSelect) {
        if (!firstLoad) {
            this.inputValue = {
                ...this.inputValue,
                municipality: value,
                districts: this.multipleDistricts ? [] : undefined,
                subdistricts: this.multipleSubdistricts ? [] : undefined,
                selectedMunicipality: source && source.selected instanceof MatOption ? source.selected.getLabel() : '',
                selectedDistricts: this.multipleDistricts ? [] : undefined,
                selectedSubdistricts: this.multipleSubdistricts ? [] : undefined
            };
        }

        this.municipalityService.getDistricts(value).pipe(
            takeUntil(this.unsubscribe),
            map(res => {
                if (this.filterDontUse) {
                    return res.filter(x => x.districtName !== Translations.global.dontUse).filter(y => y.subDistricts!.filter(z => z.subDistrictName !== Translations.global.dontUse));
                }

                return res;
            })
        ).subscribe(res => {
            this.districts.next(res);
            this.subdistricts.next([]);

            if (firstLoad) {
                this.onDistrictChange(this.inputValue.districts, firstLoad);
            }
        });

        this.applyChange();
    }

    onDistrictChange(value: number | number[] | undefined, firstLoad = false, source?: MatSelect) {
        const districts = this.districts.getValue().filter(district => {
            if (typeof value === 'number') {
                return district.subDistricts && district.subDistricts.length > 0 && district.id === value;
            }

            return (
                value && value.indexOf(district.id) !== -1 &&
                typeof district.subDistricts !== 'undefined' &&
                district.subDistricts.length > 0
            );
        });

        let selectedSubdistricts: number[] = [];
        if (Array.isArray(this.inputValue.subdistricts)) {
            selectedSubdistricts = this.inputValue.subdistricts.filter(
                subdistrictId => {
                    const subdistrictIds: number[] = [];

                    districts.map(district => {
                        if (district.subDistricts) {
                            district.subDistricts.map(subdistrict => {
                                subdistrictIds.push(subdistrict.id);
                            });
                        }
                    });

                    return subdistrictIds.indexOf(subdistrictId) !== -1;
                }
            );
        }

        if (!firstLoad) {
            this.inputValue = {
                ...this.inputValue,
                districts: value,
                subdistricts: selectedSubdistricts.length ? selectedSubdistricts : undefined,
                selectedDistricts: source ? source.selected instanceof MatOption ? source.selected.getLabel() : source.selected.map(x => x.getLabel()) : [],
                selectedSubdistricts: this.multipleSubdistricts ? [] : undefined
            };
        }

        this.subdistricts.next(districts);
        this.applyChange();
    }

    onSubdistrictChange(event: MatSelectChange) {
        this.inputValue = {
            ...this.inputValue,
            subdistricts: event.value,
            selectedSubdistricts: event.source ? event.source.selected instanceof MatOption ? event.source.selected.getLabel() : event.source.selected.map(x => x.getLabel()) : [],
        };
        this.applyChange();
    }

    /**
     * Apply the current value to Angular Reactive form
     *
     * This is a shortcut for calling onChange and onTouched collectively
     */
    private applyChange(): void {
        this.propagateChange(this.inputValue);
        this.propagateTouched();
    }
}
