import { Component, OnDestroy } from '@angular/core';

import { Translations } from '../../translations/translations';
import { CallListAdminService } from '../services/call-list-admin.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'iv-display-callcount',
    template: `
    <form (submit)="onSubmit()">
        <h1 mat-dialog-title>${Translations.callListAdmin.displayCallcount.header}</h1>
        <mat-dialog-content class="low-density-dialog-content">
            <mat-form-field>
                <mat-select placeholder="${Translations.callListAdmin.displayCallcount.type}" [(ngModel)]="options.type" name="type">
                    <mat-option [value]="i" *ngFor="let type of citizenTypes; index as i">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
            <br/>
            <mat-form-field>
                <mat-select placeholder="${Translations.callListAdmin.displayCallcount.weekday}" [(ngModel)]="options.weekday" name="weekday">
                    <mat-option [value]="i" *ngFor="let day of weekdays; index as i">{{day}}</mat-option>
                </mat-select>
            </mat-form-field>
            <br/>
            <div *ngIf="result !== undefined">
                ${Translations.callListAdmin.displayCallcount.resultLabel}: {{ result }}
            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="alt-theme">
            <button mat-raised-button color="primary">${Translations.callListAdmin.displayCallcount.okBtn}</button>
            <button mat-raised-button type="button" mat-dialog-close>${Translations.callListAdmin.displayCallcount.cancelBtn}</button>
        </mat-dialog-actions>
    </form>
    `,
    standalone: true,
    imports: [FormsModule, MatDialogTitle, MatDialogContent, MatFormField, MatSelect, NgFor, MatOption, NgIf, MatDialogActions, MatButton, MatDialogClose]
})
export class DisplayCallcountComponent implements OnDestroy {

    result: number | undefined;

    options = {
        type: 0,
        weekday: 1
    };

    weekdays = Translations.callListAdmin.weekDays;
    citizenTypes = Translations.callListAdmin.citizenTypes;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(private callListAdminService: CallListAdminService) {}

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    onSubmit() {
        this.result = undefined;
        this.callListAdminService.getCallcount(this.options.type + 1, this.options.weekday)
            .pipe(takeUntil(this.unsubscribeS$))
            .subscribe(count => {
                this.result = count;
            });
    }
}
