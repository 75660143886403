import { computed, inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Params, Router } from '@angular/router';
import axios, { AxiosError } from 'axios';
import { IUserViewModel, RealTimeInboundCallEvent } from 'Client/api/services';
import { DialogService } from 'Client/app/shared/dialog.service';
import { IDENTITIES, IUserRoles } from 'Client/app/user/user-roles';
import { UserService } from 'Client/app/user/user.service';
import { Translations } from 'Client/translations/translations';
import { combineLatest, EMPTY, firstValueFrom, from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import {
    FormBuilderOption,
    FormBuilderRadio,
    FormBuilderTextInput,
    RadioGroupSeparatorKey
} from '../../form-builder/form-builder-element.model';
import { IDialogFormSettings } from '../../shared/dialog-interfaces';
import { CallListService } from '../call-list.service';
import { getPresenceDefinitionsStatus } from '../genesys/genesys.helper';
import { GenesysService } from '../genesys/genesys.service';
import {
    CallerPresenceStatus,
    CallerPresenceStatusDefinition,
    CallEventMessage,
    EventSourceReadyState,
    PresenceStatus,
    PresenceStatusEnum
} from './calls.models';
import { FormBuilderValidators } from '../../form-builder/validators/form-builder-validators';
import { CitizenService } from '../citizen.service';
import { dateIsGreaterOrEqualsThanToday, dateIsGreaterThan } from '../../util/helpers';
import { GenesysConversationErrorDetails } from '../genesys/genesys.model';

@Injectable({
    providedIn: 'root'
})
export class CallsService {
    private eventSource?: EventSource;
    private genesysService = inject(GenesysService);
    private dialogService = inject(DialogService);
    private router = inject(Router);
    private callListService = inject(CallListService);
    private userService = inject(UserService);
    private citizenService = inject(CitizenService);
    private callMessage = new ReplaySubject<CallEventMessage>(1);
    public callMessage$ = this.callMessage.asObservable();
    private readonly CancelSubscriptionMessage = 'CANCEL_SUBSCRIPTION';

    /**
     * Computes the available call status options for a form, processing presence definitions
     * and adding separators when there are multiple occurrences of the same status.
     *
     * The function performs the following steps:
     * 1. Sorts the presence definitions by status.
     * 2. Counts the occurrences of each status.
     * 3. Inserts a separator before the first appearance of a status, only if there are multiple
     *    instances of that status.
     * 4. Appends the actual presence options to the result array. If there are multiple occurrences
     *    of the same status, the options will be marked as sub-options.
     *
     * @returns {FormBuilderOption[]} An array of form builder options containing presence statuses and separators.
     *
     * @example
     * // Example return value
     * [
     *   { label: 'Busy', value: 'RadioGroupSeparatorKey' },
     *   { label: 'Admin opg.', value: '2ff75e0b-6256-48da-86ab-948cf43ac082', asSubOption: true },
     *   { label: 'Bestillinger', value: 'c611cb28-55e3-4233-a24c-c73205c1f843', asSubOption: true },
     *   { label: 'Outbound/Call', value: 'eff942d4-6ee4-4c15-893b-eaac397af8e4', asSubOption: true }
     * ]
     */
    private callStatusOptions = computed(() => {
        // Sort presences by status once
        const presences = this.getPresenceStatusDefinitions().sort(
            (a, b) => a.status.localeCompare(b.status)
        );

        // Step 1: Count occurrences of each status
        const presenceCount = presences.reduce(
            (acc, { status }) => ({ ...acc, [status]: (acc[status] || 0) + 1 }),
            {} as { [status: string]: number }
        );

        // Step 2: Create a new array with labels and separators
        const processedPresences: FormBuilderOption[] = [];
        // eslint-disable-next-line no-null/no-null
        let lastStatus: string | null = null;

        presences.forEach(({ status, label, id }) => {
            // Insert separator only when the status changes and its count is greater than 1
            if (status !== lastStatus && presenceCount[status] > 1) {
                processedPresences.push({
                    label: this.genesysService.getSystemPreferenceLabel(status),
                    value: RadioGroupSeparatorKey // separator value
                });
            }

            // Add the actual presence item
            processedPresences.push({
                label,
                value: id,
                asSubOption: presenceCount[status] > 1
            });

            // Track the last status processed
            lastStatus = status;
        });

        return processedPresences;
    });


    private get genesysPresencesIsOutOfOffice(): boolean {
        return this.getCallerStatus().status === PresenceStatusEnum.OutOfOffice;
    }

    init(user?: IUserViewModel): void {
        const isCustomerServiceUser = user?.roles?.some(x =>
            IDENTITIES.adminAndCustomerService.includes(x as IUserRoles)
        );

        if (!this.eventSource && isCustomerServiceUser && user?.hasCallerProfile) {
            this.connect();
        }
    }


    callCitizen(customerNo: string, phoneNumber: string): Observable<boolean> {
        return combineLatest([this.genesysService.conversationSettings$, this.userService.user$, this.citizenService.getCitizenDetails(customerNo)]).pipe(
            take(1),
            switchMap(([{ useListOfAllowedPhoneNumbers, listOfAllowedPhoneNumbers }, user, citizen]) => {


                if (citizen?.deliveryEnd && citizen?.nextDeliveryDateDateTime && dateIsGreaterOrEqualsThanToday(citizen?.deliveryEnd) && dateIsGreaterThan(citizen?.nextDeliveryDateDateTime, citizen?.deliveryEnd)) {
                    console.warn('Citizen can not be called due to delivery end');
                    throw { message: Translations.genesys.errors.cantBeCalledDueToDeliveryEnd };
                }

                if (useListOfAllowedPhoneNumbers && !listOfAllowedPhoneNumbers?.includes(phoneNumber)) {
                    console.warn('Phone number not allowed', phoneNumber);
                    throw { message: Translations.genesys.errors.phoneNotAllowed };
                }

                if (!user?.hasCallerProfile) {
                    console.warn('User does not have caller profile');
                    throw { message: Translations.genesys.errors.noCallerProfile };
                }


                return from(this.genesysService.conversationsApiInstance.postConversationsCalls({ phoneNumber })).pipe(
                    switchMap(() => {
                        return this.callListService.logCitizenCall(customerNo, phoneNumber).pipe(
                            map(() => true),
                            catchError(err => {
                                console.warn('Error logging citizen call', err);
                                return of(false);
                            })
                        );
                    }),
                    catchError((err: GenesysConversationErrorDetails | AxiosError) => {
                        if (axios.isAxiosError(err) && err.response?.status === 401) {
                            console.warn(
                                'There was a failure calling postConversationsCalls regarding authentication',
                                err
                            );
                            this.genesysService.authenticate();
                            return of();
                        }
                        console.warn('There was a failure calling postConversationsCalls', err);
                        throw err;
                    })
                );
            }),
            map(() => true)
        );
    }

    getPresenceStatusDefinitions(): CallerPresenceStatusDefinition[] {
        return this.genesysService.getUserPresenceDefinitions();
    }

    getCallerStatus(): CallerPresenceStatus {
        const presence = this.genesysService.genesysPresences();
        return {
            id: presence.presenceDefinition?.id || '',
            message: presence?.message || '',
            status: presence?.presenceDefinition?.systemPresence as PresenceStatus
        };
    }

    async setCallerStatus(status: PresenceStatusEnum, id: string): Promise<boolean> {


        const dialogForm: IDialogFormSettings = {
            data: {
                title: Translations.callListAdmin.callerPresence.changePresenceStatusDialog.dialogTitle,
                inputs: [
                    new FormBuilderRadio({
                        name: `status`,
                        label: '',
                        options: [
                            ...this.callStatusOptions()
                        ],
                        value: this.genesysService
                            .getUserPresenceDefinitions()
                            .find(callerPresenceStatus => {
                                return callerPresenceStatus.status === status && callerPresenceStatus.id === id;
                            })?.id,
                        valueChanged: (value: string, form?: UntypedFormGroup) => {
                            const presenceStatus =
                                getPresenceDefinitionsStatus(value, this.genesysService.getUserPresenceDefinitions()) ??
                                PresenceStatusEnum.OutOfOffice;
                            const messageControl = form?.get('message');


                            if (presenceStatus !== PresenceStatusEnum.OutOfOffice) {
                                messageControl?.patchValue('');
                                return;
                            }

                            messageControl?.patchValue(
                                this.getCallerStatus()?.message?.length
                                    ? this.getCallerStatus().message
                                    : Translations.callListAdmin.callerPresence.outOfOffice.message
                            );
                        },
                        validation: [FormBuilderValidators.presenceStatusValidator(PresenceStatusEnum.OutOfOffice)],
                        condition: of(!this.genesysPresencesIsOutOfOffice)
                    }),
                    new FormBuilderTextInput({
                        name: 'message',
                        label: Translations.callListAdmin.callerPresence.changePresenceStatusDialog.messageLabel,
                        value: this.getCallerStatus().message,
                        condition: of(!this.genesysPresencesIsOutOfOffice)
                    })
                ],
                actionText: Translations.form.actions.choose,
                alert: this.genesysPresencesIsOutOfOffice ? {
                    type: 'danger',
                    text: Translations.callListAdmin.callerPresence.changePresenceStatusDialog.outOfOfficeWarning
                } : undefined
            }
        };

        return firstValueFrom(
            this.dialogService
                .showCallStatusForm(dialogForm)
                .afterClosed()
                .pipe(
                    switchMap(result => {
                        if (!result || !result?.status) {
                            return EMPTY;
                        }

                        return this.genesysService.setUserPresence({
                            id: result.status,
                            status:
                                getPresenceDefinitionsStatus(
                                    result.status,
                                    this.genesysService.getUserPresenceDefinitions()
                                ) ?? result.status,
                            message: result.message
                        });
                    }),
                    catchError(err => {
                        console.warn('There was a failure calling setCallerStatus', err);
                        this.dialogService.showSnackMessage({
                            message: Translations.replaceTokens(
                                Translations.callListAdmin.callerPresence.callerPresenceErrorCodes[
                                err?.code || 'unknown'
                                    ]
                            ),
                            config: { duration: 5000 }
                        });
                        return err;
                    }),
                    map(() => {
                        this.dialogService.showSnackMessage({
                            message: Translations.replaceTokens(
                                Translations.callListAdmin.callerPresence.callerPresenceSuccess
                            ),
                            config: { duration: 5000 }
                        });
                        return true;
                    })
                )
        );
    }

    checkCallStatus(params: Params, user?: IUserViewModel): Observable<boolean> {
        return this.genesysService.checkAuthentication(params, user);
    }

    validateCallEvent(callEventMessage: CallEventMessage): void {
        if (callEventMessage?.message) {
            const { message } = callEventMessage;

            if (message?.citizens && message?.citizens.length > 1) {
                this.showCallEventError(
                    Translations.replaceTokens(Translations.genesys.errors.multiplePhoneNumber, message.phoneNumber)
                );
                return;
            }

            if (message?.citizens && message?.citizens.length === 1) {
                const citizen = message?.citizens[0];

                this.router.navigate([
                    '',
                    { outlets: { admin: ['citizen', citizen.customerNo, '', message.inboundCallNotificationId] } }
                ]);
            }
        }

        if (callEventMessage?.error) {
            this.showCallEventError(callEventMessage.error);
        }
    }

    showCallEventError(message: string): void {
        this.dialogService.showValidationResult([message ?? '']);
    }

    disconnect(): void {
        if (!this.eventSource) {
            return;
        }
        this.eventSource.removeEventListener('message', this.onMessage.bind(this));
        this.eventSource.removeEventListener('error', this.onError.bind(this));
        this.eventSource.close();
        this.eventSource = undefined;
    }

    private connect(): void {
        console.log('eventSource connecting...');
        const url = `${location.protocol}//${location.host}/api/RealTimeCommunications/Subscribe`;
        const eventSource = new EventSource(url);

        eventSource.addEventListener('message', this.onMessage.bind(this));
        eventSource.addEventListener('error', this.onError.bind(this));

        this.eventSource = eventSource;
    }

    private onMessage = (event: MessageEvent) => {
        console.warn('eventSource on message', event);

        if (event.data === this.CancelSubscriptionMessage) {
            console.warn('Canceling subscription...');
            this.disconnect();
            return;
        }

        const messageData = JSON.parse(event.data);
        const realTimeInboundCallEvent = messageData.map(({ Data }) => RealTimeInboundCallEvent.fromJS(Data));

        this.callMessage.next({
            type: 'Success',
            readyState: EventSourceReadyState.OPEN,
            message: realTimeInboundCallEvent[0]
        });
    };

    private onError = (event: Event) => {
        console.warn('eventSource on error', event);

        this.callMessage.next({
            type: 'Error',
            readyState: EventSourceReadyState.CLOSED,
            error: Translations.genesys.errors.sourceEvent
        });
    };
}
