import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMapTo, takeUntil, tap } from 'rxjs/operators';

import { creditcard, cross, forward, starMenu } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CategoryMenuLevelDeviceComponent } from '../menu/category-menu-level-device.component';
import { AuthService } from '../services/auth.service';
import { CategoryNodeViewModel } from '../services/menu-models';
import { MenuService } from '../services/menu.service';
import { RouteService } from '../shared/route.service';
import { IDENTITIES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { IComponentLoaderOptions } from '../util/component-loader';
import { UtilService } from '../util/util.service';
import { UtilComponentLoaderComponent } from '../util/util-component-loader.component';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor, MatButton } from '@angular/material/button';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

enum AnimationState {
    Open = 'open',
    Close = 'close',
    Pop = 'pop'
}

interface ILevel {
    categoryMenu: CategoryNodeViewModel[];
    parent: {
        name: string;
    };
    current: {
        name: string;
        url: string;
    };
}

interface ILevelComponentLoaderOptions extends IComponentLoaderOptions<ILevel> {
    animationState: AnimationState;
}

@Component({
    selector: 'iv-layout-navigation-device',
    template: `
        <div class="layout-navigation-device">
            <div class="layout-navigation-device__overlay" [@fadeInOut]="animationState" (click)="toggleMenu()"></div>

            <div class="layout-navigation-device__navigation" [@flyInOut]="animationState">
                <div class="layout-navigation-device__level layout-navigation-device__level_first" *ngIf="isMobile">
                    <div class="layout-navigation-device__head">
                        <a [routerLink]="[{ outlets: { primary: null } }]" class="layout-navigation-device__item layout-navigation-device__item_head" mat-button>
                            ${Translations.layout.navigation.device.head}
                        </a>

                        <button class="layout-navigation-device__close" (click)="toggleMenu()" mat-button>${cross}</button>
                    </div>

                    <button class="layout-navigation-device__item layout-navigation-device__item_has-children" (click)="openCategoryMenu()" mat-button>
                        ${Translations.layout.navigation.products}
                        <mat-icon class="layout-navigation-device__forward">${forward}</mat-icon>
                    </button>

                    <ng-container *ngIf="userService.isLoggedIn | async">
                        <a routerLink="/${Translations.shop.paths.topProductsPage.path}" routerLinkActive="layout-navigation-device__item_active" class="layout-navigation-device__item" mat-button>
                            ${Translations.layout.navigation.topProducts}
                        </a>

                        <a routerLink="/${Translations.shop.paths.ordersPage.path}" routerLinkActive="layout-navigation-device__item_active" class="layout-navigation-device__item" mat-button>
                            <mat-icon class="layout-navigation-device__item-icon">${creditcard}</mat-icon>
                            ${Translations.layout.navigation.orders}
                        </a>

                        <a routerLink="/${Translations.shop.paths.favoritesPage.path}" routerLinkActive="layout-navigation-device__item_active" class="layout-navigation-device__item" mat-button>
                            <mat-icon class="layout-navigation-device__item-icon">${starMenu}</mat-icon>
                            ${Translations.layout.navigation.favourite}
                        </a>
                    </ng-container>

                    <ng-container *ngIf="userService.isLoggedIn | async">
                        <a *ngIf="canEditPassword" class="layout-navigation-device__item layout-navigation-device__item_logout" routerLink="/${Translations.pages.editPassword.path}" mat-button>
                            ${Translations.layout.navigation.editPassword}
                        </a>
                        <button class="layout-navigation-device__item layout-navigation-device__item_logout" (click)="logoutUser()" mat-button>
                            ${Translations.layout.navigation.logout}
                        </button>
                    </ng-container>
                </div>

                <div class="layout-navigation-device__level" *ngFor="let level of levels" [@flyInOut]="level.animationState" (@flyInOut.done)="popLevel($event)">
                    <iv-util-component-loader [input]="level" (output)="levelChange($event)"></iv-util-component-loader>
                </div>
            </div>
        </div>
    `,
    animations: [
        trigger('flyInOut', [
            state('open', style({
                transform: 'translateX(0)'
            })),
            state('close', style({
                transform: 'translateX(-100%)'
            })),
            state('pop', style({
                transform: 'translateX(-100%)'
            })),
            transition('* => *', animate('200ms ease-in-out'))
        ]),
        trigger('fadeInOut', [
            state('open', style({
                opacity: '1',
                display: 'block'
            })),
            state('close', style({
                opacity: '0',
                display: 'none'
            })),
            transition('close <=> open', animate('200ms ease-in-out'))
        ])
    ],
    standalone: true,
    imports: [NgIf, MatAnchor, RouterLink, MatButton, MatIcon, RouterLinkActive, NgFor, UtilComponentLoaderComponent, AsyncPipe]
})
export class LayoutNavigationDeviceComponent implements OnInit, OnChanges, OnDestroy {
    @Input() show: boolean;
    @Output() menuToggled = new EventEmitter();

    canEditPassword = false;

    animationState = AnimationState.Close;
    categoryAnimationState = AnimationState.Close;
    isMobile: boolean;

    levels: ILevelComponentLoaderOptions[] = [];

    private unsubscribe: Subject<void> = new Subject();
    private categoryMenuData: CategoryNodeViewModel[];

    constructor(
        private authService: AuthService,
        private router: Router,
        public userService: UserService,
        private routeService: RouteService,
        private utilService: UtilService,
        public menuService: MenuService
    ) { }

    ngOnInit() {
        this.routeService.activeRoute$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => {
            if (this.show) {
                this.toggleMenu();
            }
        });

        this.menuService.getCategoryMenu().pipe(
            takeUntil(this.unsubscribe),
            tap(categoryMenu => this.categoryMenuData = categoryMenu.categories),
            switchMapTo(this.utilService.deviceType$)
        ).subscribe(device => {
            this.isMobile = device === 'mobile';

            this.levels.splice(0);
            if (!this.isMobile) {
                this.openCategoryMenu();
            }

            if (this.utilService.isBrowser()) {
                this._resolveCategory(this.categoryMenuData);
            }
        });

        this.userService.activeRoles.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(userRoles => {
            this.canEditPassword = userRoles.some(x => IDENTITIES.editOwnPassword.includes(x));
        });
    }

    ngOnChanges() {
        this.animationState = this.show ? AnimationState.Open : AnimationState.Close;
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    logoutUser() {
        this.authService.logout().pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(res => {
            this.router.navigate(['/']);
        });
    }

    toggleMenu() {
        this.menuToggled.emit();
    }

    openCategoryMenu() {
        this.levels.push({
            component: CategoryMenuLevelDeviceComponent,
            data: {
                categoryMenu: this.categoryMenuData,
                parent: {
                    name: Translations.layout.navigation.device.mainMenu
                },
                current: {
                    name: Translations.layout.navigation.products,
                    url: Translations.shop.paths.shopPage.path
                }
            },
            animationState: AnimationState.Open
        });
    }

    levelChange(data: CategoryNodeViewModel | undefined) {
        if (data instanceof CategoryNodeViewModel) {
            const parentName = this.levels[this.levels.length - 1].data.current.name;
            this.levels.push({
                component: CategoryMenuLevelDeviceComponent,
                data: {
                    categoryMenu: data.subCategories,
                    parent: {
                        name: parentName
                    },
                    current: {
                        name: data.name,
                        url: data.url
                    }
                },
                animationState: AnimationState.Open
            });
        }
        else {
            if (this.isMobile || (!this.isMobile && this.levels.length > 1)) {
                this.levels[this.levels.length - 1].animationState = AnimationState.Pop;
            }
            else if (!this.isMobile && this.levels.length === 1) {
                this.toggleMenu();
            }
        }
    }

    popLevel(event: AnimationEvent) {
        if (event.toState === AnimationState.Pop) {
            this.levels.pop();
        }
    }

    private _resolveCategory(categories: CategoryNodeViewModel[], path: string | undefined = decodeURIComponent(window.location.pathname)) {
        if (path.indexOf('/' + Translations.shop.paths.shopPage.path) !== -1 && this.isMobile) {
            this.openCategoryMenu();
        }

        path = path.split('/' + Translations.shop.paths.shopPage.path).pop();
        const categoryInPath = categories.filter(category => path?.indexOf(category.path) !== -1)[0];
        if (categoryInPath && categoryInPath.subCategories.length !== 0) {
            this.levelChange(categoryInPath);
            this._resolveCategory(categoryInPath.subCategories, path);
        }
    }
}
