import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { HttpClient } from '@angular/common/http';
import { TemporaryAddressViewModel, ITemporaryAddressViewModel, IActivateTemporaryAddressViewModel, IDeactivateTemporaryAddressModel } from '../../api/services';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AddressService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    activateTemporaryAddress(activateTemporaryAddress: IActivateTemporaryAddressViewModel): Observable<ITemporaryAddressViewModel> {
        return this.http.post(this.apiBaseUrl('/Address/ActivateTemporaryAddress'), activateTemporaryAddress).pipe(
            map(TemporaryAddressViewModel.fromJS)
        );
    }

    deactivateTemporaryAddress(deactivateTemporaryAddress: IDeactivateTemporaryAddressModel): Observable<ITemporaryAddressViewModel> {
        return this.http.put(this.apiBaseUrl('/Address/DeactivateTemporaryAddress'), deactivateTemporaryAddress).pipe(
            map(TemporaryAddressViewModel.fromJS)
        );
    }
}
