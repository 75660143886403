import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { UserModule } from '../user/user.module';
import { PageFrontpageComponent } from './page-frontpage.component';
import { PageHomecarerComponent } from './page-homecarer.component';
import { PageRoutingModule } from './page-routing.module';

@NgModule({
    imports: [SharedModule, UserModule, PageRoutingModule, PageFrontpageComponent, PageHomecarerComponent],
    exports: [PageFrontpageComponent]
})
export class PageModule {}
