import { AbstractControl, AsyncValidatorFn, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { CoreOptions } from 'medium-editor';
import { BehaviorSubject, Observable } from 'rxjs';

import { Translations } from '../../translations/translations';
import { FormBuilderValidators } from './validators/form-builder-validators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface FormBuilderElementConfig<T = any> {
    value?: T;
    name: string;
    validation?: ValidatorFn[];
    asyncValidation?: AsyncValidatorFn[];
    condition?: Observable<boolean> | ((form: UntypedFormGroup) => boolean);
    /**
     * This sets the required attribute on the DOM element, which shows the '*' sign on the MatLabel.
     *
     * NB! Remember to add the Validators.required validator to the FormControl, because
     * Angular will not accept [required] without the specific validator: https://github.com/angular/angular/issues/23657
     */
    required?: boolean;
    requiredAsync?: (control: AbstractControl) => Observable<boolean>;
    disabled?: boolean;
    readonly?: boolean;
    label?: string;
    filter?: (date: Date | null) => void;
    valueChanged?: (value: T, form?: UntypedFormGroup) => void;
    excludeFromTable?: boolean;
    updateOn?: 'blur' | 'change' | 'submit';
}

interface FormBuilderElementConfigFull extends FormBuilderElementConfig {
    options?: FormBuilderOption[] | BehaviorSubject<FormBuilderOption[]>;
    config?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface FormBuilderHintConfig {
    text?: string;
    align?: 'start' | 'end';
}

interface FormBuilderHint {
    hints?: FormBuilderHintConfig[];
}

export interface FormBuilderOption {
    value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    label: string;
    asSubOption?: boolean;
}

export interface FormUser {
    [key: string]: FormUser | string;
}

export type FormBuilderTypes =
    FormBuilderMunicipalityPicker
    | FormBuilderRichtextEditor
    | FormBuilderCity
    | FormBuilderCheckbox
    | FormBuilderTextInput
    | FormBuilderTextarea
    | FormBuilderDatepicker
    | FormBuilderSelect
    | FormBuilderRadio
    | FormBuilderChipList
    | FormBuilderSeparator;

export class FormBuilderElementModel<T, C> {
    value?: T;
    name: string;
    label?: string;
    controlType: C;
    type?: string;
    options?: BehaviorSubject<FormBuilderOption[]>;
    validation?: ValidatorFn[];
    asyncValidation?: AsyncValidatorFn[];
    condition?: Observable<boolean> | ((form: UntypedFormGroup) => boolean);
    required?: boolean;
    requiredAsync?: (control: AbstractControl) => Observable<boolean>;
    disabled?: boolean;
    readonly?: boolean;
    config?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    filter?: (date: Date | null) => void;
    valueChanged?: (value: any, form?: UntypedFormGroup) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
    excludeFromTable?: boolean;
    multiple?: boolean;
    multipleDistricts?: boolean;
    multipleSubdistricts?: boolean;
    allowEmptyValue?: boolean;
    filterDontUse?: boolean;
    richtextOptions?: CoreOptions;
    hints?: FormBuilderHintConfig[];
    updateOn?: 'blur' | 'change' | 'submit';

    constructor(options: FormBuilderElementConfigFull) {
        this.value = options.value;
        this.name = options.name || '';
        this.label = options.label || '';
        this.options = Array.isArray(options.options) ? new BehaviorSubject(options.options) : options.options;
        this.condition = options.condition instanceof Observable ? options.condition : options.condition || (() => true);
        this.validation = options.validation || [];
        this.config = options.config || {};
        this.requiredAsync = options.requiredAsync;
        this.required = typeof this.requiredAsync === 'undefined' ? options.required || false : false;
        this.disabled = options.disabled || false;
        this.valueChanged = options.valueChanged;
        this.excludeFromTable = options.excludeFromTable || false;
        this.updateOn = options.updateOn;
        this.asyncValidation = options.asyncValidation || [];
    }
}

/**
 * Municipality Picker
 */

export interface FormBuilderMunicipalityPickerConfig extends FormBuilderElementConfig<FormBuilderMunicipalityOutput> {
    multipleDistricts?: boolean;
    multipleSubdistricts?: boolean;
    allowEmptyValue?: boolean;
    filterDontUse?: boolean;
}

export interface FormBuilderMunicipalityOutput {
    municipality?: number;
    districts?: number | number[];
    subdistricts?: number | number[];
    selectedMunicipality?: string;
    selectedDistricts?: string | string[];
    selectedSubdistricts?: string | string[];
}

export class FormBuilderMunicipalityPicker extends FormBuilderElementModel<FormBuilderMunicipalityOutput, 'municipality'> {
    multipleDistricts: boolean;
    multipleSubdistricts: boolean;
    label: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    allowEmptyValue?: boolean;
    filterDontUse?: boolean;

    constructor(options: FormBuilderMunicipalityPickerConfig) {
        super(options);

        this.controlType = 'municipality';
        this.label = {
            municipality: Translations.forms.labels.municipality.muncipalityId,
            district: Translations.forms.labels.municipality.districtId,
            subdistrict: Translations.forms.labels.municipality.subdistrictId
        };
        this.multipleDistricts = options.multipleDistricts === true || false;
        this.multipleSubdistricts = options.multipleSubdistricts === true || false;
        this.required = options.required === true;
        this.allowEmptyValue = options.allowEmptyValue === true || false;
        this.filterDontUse = options.filterDontUse === true || false;

        if (this.required) {
            this.validation = [FormBuilderValidators.municipality()];
        }
    }
}

/**
 * Richtext editor
 */

interface FormBuilderRichtextOptions extends FormBuilderElementConfig {
    richtextOptions?: CoreOptions;
}

export class FormBuilderRichtextEditor extends FormBuilderElementModel<string, 'richtext'> {
    richtextOptions?: CoreOptions;

    constructor(options: FormBuilderRichtextOptions) {
        super(options);

        this.controlType = 'richtext';
        this.richtextOptions = options.richtextOptions;
    }
}

/**
 * City
 */

export interface FormBuilderCityOutput {
    zipCode?: string;
    city?: string;
}

export class FormBuilderCity extends FormBuilderElementModel<FormBuilderCityOutput, 'city'> {
    label: any; // eslint-disable-line @typescript-eslint/no-explicit-any

    constructor(options: FormBuilderElementConfig) {
        super(options);

        this.controlType = 'city';
        this.label = {
            zipCode: Translations.forms.labels.city.zipCode,
            city: Translations.forms.labels.city.city
        };
    }

}


/**
 * Checkbox
 */

export class FormBuilderCheckbox extends FormBuilderElementModel<boolean, 'checkbox'> {
    constructor(options: FormBuilderElementConfig) {
        super(options);

        this.controlType = 'checkbox';
    }
}

/**
 * Text Input
 */

interface FormBuilderTextInputConfig extends FormBuilderElementConfig<string>, FormBuilderHint {
    type?: 'text' | 'password' | 'tel' | 'email';
}

export class FormBuilderTextInput extends FormBuilderElementModel<string, 'text' | 'password'> {
    type: string;

    constructor(options: FormBuilderTextInputConfig) {
        super(options);

        this.controlType = options.type === 'password' ? 'password' : 'text';
        this.value = options.value || '';
        this.type = options.type || 'text';
        this.hints = options.hints || [];
        this.readonly = options.readonly || false;
    }
}

/**
 * Textarea
 */

interface FormBuilderTextareaConfig extends FormBuilderElementConfig<string>, Partial<FormBuilderHint> {
}

export class FormBuilderTextarea extends FormBuilderElementModel<string, 'textarea'> implements FormBuilderHint {
    hints: FormBuilderHintConfig[];

    constructor(options: FormBuilderTextareaConfig) {
        super(options);

        this.controlType = 'textarea';
        this.hints = options.hints || [];
    }
}

/**
 * Date picker
 */

export class FormBuilderDatepicker extends FormBuilderElementModel<Date | null, 'date'> {
    constructor(options: FormBuilderElementConfig) {
        super(options);

        this.controlType = 'date';
        this.filter = options.filter;
    }
}

/**
 * Select
 */

interface FormBuilderSelectConfig extends FormBuilderElementConfig {
    options: FormBuilderOption[] | BehaviorSubject<FormBuilderOption[]>;
    multiple?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class FormBuilderSelect extends FormBuilderElementModel<any | any[], 'select'> {
    multiple: boolean;

    constructor(options: FormBuilderSelectConfig) {
        super(options);

        this.controlType = 'select';
        this.multiple = options.multiple === true || false;
    }
}

/**
 * Radio
 */

interface FormBuilderRadioConfig extends FormBuilderElementConfig {
    options: FormBuilderOption[] | BehaviorSubject<FormBuilderOption[]>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class FormBuilderRadio extends FormBuilderElementModel<any, 'radio'> {
    constructor(options: FormBuilderRadioConfig) {
        super(options);
        this.controlType = 'radio';
    }
}

/**
 * Chip list
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class FormBuilderChipList extends FormBuilderElementModel<any, 'chip-list'> {
    constructor(options: FormBuilderElementConfig) {
        super(options);

        this.controlType = 'chip-list';
        this.value = options.value ? options.value.split(',') : [];
    }
}

/**
 * In case we want to put a separator between fields
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class FormBuilderSeparator extends FormBuilderElementModel<any, 'separator'> {
    constructor(options: FormBuilderElementConfig) {
        super(options);

        this.controlType = 'separator';
    }
}

export type FormElementState = 'default' | 'reset';

export const RadioGroupSeparatorKey = 'RadioGroupSeparator';
