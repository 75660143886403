import { Gender } from './../api/services';

// eslint-disable  quote-props
export class Translations {
    public static variables = {
        currencyCode: 'DKK',
        customerServicePhone: '70 27 72 33',
        customerServiceEmail: 'support@intervare.dk',
        dateFormat: 'da-DK',
        orderAmountLimitation: 10000
    };

    public static global = {
        btnAcknowledge: 'Ok',
        btnCancel: 'Annuller',
        btnDelete: 'Slet',
        btnSave: 'Gem',
        btnSelectAll: 'Vælg alle',
        btnDeselectAll: 'Afvælg alle',
        dontUse: 'BRUG IKKE',
        errorMessages: {
            header: 'Ups, der skete en fejl',
            client: 'Klientfejl',
            server: 'Serverfejl',
            generic: `Der er desværre sket en fejl i forbindelse med at kontakte serveren. Prøv venligst igen og ellers kontakt kundeservice hvis problemet fortsætter på tlf. <a href="tel:${Translations.variables.customerServicePhone}">${Translations.variables.customerServicePhone}</a> eller e-mail ved at skrive til <a href="mailto:${Translations.variables.customerServiceEmail}">${Translations.variables.customerServiceEmail}</a>`,
            unauthorized: 'Du har ikke adgang til denne funktion'
        },
        logo: 'Supermarkedet Intervare',
        title: 'Intervare',
        textSet: {
            anOrder: 'Bestilling',
            delivery: 'Levering',
            municipality: 'Kommune',
            district: 'Distrikt',
            subDistrict: 'Underdistrikt',
            latestBuy: 'Sidste køb',
            yes: 'Ja'
        },
        weekDayNos: ['Ukendt', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'],
        weekDays: {
            monday: 'Mandag',
            tuesday: 'Tirsdag',
            wednesday: 'Onsdag',
            thursday: 'Torsdag',
            friday: 'Fredag'
        },
        citizenTypes: {
            UnderOprettelse: 'Under oprettelse',
            Ringeborger: 'Ringeborger',
            RingerSelv: 'Ringer selv',
            Seddelborger: 'Seddelborger',
            TastSelvBorger: 'Tast-selv-borger'
        },
        frequencyDelivery: {
            BiWeekly: 'To gange pr. uge',
            Weekly: 'Ugentlig',
            EveryOtherWeek: 'Hver anden uge',
            ThirdWeek: 'Hver tredje uge',
            FourthWeek: 'Hver fjerde uge'
        },
        paymentTypes: {
            'NEM-DK': 'NEM-DK',
            'PBS': 'PBS',
            'DIBS-GIRO': 'DIBS-Giro',
            'DIBS': 'Kortbetaling',
            'IV-EFAK': 'IV-EFAK',
            'NULL': 'Null',
            'BANK': 'Bank',
            'ANDERLEDES': '3. part betaler',
            'FIKKORT': 'Fik kort',
            'PBS-GIRO': 'PBS-Giro',
            'GIRO': 'Girokort',
            'KONTANT': 'Kontant',
            'GIROKORT': 'Girokort'
        },
        transferReasons: {
            Unspecified: 'Uangivet',
            WillSendNewOrder: 'Hjemmeplejen sender en ny bestilling',
            DontWantNothing: 'Ønsker ingen varer',
            SkipThisWeek: 'Kunden springer over i denne uge',
            TransferDueToMistake: 'Overførsel på grund af fejl',
            CallAgain: 'Ring igen',
            Hospitalized: 'Indlagt',
            Vacations: 'Ferie',
            Dead: 'Mors',
            OtherCauses: 'Andet'
        },
        transferDetails: {
            seeDetailsBtn: 'Vis afbestilling',
            header: 'Afbestilling for {0}',
            text: 'Borgeren har afbestilt på grund af: {0}',
            submitCancelBtn: 'Slet afbestilling',
            confirmText: 'Vil du annullere afbestillingen for {0}?',
            messageOk: 'Afbestillingen for {0} er annulleret'
        },
        pageNotFound: {
            title: '404',
            text: 'Siden kunne desværre ikke findes',
            goToFrontPage: 'Gå til forsiden'
        },
        reservation: {
            errorCodes: {
                Unspecified: 'Ukendt fejl',
                IMPACT_QTDIFF: 'Du har forsøgt at købe {0}, men vi har kun {1} tilgængelig.',
                IMPACT_QT_MISMATCH: 'Antal stemmer ikke overens med reservationen.',
                IMPACT_FAIL: 'Noget gik galt. Prøv venligst igen.',
                IMPACT_RESERVATION_NOT_FOUND: 'Reservationen blev ikke fundet.',
                IMPACT_RESERVATION_EXTENDED_FAIL: 'Reservationen kunne ikke ændres.',
                R003: 'Reservationen blev ikke fundet og kan derfor ikke ændres.',
                R005: 'Der er opstået en systemfejl. Denne er rapporteret til IT Support.',
                R104: 'Reservation kan ikke ændres, da den er udløbet.',
                R105: 'Der er ikke nok varer på lager. Venligst vælg en erstatning eller fjern varen.',
                R106: 'Reservationen kan ikke sammenlægges, grundet en systemfejl.'
            }
        }
    };

    public static layout = {
        trigger: {
            label: 'Åbn menu'
        },
        navigation: {
            products: 'Varer',
            weeklySales: 'Ugens tilbud',
            sales: 'Tilbud',
            topProducts: 'Top 50',
            login: 'Log ind',
            logout: 'Log ud',
            editPassword: 'Skift adgangskode',
            orders: 'Ordrer',
            favourite: 'Favoritter',
            basket: 'Kurv',
            device: {
                head: 'Intervare',
                mainMenu: 'Hovedmenu',
                showAll: 'Vis alle',
                back: 'Tilbage til'
            },
            impersonated: {
                citizen: 'Borger',
                delivery: 'Levering d.',
                deliveryChanged: 'OBS – leveringsdagen er ændret til:',
                deliveryChangedFrom: 'OBS – leveringsdagen er ændret fra den:',
                deliveryChangedTo: 'OBS – leveringsdagen er ændret til:'
            }
        },
        footer: {
            company: 'Intervare A/S',
            streetName: 'Banemarksvej',
            streetNumber: '58',
            zip: '2605',
            municipality: 'Brøndby',
            customerService: 'Kundeservice',
            controlreport: {
                text: 'Se kontrolrapport',
                link: 'http://www.findsmiley.dk/555489'
            },
            open: {
                key: 'Åbent',
                value: 'Man - fre kl. 8-16'
            },
            phone: {
                key: 'Telefon',
                value: Translations.variables.customerServicePhone
            },
            email: {
                key: 'E-mail',
                value: Translations.variables.customerServiceEmail
            }
        },
        cookieWarning: {
            text: 'Vi bruger cookies til statistik, personalisering og markedsføring. Du accepterer vores cookies, hvis du fortsætter med at bruge intervare.dk',
            btn: 'Ok'
        },
        confirmDialog: {
            header: 'Handling påkrævet'
        }
    };

    public static form = {
        actions: {
            choose: 'Vælg',
            cancel: 'Annuller',
            save: 'Gem'
        }
    };

    public static shop = {
        buttons: {
            allDiscounts: 'Alle tilbud'
        },
        paths: {
            shopPage: {
                title: 'Butik',
                path: 'varer'
            },
            discountCategory: {
                title: 'Tilbud',
                path: 'tilbud'
            },
            discountPage: {
                title: 'Ugens tilbud',
                path: 'tilbud'
            },
            discountPapers: {
                title: 'Tilbudsaviser',
                path: 'tilbud'
            },
            topProductsPage: {
                title: 'Top 50',
                path: 'top50'
            },
            basketPage: {
                title: 'Indkøbskurv',
                path: 'kurv'
            },
            favoritesPage: {
                title: 'Mine favoritter',
                path: 'favoritter'
            },
            ordersPage: {
                title: 'Ordrer',
                path: 'ordrer'
            },
            creditNotePage: {
                title: 'Kreditnotaer',
                path: 'kreditnota'
            },
            searchPage: {
                title: 'Søgning',
                path: 'soegning'
            },
            receiptPage: {
                path: 'kvittering'
            }
        }
    };

    public static municipality = {
        paths: {
            createUser: {
                title: 'Opret bruger',
                path: 'opret-bruger'
            },
            userAdministration: {
                title: 'Bruger Administration',
                path: 'bruger-administration'
            },
            citizenStatus: {
                title: 'Borger Status',
                path: 'borger-status'
            },
            viewCitizens: {
                title: 'Vis borgere',
                path: 'vis-borgere'
            },
            citizenOverview: {
                title: 'Borger overblik',
                path: 'borger-overblik'
            },
            subscribeCitizen: {
                title: 'Tilmeld borger',
                path: 'tilmeld-borger'
            },
            temporaryAddress: {
                title: 'Midlertidig adresse',
                path: 'midlertidig-adresse'
            },
            citizenDrafts: {
                title: 'Borger Kladde',
                path: 'borger-kladde'
            },
            pdaStatistics: {
                title: 'PDA statistik',
                path: 'pda-statistik'
            },
            editCitizen: {
                title: 'Rediger borger',
                path: 'rediger-borger'
            },
            editPasswordForCitizen: {
                title: 'Rediger adgangskode for borger',
                path: 'rediger-password-borger'
            },
            citizenPayment: {
                title: 'Betalingsoplysninger',
                path: 'citizen-payment'
            },
            citizenDriverMessages: {
                title: 'Besked til chauffør',
                path: 'citizen-driver-messages'
            },
            deliveryDate: {
                title: 'Skift leveringsdato',
                path: 'change-delivery-date'
            },
            createInternalUser: {
                title: 'Opret intern bruger',
                path: 'create-internal-user'
            },
            editInternalUser: {
                title: 'Opdater intern bruger',
                path: 'edit-internal-user'
            },
            listInternalUsers: {
                title: 'Vis interne brugere',
                path: 'list-internal-users'
            },
            userHistory: {
                title: 'Brugerhistorik',
                path: 'user-history'
            }
        },
        common: {
            noResults: 'Ingen resultater fundet, finjuster din søgning',
            dateFrom: 'Fra dato',
            dateTo: 'Til dato',
            exportToExcel: 'Eksportér til Excel'
        },
        subscribeCitizen: {
            customerNo: 'Kundenr.',
            next: 'Næste',
            previous: 'Tilbage',
            approve: 'Godkend',
            steps: {
                info: 'Oplysninger',
                relatives: 'Pårørende',
                payment: 'Betaling',
                activate: 'Aktivér',
                result: 'Resultat'
            },
            customerSubTypeTranslations: {
                Ringeborger: 'Ringeborger',
                Seddelborger: 'Seddelborger',
                TastSelvBorger: 'Tast-selv Borger',
                RingerSelv: 'Ringer selv',
                UnderOprettelse: 'Under oprettelse'
            },
            customerSubTypeEnum: {
                '1': 'Ringeborger',
                '2': 'Seddelborger',
                '3': 'Tast-selv Borger',
                '4': 'Ringer selv',
                '5': 'Under oprettelse'
            },
            genderTranslations: {
                Male: 'Mand',
                Female: 'Kvinde'
            },
            genderEnum: {
                '0': 'Mand',
                '1': 'Kvinde'
            },
            visitationTranslations: {
                Permanent: 'Permanent',
                Temporary: 'Midlertidig'
            },
            visitationEnum: {
                '0': 'Permanent',
                '1': 'Midlertidig'
            },
            deliveryFrequencyTranslations: {
                Unspecified: 'Uangivet',
                BiWeekly: 'To gange pr. uge',
                Weekly: 'Ugentlig',
                EveryOtherWeek: 'Hver anden uge',
                ThirdWeek: 'Hver tredje uge',
                FourthWeek: 'Hver fjerde uge'
            },
            deliveryFrequencyEnum: {
                '0': 'Uspecificeret',
                '1': 'To gange ugentligt',
                '2': 'Ugentligt',
                '3': 'Hver 2. uge',
                '4': 'Hver 3. uge',
                '5': 'Hver 4. uge'
            },
            primaryUserTranslations: {
                Citizen: 'Borger',
                NextOfKin: 'Pårørende'
            },
            primaryUserEnum: {
                '0': 'Borger',
                '1': 'Pårørende'
            },
            citizenRelationIdTranslations: {
                Unspecified: 'Uangivet',
                Child: 'Barn',
                Parent: 'Forælder',
                HomeCareer: 'Hjemmehjælper',
                InLaws: 'Svigerfamilie',
                Guidance: 'Vejleder',
                Friend: 'Ven',
                Spouse: 'Ægtefælle',
                Guardian: 'Værge',
                SupportPerson: 'Støtteperson',
                Attorney: 'Fuldmagt indehaver'
            },
            citizenRelationEnum: {
                '0': 'Uspecificeret / Ingen',
                '1': 'Barn',
                '2': 'Forælder',
                '3': 'Hjemmehjælper',
                '4': 'Svigerfamilie',
                '5': 'Vejleder',
                '6': 'Ven',
                '7': 'Ægtefælle',
                '8': 'Fuldmagt indehaver',
                '9': 'Støtteperson',
                '10': 'Værge'
            },
            weekDaysTranslations: {
                '0': 'Uspecificeret',
                '1': 'Mandag',
                '2': 'Tirsdag',
                '3': 'Onsdag',
                '4': 'Torsdag',
                '5': 'Fredag',
                '6': 'Lørdag',
                '7': 'Søndag'
            },
            info: {
                clock: 'kl.',
                districts: { name: 'Districts' },
                customerSubType: { name: 'CustomerSubType', label: 'Kunde undertype' },
                firstName: { name: 'FirstName', label: 'Fornavn' },
                middleName: { name: 'MiddleName', label: 'Mellemnavn' },
                lastName: { name: 'LastName', label: 'Efternavn' },
                streetName: { name: 'StreetName', label: 'Gadenavn' },
                streetNumber: { name: 'StreetNumber', label: 'Nr.' },
                addressFloor: { name: 'AddressFloor', label: 'Etage' },
                addressSide: { name: 'AddressSide', label: 'Side' },
                zipCode: { name: 'ZipCode', label: 'Postnr.' },
                city: { name: 'City' },
                cpr: { name: 'CPR', label: 'CPR nr.' },
                gender: { name: 'Gender', label: 'Køn' },
                phoneNumber: { name: 'PhoneNumber', label: 'Telefon nr.' },
                mobileNumber: { name: 'MobileNumber', label: 'Mobil telefon nr.' },
                email: { name: 'Email', label: 'E-mail' },
                invoiceEmailList: { name: 'InvoiceEmailList', label: 'Fakturaer' },
                orderConfirmationEmailList: { name: 'OrderConfirmationEmailList', label: 'Ordrebekræftelse' },
                replacementProduct: { name: 'ReplacementProduct', label: 'Tilbyd erstatningsvare' },
                specialInformationIds: { name: 'SpecialInformationIds', label: 'Særoplysninger' },
                driverId: { name: 'DriverId', label: 'Chauffør kreditor' },
                driverInformationIds: { name: 'DriverInformationIds', label: 'Chauffør information' },
                keyForCustomer: { name: 'KeyForCustomer', label: 'Nøgle til kunde' },
                signUpDate: { name: 'SignUpDate', label: 'Tilmeldingsdato' },
                dateForReceivingSignUp: { name: 'DateForReceivingSignUp', label: 'Dato for modtagelse af tilmelding' },
                deliveryStart: { name: 'DeliveryStart', label: 'Første bestillingsdag' },
                deliveryEnd: { name: 'DeliveryEnd', label: 'Midlertidig levering ophører' },
                visitationId: { name: 'VisitationId', label: 'Visitation' },
                deliveryFrequencyId: { name: 'DeliveryFrequencyId', label: 'Leveringsfrekvens' },
                deliveryDay: { name: 'DeliveryDay', label: 'Levering' },
                orderDay: { name: 'OrderDay', label: 'Bestilling' },
                timeslot: { name: 'Timeslot', label: 'Ringeinterval' },
                biWeeklyDeliveryDay: { name: 'BiWeeklyDeliveryDay', label: '2. levering' },
                biWeeklyOrderDay: { name: 'BiWeeklyOrderDay', label: '2. bestilling' },
                biWeeklyTimeslot: { name: 'BiWeeklyTimeslot', label: 'Ringeinterval' },
                webUsername: { name: 'WebUsername', label: 'Web brugernavn' },
                password: { name: 'Password', label: 'Web adgangskode' },
                confirmPassword: { name: 'ConfirmPassword', label: 'Gentag adgangskode' }
            },
            relatives: {
                primaryUser: { name: 'PrimaryUser', label: 'Primær bruger' },
                relationID: { name: 'RelationID', label: 'Relation' },
                firstName: { name: 'FirstName', label: 'Fornavn' },
                middleName: { name: 'MiddleName', label: 'Mellemnavn' },
                lastName: { name: 'LastName', label: 'Efternavn' },
                name2: { name: 'Name2', label: 'Navn 2' },
                streetName: { name: 'StreetName', label: 'Gadenavn' },
                streetNumber: { name: 'StreetNumber', label: 'Nr.' },
                addressFloor: { name: 'AddressFloor', label: 'Etage' },
                addressSide: { name: 'AddressSide', label: 'Side' },
                zipCode: { name: 'ZipCode', label: 'Postnr.' },
                city: { name: 'City' },
                phoneNumber: { name: 'PhoneNumber', label: 'Telefon nr.' },
                mobileNumber: { name: 'MobileNumber', label: 'Alt. nr.' },
                faxNumber: { name: 'FaxNumber', label: 'Telefax nr.' },
                email: { name: 'Email', label: 'E-mail' },
                comment: { name: 'Comment', label: 'Adressekommentar' }
            },
            result: {
                activated: 'Borgeren er aktiveret og oprettet',
                edited: 'Borgeren er redigeret',
                createAnother: 'Tilmeld endnu en borger'
            }
        },
        driverMessages: {
            temporaryMessage: 'Midlertidig besked',
            permanentMessage: 'Permanent besked',
            btnDelete: 'Slet besked',
            unsynchronized: 'Usynkroniseret',
            undo: 'Fortryd sletning',
            placeholderTemp: 'Midlertidig kommentar',
            placeholderPerm: 'Permanent kommentar',
            btnTemporaryAdd: 'Tilføj midlertidig besked',
            btnPermanentAdd: 'Tilføj permanent besked',
            noMessages: 'Der er pt. ingen kommentarer for denne kunde',
            headerContent: 'Besked',
            headerIsTemporary: 'Midlertidig',
            confirmDelete: 'Vil du slette beskeden?',
            msgAdd: 'Beskeden er tilføjet',
            msgDelete: 'Beskeden er slettet',
            btnSubmitMessages: 'Godkend'
        },
        temporaryAddress: {
            clock: 'kl.',
            impersonate: 'Ingen borger valgt',
            yes: 'Ja',
            no: 'Net',
            activate: 'Aktiver',
            activated: 'Midlertidig adresse er aktiveret',
            deactivated: 'Midlertidig adresse er deaktiveret',
            deactivate: {
                text: 'Kunden har følgende aktive midlertidige adresse:',
                text2: 'Ønsker du at deaktivere?',
                btn: 'Deaktiver'
            },
            form: {
                districts: { name: 'Districts' },
                startDate: { name: 'StartDate', label: 'Gældende fra' },
                endDate: { name: 'EndDate', label: 'Gældende til' },
                existingAddresses: { name: 'ExistingAddresses', label: 'Eksisterende adresser' },
                deliveryAddressCode: { name: 'DeliveryAddressCode', label: 'Leveringsadressekode' },
                streetName: { name: 'StreetName', label: 'Vejnavn' },
                streetNumber: { name: 'StreetNumber', label: 'Nr.' },
                addressFloor: { name: 'AddressFloor', label: 'Etage' },
                addressSide: { name: 'AddressSide', label: 'Side' },
                city: { name: 'City' },
                driverId: { name: 'DriverId', label: 'Chauffør' },
                deliveryFrequencyId: { name: 'DeliveryFrequencyId', label: 'Leveringsfrekvens' },
                deliveryDay: { name: 'DeliveryDay', label: 'Levering' },
                orderDay: { name: 'OrderDay', label: 'Bestilling' },
                timeslot: { name: 'Timeslot', label: 'Timeslot' },
                biWeeklyDeliveryDay: { name: 'BiWeeklyDeliveryDay', label: 'Levering' },
                biWeeklyOrderDay: { name: 'BiWeeklyOrderDay', label: 'Bestilling' },
                biWeeklyTimeslot: { name: 'BiWeeklyTimeslot', label: 'Timeslot' }
            }
        },
        citizenPayment: {
            paymentMethodCode: { name: 'PaymentMethodCode', label: 'Betalingsform' },
            dibsTicketId: { name: 'DIBSTicketId', label: 'Netaxept ticket' },
            pbsGiroCpr: { name: 'PbsGiroCpr', label: 'CPR nr.' },
            pbsGiroBankRegNr: { name: 'PbsGiroBankRegNr', label: 'Bank reg. nr.' },
            pbsGiroBankAccountNr: { name: 'PbsGiroBankAccountNr', label: 'Bank kontonr.' },
            dibsButton: 'Tilmeld hos Netaxept',
            bsButton: 'Tilmeld hos BetalingsService',
            dibsSuccess: 'DIBS autorisation gennemført',
            dibsError: 'Der skete en fejl med DIBS (statuskode {0}), prøv venligst igen',
            messageOk: 'Borgeren er opdateret',
            messageIsSync: 'Borgeren afventer synkronisering og kan ikke opdateres',
            cprNumber: 'CPR-nummer',
            getCprBtn: 'Hent CPR-nummer'
        },
        citizenTable: {
            field: 'Felt',
            new: 'Nye værdier',
            old: 'Gamle værdier',
            yes: 'Ja',
            no: 'Nej'
        },
        citizenOverview: {
            dayOfWeekNo: 'Bestillingsdag',
            typeOfCitizen: 'Borgertype',
            linkTitle: 'Se alle {0} borgere',
            daysLinkTitle: 'Se alle {0} borgere for {1}',
            weekDays: Translations.global.weekDays,
            citizenTypes: Translations.global.citizenTypes,
            inactiveCitizen: 'Inaktiv'
        },
        viewCitizens: {
            table: {
                name: 'Navn',
                address: 'Adresse',
                typeOfCitizen: 'Borgertype',
                actions: 'Handlinger',
                chooseCitizen: 'Vælg borger',
                inactive: 'Inaktiv'
            }
        },
        userList: {
            table: {
                name: 'Navn',
                username: 'Brugernavn',
                district: 'Distrikt',
                subDistrict: 'Underdistrikt',
                superuser: 'Superbruger',
                pdauser: 'PDA',
                inactive: 'Inaktiv',
                editUserTitle: 'Rediger bruger',
                changePasswordTitle: 'Skift adgangskode',
                changeUsernameTitle: 'Skift brugernavn',
                inactiveUser: 'Inaktivere bruger'
            }
        },
        createInternalUser: {
            messages: {
                userNameUpdated: 'Brugernavn er opdateret',
                userUpdated: 'Bruger er opdateret'
            }
        },
        viewUsers: {
            table: {
                name: 'Navn',
                username: 'Brugernavn',
                roles: 'Roles',
                phoneAndEmail: 'Telefon',
                inactive: 'Inaktiv',
                actions: 'Handlinger'
            },
            labels: {
                editUser: 'Rediger bruger',
                changeUsername: 'Skift brugernavn',
                changePassword: 'Skift adgangskode',
                inactiveUser: 'Inaktivere bruger'
            },
            userUpdated: 'Bruger {0} er opdateret',
            confirmDialog: {
                description: 'Er du sikker på, at du vil inaktivere <b>{0}</b> bruger?',
                confirmed: 'Bruger {0} er inaktiveret'
            }
        },
        viewUserHistory: {
            table: {
                changedData: 'Ændret dato',
                oldUsername: 'Gammelt brugernavn',
                newUsername: 'Nyt brugernavn'
            },
            labels: {
                currentUsername: 'Nuværende brugernavn',
                userId: 'Bruger ID'
            }
        },
        citizenStatus: {
            actionText: 'Vælg en årsag',
            logLink: 'Log',
            logLinkTitle: 'Se historik for {0}',
            info: 'Til orientering<br/>Ingen handling påkrævet',
            dialog: {
                dialogTitle: 'Aktion for {0}',
                dialogActionText: 'Udfør',
                form: {
                    actionSelect: 'Vælg en årsag',
                    reasonField: 'Årsag'
                }
            },
            transferDialog: {
                chooseReasonForTransfer: 'Vælg årsag til overførsel',
                messageOk: '{0} blev overført med grunden {1}',
                messageAlreadyDone: 'Borgeren er allerede blevet overført',
                messageError: 'Handlingen blev ikke gennemført for {0}'
            },
            table: {
                date: 'Dato',
                name: 'Navn',
                phone: 'Telefon',
                typeOfCitizen: 'Borgertype',
                subDistrict: 'Underdistrikt',
                status: 'Status'
            }
        },
        citizenDrafts: {
            statusTitle: 'Inaktiveringer',
            noteTitle: 'Sager',
            unsubscribedTitle: 'Udmeldelser',

            statusColumns: [
                'createdBy',
                'createDate',
                'customerNo',
                'firstName',
                'lastName',
                'statusFrom',
                'statusTo',
                'inactivityReason'
            ],
            noteColumns: ['createdBy', 'createDate', 'customerNo', 'firstName', 'lastName', 'noteComment'],
            unsubscribeColumns: [
                'createdBy',
                'createDate',
                'customerNo',
                'firstName',
                'lastName',
                'unsubscribeComment'
            ],

            commonDisplayedColumn: ['actions'],

            noResults: 'Ingen resultater fundet',

            approvePrompt: {
                header: 'Godkend {0}',
                text: 'Vil du godkende <b>{0}</b> {1}?',
                messageOk: '{0} for {1} er blevet godkendt'
            },

            deletePrompt: {
                header: 'Slet {0}',
                text: 'Vil du slette <b>{0}</b> {1}?',
                messageOk: '{0} for {1} er blevet slettet'
            },

            messageError: 'Der skete en fejl med at starte {0}, prøv igen',

            draftsType: {
                Note: 'sag',
                Status: 'inaktivering',
                Unsubscribe: 'udmeldelse'
            },

            createdBy: 'Bruger',
            userMunicipality: 'Kommune<br/>Bruger',
            municipality: 'Kommune',
            createDate: 'Tidspunkt',
            customerNo: 'Kundenr.',
            firstName: 'Fornavn',
            lastName: 'Efternavn',
            statusFrom: 'Gældende Fra',
            statusTo: 'Gældende Til',
            noteComment: 'Kommentar',
            unsubscribeComment: 'Årsag',
            inactivityReason: 'Årsag',

            edit: 'Rediger',
            approve: 'Godkend',
            delete: 'Slet'
        },
        pdaStatistics: {
            users: 'Brugere',

            PDA: 'PDA:',
            FAX: 'FAX:',

            table: {
                name: 'Navn',

                numberOfPdaOrders: 'Bestillinger',
                numberOfTransfers: 'Overførsler',
                totalOfPdaOrdersAndTransfers: 'Bestillinger og overførsler',
                percentageOfPdaOrders: 'Andel af total',

                numberOfFaxOrders: 'Bestillinger',
                customerServiceTransfers: 'Overførsler',
                percentageFaxOrders: 'Andel af total',

                totalOrders: 'Total',

                noResults: 'Ingen resultater fundet, finjuster din søgning'
            }
        },
        deliveryDateChange: {
            fromDate: 'Original leveringsdato',
            toDate: 'Ny leveringsdato',
            originalOrderDate: 'Original opfølgningsdag',
            destinationOrderDate: 'Ny opfølgningsdag',
            addButton: 'Tilføj ny leveringsdato',
            editButton: 'Rediger leveringsdato',
            editedBy: 'Redigeret af',
            lastEdited: 'Sidste ændring',
            edit: 'Rediger',
            delete: 'Slet',
            notifyingMessage: 'Vær opmærksom på ændret leveringsdag',
            deleteMessage: 'Leveringsdato-ændringen fra {0} til {1} er slettet.',
            numOfChanges: 'Leveringsdato ændringer',
            emptyDeliveryDates: 'Der er ingen aktive alternative leveringsdatoer.',
            showHistoryLogButton: 'Vis historiske ændringer',
            hideHistoryLogButton: 'Skjul historiske ændringer',
            deliveryInfo: {
                title: 'Leveringsdag',
                description:
                    'Leveringsdag ændres for alle typer af borgere og ændringen er gældende for alle kommuner. Hvis du ændrer en torsdag til en onsdag, vil alle med leveringsdag torsdag nu få onsdag i check-out i stedet.'
            },
            followUpInfo: {
                title: 'Opfølgningsdag',
                description:
                    'Opfølgningsdag ændres kun for seddel, ringer-selv og tast-selv borgere, men er også gældende for alle kommuner. Hvis en leveringsdag er ændret fra torsdag til onsdag, vil opfølgningsdagen i samme uge (tirsdag) skulle ændres til mandag i stedet.'
            }
        },
        deliveryDateChangeHistory: {
            title: 'Historiske ændringer for alternative leveringsdatoer',
            startDate: 'Startdato',
            endDate: 'Slutdato',
            emptyHistoryDates: 'Der er ingen logfiler på historikken.'
        }
    };

    public static search = {
        field: {
            placeholder: 'Søg på bestillingsnummer eller varenavn'
        },
        suggestions: {
            goToCategory: 'Gå til siden'
        },
        noExistingProduct: 'Produktet med id = {0} blev ikke fundet'
    };

    public static commerce = {
        productFilterPage: {
            products: 'varer',
            sortBy: 'Sortér efter',
            itemsPerPageLabel: 'Varer pr. side:',
            recommended: {
                key: 'Anbefalet til dig',
                value: ''
            },
            lastPurchased: {
                key: 'Sidst købt',
                value: 'lastpurchased'
            },
            mostPurchased: {
                key: 'Mest købt',
                value: 'mostpurchased'
            },
            alphabetic: {
                key: 'Alfabetisk',
                value: 'alphabetic'
            },
            priceLowest: {
                key: 'Billigst',
                value: 'price'
            },
            priceHighest: {
                key: 'Dyrest',
                value: 'pricedesc'
            }
        },
        productHead: {
            products: 'Vare',
            orderNumber: 'Bestil.nr.',
            price: 'Pris',
            total: 'Total',
            quantity: 'Antal',
            noter: 'Noter',
            replacedItem: 'Erstatter: ',
            originalPrice: 'Originalpris: ',
            reducedQty: 'Antal bestilt: ',
            credit: 'Krediter',
            newUnitPrice: 'Ny stykpris',
            newTotalPrice: 'Ny totalpris'
        },
        productListItem: {
            discountItem: 'Prismatch',
            campaign: {
                mixOffer: 'Uge {0}: Mix - {1} stk. for {2}',
                xForY: 'Uge {0}: {1} stk. for {2}',
                discount: 'Uge {0}: Ugeavis'
            },
            lastPurchased: 'Sidst købt d. {0}',
            temporarilySoldout: 'Midlertidig udsolgt',
            replacementProduct: 'Erstatningsvare til ovenstående',
            noProducts: 'Ingen produkter fundet'
        },
        productDetails: {
            linkToMedicinal:
                'https://laegemiddelstyrelsen.dk/da/apoteker/koeb-og-salg-af-medicin-paa-internettet/detailforhandlere-der-lovligt-saelger-medicin-paa-nettet',
            declarations: {
                declarationHead: 'Varedeklaration',
                ingredientList: 'Ingrediensliste',
                nutrition: 'Næringsindhold pr. 100 g',
                nutritionEnergy: 'Energi',
                nutritionFat: 'Fedt',
                nutritionSaturatedFattyAcids: 'heraf mættede fedtsyrer',
                nutritionCarbohydrate: 'Kulhydrat',
                nutritionSugars: 'heraf sukkerarter',
                nutritionDietaryFibers: 'Kostfibre',
                nutritionProtein: 'Protein',
                nutritionSalt: 'Salt',
                nutritionKiloJoule: 'kJ',
                nutritionKiloCalories: 'kcal',
                nutritionGram: 'g',
                vkNumber: 'Varefakta kontrolleret'
            },
            error: {
                notOnCatalog: 'Varen er ikke længere aktiv'
            }
        },
        addtobasket: {
            buy: 'Køb',
            temporarilySoldout: 'Udsolgt',
            inBasket: '{0} i kurv',
            messageOk: 'Kurven er opdateret',
            removeBtnAlt: 'Fjern {0} fra kurven',
            addOneAlt: 'Tilføj 1 stk af {0}',
            removeOneAlt: 'Fjern 1 stk af {0}',
            qtyFieldAlt: 'Antal af {0} i kurven',
            qtyWarningRules: {
                max: 9,
                moreThanTen: 'Vær opmærksom på, at du har lagt mere end {0} stk. af det samme produkt i indkøbskurven.'
            }
        },
        basket: {
            basket: 'Indkøbskurv',
            maxbuy: 'Kunden har loft på {0} pr. indkøb',
            buyLimitReached: 'Kundens loft er oversteget med {0}',
            linePluralization: {
                line: 'linje',
                lines: 'linjer'
            },
            productPluralization: {
                product: 'vare',
                products: 'varer'
            },
            campaignPriceAlt: 'Kampagnepris for {0}',
            mainPriceAlt: 'Pris for {0} pr. stk.',
            totalPriceAlt: 'Pris for {0} i alt',
            unitPriceAlt: 'Enhedspris for {0} pr. stk.',
            quantity: 'I alt {0}, {1}',
            bottleDeposit: 'Pant: {0} enhed',
            bottleDeposits: 'Pant: {0} enheder',
            amount: 'I alt {0}',
            savedOnDiscount: 'Sparet på tilbud:',
            goToBasket: 'Gå til kurven',
            emptyBasket: 'Din indkøbskurv er tom',
            impersonate: 'Ingen borger valgt',
            clearBasket: 'Tøm kurv',
            clearBasketPrompt: {
                header: 'Tøm kurv',
                text: 'Er du sikker på, at du vil tømme din kurv?'
            },
            deliveryDate: 'Leveringsdag',
            preferredDeliveryDays: 'Foretrukne leveringsdage',
            weekOffer: 'Ugens tilbud',
            checkout: 'Accepter bestilling',
            remember: 'Har du husket?',
            normalFee: 'Normalt gebyr',
            noteComment: 'Bestilt i samarbejde med: ',
            noteCommentAddNote: 'The order is made by {0} in cooperation with {1}',
            urgentDeliveryFee: 'Akutleveringsgebyr',
            noFee: 'Intet gebyr (Intervare fejl)',
            addWeeklyNewspaper: 'Tilføjet korrekt ugeavis til ordren',
            confirmOrderOverLimitation: 'Denne ordre er over {0} kr. Ønsker du at fortsætte dit køb?',
            repeatedOrder: 'Jeg har gentaget ordren',
            validation: {
                deliveryDate: {
                    required: 'Indtast en gyldig dato<br/>Format dd/mm/YYYY',
                    notAvailable: 'Denne dato er ikke tilgængelig'
                },
                repeatedOrder: 'Gentag venligst ordren for at acceptere bestilling',
                citizenInactive: {
                    forPeriod: 'OBS - borgeren er inaktiv mellem:',
                    forFuture: 'OBS - borgeren er inaktiv fra den:'
                }
            },
            latestOrderTime: {
                header: 'Bestillings tid udløber snart',
                text: 'Dagens bestillings tid udløber <strong>kl: {0}</strong><br>Hvis ordren ikke fuldføres inden da, vil den blive flyttet til næste bestillings dag',
                expiredHeader: 'Bestillings tid udløbet',
                expiredText: 'Dagens bestillings tid er udløbet og ordren er flyttet til næste bestillings dag'
            },
            catalogItemDialog: {
                header: 'Bestil katalog-nummer'
            },
            citizenBlocked: {
                header: 'Borgeren er blokeret',
                text: 'Der kan ikke gennemføres en ordre når borgeren er blokeret'
            },
            merge: {
                header: 'Ordre sammenlægning',
                text: 'OBS: Ordren bliver sammenlagt med eksisterende ordre',
                textAllowMerge:
                    '<p class="alert alert-danger">OBS: Du er ved at sammenlægge en ordre hvor en anden bruger har påbegyndt redigering af ordren. Det kan betyde at antallet af varer på ordren bliver fordoblet. Tjek det venligst efterfølgende!</p>'
            },
            edit: {
                header: 'Ordre redigering',
                text: 'OBS: Du er ved at redigere en eksisterende ordre<br/>Hvis du vælger at annullere vil ordren ikke ændres'
            },
            deliveryDateDialog: {
                title: 'Kundens første bestillingsdag begrænser leveringsdagen',
                text: 'Vælg venligst en leveringsdag efter {0}, eller ret kundens første bestillingsdag.'
            }
        },
        order: {
            completed: 'Ordre gennemført',
            notFound: 'Ordre ikke fundet',
            orderHead: 'Din bestilling er gennemført!',
            goToFrontpage: 'Gå til forsiden',
            continueShopping: 'Fortsæt med næste indkøb',
            delivery: {
                citizen: 'Tak for ordren, den vil blive leveret d. {0}',
                municipality: 'Tak for ordren til {0}, den vil blive leveret d. {1}'
            },
            info: {
                head: 'Ordre info',
                id: 'Ordrenummer:',
                total: 'Ordrebeløb:'
            },
            summary: {
                head: 'Ordre oversigt',
                products: 'Varer:',
                discount: 'Rabat:',
                total: 'Total:'
            }
        },
        invoice: {
            invoiceHeader: 'Faktura',
            creditNoteHeader: 'Kreditnota',
            creditedInvoiceHeader: 'Krediteret faktura',
            creditedHint: '{0} krediteret'
        },
        favoriteToggle: {
            text: 'Tilføj til favoritter'
        },
        orders: {
            order: 'Ordre: {0}',
            deliveryDay: 'Leveringsdag: {0}',
            noOrders: 'Der er ikke fundet nogen ordrer',
            noRows: 'Der er ikke nogle linjer på ordren',
            status: {
                ordered: 'Bestilt',
                processing: 'Frigivet',
                delivered: 'Faktureret',
                canceled: 'Annulleret'
            },
            header: {
                title: 'Ordre',
                orderNumber: 'Ordrenummer',
                orderStatus: 'Ordrestatus'
            },
            controls: {
                copy: 'Kopier til kurv',
                cancelAndCopy: 'Rediger',
                cancel: 'Annuller'
            },
            dialogText: {
                copy: 'Vil du kopiere denne ordre til kurv?',
                cancelAndCopy:
                    'Vil du redigere denne ordre? Ved at trykke OK overskriver du den nuværende kurv, hvor der allerede ligger en eller flere varer.',
                cancelAndCopyNoBasket:
                    'Vil du redigere denne ordre? Ved at trykke OK lægges alle varerne i kurven igen. Husk at acceptere bestillingen igen efter rettelserne er foretaget.',
                cancel: 'Vil du annullere denne ordre?',
                cancelReason: 'Annuller årsag'
            }
        },
        creditNote: {
            amount: 'Beløb',
            noOrders: 'Der er ikke fundet nogen kreditnota',
            status: {
                open: 'Under behandling',
                closed: 'Behandlet'
            },
            controls: {
                mail: 'Indtast e-mail',
                mailSend: 'Send bilag',
                view: 'Se bilag'
            },
            messages: {
                emailSent: 'E-mail er afsendt',
                emailError: 'Du skal indtaste en gyldig e-mail-adresse'
            }
        },
        catalogues: {
            weekLabel: 'Ugeaviser',
            specialLabel: 'Kataloger'
        },
        reservation: {
            alternativeButtonTitle: 'Tjek det udskiftningsprodukt, vi foreslår',
            alternativeButtonLabel: 'Foreslået produkt',
            replaceButtonLabel: 'Erstatning',
            messageOk: 'Produktet er blevet erstattet af det nye'
        }
    };

    public static callListAdmin = {
        paths: {
            planning: {
                path: 'planning',
                title: 'Planlægning'
            },
            calllist: {
                path: 'calllist',
                title: 'Ringeliste'
            }
        },
        title: 'Ringelisteadministration',
        weekDays: ['Uspecificeret', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag'],
        citizenTypes: ['Ringeborger', 'Seddelborger', 'Tast-selv borger', 'Ring-selv borger'],
        callCitizenType: 'Ringeborger type',
        callCitizenTypes: ['Alle', 'Efter 4. kald', 'Efter aut. 4. og 5. kald'],
        navigation: {
            planning: 'Planlægning',
            callLists: 'Ringelister',
            callers: 'Bestillere',
            assigncitizens: 'Tildel nye borgere',
            functions: 'Funktioner',
            addCallDay: 'Opret ringedag',
            editCallDay: 'Opdatér ringedag',
            notifyFourthTrys: 'Notificer 4. opkald',
            endDay: 'Afslut ringedag',
            endFaxDay: 'Afslut faxdag',
            addCallList: 'Tilføj ringeliste',
            addCaller: 'Tilføj bestiller',
            generateLists: 'Generer lister',
            displayCallcount: 'Vis antal kald',
            refresh: 'Genindlæs',
            pendingCalls: 'Vis afventende kald'
        },
        assigncitizens: {
            header: 'Tildel nye borgere',
            confirmText: 'Vil du tildele nye borgere?',
            okBtn: 'Tildel',
            cancelBtn: 'Annuller'
        },
        callListPage: {
            header: 'Ringeliste',
            citizenTab: {
                tabHeader: 'Borgere',
                header: 'Borgere',
                description: 'Vælg en eller flere borgere og træk dem ind til et tidsinterval.'
            },
            propertiesTab: {
                tabHeader: 'Egenskaber',
                header: 'Egenskaber',
                noneSelected: 'Vælg et event for at se egenskaber...',
                startHoursLabel: 'Fra time',
                startMinsLabel: 'Fra min.',
                okBtn: 'Gem ændringer',
                deleteBtn: 'Slet interval',
                removeBtn: 'Fjern valgte borger(e)',
                citizenHeader: 'Borgere i valgte interval',
                confirmDelete: 'Vil du slette intervallet?',
                messageDeleteOk: 'Intervallet er slettet'
            },
            intervalTab: {
                tabHeader: 'Intervaller',
                header: 'Intervaller',
                callBlock: 'Ringeinterval',
                breakBlock: 'Pause'
            },
            addCitizensError: 'Det lykkedes ikke at tildele:{0}'
        },
        displayCallcount: {
            header: 'Vis antal kald',
            resultLabel: 'Resultat',
            type: 'Type',
            weekday: 'Ugedag',
            okBtn: 'Vis resultat',
            cancelBtn: 'Luk'
        },
        editCallDay: {
            add: 'Tilføj ny',
            edit: 'Rediger',
            header: 'ringedag',
            callDayDate: 'Dato',
            callInAdvanceAllowed: 'Ring forud tilladt',
            includeExtraDay: 'Medtag ekstra dag',
            moveCallsFrom: 'Flyt kald fra',
            okBtn: 'Ok',
            cancelBtn: 'Annuller',
            confirmText: 'Er du sikker?',
            messageError: 'Det er ikke længere muligt at slette denne ringedag'
        },
        deleteCallDay: {
            confirm: 'Vil du slette ringedagen?',
            deleteBtn: 'Slet ringedag',
            messageOk: 'Ringedagen er slettet',
            messageNotOk: 'Det er ikke muligt at slette ringedagen'
        },
        editCallList: {
            add: 'Tilføj ny',
            edit: 'Rediger',
            header: 'ringeliste',
            name: 'Navn',
            primaryCaller: 'Primær bestiller',
            actualCaller: 'Aktuel bestiller',
            okBtn: 'Ok',
            cancelBtn: 'Annuller',
            deleteBtn: 'Slet ringeliste',
            confirmText: 'Er du sikker?',
            messageError: 'Det er ikke muligt at slette denne ringeliste'
        },
        editCaller: {
            add: 'Tilføj ny',
            edit: 'Rediger',
            header: 'bestiller',
            firstName: 'Fornavn',
            lastName: 'Efternavn',
            userId: 'Bruger',
            shareWith: 'Del med',
            localNumber: 'Lokal nummer',
            callerCapacity: 'Ringekapacitet',
            okBtn: 'Ok',
            cancelBtn: 'Annuller',
            deleteBtn: 'Slet bestiller',
            confirmText: 'Er du sikker?',
            messageError: 'Det er ikke muligt at slette denne bestiller'
        },
        endDay: {
            header: 'Afslut ringedag',
            doCallsDate: 'Udfør 4. kald d.',
            fromTime: 'Fra kl.',
            timeSlots: [
                { value: 8, label: '8.00' },
                { value: 9, label: '9.00' },
                { value: 10, label: '10.00' },
                { value: 11, label: '11.00' },
                { value: 12, label: '12.00' },
                { value: 13, label: '13.00' },
                { value: 14, label: '14.00' },
                { value: 15, label: '15.00' },
                { value: 16, label: '16.00' }
            ],
            yesterday: {
                text: 'Dagen i går er ikke lukket',
                confirm: 'Vil du lukke dagen i går?'
            },
            confirmText: 'Er du helt sikker på, at du vil lukke ringedagen og overføre 4. kald til d. {0} kl. {1}?',
            messageOk: 'Ringedagen er lukket og {0} borgere blev overført'
        },
        endFaxDay: {
            header: 'Afslut faxdag',
            text: 'Vil du afslutte faxdagen?',
            messageOk: 'Faxdagen blev lukket'
        },
        notifyFourthTrys: {
            confirm: 'Vil du notificere alle 4. opkald?',
            messageOk: 'Notifikationer er afsendt'
        },
        generateLists: {
            header: 'Generer lister',
            type: 'Type',
            municipality: 'Kommune',
            allMunicipality: 'Alle kommuner',
            date: 'Dato',
            dateMessage:
                'Bemærk at for ring-selv borgere er det KUN den valgte dato der kommer med på den downloadede liste.',
            okBtn: 'Download borger liste',
            cancelBtn: 'Annuller',
            fileName: 'liste.xsl'
        },
        planningPage: {
            header: 'Planlægning',
            propertiesTab: {
                tabHeader: 'Egenskaber',
                header: 'Egenskaber',
                description: 'Vælg en ringeliste/bestiller eller et interval for at se egenskaber',
                capacity: 'Kapacitet (time)',
                updateCapacityBtn: 'Opdater kapacitet',
                totalCapacity: 'Totalkapacitet',
                availableCapacity: 'Ledig kapacitet',
                remainingCalls: 'Manglende kald',
                remainingCallsHeader: 'Manglende kald',
                completedCallsHeader: 'Færdige kald',
                removeBtn: 'Fjern valgte borger(e)',
                removeConfirm: 'Er du sikker på at du vil fjerne de valgte borgere?'
            },
            citizensTab: {
                tabHeader: 'Borgere',
                header: 'Borgere',
                description: 'Vælg en eller flere borgere og træk dem ind til et tidsinterval.',
                assignBtn: 'Fordel alle automatisk'
            },
            moveCitizensError: 'Det lykkedes ikke at flytte:{0}'
        },
        citizenDetails: {
            loading: 'Indlæser...',
            citizenNo: 'Nummer',
            interval: 'Interval',
            delivery: 'Levering',
            deliveryStart: 'Startdato',
            deliveryEnd: 'Slutdato',
            resignationDate: 'Udmeldelsesdato',
            tryCount: 'Antal intet svar',
            notAvailable: 'Ikke angivet',
            municipality: 'Kommune',
            customerNumber: 'Kundenr',
            district: 'Distrikt'
        },
        callerPresence: {
            gettingStatus: 'Henter status...',
            status: {
                Offline: 'Offline',
                Available: 'Tilgængelig',
                Busy: 'Optaget',
                Away: 'Væk',
                Break: 'Pause',
                Meeting: 'Møde',
                Training: 'Uddannelse',
                Meal: 'Måltid',
                OutOfOffice: 'Ude af kontoret'
            },
            outOfOffice: {
                message: 'Ikke til stede'
            },
            currentStatusLabel: 'Status: {0}',

            changePresenceStatusDialog: {
                dialogTitle: 'Genesys - Opdater status',
                messageLabel: 'Besked',
                statusLabel: 'Status',
                outOfOfficeWarning: 'Din status er "Ikke på kontoret". Denne status skal ændres i Genesys.'
            },
            callerPresenceErrorMessages: {
                userNotFound: 'Bruger ikke fundet',
                noPresenceStatus: 'Ingen status'
            },
            callerPresenceSuccess: 'Din status blev opdateret',
            callerPresenceErrorCodes: {
                'app.not.authorized.for.scope': 'Appen er ikke autoriseret til at bruge scope',
                'too.many.requests': 'Forespørgsler overskred maksimum',
                'too.many.requests.retry.after': 'For mange forsøg. Antal forespørgsler overskred maksimum',
                'internal.server.error': 'Der skete en fejl',
                'service.unavailable': 'Tjenesten er ikke tilgængelig',
                'unknown': 'Der skete en ukendt fejl'
            }
        }
    };

    public static pages = {
        frontpage: {
            welcomeHeader: 'Velkommen {0}',
            welcomeMessage: 'Du kan nu starte dit indkøb. Brug ovenstående søgefelt til at finde ønskede varer.',
            existingOrderText: 'Der ligger en ordre.',
            existingOrderLink: 'Klik her for at redigere den.',
            quicklinks: 'Dine:',
            chooseAnotherCitizen: 'Vælg en anden borger',
            weeklyNewspaper: 'Ugeavis',
            weeklyNewspaperLink: 'http://ugeavis.intervare.dk/'
        },
        homecarer: {
            welcome: 'Velkommen {0}',
            headerSearch: 'Vælg borger:',
            headerCitizens: 'Viser borgere:',
            orderDay: 'Bestillingsdag',
            unsubscribe: 'Afbestil',
            impersonate: 'Køb',
            inactive: 'Borger inaktiv<br/> Kontakt kundeservice',
            seeOrder: 'Se bestilling',
            noCitizens: 'Der blev ikke fundet nogle borgere',
            noOrderDays: 'Der blev ikke fundet nogle bestillingsdage med borgere',
            transferred: 'Overført',
            blocked: 'Borger blocked<br/> Kontakt kundeservice'
        },
        loginPage: {
            formLabel: 'Log ind formular',
            username: 'Brugernavn',
            password: 'Adgangskode',
            loginBtn: 'Log ind',
            forgotPasswordFormAssistLabel: 'Formular til at nulstille din adgangskode',
            forgotPasswordBtn: 'Glemt adgangskode?',
            forgotPasswordTitle: 'Glemt adgangskode?',
            forgotPasswordSubtitle: 'Du kan nulstille din adgangskode her',
            forgotPasswordInput: 'Indtast din e-mail-adresse',
            forgotPasswordSubmit: 'Nulstil adgangskode',
            forgotPasswordGoBackBtn: 'Gå tilbage',
            forgotPasswordResetText: `Du vil modtage en e-mail med instruktioner om, hvordan du indstiller en ny adgangskode.<br><br>Hvis du ikke har modtaget en e-mail fra os inden for 5 minutter, så kontakt venligst kundeservice på tlf. <a href="tel:${Translations.variables.customerServicePhone}">${Translations.variables.customerServicePhone}</a> eller e-mail ved at skrive til <a href="mailto:${Translations.variables.customerServiceEmail}">${Translations.variables.customerServiceEmail}</a>`,
            forgotPasswordText: `Hvis du er hjemmehjælper, som bestiller dagligvarer for borgerne, bedes du ved glemt password spørge en kollega.<br><br>Er der ingen i din gruppe, som kan huske koden, skal kundeservice kontaktes på <a href="tel:${Translations.variables.customerServicePhone}">${Translations.variables.customerServicePhone}</a>`
        },
        newPassword: {
            path: 'nyt-password',
            title: 'Opret ny adgangskode',
            explanatoryText: 'Du skal nu oprette en ny adgangskode ved at udfylde denne formular',
            newPasswordLabel: 'Ny adgangskode',
            newPasswordConfirmLabel: 'Gentag ny adgangskode',
            newPasswordConfirmError: 'Adgangskoder matcher ikke',
            newPasswordSubmit: 'Opret ny adgangskode',
            expiredText: 'Det ser ud til, at du har brugt et gammelt link til at nulstille din adgangskode',
            goToFrontPage: 'Gå til forsiden'
        },
        editPassword: {
            path: 'rediger-password',
            title: 'Skift adgangskode',
            explanatoryText: 'Du skal skifte din adgangskode for at fortsætte',
            formLabel: 'Formular til at ændre din adgangskode',
            passwordLabel: 'Midlertidig adgangskode',
            newPasswordLabel: 'Ny adgangskode',
            newPasswordHelp: 'Skal indeholde minimum 6 tegn med både bogstaver og tal',
            newPasswordConfirmLabel: 'Gentag ny adgangskode',
            editPasswordSubmit: 'Skift adgangskode',
            voluntaryTitle: 'Skift adgangskode',
            goToFrontPage: 'Gå til forsiden',
            editPasswordSuccess: 'Password er opdateret.'
        }
    };

    public static administration = {
        ariaProfileName: 'Nuværende bruger',
        callList: {
            tabs: {
                current: {
                    title: 'Aktuelle'
                },
                finished: {
                    title: 'Afsluttet'
                }
            },
            textSet: {
                call: 'Ring',
                notFound: 'Din ringeliste er tom',
                callAhead: 'I dag er <strong>ringe forud</strong> automatisk aktiveret pga. de mange opkald.'
            },
            citizenBlocked: {
                header: 'Borgeren er blokeret',
                text: 'Der kan ikke gennemføres ordrer når borgeren er blokeret'
            }
        },
        callSection: {
            currenCall: 'IGANGVÆRENDE OPKALD',
            currentUser: 'IGANGVÆRENDE BORGER'
        },
        menu: {
            callList: 'RINGELISTE',
            searchCustomer: 'SØG BORGER',
            municipilityportal: 'KOMMUNEPORTAL',
            searchNemlig: 'SØG NEMLIG',
            stock: 'LAGER',
            intervare: 'INTERVARE',
            municipalityadmin: 'ADMIN',
            logOut: 'LOG UD'
        },
        citizen: {
            menuInfo: 'KUNDEPROFIL',
            menuNotes: 'HISTORIK',
            menuOrders: 'ORDRER',
            menuCreditNotes: 'KREDITNOTA',
            menuDebtorPosts: 'DEBITORPOSTER',
            informationHeader: 'Information',
            deliveryHeader: 'Bestilling & levering',
            specialInformationHeader: 'Sager & særoplysninger',
            paymentHeader: 'Betaling',
            name: 'Navn',
            number: 'Kundenr',
            username: 'Brugernavn',
            address: 'Adresse',
            phone: 'telefoner',
            zipCodeCity: 'Postnr. & by',
            municipality: 'Kommune',
            district: 'Distrikt',
            subDistrict: 'Underdistrikt',
            customerType: 'Kundetype',
            customerStatus: 'Status',
            replacementProducts: 'Erstatningsvarer',
            replacementProductsValue: 'NEJ',
            isBlocked: 'Blokeret',
            isBlockedValue: 'Borgeren er spærret',
            deliveryInterval: 'Bestilling',
            deliveryDay: 'Levering',
            keyForCustomer: 'Nøgle til kunde',
            deliveryDriver: 'Chauffør',
            lastBuyDate: 'Sidste køb',
            nextDeliveryDate: 'Næste levering',
            plannedCaller: 'Primær kontakt',
            paymentStatus: 'Status',
            paymentMethod: 'Betaling Metode',
            paymentBalance: 'Saldo (RV)',
            hasOpenOrder: 'Åben bestilling',
            showSpecialInformation: 'Vis særoplysninger',
            citizenTypes: Translations.global.citizenTypes,
            inactivationPrompt: {
                header: 'Inaktivering',
                start: 'Start inaktivering',
                stop: 'Stop inaktivering',
                form: {
                    step1: 'Dato',
                    step2: 'Oplysninger til log',
                    nextStep: 'Videre',
                    dateFrom: 'Inaktiv fra',
                    dateTo: 'Inaktiv til',
                    receivedDate: 'Modtaget dato',
                    receivedMethod: 'Hvordan er oplysningerne modtaget?',
                    receivedFrom: 'Oplysninger modtaget fra',
                    receivedVocally: 'Mundtligt',
                    receivedWritten: 'Skriftligt',
                    submit: 'Bogfør',
                    messageOk: 'Inaktivering er startet. Der kan gå nogle minutter før opdateringen er gennemført.',
                    messageError: 'Der skete en fejl med at starte inaktivering, prøv igen'
                }
            },
            unsubscribePrompt: {
                actionOpen: 'Udmeld borger',
                header: 'Udmeld borger',
                comment: 'Bemærkning:',
                commentHint: 'Skriv fx en anden grund',
                submit: 'Udmeld',
                messageOk: 'Borgeren er nu udmeldt. Der kan gå nogle minutter før opdateringen er gennemført.'
            },
            extraOrderPrompt: {
                actionOpen: 'Bestil indkøb',
                title: 'Vælg en årsag for indkøb',
                actionText: 'Vælg',
                messageOk: 'Indkøb bestilt til {0}',
                extraOrderReasonsTranslations: {
                    DriverSentOnPurchase: 'Chauffør sendes på indkøb',
                    TaxiIsShippedOnPurchase: 'Taxi sendes på indkøb',
                    DriverAdditionalAttemptsToDeliverGoodsIntervareError:
                        'Chaufførs ekstra forsøg for at aflevere varer (Intervare fejl)',
                    DriverAdditionalAttemptsToDeliverGoodsMunicipalityMustBeInvoiced:
                        'Chaufførs ekstra forsøg for at aflevere varer (kommunen skal faktureres)'
                }
            }
        },
        citizenHistory: {
            syncing: 'Synkroniserer',
            tabs: {
                all: 'Alle',
                calls: 'Opkald',
                orders: 'Køb',
                notes: 'Sager',
                changes: 'Ændringer'
            },
            newNoteBtn: 'Ny sag',
            detailsNoteBtn: 'Se detaljer',
            detailsOrderBtn: 'Se bestilling',
            form: {
                submit: 'Opret',
                cause: 'Årsag',
                subCause: 'Underårsag',
                subSubCause: 'Vælg grund',
                comment: 'Kommentar',
                emailRecipients: 'Modtager(e)',
                emailBody: 'Mail tekst',
                hintUserField: 'Udfyldes til e-mail',
                messageOk: 'Sag oprettet'
            },
            noteDetails: {
                header: 'Viser sag',
                date: 'Dato',
                noSubSubCause: 'Ingen'
            }
        },
        citizenCreditNotes: {
            actionOpen: 'Opret kreditnota',
            title: 'Opret kreditnota',
            invoice: 'Faktura',
            allLinesTitle: 'Angiv en grund for alle uspecificerede linjer',
            allLinesPlaceholder: 'Grund',
            labelInvoiceNo: 'Indtast fakturanummer',
            labelChooseReason: 'Vælg grund for kreditering',
            btnGetOrder: 'Hent ordre',
            btnPost: 'Bogfør',
            btnPostAll: 'Kreditér hele ordren',
            messageMustChange: 'Du skal ændre i mindst et produkt',
            messageOk: 'Kreditnota er oprettet',
            messageErrorDialogTitle: 'Fejl',
            messageError: 'Følgende produkter har brug for en grund til at blive returneret',
            messageErrorAllLines: 'Alle linjer er allerede krediteret'
        },
        citizenPriceAdjustment: {
            actionOpen: 'Priskorrektion',
            title: 'Priskorrektion',
            btnPost: 'Bogfør',
            messageMustChange: 'Du skal ændre i mindst et produkt',
            messageOk: 'Kreditnota er oprettet'
        },
        citizenDebtorPosts: {
            noRecords: 'Der blev ikke fundet nogen debitorposter',
            columns: {
                date: 'Dato',
                type: 'Bilagstype',
                typeNo: 'Bilagsnr.',
                description: 'Beskrivelse',
                amount: 'Beløb (RV)'
            },
            types: {
                invoice: 'Faktura',
                creditNote: 'Kreditnota',
                payment: 'Indbetaling',
                refund: 'Tilbagebetaling',
                reminder: 'Påmindelse'
            }
        },
        citizenQuickView: {
            actions: 'Handlinger',
            specialInformation: 'Særoplysninger',
            notesSectionTitle: 'Kundecenter',
            notes: 'Noter',
            writeNotes: 'Skriv noter',
            customerCard: 'Kundekort',
            history: 'Historik',
            newCallAppointment: 'Ny ringetid',
            showCustomerCard: 'Vis kundekort',
            showHistory: 'Vis historik',
            transfer: 'Overfør',
            doesNotAnswer: 'Svarer ikke',
            occupied: 'Optaget',
            newNote: 'Ny sag',
            pendingChanges: 'Seneste ændringer afventer synkronisering',
            transferStatus: {
                key: 'Overført',
                value: 'Borgeren er overført'
            },
            transferDialog: {
                chooseReasonForTransfer: 'Vælg årsag til overførsel',
                messageOk: '{0} blev overført',
                messageAlreadyDone: 'Borgeren er allerede blevet overført'
            },
            switchCitizenPrompt: {
                header: 'Varer i kurven',
                text: 'Vær opmærksom på, at der er varer i kurven. Vil du afslutte alligevel? Varerne bliver i kurven',
                messageOk: '{0} er nu aktiv'
            },
            takeOverLockPrompt: {
                header: 'Overtag borger?',
                text: 'En anden bruger er ved at handle for denne borger. Vil du overtage?'
            },
            newCallTimePrompt: {
                title: 'Skift ringetid',
                placeholder: 'Vælg ny intervalplacering',
                options: {
                    start: 'Starten',
                    mid: 'Midten',
                    end: 'Slutningen'
                },
                actionText: 'Skift',
                messageOk: '{0} har fået en ny ringetid'
            },
            noResponsePrompt: {
                title: 'Flyt borger',
                placeholder: 'Vælg ny tid',
                options: {
                    mid: 'Midten af dette interval',
                    end: 'Slutningen af dette interval',
                    startNext: 'Starten af næste interval',
                    endNext: 'Slutningen af næste interval'
                },
                actionText: 'Flyt',
                messageOk: '{0} har fået en ny ringetid',
                confirmDirectMove: 'Er du sikker?'
            },
            makeCall: {
                preMessage: 'Ringer op...',
                postMessage: 'Opkald overført til telefon'
            },
            noteAdded: {
                message: 'Noten blev tilføjet'
            },
            citizenActionError: 'Handlingen blev ikke gennemført for {0}'
        },
        citizenSearch: {
            forCustomer: 'Søg efter kunde',
            byParameters: 'Søg på navn, kundenr. eller telefonnr.',
            numFound: '{0} Resultater',
            orderDays: 'Bestillingsdage:',
            deliveryDays: 'Leveringsdage:',
            telephone: 't:',
            mobile: 'm:',
            filter: {
                resignedOnly: 'Søg udmeldte'
            }
        },
        pendingCalls: {
            pendingCallCount: 'Antal kald',
            callList: 'Ringe liste',
            startingTime: 'Start tid',
            noPendingCalls: 'Der er ingen afventende kald'
        }
    };

    public static intervare = {
        paths: {
            messageAdmin: {
                path: 'messages',
                title: 'Beskedhåndtering'
            },
            notesAdmin: {
                path: 'notes',
                title: 'Årsag'
            },
            settingsAdmin: {
                path: 'settings',
                title: 'Indstillinger'
            },
            weeklyOffersAdmin: {
                path: 'ugens-tilbud-admin',
                title: 'Ugens tilbud (Administration)'
            },
            pendingCalls: {
                path: 'afventende-kald',
                title: 'Afventende kald'
            },
            creationError: {
                path: 'fejl-ved-borgeroprettelser',
                title: 'Fejl ved borgeroprettelser'
            }
        },
        messageAdmin: {
            menuTitle: 'Beskedhåndtering',
            createTitle: 'Opret besked',
            editTitle: 'Rediger besked',
            createMessage: 'Opret ny besked',
            list: 'Beskeder',
            startDate: 'Gældende fra',
            endDate: 'Gældende til',
            isActive: 'Aktiv',
            textMessage: 'Besked',
            createBtn: 'Opret',
            goToEditBtn: 'Rediger',
            editBtn: 'Ret',
            deleteBtn: 'Slet',
            successMsg: 'Ny besked oprettet',
            deleteMsg: 'Beskeden er slettet',
            activateMsg: 'Beskeden er aktiveret',
            deactivateMsg: 'Beskeden er deaktiveret',
            confirmDeleteMsg: 'Vil du slette beskeden?',
            roleField: 'Vælg rolle for specifik visning',
            roles: {
                All: 'Alle',
                Admin: 'Admins',
                Citizen: 'Borgere',
                HomeCarer: 'Hjemmehjælpere',
                CustomerService: 'Kundeservice',
                MunicipalityAdmin: 'Kommunebrugere'
            }
        },
        notesAdmin: {
            menuTitle: 'Årsagshåndtering',
            subCause: {
                path: 'subcause',
                title: 'Underårsag'
            },
            navigation: {
                createCause: 'Opret årsag',
                createSubCause: 'Opret underårsag',
                createSubSubcause: 'Opret grund'
            },
            createCause: {
                header: 'Opret årsag',
                name: 'Navn',
                submit: 'Opret',
                messageOk: 'Årsagen blev oprettet',
                messageError: 'Kunne ikke oprette årsagen, forsøg igen'
            },
            editCause: {
                header: 'Rediger årsag',
                submit: 'Ret',
                messageOk: 'Årsagen er rettet',
                messageError: 'Kunne ikke rette årsagen, forsøg igen',
                confirmDelete: 'Vil du slette årsagen?',
                messageDeleteOk: 'Årsagen er slettet',
                messageDeleteError: 'Kunne ikke slette årsagen fordi: {0}'
            },
            createSubCause: {
                recipients: 'Alle varehuse - E-mail til modtagelse',
                recipientsDK01: 'Brøndby - E-mail til modtagelse',
                recipientsDK02: 'Århus - E-mail til modtagelse',
                emailSubject: 'Emnefelt til e-mail',
                template: 'E-mail-skabelon',
                templateHint:
                    'Tilgængelige felter: [felt til udfyldning], %bruger%, %kundenummer%, %kundens navn%, %kundens adresse%, %kommune%, %chauffør%, %postnummer og by%',
                messageRecipientError: 'Du skal indtaste en korrekt e-mail-adresse',
                emailHint: 'Brug mellemrum eller komma mellem hver adresse'
            }
        },
        settingsAdmin: {
            stockService: {
                name: 'Benyt Nemligs reservationssystem',
                title: 'Ved ON benytter vi Nemligs reservationssystem. Ved OFF har vi ingen forespørgsler mod reservationssystemet, i stedet benyttes falske reservationsID\'ere til produktreservationer. Hvis reservationssystemet er nede, og man ønsker at fortsætte med at handle, sættes denne til OFF.',
                messageOk: 'Andringerne er gemt.'
            },
            stockServiceErrorHandling: {
                name: 'Vis fejlbeskeder fra reservationssystemet',
                title: 'Ved ON vises relevante fejlbeskeder til brugeren ved fejl med produktreservationer. Ved OFF vil Intervare Platformen ignorere fejlbeskeder returneret fra reservationssystemet og brugerne ser derfor ikke eventuelle fejl med produktreservationer. Det er stadig muligt at lægge ordrer (med eventuelle falske reservationsID\'ere for de produktreservationer der fejler). Bør ikke deaktiveres.',
                messageOk: 'Andringerne er gemt.'
            },
            setPdfEmailJobActivationStatus: {
                name: 'Aktiver ordrebekræftelse og faktura via e-mail',
                title: 'Ved aktivering afsendes automatisk ordrebekræftelser og faktura via e-mail til relevante borgere. Bør ikke deaktiveres.',
                messageOk: 'Andringerne er gemt.'
            }
        },
        weeklyOffersAdmin: {
            menuTitle: 'Ugens tilbud',
            offersHeader: 'Ugens tilbud',
            noOffers: 'Ingen tilbud fundet',
            inputWeekNo: 'Ugenummer',
            inputOffers: 'Indtast tilbud',
            placeholder: 'Bestillingsnumre',
            hint: 'Brug mellemrum eller komma mellem hvert nummer',
            btnSubmit: 'Tilføj'
        },
        creationError: {
            form: {
                typeOfError: {
                    name: 'typeOfError',
                    label: 'Fejltype',
                    options: {
                        citizen: 'Borger',
                        user: 'Bruger'
                    }
                }
            },
            table: {
                customerNo: 'Kundenr.',
                customerSubType: 'Kundesubtype',
                firstName: 'Fornavn',
                lastName: 'Efternavn',
                userName: 'Brugernavn',
                email: 'Email',
                lastModifiedDate: 'Date',
                syncResult: 'Status'
            },
            empty: 'uden resultat, vælg venligst en af mulighederne ovenfor'
        }
    };

    public static material = {
        paginator: {
            itemsPerPageLabel: 'Resultater pr. side:',
            nextPageLabel: 'Næste side',
            previousPageLabel: 'Forrige side',
            lastPageLabel: 'Sidste side',
            firstPageLabel: 'Første side',
            getRangeLabelOf: 'af'
        }
    };

    public static forms = {
        validation: {
            email: 'Indtast en gyldig email',
            emailRequired: 'På grund af dine valg er det nødvendigt at indtaste en e-mail-adresse',
            required: 'Dette felt er påkrævet',
            cpr: 'CPR-nummer skal indtastes i formatet ddmmyyxxxx',
            city: 'Det indtastede postnummer findes ikke',
            matchField: 'Passwords matcher ikke',
            minlength: 'Kontroller antal af tegn - værdien er for kort',
            maxlength: 'Kontroller antal af tegn - værdien er for lang',
            default: 'Der er en fejl i det indtastede data',
            usernameExists: 'Denne bruger er allerede registreret'
        },
        staticValidation: {
            valueLength: 'Værdien af felterne {0} må ikke være længere end {1} tegn'
        },
        labels: {
            municipality: {
                none: 'Alle kommuner',
                muncipalityId: 'Kommune-ID',
                districtId: 'Distrikts-ID',
                subdistrictId: 'Underdistrikts-ID',
                createUser: 'Opret bruger',
                createAnotherUser: 'Opret en anden bruger',
                updateUser: 'Opdater bruger',
                updateUserName: 'Opdatere brugernavn',
                userCreated: 'Brugeren er oprettet',
                userUpdated: 'Brugeren er opdateret',
                requestFailed: 'Der opstod en fejl, prøv venligst igen senere',
                snackbarDismiss: 'Luk',
                isSuperUser: 'Superbruger (kan oprette nye brugere)',
                isPdaUser: 'PDA bruger (kan benytte PDA adgang)',
                isFollowUpUser: 'Opfølgningsbruger',
                noRolesUser: 'Ingen adgangsroller',
                isDeleted: 'Inaktiveret',
                name: 'Navn',
                phoneNumber: 'Telefon',
                email: 'E-mail',
                username: 'Brugernavn',
                searchUsername: { name: 'Username', label: 'Brugernavn' },
                currentUsername: { name: 'currentUsername', label: 'Brugernavn' },
                currentPassowrd: 'Nuværende kodeord',
                password: 'Adgangskode',
                confirmPassword: 'Gentag adgangskode',
                ring1Mail: 'Ring1 mail (Modtager mail om ringeborgere, hvor 4. opkaldsforsøg har været forgæves)',
                ring2Mail: 'Ring2 mail (Modtager mail om ringeborgere, hvor 5. opkaldsforsøg har været forgæves)',
                note1Mail:
                    'Seddel1 mail (Modtager mail om seddelborgere, hvor der ikke er modtaget fax/PDA ordre eller foretaget overførsel)',
                note2Mail:
                    'Seddel2 mail (Modtager mail om seddelborgere, hvor der er blevet angivet at fax/PDA ordre indsendes hurtigst mulig og denne stadig ikke er modtaget)',
                noUsersFound: 'Der blev ikke fundet nogle brugere der matchede det valgte filter',
                columnName: 'Navn',
                columnDistrict: 'Distrikt',
                columnSubdistrict: 'Underdistrikt',
                actionEdit: 'Rediger',
                userName: 'Brugernavn',
                securityRoles: 'Sikkerhedsroller',
                includeInactive: {
                    name: 'includeInactive',
                    label: 'Inkluder inaktive'
                },
                clearForm: 'Ryd formular',
                back: 'Tilbage'
            },
            city: {
                zipCode: 'Postnr.',
                city: 'By'
            },
            editPassword: {
                title: 'Rediger adgangskode',
                submit: 'Accepter',
                info: 'Vær opmærksom på, at kun kommuneadministratorer må anfordre skift af adgangskode, og at der ved næste login skal skiftes adgangskode',
                infoCitizen:
                    'Vær opmærksom på, at skift af adgangskode er midlertidigt, og at borgeren skal ændre adgangskode ved næste login',
                passwordChanged: 'Adgangskode er ændret',
                password: { name: 'Password', label: 'Ny adgangskode' },
                confirmPassword: { name: 'ConfirmPassword', label: 'Gentag adgangskode' }
            },
            editUsername: {
                title: 'Rediger brugernavn',
                submit: 'Accepter',
                info: '',
                currentUsername: { name: 'currentUsername', label: 'Nuværende brugernavn' },
                newUsername: { name: 'newUsername', label: 'Nyt brugernavn' }
            }
        }
    };

    public static genesys = {
        errors: {
            phoneNotAllowed: 'Telefonnummer ikke tilladt',
            multiplePhoneNumber:
                'Flere borgere fundet for det samme telefonnummer {0}. Kontakt venligst support for at få hjælp.',
            sourceEvent:
                'Der er et problem med indgående opkald. Hvis problemet fortsætter, skal du kontakte support for yderligere hjælp.',
            noCallerProfile:
                'Der er ingen opkalderprofil knyttet til dette telefonnummer. Kontakt support for at få hjælp.',
            cantBeCalledDueToDeliveryEnd: 'OBS! Borger er midlertidig. Leveringsdatoen for den næste ordre ligger efter borgerens slutdato.'
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static replaceTokens(translation: string, ...tokens: any[]): string {
        for (let i = 0; i < tokens.length; i++) {
            translation = translation.replace(`{${i}}`, tokens[i]);
        }

        return translation;
    }
}
