import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { Translations } from '../../translations/translations';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'iv-dialog-confirm',
    template: `
        <aside class="dialog alt-theme">
            <h2 mat-dialog-title class="dialog__header">${Translations.layout.confirmDialog.header}</h2>
            <mat-dialog-content class="dialog__content"><div [innerHtml]="confirmText"></div></mat-dialog-content>
            <mat-dialog-actions class="dialog__actions">
                <button type="button" mat-raised-button color="primary" [mat-dialog-close]="true">${Translations.global.btnAcknowledge}</button>
                <button type="button" mat-raised-button color="accent" [mat-dialog-close]="false">${Translations.global.btnCancel}</button>
            </mat-dialog-actions>
        </aside>
    `,
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class DialogConfirmComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public confirmText: string
    ) { }
}
