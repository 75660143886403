import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Observable, Subject, of } from 'rxjs';

import { catchError, takeUntil } from 'rxjs/operators';
import { ISkipTakeResultObjectOfCreditNoteHistoryViewModel } from '../../api/services';
import { environment } from '../../environments/environment';
import { check, magnifyingGlass, pdf } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CURRENCY_CODE } from '../app.locale_data';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { OrderService } from '../services/order.service';
import { DialogService } from '../shared/dialog.service';
import { IDENTITIES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { Helpers } from '../util/helpers';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CommerceInvoiceComponent } from '../commerce/commerce-invoice.component';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { NgIf, NgFor, AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'iv-citizen-details-creditnotes',
    template: `
        <article class="citizen-creditnotes commerce-orders__orders">
            <ng-container *ngIf="orders$ | async as orders; else fetching">
                <ng-container *ngIf="orders.numFound; else noOrders">
                    <mat-accordion>
                        <mat-expansion-panel
                            class="commerce-orders__order"
                            *ngFor="let order of orders.results"
                            [hideToggle]="true"
                            (opened)="order.open = true"
                        >
                            <mat-expansion-panel-header [collapsedHeight]="'100px'" [expandedHeight]="'116px'">
                                <mat-panel-title>
                                    <h3 class="commerce-orders__order-head">
                                        ${Translations.commerce.invoice.creditNoteHeader}: {{ order.creditNo }}
                                    </h3>
                                </mat-panel-title>

                                <mat-panel-description class="citizen-creditnotes__subtitle">
                                    <div class="item">
                                        ${Translations.commerce.creditNote.amount}:
                                        {{ order.amount | currency : currencyCode : 'symbol-narrow' }}
                                    </div>
                                    <div class="item">
                                        ${Translations.commerce.invoice.creditedInvoiceHeader}: {{ order.orderNo }}
                                    </div>
                                    <div class="item">{{ order.createdDate | date : 'short' }}</div>
                                </mat-panel-description>

                                <div class="commerce-orders__order-status">
                                    <ng-container *ngIf="order.isClosed; else open">
                                        ${check} ${Translations.commerce.creditNote.status.closed}
                                    </ng-container>

                                    <ng-template #open>
                                        ${magnifyingGlass} ${Translations.commerce.creditNote.status.open}
                                    </ng-template>
                                </div>
                            </mat-expansion-panel-header>

                            <div class="commerce-orders__order-content">
                                <div class="commerce-orders__controls" *ngIf="isAdmin && order.hasPdf">
                                    <form (submit)="mailInvoice(order.creditNo)">
                                        <mat-form-field>
                                            <input
                                                matInput
                                                ngModel
                                                type="email"
                                                name="recipients"
                                                [formControl]="recipients"
                                                placeholder="${Translations.commerce.creditNote.controls.mail}"
                                            />
                                            <mat-error *ngIf="recipients.invalid">
                                                ${Translations.commerce.creditNote.messages.emailError}
                                            </mat-error>
                                        </mat-form-field>
                                        <iv-progress-button
                                            class="commerce-orders__btn"
                                            mat-raised-button
                                            color="primary"
                                            [loadingState]="states.sending"
                                        >
                                            ${Translations.commerce.creditNote.controls.mailSend}
                                        </iv-progress-button>
                                    </form>

                                    <a
                                        class="commerce-orders__btn commerce-orders__pdf icon"
                                        href="${environment.apiBaseUrl}/api/Order/GetCreditNotePdf/?creditNo={{
                                            order.creditNo
                                        }}"
                                        target="_blank"
                                        title="${Translations.commerce.creditNote.controls.view}"
                                    >
                                        ${pdf}
                                    </a>
                                </div>

                                <iv-commerce-invoice
                                    [invoice]="getOrder(order.creditNo)"
                                    *ngIf="order.open"
                                ></iv-commerce-invoice>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <mat-paginator
                        *ngIf="orders.numFound > paginatorSettings.take"
                        [length]="orders.numFound"
                        [pageSize]="paginatorSettings.take"
                        [pageIndex]="paginatorSettings.skip / paginatorSettings.take"
                        (page)="onPaginatorChange($event)"
                    ></mat-paginator>
                </ng-container>
            </ng-container>

            <ng-template #fetching>
                <div class="commerce-orders__status alt-theme">
                    <mat-progress-spinner
                        color="accent"
                        mode="indeterminate"
                        [strokeWidth]="3"
                        [diameter]="60"
                    ></mat-progress-spinner>
                </div>
            </ng-template>

            <ng-template #noOrders>
                <div class="commerce-orders__status">${Translations.commerce.creditNote.noOrders}</div>
            </ng-template>
        </article>
    `,
    standalone: true,
    imports: [NgIf, MatAccordion, NgFor, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, FormsModule, MatFormField, MatInput, ReactiveFormsModule, MatError, ProgressButtonComponent, CommerceInvoiceComponent, MatPaginator, MatProgressSpinner, AsyncPipe, CurrencyPipe, DatePipe]
})
export class CitizenDetailsCreditnotesComponent implements OnInit, OnDestroy {
    orders$: Observable<ISkipTakeResultObjectOfCreditNoteHistoryViewModel>;
    recipients = new UntypedFormControl('', [Validators.email]);

    paginatorSettings = {
        skip: 0,
        take: 10
    };

    states = {
        sending: false
    };

    isAdmin = false;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        @Inject(CURRENCY_CODE) public currencyCode: string,
        private userService: UserService,
        private orderService: OrderService,
        private dialogService: DialogService
    ) {}

    ngOnInit() {
        this.getOrders();

        this.userService.activeRoles.pipe(takeUntil(this.unsubscribeS$)).subscribe(userRoles => {
            this.isAdmin = userRoles.some(x => IDENTITIES.adminAndCustomerService.includes(x));
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    getOrders() {
        this.orders$ = this.orderService
            .getCreditNotesByCustomerNo(this.paginatorSettings)
            .pipe(catchError(() => of({ numFound: 0 })));
    }

    getOrder(creditNo: number) {
        return this.orderService.getCreditNoteByNo(creditNo.toString());
    }

    onPaginatorChange(event: PageEvent) {
        this.paginatorSettings.skip = event.pageIndex * this.paginatorSettings.take;
        this.getOrders();
    }

    mailInvoice(documentNo: string): void {
        if (!this.recipients.valid || !Helpers.isValidEmail(this.recipients.value)) {
            this.recipients.setErrors({ email: true });
            return;
        }

        this.states.sending = true;

        this.orderService
            .sendCreditNoteEmail({ documentNo, recipients: this.recipients.value })
            .pipe(takeUntil(this.unsubscribeS$))
            .subscribe(
                () => {
                    this.dialogService.showSnackMessage({
                        message: Translations.commerce.creditNote.messages.emailSent
                    });
                    this.states.sending = false;
                },
                (err: IntervareHttpErrorResponse) => {
                    if (err.validationErrors.length) {
                        this.dialogService.showSnackMessage({ message: err.validationErrors.join(', ') });
                    }
                    this.states.sending = false;
                }
            );
    }
}
