import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CitizenModule } from '../citizen/citizen.module';
import { CommerceModule } from '../commerce/commerce.module';
import { MunicipalityModule } from '../municipality/municipality.module';
import { NotesAdminModule } from '../notes-admin/notes-admin.module';
import { SharedModule } from '../shared/shared.module';
import { AdministrationCallInfoComponent } from './administration-call-info.component';
import { AdministrationCallListComponent } from './administration-call-list.component';
import { AdministrationCalllistadminCalllistListComponent } from './administration-calllistadmin-calllist-list.component';
import { AdministrationCalllistadminPlanningComponent } from './administration-calllistadmin-planning.component';
import { AdministrationCalllistadminUserlistComponent } from './administration-calllistadmin-userlist.component';
import { AdministrationCitizenSearchComponent } from './administration-citizen-search.component';
import { AdministrationIntervareMenuComponent } from './administration-intervare-menu.component';
import { AdministrationMenuComponent } from './administration-menu.component';
import { AdministrationMessagesCreateUpdateComponent } from './administration-messages-create-update.component';
import { AdministrationMessagesComponent } from './administration-messages.component';
import { AdministrationPendingCallsComponent } from './administration-pending-calls.component';
import { AdministrationRoutingModule } from './administration-routing.module';
import { AdministrationSearchNemligComponent } from './administration-search-nemlig.component';
import { AdministrationSettingsComponent } from './administration-settings.component';
import { AdministrationStockComponent } from './administration-stock.component';
import { AdministrationWeeklyoffersAdminComponent } from './administration-weeklyoffers-admin.component';
import { AdministrationCreationErrorsComponent } from './administration-creation-errors.component';
import { AdministrationDeliveryDateComponent } from './administration-delivery-date.component';
import { AdministrationEditDeliveryDateComponent } from './administration-edit-delivery-date.component';
import { AdministrationHistoryDeliveryDateComponent } from './administration-history-delivery-date.component';
import { AdministrationCreateUserComponent } from './administration-create-user.component';
import { AdministrationViewUsersComponent } from './administration-view-users.component';
import { AdministrationUserHistoryComponent } from './administration-user-history.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NotesAdminModule,
        CitizenModule,
        MunicipalityModule,
        AdministrationRoutingModule,
        CommerceModule,
        AdministrationMenuComponent,
        AdministrationCallListComponent,
        AdministrationStockComponent,
        AdministrationSearchNemligComponent,
        AdministrationCallInfoComponent,
        AdministrationMessagesComponent,
        AdministrationMessagesCreateUpdateComponent,
        AdministrationIntervareMenuComponent,
        AdministrationCalllistadminUserlistComponent,
        AdministrationCalllistadminCalllistListComponent,
        AdministrationCalllistadminPlanningComponent,
        AdministrationCitizenSearchComponent,
        AdministrationWeeklyoffersAdminComponent,
        AdministrationPendingCallsComponent,
        AdministrationSettingsComponent,
        AdministrationCreationErrorsComponent,
        AdministrationDeliveryDateComponent,
        AdministrationEditDeliveryDateComponent,
        AdministrationHistoryDeliveryDateComponent,
        AdministrationCreateUserComponent,
        AdministrationViewUsersComponent,
        AdministrationUserHistoryComponent
    ],
    exports: [AdministrationMenuComponent]
})
export class AdministrationModule { }
