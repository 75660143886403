import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/collections';
import { Component, HostBinding, Input, OnInit, TemplateRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CdkDetailRowDirective } from './cdk-detail-row.directive';
import { NgFor } from '@angular/common';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';

export class DynamicDataSource extends DataSource<any> {

    data: any;

    constructor(data: any) {
        super();
        this.data = data;
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<any[]> {
        return of(this.data);
    }

    disconnect() {
    }
}

@Component({
    selector: 'iv-dynamic-table',
    template: `
        <mat-table #table [dataSource]="dataSource">

            <ng-container *ngFor="let column of columns; let index;" [matColumnDef]="column.columnDef">
                <mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ column.cell(row) }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"
                     matRipple
                     [ivCdkDetailRow]="row"
                     [ivCdkDetailRowTpl]="templateRef">
            </mat-row>

        </mat-table>
    `,
    animations: [
        trigger('detailExpand', [
            state('void', style({height: '0px', minHeight: '0', visibility: 'hidden'})),
            state('*', style({height: '*', visibility: 'visible'})),
            transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [
        MatTable,
        NgFor,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        CdkDetailRowDirective,
    ],
})
export class DynamicTableComponent implements OnInit {

    @HostBinding('class') hostClass = 'dynamic-table';

    private _data: any[];
    @Input()
    set data(value: any[]) {

        if (value && value.length) {
            this._data = value;
            this.createData();
        }

    }

    get data(): any[] {
        return this._data;
    }

    @Input() columns: any;
    @Input() templateRef: TemplateRef<any>;

    dataSource: any;

    displayedColumns: any;

    ngOnInit() {
        this.displayedColumns = this.columns?.map(c => c.columnDef);
    }


    private createData() {
        if (!this.dataSource) {
            this.dataSource = new DynamicDataSource(this.data);
        }
    }
}
