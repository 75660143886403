import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { acidic, acuteToxicity, aquaticPollution, astmaallergimaerket, danskmaerket, dkOekoMaerket, ecocertIcon, euOekoMaerkning, explosive, fairtrademaerket, flammable, frostIcon, fuldkornsmaerket, gasUnderPressure, halalmaerket, healthRisk, mscIcon, noeglehulsmaerket, oxidising, seriousHealthRisk, svanemaerket } from '../../scripts/generated/icons';
import { NgFor, NgIf } from '@angular/common';

enum CommerceLabels {
    // Danger labels
    acuteToxicity = 'Akut toksicitet',
    seriousHealthRisk = 'Alvorlig sundhedsfare',
    flammable = 'Brandfarligt',
    oxidising = 'Brandnærende',
    explosive = 'Eksplosivt stof',
    aquaticPollution = 'Farligt for miljøet',
    gasUnderPressure = 'Gas under tryk',
    healthRisk = 'Sundhedsfare',
    acidic = 'Ætsende',
    // Misc labels
    dkOekoMaerket = 'Øko (dansk)',
    euOekoMaerkning = 'Øko (europæisk)',
    fairtrademaerket = 'Fairtrade',
    svanemaerket = 'Svanemærket',
    astmaallergimaerket = 'Astma-Allergi Danmark',
    noeglehulsmaerket = 'Nøglehulsmærket',
    fuldkornsmaerket = 'Fuldkornsmærket',
    halalmaerket = 'Halal',
    ecocertIcon = 'Ecocert',
    mscIcon = 'MSC-mærket',
    danskmaerket = 'Dansk',
    frostIcon = 'Frost'
}

const commerceLabelIcons = {
    // Danger icons
    [CommerceLabels.acuteToxicity]: acuteToxicity,
    [CommerceLabels.seriousHealthRisk]: seriousHealthRisk,
    [CommerceLabels.flammable]: flammable,
    [CommerceLabels.oxidising]: oxidising,
    [CommerceLabels.explosive]: explosive,
    [CommerceLabels.aquaticPollution]: aquaticPollution,
    [CommerceLabels.gasUnderPressure]: gasUnderPressure,
    [CommerceLabels.healthRisk]: healthRisk,
    [CommerceLabels.acidic]: acidic,
    // Misc icons
    [CommerceLabels.dkOekoMaerket]: dkOekoMaerket,
    [CommerceLabels.euOekoMaerkning]: euOekoMaerkning,
    [CommerceLabels.fairtrademaerket]: fairtrademaerket,
    [CommerceLabels.svanemaerket]: svanemaerket,
    [CommerceLabels.astmaallergimaerket]: astmaallergimaerket,
    [CommerceLabels.noeglehulsmaerket]: noeglehulsmaerket,
    [CommerceLabels.fuldkornsmaerket]: fuldkornsmaerket,
    [CommerceLabels.halalmaerket]: halalmaerket,
    [CommerceLabels.ecocertIcon]: ecocertIcon,
    [CommerceLabels.mscIcon]: mscIcon,
    [CommerceLabels.danskmaerket]: danskmaerket,
    [CommerceLabels.frostIcon]: frostIcon
};


@Component({
    selector: 'iv-commerce-labels',
    template: `
        <div class="commerce-labels" *ngIf="icons.length">
            <div class="commerce-labels__label" [innerHTML]="icon" *ngFor="let icon of icons"></div>
        </div>
    `,
    standalone: true,
    imports: [NgIf, NgFor]
})
export class CommerceLabelsComponent implements OnInit {
    @Input() labels: string[] = [];
    icons: SafeHtml[] = [];

    constructor(private domSanitizer: DomSanitizer) {
    }

    ngOnInit() {
        // Create a map of the CommerceLabels enum
        const commerceLabelsMap = new Map(Object.keys(CommerceLabels).map((key, i) => [CommerceLabels[key], i]));

        // Sort the labels by the order of the CommerceLabels enum
        this.labels.sort((a, b) => {
            const aIndex = commerceLabelsMap.get(a);
            const bIndex = commerceLabelsMap.get(b);

            if (aIndex === undefined && bIndex === undefined) {
                return 0;
            } else if (aIndex === undefined) {
                return 1;
            } else if (bIndex === undefined) {
                return -1;
            } else {
                return aIndex - bIndex;
            }
        });

        // Add the icons for the labels
        this.labels.forEach(label => {
            if (commerceLabelIcons[label]) {
                this.icons.push(this.domSanitizer.bypassSecurityTrustHtml(commerceLabelIcons[label]));
            }
        });
    }
}
