import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Translations } from '../../translations/translations';

@Injectable()
export class MatPaginatorIntlResults extends MatPaginatorIntl {
    itemsPerPageLabel = Translations.material.paginator.itemsPerPageLabel;
    nextPageLabel = Translations.material.paginator.nextPageLabel;
    previousPageLabel = Translations.material.paginator.previousPageLabel;
    firstPageLabel = Translations.material.paginator.firstPageLabel;
    lastPageLabel = Translations.material.paginator.lastPageLabel;

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${Translations.material.paginator.getRangeLabelOf} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${Translations.material.paginator.getRangeLabelOf} ${length}`;
    };
}
@Injectable()
export class DaDateAdapter extends NativeDateAdapter {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse(value: any): Date | null {
        if (typeof value === 'string' && value.indexOf('/') > -1) {
            const str = value.split('/');
            if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
                // eslint-disable-next-line no-null/no-null
                return null;
            }
            return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        // eslint-disable-next-line no-null/no-null
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    getFirstDayOfWeek(): number {
        return 1;
    }
}
