import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CategoryNodeViewModel } from '../services/menu-models';

import { down, forward } from '../../scripts/generated/icons';
import { UtilService } from '../util/util.service';
import { RouteService } from '../shared/route.service';
import { NgClass, NgFor } from '@angular/common';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
    selector: 'iv-category-menu-level',
    template: `
        <div class="category-menu__level">
            <a [routerLink]="category.url" routerLinkActive="category-menu__link_active" class="category-menu__link" (click)="toggle()" [ngClass]="{ 'category-menu__link_has-children' : hasChildren, 'category-menu__link_close' : close }">
                {{category.name}}
                <div class="category-menu__link-icon down">${down}</div>
                <div class="category-menu__link-icon forward">${forward}</div>
            </a>

            <div class="category-menu__children">
                <iv-category-menu-level [category]="subCategory" *ngFor="let subCategory of category.subCategories"></iv-category-menu-level>
            </div>
        </div>
    `,
    standalone: true,
    imports: [RouterLinkActive, RouterLink, NgClass, NgFor]
})
export class CategoryMenuLevelComponent implements OnInit, OnChanges, OnDestroy {
    @Input() category: CategoryNodeViewModel;

    hasChildren = false;
    close = false;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private utilService: UtilService, private routeService: RouteService) { }

    ngOnInit() {
        this.routeService.activeRoute$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(() => {
            this.close = false;
        });
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes.category && this.category.subCategories) {
            this.hasChildren = this.category.subCategories.length !== 0;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    toggle() {
        if (this.utilService.isBrowser()) {
            if (this.hasChildren && decodeURIComponent(window.location.pathname).indexOf(this.category.url) !== -1) {
                this.close = !this.close;
            }
        }
    }
}
