import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import {
    ICauseViewModel,
    INoteViewModel,
    ISubCauseViewModel,
    ISubSubCauseViewModel,
    RequiredFieldModel
} from '../../api/services';
import { Translations } from '../../translations/translations';
import {
    FormBuilderOption,
    FormBuilderSelect,
    FormBuilderTextInput,
    FormBuilderTextarea,
    FormBuilderTypes
} from '../form-builder/form-builder-element.model';
import { toFormOptions } from '../form-builder/form-builder-mappings';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { NoteService } from '../services/note.service';
import { DialogService } from '../shared/dialog.service';
import { ProgressButtonComponent } from '../shared/progress-button.component';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'iv-citizen-note-form',
    template: `
        <section class="citizen-note-form">
            <form class="alt-theme" *ngIf="form" [formGroup]="form" (submit)="onSubmit()">
                <iv-form-builder-element
                    *ngFor="let input of inputs"
                    [form]="form"
                    [input]="input"
                ></iv-form-builder-element>
                <div class="citizen-note-form__errors error" *ngIf="errorMsg">
                    {{ errorMsg }}
                </div>
                <div class="citizen-note-form__actions" *ngIf="form">
                    <iv-progress-button
                        color="primary"
                        [loadingState]="state.creating"
                        [disabled]="disabled || !form.valid"
                        >${Translations.administration.citizenHistory.form.submit}</iv-progress-button
                    >
                </div>
            </form>
        </section>
    `,
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, FormBuilderElementComponent, ProgressButtonComponent]
})
export class CitizenNoteFormComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup = new UntypedFormGroup({});
    inputs: FormBuilderTypes[] = [];
    state = {
        creating: false
    };
    errorMsg: string;
    disabled = false;

    @Output() create = new EventEmitter<INoteViewModel>();

    private selectedCauseId?: number;
    private selectedSubCauseId?: number;
    private selectedSubSubCauseId?: number;

    private optionsMode = false;

    private unsubscribe = new Subject<void>();

    private subCauses = new BehaviorSubject<FormBuilderOption[]>([]);
    private subSubCauses = new BehaviorSubject<FormBuilderOption[]>([]);
    private userFields: string[] = [];

    constructor(
        private formBuilder: FormBuilderService,
        private noteService: NoteService,
        private dialogService: DialogService
    ) {}

    ngOnInit() {
        this._buildForm();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.state.creating = true;
            const model = this.form.value;

            const requiredFields: RequiredFieldModel[] = Object.keys(model)
                .filter(x => x.indexOf('ufield') === 0)
                .map(field => {
                    const fieldModel = new RequiredFieldModel();
                    fieldModel.field = this.userFields[field.replace('ufield', '')];
                    fieldModel.value = model[field];
                    return fieldModel;
                });

            this.noteService
                .createNote({
                    subCauseId: model.subSubCause ? 0 : model.subCause.subCauseId,
                    subSubCauseId: model.subSubCause ? model.subSubCause.subSubCauseId : 0,
                    comment: model.comment,
                    requiredFields
                })
                .pipe(
                    finalize(() => (this.state.creating = false)),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(
                    note => {
                        this.create.next(note);
                        this.dialogService.showSnackMessage({
                            message: Translations.administration.citizenHistory.form.messageOk
                        });
                    },
                    (err: IntervareHttpErrorResponse) => (this.errorMsg = err.validationErrors.join(', '))
                );
        }
    }

    private _buildForm() {
        this.noteService
            .getCauses()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(causes => {
                this.inputs = [
                    new FormBuilderSelect({
                        name: 'cause',
                        label: Translations.administration.citizenHistory.form.cause,
                        required: true,
                        options: causes.map(x => toFormOptions(x, x.causeName))
                    }),
                    new FormBuilderSelect({
                        name: 'subCause',
                        label: Translations.administration.citizenHistory.form.subCause,
                        required: true,
                        options: this.subCauses
                    }),
                    new FormBuilderSelect({
                        name: 'subSubCause',
                        label: Translations.administration.citizenHistory.form.subSubCause,
                        options: this.subSubCauses,
                        condition: () => this.optionsMode
                    }),
                    new FormBuilderTextarea({
                        name: 'comment',
                        label: Translations.administration.citizenHistory.form.comment
                    })
                ];

                this.form = this.formBuilder.toFormGroup(this.inputs);
                this._bindValueChanges();
            });
    }

    private _bindValueChanges() {
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(changes => {
            this.errorMsg = '';

            // Cause
            if (changes.cause && this.selectedCauseId !== changes.cause.causeId) {
                const cause: ICauseViewModel = changes.cause;
                this.selectedCauseId = cause.causeId;

                // reset the other fields
                this.selectedSubCauseId = undefined;
                this.selectedSubSubCauseId = undefined;

                this.optionsMode = false;
                this.form.controls.subCause.setValue(undefined);
                this.form.controls.subCause.updateValueAndValidity();

                this.form.controls.subSubCause.setValue(undefined);
                this.form.controls.subSubCause.updateValueAndValidity();

                this.form.controls.comment.updateValueAndValidity();

                this.subCauses.next([]);
                this.subSubCauses.next([]);
                this._resetUserFields();

                if (cause.subCauses && cause.subCauses.length) {
                    this.subCauses.next(cause.subCauses.map(x => toFormOptions(x, x.subCauseName)));
                }
            }

            // SubCause
            if (changes.subCause && this.selectedSubCauseId !== changes.subCause.subCauseId) {
                const subCause: ISubCauseViewModel = changes.subCause;
                this.selectedSubCauseId = subCause.subCauseId;

                this.optionsMode = false;
                this.form.controls.subSubCause.updateValueAndValidity();
                this.form.controls.comment.updateValueAndValidity();

                this.form.controls.subSubCause.setValue(undefined);
                this.subSubCauses.next([]);
                this._resetUserFields();

                if (subCause.subSubCauses && subCause.subSubCauses.length) {
                    this.optionsMode = true;
                    this.form.controls.subSubCause.updateValueAndValidity();
                    this.form.controls.comment.updateValueAndValidity();

                    this.subSubCauses.next(subCause.subSubCauses.map(x => toFormOptions(x, x.subSubCauseName)));
                } else {
                    this._createUserFields(subCause.subCauseId, false);
                }
            }

            // SubSubCause
            if (changes.subSubCause && this.selectedSubSubCauseId !== changes.subSubCause.subSubCauseId) {
                const subSubCause: ISubSubCauseViewModel = changes.subSubCause;
                this.selectedSubSubCauseId = subSubCause.subSubCauseId;
                this._resetUserFields();
                this._createUserFields(subSubCause.subSubCauseId, true);
            }
        });
    }

    private _createUserFields(id: number, isSubSubCause: boolean): void {
        this.disabled = true;
        this.noteService
            .getUserFields(id, isSubSubCause)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(fields => {
                this.userFields = fields;
                fields.forEach((field, index) => {
                    this.form.addControl('ufield' + index, new UntypedFormControl(''));
                    this.inputs.push(
                        new FormBuilderTextInput({
                            name: 'ufield' + index,
                            label: field,
                            hints: [
                                {
                                    text: Translations.administration.citizenHistory.form.hintUserField
                                }
                            ]
                        })
                    );
                });

                this.disabled = false;
            });
        this.form.updateValueAndValidity();
    }

    private _resetUserFields(): void {
        this.inputs = this.inputs.filter(x => x.name.indexOf('ufield') !== 0);
        Object.keys(this.form.controls).forEach(x => (x.indexOf('ufield') === 0 ? this.form.removeControl(x) : ''));
        this.userFields = [];
        this.form.updateValueAndValidity();
    }
}
