import { Location } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Raven from 'raven-js';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMapTo, tap } from 'rxjs/operators';

import { Translations } from '../../translations/translations';
import { DialogService } from '../shared/dialog.service';
import { AuthService } from './auth.service';
import { IntervareHttpErrorResponse } from './base-service';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method === 'GET') {
            req = req.clone({
                headers: req.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache')
            });
        }
        return next.handle(req).pipe(
            catchError(this.handleErrors)
        );
    }

    handleErrors = (err: HttpErrorResponse): Observable<IntervareHttpErrorResponse> => {
        try {
            Raven.captureException(err);
        }
        catch { }

        const response = new IntervareHttpErrorResponse();

        response.error = err;

        // 400 (ValidationErrors) - will be mapped to be shown in the client
        if (err.status === 400) {
            const validationResult = IntervareHttpErrorResponse.fromJS(err.error);
            if (validationResult.validationErrors) {
                response.validationErrors = validationResult.validationErrors;
            }
        }

        // 401 (Unauthorized) - User needs to log in and/or have the correct permissions
        else if (err.status === 401) {
            return this.authService.isLoggedIn().pipe(
                tap(isLoggedIn => {
                    if (isLoggedIn) {
                        this.dialogService.showMessage(Translations.global.errorMessages.unauthorized, undefined, 'alertdialog');
                    }
                    else {
                        this.router.navigate(['/']);
                    }
                }),
                switchMapTo(throwError(response))
            );
        }

        // Unspecified errors, most common is 500 and Angular errors
        else {
            let message = Translations.global.errorMessages.generic + ' (' + err.error + ')';
            if (err.url?.includes('/api/Product/ProductDetails')) {
                message = Translations.commerce.productDetails.error.notOnCatalog;
            }
            this.dialogService.showMessage(message, undefined, 'alertdialog');
        }

        return throwError(response);
    }

    constructor(
        private dialogService: DialogService,
        private authService: AuthService,
        private router: Router,
        private location: Location
    ) { }
}
