import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { map } from 'rxjs/operators';
import { Driver, IDriver, DriverAdditionalInformation, IDriverAdditionalInformation } from '../../api/services';

@Injectable()
export class DriverService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    getDrivers(municipalityId: number): Observable<IDriver[]> {
        return this.http.get<any[]>(this.apiBaseUrl('/driver/GetDrivers'), { params: { municipalityId: municipalityId.toString() } }).pipe(
            map(x => x.map(Driver.fromJS))
        );
    }

    getDriverAdditionalInformation(): Observable<IDriverAdditionalInformation[]> {
        return this.http.get<any[]>(this.apiBaseUrl('/driver/GetDriverAdditionalInformation')).pipe(
            map(x => x.map(DriverAdditionalInformation.fromJS))
        );
    }
}
