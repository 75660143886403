import { Pipe, PipeTransform } from '@angular/core';
import { Translations } from '../../../translations/translations';

@Pipe({
    name: 'processHeaderColumn',
    standalone: true
})
export class ProcessHeaderColumnPipe implements PipeTransform {

    transform(column: string): string {
        return Translations.municipality.citizenDrafts[column];
    }

}
