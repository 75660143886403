import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';

import { CitizenTransferReason, CustomerSubType, ICitizenTransferViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import {
    FormBuilderDatepicker,
    FormBuilderOption,
    FormBuilderSelect,
    FormBuilderTextInput,
    FormBuilderTypes,
} from '../form-builder/form-builder-element.model';
import { CitizenService } from '../services/citizen.service';
import { IDENTITIES, ROLES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { Helpers } from '../util/helpers';
import { IDialogFormSettings } from './dialog-interfaces';
import { DialogService } from './dialog.service';

export interface ITransferReasonDialogSettings {
    dialogTitle?: string;
    customerNo: string;
    noOrderDate: Date;
    callRecordId?: number;
    customerSubType?: CustomerSubType;
}

interface ITransferReasonDialogResult {
    action: number;
    fromDate?: Date | undefined;
    toDate?: Date | undefined;
    reason?: string;
}

export interface ITransferReasonDialogOutput {
    success: boolean;
    action: number;
    customerNo: string;
}

@Injectable()
export class TransferReasonDialogService implements OnDestroy {
    private _transferOptions$ = new BehaviorSubject<FormBuilderOption[]>([]);
    private unsubscribe: Subject<void> = new Subject();

    private showDates = new BehaviorSubject<boolean>(false);
    private showReason = new BehaviorSubject<boolean>(false);

    private currentDialogSettings: ITransferReasonDialogSettings;

    private isCustomerService = false;

    constructor(
        private userService: UserService,
        private citizenService: CitizenService,
        private dialogService: DialogService
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    open(data: ITransferReasonDialogSettings): Observable<ITransferReasonDialogOutput> {

        this.currentDialogSettings = data;

        this._createOptions();
        return this.userService.activeRoles.pipe(
            first(),
            switchMap(userRoles => {
                const isMunicipalityUser = userRoles.some(x => IDENTITIES.municipalityPortal.includes(x));
                const isInternalUser = userRoles.some(x => IDENTITIES.adminAndCustomerService.includes(x));
                const isPda = userRoles.some(x => IDENTITIES.pda.includes(x));

                const hideDateOptions = isInternalUser || isMunicipalityUser && isInternalUser;

                const dialogForm: IDialogFormSettings = {
                    data: {
                        title: data.dialogTitle,
                        inputs: this._buildDialogForm(hideDateOptions, isPda),
                        actionText: Translations.form.actions.choose
                    }
                };
                return this.dialogService.showForm(dialogForm).afterClosed().pipe(
                    switchMap((res?: ITransferReasonDialogResult) => res ?
                        this._processTransferAction(res)
                        : of<ITransferReasonDialogOutput>({ success: false, action: 0, customerNo: data.customerNo })
                    ),
                    takeUntil(this.unsubscribe)
                );
            })
        );

    }

    private _buildDialogForm(isInternalUserAndNotMunicipality: boolean, isPda: boolean): FormBuilderTypes[] {
        let prevDateSelected!: Date;

        const fromDateControl = new FormBuilderDatepicker({
            name: 'fromDate',
            label: Translations.municipality.common.dateFrom,
            condition: this.showDates.asObservable(),
            requiredAsync: () => this.showDates.pipe(takeUntil(this.unsubscribe)),
            valueChanged: (value: Date) => {
                prevDateSelected = value;
            }
        });

        const toDateControl = new FormBuilderDatepicker({
            name: 'toDate',
            label: Translations.municipality.common.dateTo,
            condition: this.showDates.asObservable(),
            filter: (d): boolean => {
                if (!d) {
                    return false;
                }
                const dayBefore: Date = prevDateSelected ? prevDateSelected : new Date();
                const nextDay = new Date(dayBefore.getFullYear(), dayBefore.getMonth(), dayBefore.getDate() + 1);
                // Prevent the day selected to the starting day shall be selected
                return Helpers.filterDates(d, nextDay);
            }
        });

        const reasonControl = new FormBuilderTextInput({
            name: 'reason',
            type: 'text',
            label: Translations.municipality.citizenStatus.dialog.form.reasonField,
            condition: this.showReason.asObservable()
        })

        const dateForms = isInternalUserAndNotMunicipality ? [] : [fromDateControl, toDateControl];
        const reasonForm = isPda ? [] : [reasonControl];


        const formElements: FormBuilderTypes[] = [
            new FormBuilderSelect({
                name: 'action',
                label: Translations.municipality.citizenStatus.dialog.form.actionSelect,
                required: true,
                options: this._transferOptions$,
                valueChanged: (value, form) => {
                    this.showDates.next(value === CitizenTransferReason.Hospitalized || value === CitizenTransferReason.Vacations);
                    this.showReason.next((this.isCustomerService && value !== '') || value === CitizenTransferReason.OtherCauses);

                    form?.patchValue({ fromDate: "", toDate: "", });
                }
            }),
            ...dateForms,
            ...reasonForm
        ];

        return formElements;
    }

    /**
     * Mapping all the actions each User Customer Service can take to each citizen;
     *
     * Homecarer
     * 1. Skip this week (DontWantNothing)
     * 2. Vacation
     * 3. Hospitalized
     * 4. Dead
     *
     * Municipality
     * 1. Homecarer will send new order (WillSendNewOrder)
     * 2. Dont Want Nothing (DontWantNothing)
     * 3. Call Again (CallAgain)
     * 4. Hospitalized (Hospitalized)
     * 5. Vacations (Vacations)
     * 6. Dead
     * 7. OtherCauses
     *
     * Customer Service
     * 1. Skip this week
     * 2. Transferred due to mistake
     * 3. OtherCauses
     *
     *
     * @private
     * @param {CitizenWithoutOrder} citizen
     * @returns {FormBuilderSelectOption[]}
     * @memberof MunicipalityCitizenStatusComponent
     */
    private _createOptions(): void {

        const optActions: FormBuilderOption[] = [];

        this.userService.activeRoles.pipe(
            first(),
            takeUntil(this.unsubscribe)
        ).subscribe(userRoles => {

            const isHomeCarer = userRoles.includes(ROLES.HomeCarer);
            const isMunicipalityUser = userRoles.some(x => IDENTITIES.municipalityPortal.includes(x));
            const isCustomerService = userRoles.some(x => IDENTITIES.adminAndCustomerService.includes(x));

            if (isMunicipalityUser || isCustomerService) {

                if (isCustomerService) {

                    this.isCustomerService = true;

                    optActions.push({
                        label: Translations.global.transferReasons.SkipThisWeek,
                        value: CitizenTransferReason.SkipThisWeek
                    });
                    optActions.push({
                        label: Translations.global.transferReasons.TransferDueToMistake,
                        value: CitizenTransferReason.TransferDueToMistake
                    });
                    optActions.push({
                        label: Translations.global.transferReasons.OtherCauses,
                        value: CitizenTransferReason.OtherCauses
                    });

                } else {

                    optActions.push({
                        label: Translations.global.transferReasons.DontWantNothing,
                        value: CitizenTransferReason.DontWantNothing
                    });

                    const citizenType = this.currentDialogSettings ? this.currentDialogSettings.customerSubType : 0;

                    if (citizenType === CustomerSubType.Seddelborger) {
                        optActions.push({
                            label: Translations.global.transferReasons.WillSendNewOrder,
                            value: CitizenTransferReason.WillSendNewOrder
                        });
                    }

                    if (citizenType === CustomerSubType.Ringeborger) {
                        optActions.push({
                            label: Translations.global.transferReasons.CallAgain,
                            value: CitizenTransferReason.CallAgain
                        });
                    }

                    optActions.push({
                        label: Translations.global.transferReasons.Vacations,
                        value: CitizenTransferReason.Vacations
                    });
                    optActions.push({
                        label: Translations.global.transferReasons.Hospitalized,
                        value: CitizenTransferReason.Hospitalized
                    });
                    optActions.push({
                        label: Translations.global.transferReasons.Dead,
                        value: CitizenTransferReason.Dead
                    });
                    optActions.push({
                        label: Translations.global.transferReasons.OtherCauses,
                        value: CitizenTransferReason.OtherCauses
                    });
                }

            } else if (isHomeCarer) {

                optActions.push({
                    label: Translations.global.transferReasons.DontWantNothing,
                    value: CitizenTransferReason.SkipThisWeek
                });
                optActions.push({
                    label: Translations.global.transferReasons.Vacations,
                    value: CitizenTransferReason.Vacations
                });
                optActions.push({
                    label: Translations.global.transferReasons.Hospitalized,
                    value: CitizenTransferReason.Hospitalized
                });
                optActions.push({ label: Translations.global.transferReasons.Dead, value: CitizenTransferReason.Dead });

            }

            this._transferOptions$.next(optActions);
        });
    }

    /**
     * Process the tranfer reason for the specific citizen
     *
     * @private
     * @param {CitizenWithoutOrder} citizen
     * @param {ITransferReasonDialogOutput} response
     * @returns {Observable<boolean>}
     * @memberof TransferReasonDialogService
     */
    private _processTransferAction(response: ITransferReasonDialogResult): Observable<ITransferReasonDialogOutput> {

        const transferViewModel: ICitizenTransferViewModel = {
            customerNo: this.currentDialogSettings.customerNo,
            transferReason: response.action,
            callRecordId: this.currentDialogSettings.callRecordId || 0,
            noOrderDate: this.currentDialogSettings.noOrderDate,
            noteComment: response.reason
        } as ICitizenTransferViewModel;

        if (response.action === CitizenTransferReason.Hospitalized || response.action === CitizenTransferReason.Vacations) {
            if (response.fromDate) {
                transferViewModel.fromDate = response.fromDate;
            }
            if (response.toDate) {
                transferViewModel.toDate = response.toDate;
            }
        }

        return this.citizenService.transferCitizen(transferViewModel).pipe(
            map<boolean, ITransferReasonDialogOutput>(success => ({
                success,
                action: response.action,
                customerNo: transferViewModel.customerNo
            }))
        );
    }

}
