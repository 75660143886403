import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AddressService } from './address.service';
import { ApiHttpInterceptor } from './api-http-interceptor.service';
import { AuthService } from './auth.service';
import { BasketService } from './basket.service';
import { CalendarService } from './calendar.service';
import { CallListAdminService } from './call-list-admin.service';
import { CallListService } from './call-list.service';
import { CitizenService } from './citizen.service';
import { DriverService } from './driver.service';
import { HandicapService } from './handicap.service';
import { MenuService } from './menu.service';
import { MessageService } from './message.service';
import { NoteService } from './note.service';
import { OrderService } from './order.service';
import { ProductService } from './product.service';
import { SettingsService } from './settings.service';
import { FailedSyncsService } from './failed-syncs.service';
import { AdministrationDeliveryService } from './administration-delivery.service';
import { InternalUserService } from './internal-user.service';


@NgModule({ imports: [], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiHttpInterceptor,
            multi: true
        },
        MenuService,
        AuthService,
        ProductService,
        BasketService,
        CallListService,
        CitizenService,
        MessageService,
        CallListAdminService,
        CalendarService,
        OrderService,
        NoteService,
        HandicapService,
        DriverService,
        SettingsService,
        AddressService,
        FailedSyncsService,
        AdministrationDeliveryService,
        InternalUserService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class ServicesModule {
    constructor(
        authService: AuthService
    ) {
        authService.init();
    }
}
