import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PageEvent, MatPaginator } from '@angular/material/paginator';

import { Observable, Subject, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { DatePipe, NgIf, NgFor } from '@angular/common';
import {
    CustomerSubType,
    SimpleTemporaryCitizen,
    SkipTakeResultObjectOfSimpleTemporaryCitizen,
    UserViewModel
} from '../../api/services';
import { Translations } from '../../translations/translations';
import { FormBuilderSelect, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { toFormOptions } from '../form-builder/form-builder-mappings';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { FailedSyncsService } from '../services/failed-syncs.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { DynamicTableComponent } from '../shared/dynamic-table.component';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';

enum TYPE_OF_ERROR {
    CITIZEN = 'citizen'
}

interface IColumn {
    columnDef: string;
    header: string;
    cell: () => void;
}

interface IFilterChange {
    skip: number;
    take: number;
    type: TYPE_OF_ERROR;
}

@Component({
    selector: 'iv-administration-creation-errors',
    template: `
        <section class="administration-creation-errors">
            <form *ngIf="form" [formGroup]="form" class="alt-theme form" autocomplete="off">
                <iv-form-builder-element
                    [form]="form"
                    [input]="input"
                    *ngFor="let input of inputs"
                ></iv-form-builder-element>
            </form>

            <ng-container *ngIf="!isFetching; else fetching">
                <ng-container *ngIf="syncFailErrors; else empty">
                    <iv-dynamic-table
                        [templateRef]="tpl"
                        [data]="syncFailErrors!.results"
                        [columns]="displayedColumns"
                    ></iv-dynamic-table>

                    <mat-paginator
                        [length]="syncFailErrors!.numFound"
                        [pageIndex]="pageIndex"
                        [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions"
                        [showFirstLastButtons]="true"
                        (page)="onPageChange($event)"
                    >
                    </mat-paginator>
                </ng-container>
            </ng-container>
        </section>

        <ng-template #tpl let-row>
            <div class="mat-row mat-row__expanded" [@detailExpand] (@detailExpand.start)="onOpenDetail($event, row)">
                <div
                    class="mat-row__expanded-detail"
                    *ngIf="row.syncResultMessage && !isFetchingDetail; else fetchingDetail"
                >
                    <pre>{{ row.syncResultMessage }}</pre>
                </div>
            </div>
        </ng-template>

        <ng-template #fetching>
            <div class="center-content alt-theme">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="3"
                    [diameter]="60"
                ></mat-progress-spinner>
            </div>
        </ng-template>

        <ng-template #fetchingDetail>
            <div class="center-content alt-theme">
                <mat-progress-spinner
                    color="primary"
                    mode="indeterminate"
                    [strokeWidth]="2"
                    [diameter]="20"
                ></mat-progress-spinner>
            </div>
        </ng-template>

        <ng-template #empty>
            <div class="center-content alt-theme">
                <p>${Translations.intervare.creationError.empty}</p>
            </div>
        </ng-template>
    `,
    animations: [
        trigger('detailExpand', [
            state('void', style({ height: 0, minHeight: 0, visibility: 'hidden', overflow: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible', overflow: 'hidden' })),
            transition('void <=> *', animate('325ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, FormBuilderElementComponent, DynamicTableComponent, MatPaginator, MatProgressSpinner]
})
export class AdministrationCreationErrorsComponent implements OnInit, OnDestroy {
    isFetching = false;

    isFetchingDetail = false;

    form: UntypedFormGroup;
    inputs: FormBuilderTypes[];

    pageSizeOptions = [10, 20, 30, 50];
    pageIndex = 0;
    pageSize = this.pageSizeOptions[0];

    displayedColumns: IColumn[] = [];

    syncFailErrors?: SkipTakeResultObjectOfSimpleTemporaryCitizen;

    private filterChanges = new Subject<IFilterChange>();

    private unsubscribe = new Subject<void>();

    constructor(
        private date: DatePipe,
        private formBuilder: FormBuilderService,
        private failedSyncsService: FailedSyncsService
    ) {}

    ngOnInit() {
        this._buildForm();

        this.filterChanges
            .pipe(
                tap(() => {
                    this.isFetching = true;
                }),
                switchMap(changes => this.getFails(changes)),
                takeUntil(this.unsubscribe)
            )
            .subscribe(results => {
                this.syncFailErrors = results;
                this.isFetching = false;
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onPageChange(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        this.filterChanges.next({
            skip: event.pageIndex * event.pageSize,
            take: event.pageSize,
            type: this.form.controls[Translations.intervare.creationError.form.typeOfError.name].value
        });
    }

    private onOpenDetail(event: AnimationEvent, row: SimpleTemporaryCitizen | UserViewModel) {
        if (event.fromState === 'void') {
            if (row instanceof SimpleTemporaryCitizen && !row.syncResultMessage) {
                this.isFetchingDetail = true;

                this.getCitizenFail(row.syncCustomerId)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(value => {
                        row.syncResultMessage = value;
                        this.isFetchingDetail = false;
                    });
            }
        }
    }

    private _buildForm() {
        this.inputs = [
            new FormBuilderSelect({
                name: Translations.intervare.creationError.form.typeOfError.name,
                label: Translations.intervare.creationError.form.typeOfError.label,
                required: true,
                options: Object.values(TYPE_OF_ERROR)
                    .filter(x => typeof x === 'string')
                    .map(x => toFormOptions(x, Translations.intervare.creationError.form.typeOfError.options[x])),
                valueChanged: value => {
                    this.filterChanges.next({
                        skip: this.pageIndex * this.pageSize,
                        take: this.pageSize,
                        type: value
                    });
                }
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }

    private getFails(filter: IFilterChange): Observable<SkipTakeResultObjectOfSimpleTemporaryCitizen> {
        let current: Observable<SkipTakeResultObjectOfSimpleTemporaryCitizen>;

        if (filter.type === TYPE_OF_ERROR.CITIZEN) {
            current = this.getCitizenFails(filter.skip, filter.take);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            current = of({ results: [], numFound: 0 } as any);
        }

        return current.pipe(
            tap(fails => {
                if (fails instanceof SkipTakeResultObjectOfSimpleTemporaryCitizen) {
                    this.displayedColumns = this.getColumns(TYPE_OF_ERROR.CITIZEN);
                }
            }),
            takeUntil(this.unsubscribe)
        );
    }

    private getCitizenFails(skip: number, take: number): Observable<SkipTakeResultObjectOfSimpleTemporaryCitizen> {
        return this.failedSyncsService.getCitizenSyncs(skip, take);
    }

    private getCitizenFail(id: number): Observable<string> {
        return this.failedSyncsService.getCitizenSync(id);
    }

    private getUserFail(id: string): Observable<string> {
        return this.failedSyncsService.getUserSync(id);
    }

    private getColumns(value: TYPE_OF_ERROR): IColumn[] {
        const columns = {
            citizen: [
                {
                    columnDef: 'syncCustomerId',
                    header: '#',
                    cell: (row: SimpleTemporaryCitizen) => row.syncCustomerId || ''
                },
                {
                    columnDef: 'lastModifiedDate',
                    header: Translations.intervare.creationError.table.lastModifiedDate,
                    cell: (row: SimpleTemporaryCitizen) => this.date.transform(row.lastModifiedDate, 'medium')
                },
                {
                    columnDef: 'customerNo',
                    header: Translations.intervare.creationError.table.customerNo,
                    cell: (row: SimpleTemporaryCitizen) => row.customerNo || ''
                },
                {
                    columnDef: 'customerSubType',
                    header: Translations.intervare.creationError.table.customerSubType,
                    cell: (row: SimpleTemporaryCitizen) => CustomerSubType[row.customerSubType] || ''
                },
                {
                    columnDef: 'firstName',
                    header: Translations.intervare.creationError.table.firstName,
                    cell: (row: SimpleTemporaryCitizen) => row.firstName || ''
                },
                {
                    columnDef: 'lastName',
                    header: Translations.intervare.creationError.table.lastName,
                    cell: (row: SimpleTemporaryCitizen) => row.lastName || ''
                },
                {
                    columnDef: 'syncResult',
                    header: Translations.intervare.creationError.table.syncResult,
                    cell: (row: SimpleTemporaryCitizen) => row.syncResult || ''
                }
            ]
        };

        return columns[value] as unknown as IColumn[];
    }
}
