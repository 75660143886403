import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import * as Raven from 'raven-js';
import { EMPTY, Observable, throwError } from 'rxjs';

import { catchError, first } from 'rxjs/operators';
import { IProductViewModel } from '../../api/services';
import { ProductService } from '../services/product.service';

export const ProductResolver: ResolveFn<Observable<IProductViewModel>> = (
    route: ActivatedRouteSnapshot
): Observable<IProductViewModel> => {
    const productService: ProductService = inject(ProductService);
    const productId: string | null = route.paramMap.get('product-id');

    if (productId) {
        return productService.getProductDetails(productId).pipe(
            first(),
            catchError(() => {
                return EMPTY;
            })
        );
    } else {
        const message = `Product error: Tried to access product with no id on path "${route.pathFromRoot}"`;
        Raven.captureMessage(message);
        return throwError(message);
    }
};
