import {Component, Input, OnInit} from '@angular/core';

import {Translations} from '../../translations/translations';
import { NgForm, FormsModule } from '@angular/forms';
import {CitizenService} from '../services/citizen.service';
import {DialogService} from '../shared/dialog.service';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'iv-citizen-notes-form',
    template: `
        <div class="citizen-summary__customer-card" *ngIf="isCustomerService">
            <div class="citizen-summary__header-section">
                <h2 class="citizen-summary__header">
                    ${Translations.administration.citizenQuickView.notesSectionTitle}
                </h2>
            </div>

            <button *ngIf="!openNotes" mat-raised-button color="primary" (click)="openNotes = true">
                ${Translations.administration.citizenQuickView.writeNotes}
            </button>

            <form *ngIf="openNotes" #f="ngForm" (ngSubmit)="addNote(f)" novalidate>
                <mat-form-field class="form__element">
                    <textarea
                        matInput
                        matTextareaAutosize
                        matAutosizeMinRows="2"
                        matAutosizeMaxRows="10"
                        name="note"
                        placeholder="${Translations.administration.citizenQuickView.notes}"
                        [(ngModel)]="note"
                    ></textarea>
                </mat-form-field>
                <button type="submit" mat-raised-button color="primary">${Translations.global.btnSave}</button>
            </form>
        </div>
    `,
    standalone: true,
    imports: [NgIf, MatButton, FormsModule, MatFormField, MatInput]
})
export class CitizenNotesFormComponent implements OnInit {

    @Input() isCustomerService: boolean;
    @Input() note: string | undefined;

    openNotes = false;

    constructor(
        private _citizenService: CitizenService,
        private _dialogService: DialogService,
    ) {
    }

    ngOnInit() {
        this.openNotes = this.note === '' ? false : true;
    }

    addNote(form: NgForm): void {
        if (!form.valid) {
            return;
        }

        this._citizenService.updateCitizenNotes(this.note)
            .subscribe(response => this._dialogService.showSnackMessage({message: Translations.administration.citizenQuickView.noteAdded.message}));
    }
}
