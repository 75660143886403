/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { Translations } from '../../translations/translations';
import { CategoryBreadcrumbSegment } from '../services/menu-models';
import { MenuService } from '../services/menu.service';
import { IDefaultPageData } from '../shared/route-data';

export const CategoryResolver: ResolveFn<Observable<IDefaultPageData<any> | string>> = (
    activatedRouteSnapshot: ActivatedRouteSnapshot
): Observable<IDefaultPageData<any> | string> => {
    const menuService: MenuService = inject(MenuService);
    const categories = activatedRouteSnapshot.url.map(x => x.path);

    return menuService.getCategoryTree(categories).pipe(
        map(tree => {
            let mainNode: CategoryBreadcrumbSegment | undefined;
            let subNode: CategoryBreadcrumbSegment | undefined;

            if (tree.breadcrumb.length > 1 && tree.breadcrumb[0].id === Translations.shop.paths.discountCategory.path) {
                tree.breadcrumb.shift();
            }

            if (tree.breadcrumb.length > 1) {
                subNode = tree.breadcrumb.pop();
                mainNode = tree.breadcrumb.pop();
            } else {
                mainNode = tree.breadcrumb.pop();
            }

            const activeNode = subNode || mainNode;
            const pageData: IDefaultPageData<any> = {
                title: activeNode!.name,
                data: {
                    mainNode,
                    subNode,
                    isDiscount: tree.node.isDiscount
                }
            };
            return pageData;
        }),
        catchError(() => {
            const pageData: IDefaultPageData<any> = {
                title: '404',
                data: '404'
            };
            return of(pageData);
        }),
        first()
    );
};
