import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IDetailedCitizenViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { FormBuilderTextInput, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { FormBuilderValidators } from '../form-builder/validators/form-builder-validators';
import { AuthService } from '../services/auth.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { DialogService } from '../shared/dialog.service';
import { UserService } from '../user/user.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { FormBuilderElementComponent } from '../form-builder/form-builder-element.component';
import { CitizenDetailsInfoComponent } from '../citizen/citizen-details-info.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'iv-municipality-edit-password-citizen',
    template: `
        <div class="municipality-edit-password-citizen">
            <ng-container *ngIf="citizen">
                <iv-citizen-details-info [citizen]="citizen"></iv-citizen-details-info>

                <form
                    class="municipality-edit-password-citizen__form alt-theme form"
                    *ngIf="form"
                    [formGroup]="form"
                    (submit)="submit()"
                >
                    <div class="municipality-edit-password-citizen__info">
                        <div class="alert alert-info">${Translations.forms.labels.editPassword.infoCitizen}</div>
                    </div>

                    <iv-form-builder-element
                        [form]="form"
                        [input]="input"
                        *ngFor="let input of inputs"
                    ></iv-form-builder-element>

                    <div class="municipality-edit-password-citizen__btn-container">
                        <button class="municipality-edit-password-citizen__btn" mat-raised-button color="primary">
                            ${Translations.forms.labels.editPassword.submit}
                        </button>
                    </div>
                </form>
            </ng-container>

            <ng-template #unavailable>
                <div class="municipality-edit-password-citizen__status alt-theme">
                    <mat-progress-spinner
                        color="accent"
                        mode="indeterminate"
                        [strokeWidth]="3"
                        [diameter]="60"
                    ></mat-progress-spinner>
                </div>
            </ng-template>
        </div>
    `,
    standalone: true,
    imports: [NgIf, CitizenDetailsInfoComponent, FormsModule, ReactiveFormsModule, NgFor, FormBuilderElementComponent, MatButton, MatProgressSpinner]
})
export class MunicipalityEditPasswordCitizenComponent implements OnInit, OnDestroy {
    inputs: FormBuilderTypes[];
    form: UntypedFormGroup;

    citizen: IDetailedCitizenViewModel;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private formBuilder: FormBuilderService,
        private authService: AuthService,
        private userService: UserService,
        private citizenService: CitizenService,
        private dialogService: DialogService
    ) {
    }

    ngOnInit() {

        const user = this.userService.user$.getValue();
        const customerNumber = user?.impersonatedCitizenQuickInfo?.customerNumber;

        if (customerNumber) {
            this.citizenService
                .getCitizenDetails(customerNumber)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(citizen => (this.citizen = citizen));
        }

        this.inputs = [
            new FormBuilderTextInput({
                name: Translations.forms.labels.editPassword.password.name,
                label: Translations.forms.labels.editPassword.password.label,
                validation: [Validators.minLength(6)],
                valueChanged: () =>
                    this.form &&
                    this.form.controls[
                        Translations.forms.labels.editPassword.confirmPassword.name
                        ].updateValueAndValidity(),
                type: 'password',
                required: true
            }),
            new FormBuilderTextInput({
                name: Translations.forms.labels.editPassword.confirmPassword.name,
                label: Translations.forms.labels.editPassword.confirmPassword.label,
                validation: [
                    FormBuilderValidators.matchFieldValidator(Translations.forms.labels.editPassword.password.name)
                ],
                type: 'password',
                required: true
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    submit() {
        if (this.form.valid) {
            const loginCredentials = {
                userName: this.userService.user$.getValue()!.impersonatedCitizenQuickInfo!.userName!,
                password: this.form.controls[Translations.forms.labels.editPassword.password.name].value
            };

            this.authService
                .putResetPassword(loginCredentials)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    () => {
                        this.dialogService.showSnackMessage({
                            message: Translations.forms.labels.editPassword.passwordChanged
                        });
                        this.form.reset();
                    },
                    (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
                );
        }
    }
}
